import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { StyleProvider, useStyles } from '@exprzn/react-style-lib';
import container from './style';
export const ModalInt = (props) => {
    const { children, close: c } = props;
    const { centrestyle, modalstyle, closestyle } = useStyles('centrestyle', 'modalstyle', 'closestyle');
    const ref = useRef(null);
    useLayoutEffect(() => {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    return _jsx("div", Object.assign({ ref: ref, onKeyDown: (e) => e.keyCode == 27 && c && c() }, { children: createPortal(_jsx("div", Object.assign({ css: modalstyle }, { children: _jsxs("div", Object.assign({ css: centrestyle }, { children: [" ", children, " "] }), void 0) }), void 0), document.body) }), void 0);
};
export const Modal = (props) => _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ModalInt, Object.assign({}, props), void 0) }), void 0);
