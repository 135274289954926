import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, } from "react";
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import container from "./style";
export const SelectClass = (props) => {
    const { select } = useStyles("Select");
    const { selecttext, title, items, onSelectItem, keyval } = props;
    const ref = useRef(null);
    const [defValue, setDefValue] = useState(keyval ? keyval : 0);
    let currentValue = defValue || "0";
    const handleChange = (event) => {
        var _a;
        const value = event.target.value;
        setDefValue(value);
        const index = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.selectedIndex;
        if (index)
            onSelectItem(value, index);
    };
    return (_jsx("div", { children: _jsx("div", Object.assign({ className: "flex flex-col", style: {
                marginTop: "1em",
                border: "0px solid #bababa", padding: "2px 6px 2px 2px", borderRadius: "0.5em", backgroundColor: '#F6F8F9'
            } }, { children: _jsxs("select", Object.assign({ ref: ref, name: title, style: {
                    position: "relative",
                    padding: "0.8em 0.8em",
                    width: "100%",
                    backgroundColor: "#F6F8F9",
                    border: "0px solid #bababa",
                    color: "#111",
                    fontWeight: 600,
                    fontSize: "1em",
                }, onChange: handleChange, value: currentValue, defaultValue: 0 }, { children: [_jsx("option", Object.assign({ value: "0" }, { children: selecttext }), void 0), items &&
                        items.map((item, index) => (_jsx("option", Object.assign({ value: item.value }, { children: item.text }), index)))] }), void 0) }), void 0) }, void 0));
};
export const Select = (props) => (_jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(SelectClass, Object.assign({}, props), void 0) }), void 0));
export const UpdatedSelect = (props) => (_jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(SelectClass, Object.assign({}, props), void 0) }), void 0));
