import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useState } from "react";
import { Col } from "../../../components/Column";
import { Modal } from "../../../components/Modal";
import { Row } from "../../../components/Row";
import { HexColorPicker } from "react-colorful";
import { DragDrop } from "../../../components/DragDrop";
import { useRestSubmit } from "../../../saga/use-rest-submit";
import { toast } from "react-toastify";
import ReactSlidingPane from "react-sliding-pane";
import { VariantTypeAddComponent } from "./variant-type-add-view";
export const VariantTypesComponent = (props) => {
    const { variant_types = [], mode, loadFunction } = props;
    return _jsx("div", { children: variant_types.map((vType, index) => _jsx(VariantTypeComponent, { variantType: vType, index: index, loadFunction: loadFunction }, void 0)) }, void 0);
};
export const VariantTypeComponent = (props) => {
    const { variantType, addVariant, index, loadFunction } = props;
    return _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { children: [variantType.variant_type == "color" ?
                        _jsx(ColorVariantType, { variantType: variantType, loadFunction: loadFunction }, void 0) : null, variantType.variant_type == "string" ?
                        _jsx(StringVariantType, { variantType: variantType, loadFunction: loadFunction }, void 0) : null, variantType.variant_type == "image" ?
                        _jsx(ImageVariantType, { variantType: variantType, loadFunction: loadFunction }, void 0) : null] }, void 0) }, void 0) }, void 0);
};
export const ColorVariantType = (props) => {
    var _a;
    const { variantType, loadFunction } = props;
    const [dialogVisible, showDialog] = useState(false);
    const addVariantSubmit = useRestSubmit(() => {
        toast.success("Variant Added");
        loadFunction();
    }, (error) => {
        toast.error(`${error}`);
    });
    function onSelect(name, color) {
        showDialog(false);
        addVariantSubmit(`/trendutube/v1/product-catalog/variant-types/${variantType.uid}/variants/add`, {
            body: {
                type: "color",
                values: [{ name, value: { color } }]
            }
        });
    }
    return _jsxs("div", Object.assign({ css: { border: "none", padding: "1em", backgroundColor: '#F6F8F9', borderRadius: ".5em", marginTop: 10 } }, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsxs("div", Object.assign({ css: { fontWeight: 600, fontSize: "18px", paddingBottom: 10 } }, { children: [" ", variantType.variant_type_name, _jsx("span", Object.assign({ onClick: () => showDialog(true), css: { cursor: "pointer", fontWeight: 400, color: "#00f", fontSize: "16px", paddingBottom: 10 } }, { children: " ( Add a Variant Color )" }), void 0)] }), void 0), _jsx("div", Object.assign({ css: { display: "flex" } }, { children: (_a = variantType.variant_values) === null || _a === void 0 ? void 0 : _a.map(vt => _jsx(ColorTypeValue, { variantTypeValue: vt }, void 0)) }), void 0)] }, void 0) }, void 0), _jsx(_Fragment, { children: dialogVisible ? _jsx(ColorAdd, { close: showDialog, onSelect: onSelect, color: "#fff" }, void 0) : null }, void 0)] }), void 0);
};
export const StringVariantType = (props) => {
    var _a;
    const { variantType, loadFunction } = props;
    const [dialogVisible, showDialog] = useState(false);
    const addVariantSubmit = useRestSubmit(() => {
        toast.success("Variant Added");
        loadFunction();
    }, (error) => {
        toast.error(`${error}`);
    });
    function onSelect(name) {
        showDialog(false);
        addVariantSubmit(`/trendutube/v1/product-catalog/variant-types/${variantType.uid}/variants/add`, {
            body: {
                type: "string",
                values: [{ name, value: { label: name } }]
            }
        });
    }
    return _jsxs("div", Object.assign({ css: { border: "none", padding: "1em", backgroundColor: '#F6F8F9', borderRadius: ".5em", marginTop: 20 } }, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsxs("div", Object.assign({ css: { fontWeight: 600, fontSize: "18px", paddingBottom: 10 } }, { children: [" ", variantType.variant_type_name, _jsx("span", Object.assign({ onClick: () => showDialog(true), css: { cursor: "pointer", fontWeight: 400, color: "#00f", fontSize: "16px", paddingBottom: 10 } }, { children: " ( Add a Variant String )" }), void 0)] }), void 0), _jsx("div", Object.assign({ css: { display: "flex" } }, { children: (_a = variantType.variant_values) === null || _a === void 0 ? void 0 : _a.map(vt => _jsx(StringTypeValue, { variantTypeValue: vt }, void 0)) }), void 0)] }, void 0) }, void 0), _jsx(_Fragment, { children: dialogVisible ? _jsx(StringAdd, { close: showDialog, onSelect: onSelect, value: "" }, void 0) : null }, void 0)] }), void 0);
};
export const ImageVariantType = (props) => {
    var _a;
    const { variantType, loadFunction } = props;
    const [dialogVisible, showDialog] = useState(false);
    const addVariantSubmit = useRestSubmit(() => {
        toast.success("Variant Added");
        loadFunction();
    }, (error) => {
        toast.error(`${error}`);
    });
    function onSelect(name, images) {
        showDialog(false);
        addVariantSubmit(`/trendutube/v1/product-catalog/variant-types/${variantType.uid}/variants/add`, {
            body: {
                type: "image",
                values: [{ name, value: { uid: images[0].uid } }]
            }
        });
    }
    return _jsxs("div", Object.assign({ css: { border: "none", padding: "1em", backgroundColor: '#F6F8F9', borderRadius: ".5em", marginTop: 10 } }, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsxs("div", Object.assign({ css: { fontWeight: 600, fontSize: "18px", paddingBottom: 10 } }, { children: [" ", variantType.variant_type_name, _jsx("span", Object.assign({ onClick: () => showDialog(true), css: { cursor: "pointer", fontWeight: 400, color: "#00f", fontSize: "16px", paddingBottom: 10 } }, { children: " ( Add a Variant Image )" }), void 0)] }), void 0), _jsx("div", Object.assign({ css: { display: "flex" } }, { children: (_a = variantType.variant_values) === null || _a === void 0 ? void 0 : _a.map(vt => _jsx(ImageTypeValue, { variantTypeValue: vt }, void 0)) }), void 0)] }, void 0) }, void 0), _jsx(_Fragment, { children: dialogVisible ? _jsx(ImageAdd, { close: showDialog, onSelect: onSelect }, void 0) : null }, void 0)] }), void 0);
};
export const ColorTypeValue = (props) => {
    const { variantTypeValue } = props;
    const { color, name } = variantTypeValue;
    return _jsxs("div", Object.assign({ css: { ":not(first-child)": { paddingRight: 18 } } }, { children: [_jsx("div", { css: { width: "28px", height: "28px", borderRadius: "4px", backgroundColor: color } }, void 0), _jsx("div", { children: name }, void 0)] }), void 0);
};
export const ImageTypeValue = (props) => {
    const { variantTypeValue } = props;
    const { color, name, image } = variantTypeValue;
    return _jsxs("div", Object.assign({ css: { ":not(first-child)": { paddingRight: 18 } } }, { children: [_jsx("div", { css: { width: "28px", height: "28px", borderRadius: "4px", backgroundImage: `url(${image.url})` } }, void 0), _jsx("div", { children: name }, void 0)] }), void 0);
};
export const StringTypeValue = (props) => {
    const { variantTypeValue } = props;
    const { name } = variantTypeValue;
    return _jsx("div", Object.assign({ css: { ":not(first-child)": { marginRight: 18 } } }, { children: _jsx("div", Object.assign({ css: { padding: "8px 16px ", borderRadius: "4px", border: "1px solid #dedede", color: "#000", fontWeight: 600 } }, { children: name }), void 0) }), void 0);
};
export const ColorAdd = (props) => {
    const { close, onSelect, color } = props;
    const [selColor, setColor] = useState(color);
    const [name, setName] = useState();
    function selectColor(cl) {
        // onSelect(cl)
        setColor(cl);
    }
    function save() {
        if (!name || name == "")
            alert("Please enter a valid name");
        else if (!selColor)
            alert("Please enter a valid name");
        else
            onSelect(name, selColor);
    }
    return _jsx(Modal, Object.assign({ close: () => close(false) }, { children: _jsxs("div", Object.assign({ css: { backgroundColor: "#fff", padding: 40, borderRadius: 6 } }, { children: [_jsx(HexColorPicker, { color: color, onChange: selectColor }, void 0), _jsx("div", { css: { marginTop: 10, height: "24px", borderRadius: "4px", backgroundColor: selColor } }, void 0), _jsx("div", Object.assign({ css: { marginTop: 10 } }, { children: "Color Name" }), void 0), _jsx("div", { children: _jsx("input", { css: { marginTop: 10, width: "100%", border: '1px solid #045998', backgroundColor: '#F6F8F9', padding: '5px' }, value: name, onChange: (e) => setName(e.target.value) }, void 0) }, void 0), _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "end", marginTop: 16 } }, { children: [_jsx("button", Object.assign({ type: "button", onClick: () => save(), css: { padding: 6, backgroundColor: '#045998', color: 'white', border: 'none' } }, { children: "Save" }), void 0), _jsx("button", Object.assign({ type: "button", onClick: () => close(false), css: { padding: 6, marginLeft: 10, backgroundColor: 'white', color: '#045998', border: '1px solid #045998' } }, { children: "Cancel" }), void 0)] }), void 0)] }), void 0) }), void 0);
};
export const StringAdd = (props) => {
    const { close, onSelect, value } = props;
    const [name, setName] = useState();
    function save() {
        if (!name || name == "")
            alert("Please enter a valid name");
        else
            onSelect(name);
    }
    return _jsx(Modal, Object.assign({ close: () => close(false) }, { children: _jsxs("div", Object.assign({ css: { backgroundColor: "#fff", padding: 40, borderRadius: 6 } }, { children: [_jsx("div", { children: "Enter a value (eg. M or XL for size) " }, void 0), _jsx("div", { children: _jsx("input", { css: { marginTop: 10, width: "100%", border: '1px solid #045998', backgroundColor: '#F6F8F9', padding: '5px' }, value: name, onChange: (e) => setName(e.target.value) }, void 0) }, void 0), _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "end", marginTop: 16 } }, { children: [_jsx("button", Object.assign({ type: "button", onClick: () => save(), css: { padding: 6, backgroundColor: '#045998', color: 'white', border: 'none' } }, { children: "Save" }), void 0), _jsx("button", Object.assign({ type: "button", onClick: () => close(false), css: { padding: 6, marginLeft: 10, backgroundColor: 'white', color: '#045998', border: '1px solid #045998' } }, { children: "Cancel" }), void 0)] }), void 0)] }), void 0) }), void 0);
};
export const ImageAdd = (props) => {
    const { close, onSelect } = props;
    const [name, setName] = useState();
    const [images, setImages] = useState([]);
    function save() {
        if (!name || name == "")
            alert("Please enter a valid name");
        else if (images.length <= 0)
            alert("Upload an image");
        else
            onSelect(name, images);
    }
    return _jsx(Modal, Object.assign({ close: () => close(false) }, { children: _jsxs("div", Object.assign({ css: { backgroundColor: "#fff", padding: 40, borderRadius: 6, width: 800, height: 510 } }, { children: [_jsx(DragDrop, { onImagesUpload: (images) => { setImages(images); }, entity: "variant" }, void 0), _jsx("div", { children: "Enter a value to desccribe the variant " }, void 0), _jsx("div", { children: _jsx("input", { css: { marginTop: 10, width: "100%", border: '1px solid #045998', backgroundColor: '#F6F8F9', padding: '5px' }, value: name, onChange: (e) => setName(e.target.value) }, void 0) }, void 0), _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "end", marginTop: 16 } }, { children: [_jsx("button", Object.assign({ type: "button", onClick: () => save(), css: { padding: 6, backgroundColor: '#045998', color: 'white', border: 'none' } }, { children: "Save" }), void 0), _jsx("button", Object.assign({ type: "button", onClick: () => close(false), css: { padding: 6, marginLeft: 10, backgroundColor: 'white', color: '#045998', border: '1px solid #045998' } }, { children: "Cancel" }), void 0)] }), void 0)] }), void 0) }), void 0);
};
export const VariantTypeAdd = (props) => {
    const { isOpen, onClose, addVaraintType } = props;
    return _jsx("div", { children: isOpen ?
            _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isOpen, title: "Add Variant Type", subtitle: "", width: "20%", onRequestClose: () => {
                    onClose(false);
                } }, { children: _jsx("div", { children: _jsx(VariantTypeAddComponent, { addVariantType: addVaraintType }, void 0) }, void 0) }), void 0) : null }, void 0);
};
