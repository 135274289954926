import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyleProvider, useStyles, } from "@exprzn/react-style-lib";
import { Col } from "../../components/Column";
import { Row } from "../../components/Row";
import { Form, Field } from "../../components/Form";
import { SagaProvider } from "../../saga/saga-context";
import { useSaga } from "../../saga/use-saga";
import { useAuth } from "../../use-auth";
import container from "./style";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { useSubmit } from "../../saga/use-submit";
import { InputEx } from "../../components/Form/fields-extended";
import { toast } from "react-toastify";
import { global_org_uid } from "../../config";
const ConfigInternal = (props) => {
    const { productstyle, button, btnstyle, xbtnstyle, rowstyle, titlestyle, inputLatestStyle, disabledInputStyle } = useStyles("productstyle", "button", "btnstyle", "xbtnstyle", "rowstyle", "titlestyle", "inputLatestStyle", "disabledInputStyle");
    const { submit, error, status } = useSubmit();
    const { submit: deliveryModeSumbit, error: deliveryModeError, status: deliveryModeStatus } = useSubmit();
    const [action, setAction] = useState('');
    const [deliveryModesAdd, setdeliveryModeAdd] = useState("none");
    const [deliveryModeEdit, setdeliveryModeEdit] = useState({ display: "none", uid: null });
    const [dispbaseAdd, setdispbaseAdd] = useState("none");
    const [dispdistanceAdd, setdispdistanceAdd] = useState("none");
    const [dispshippingAdd, setdispshippingAdd] = useState("none");
    const [dispdistanceEdit, setdispdistanceEdit] = useState("none");
    const [dispshippingEdit, setdispshippingEdit] = useState("none");
    const [dispbaseEdit, setbaseEdit] = useState("none");
    const [shipuid, setShipuid] = useState(null);
    const [distanceuid, setDistanceuid] = useState(null);
    const [baseuid, setBaseuid] = useState(null);
    const [deliveryOption, setDeliveryOption] = useState({ type: '', number_of_days_for_delivery: 0, free_delivery_opt_out: false, minimum_amount_of_products_for_free_delivery: 0, delivery_mode_uid: '', shipment_rate: '' });
    const [deliveryModeuid, setDeliveryModeuid] = useState(null);
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { data, meta, loadUrl } = useSaga(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/config/list`, { headers });
    const { data: deliveryModes, loadUrl: loadDeliveryModeUrl } = useSaga(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/delivery-mode/list`, { headers });
    const { data: deliveryOptionData, loadUrl: loadDeliveryOptionUrl } = useSaga(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/delivery-option/get`, { headers });
    useEffect(() => {
        console.log("Config Data:", data);
    }, [data]);
    useEffect(() => {
        if (deliveryModeStatus === "ok") {
            toast.success(action);
            setdeliveryModeAdd('none');
            setdeliveryModeEdit({ display: 'none', uid: null });
            loadDeliveryModeUrl(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/delivery-mode/list`);
        }
    }, [deliveryModeStatus]);
    const [type, setType] = useState((deliveryOptionData === null || deliveryOptionData === void 0 ? void 0 : deliveryOptionData.type) || "");
    useEffect(() => {
        if (deliveryOptionData) {
            setDeliveryOption(deliveryOptionData);
            loadDeliveryModeUrl(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/delivery-mode/list`);
            setType(deliveryOptionData.type);
        }
        else {
            setType("");
        }
    }, [deliveryOptionData]);
    useEffect(() => {
        if (deliveryModeError && deliveryModeError.message)
            toast.error(`Error:${deliveryModeError.message}`);
    }, [deliveryModeError]);
    /*Display States*/
    /*Delete Code*/
    const deleteShipping = (uid) => {
        submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/shipping-rate/${uid}/delete`, { method: "DElETE" });
    };
    const deleteDistance = (uid) => {
        submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/base-distance/${uid}/delete`, { method: "DElETE" });
    };
    const deleteBase = (uid) => {
        submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/base-rate/${uid}/delete`, { method: "DElETE" });
    };
    const deleteDeliveryMode = (uid) => {
        deliveryModeSumbit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/delivery-mode/${uid}/delete`, { method: "DElETE" });
        setAction("Delivery Mode Deleted");
    };
    /*FORM SUBMIT*/
    const submitBaseHandler = (formData) => {
        submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/base-rate/add`, {
            body: Object.assign({}, formData.add_base_rates),
        });
    };
    const submitDeliveryModeHandler = (formData) => {
        deliveryModeSumbit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/delivery-mode/add`, {
            body: Object.assign({}, formData.add_delivery_mode),
        });
        setAction("Delivery Mode Created");
    };
    const submitDistanceHandler = (formData) => {
        submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/base-distance/add`, {
            body: Object.assign({}, formData.add_distance),
        });
    };
    const submitShippingHandler = (formData) => {
        submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/shipping-rate/add`, {
            body: Object.assign({}, formData.add_shipping),
        });
    };
    const submitEditShippingHandler = (formData) => {
        submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/shipping-rate/${shipuid}/update`, {
            body: Object.assign({}, formData.edit_shipping),
        });
    };
    const submitEditDistanceHandler = (formData) => {
        submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/base-distance/${distanceuid}/update`, {
            body: Object.assign({}, formData.edit_distance),
        });
    };
    const submitEditBaseHandler = (formData) => {
        submit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/base-rate/${baseuid}/update`, {
            body: Object.assign({}, formData.edit_distance),
        });
    };
    const submitEditDeliveryModeHandler = (formData) => {
        deliveryModeSumbit(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/delivery-mode/${deliveryModeuid}/update`, {
            body: Object.assign({}, formData.edit_delivery_mode),
        });
        setAction("Delivery Mode Updated");
    };
    return (_jsx("div", { children: _jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: 12 }, { children: _jsx("h1", { children: "Config Settings" }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: 6 }, { children: _jsxs("div", Object.assign({ css: productstyle }, { children: [_jsx("h1", { children: "Delivery Modes" }, void 0), _jsx("div", Object.assign({ style: { textAlign: "right", marginBottom: "5px" } }, { children: _jsx("button", Object.assign({ type: "button", css: button, onClick: () => {
                                        setdeliveryModeAdd("block");
                                    } }, { children: "Add Delivery Modes" }), void 0) }), void 0), _jsx("div", Object.assign({ style: {
                                    border: "1px dotted",
                                    padding: "5px",
                                    marginBottom: "25px",
                                    display: deliveryModesAdd,
                                } }, { children: _jsxs(Form, Object.assign({ name: "add_delivery_mode", onSubmit: submitDeliveryModeHandler }, { children: [_jsx("div", Object.assign({ style: { textAlign: "right" } }, { children: _jsx("span", Object.assign({ style: { cursor: "pointer" }, onClick: () => setdeliveryModeAdd("none") }, { children: _jsx(X, {}, void 0) }), void 0) }), void 0), _jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: 12 }, { children: _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "Delivery Type" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "delivery_type" }, { children: _jsx(InputEx, {}, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Col, Object.assign({ cols: 12 }, { children: _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "Delivery Days" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "number_of_days_for_delivery" }, { children: _jsx(InputEx, {}, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Col, Object.assign({ cols: 12 }, { children: _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "Delivery Code" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "delivery_code" }, { children: _jsx(InputEx, {}, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Col, Object.assign({ cols: 12 }, { children: _jsx("div", Object.assign({ style: { textAlign: "right" } }, { children: _jsx("input", { type: "submit", value: "Submit", css: button }, void 0) }), void 0) }), void 0)] }, void 0)] }), void 0) }), void 0), deliveryModes
                                ? deliveryModes.map((item, index) => _jsxs("div", Object.assign({ style: {
                                        padding: "4px",
                                        marginBottom: "8px",
                                        border: "1px dotted",
                                    } }, { children: [_jsxs("div", Object.assign({ style: { textAlign: "right" } }, { children: [_jsx("button", Object.assign({ type: "button", css: button, style: { marginRight: "8px" }, onClick: () => {
                                                        setdeliveryModeEdit({ display: "block", uid: item.uid });
                                                    } }, { children: "Edit" }), void 0), _jsx("button", Object.assign({ type: "button", css: button, style: { marginRight: "8px" }, onClick: () => deleteDeliveryMode(item.uid) }, { children: "Delete" }), void 0)] }), void 0), _jsxs("div", { children: ["Delivery Type: ", item.delivery_type] }, void 0), _jsxs("div", { children: ["Delivery Days: ", item.number_of_days_for_delivery] }, void 0), _jsxs("div", { children: ["Delivery Code: ", item.delivery_code] }, void 0), _jsx("div", Object.assign({ style: {
                                                border: "1px dotted",
                                                padding: "5px",
                                                marginBottom: "25px",
                                                marginTop: "10px",
                                                display: item.uid === deliveryModeEdit.uid ? deliveryModeEdit.display : "none",
                                            } }, { children: _jsxs(Form, Object.assign({ name: "edit_delivery_mode", onSubmit: submitEditDeliveryModeHandler }, { children: [_jsx("div", Object.assign({ style: { textAlign: "right" } }, { children: _jsx("span", Object.assign({ style: { cursor: "pointer" }, onClick: () => setdeliveryModeEdit({ display: "none", uid: item.uid }) }, { children: _jsx(X, {}, void 0) }), void 0) }), void 0), _jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: 12 }, { children: _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "Delivery Type" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "delivery_type", value: item.delivery_type, onChange: () => {
                                                                                        setDeliveryModeuid(item.uid);
                                                                                    } }, { children: _jsx(InputEx, {}, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Col, Object.assign({ cols: 12 }, { children: _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "Delivery Days" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "number_of_days_for_delivery", value: item.number_of_days_for_delivery, onChange: () => {
                                                                                        setDeliveryModeuid(item.uid);
                                                                                    } }, { children: _jsx(InputEx, {}, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Col, Object.assign({ cols: 12 }, { children: _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "Delivery Code" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "delivery_code", value: item.delivery_code, onChange: () => {
                                                                                        setDeliveryModeuid(item.uid);
                                                                                    } }, { children: _jsx(InputEx, {}, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0), _jsx(Col, Object.assign({ cols: 12 }, { children: _jsx("div", Object.assign({ style: { textAlign: "right" } }, { children: _jsx("input", { type: "submit", value: "Submit", css: button }, void 0) }), void 0) }), void 0)] }, void 0)] }), void 0) }), void 0)] }), void 0))
                                : "No Data"] }), void 0) }), void 0)] }, void 0) }, void 0));
};
export const Config = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ConfigInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
