import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import SlidingPane from "react-sliding-pane";
import { Col } from "../../../components/Column";
import { Field, Form } from "../../../components/Form";
import { HiddenInputEx } from "../../../components/Form/fields-extended";
import { Pagination } from "../../../components/Pagination";
import { Row } from "../../../components/Row";
import { SearchToolBar } from "../../../components/Search";
import { usePagination } from "../../../saga/use-pagination";
import { CardNano } from "../../orders/card-nano";
const StoreComponent = (props) => {
    const { store, onAssign } = props || {};
    const { uid, store_name, address, distance } = store || {};
    const { storestyle, action_btn_ms, row_basket } = useStyles("storestyle", "action_btn_ms", "row_basket");
    const [addr] = address || [];
    const { address_line1, address_line2, city, pin_code, landmark, location } = addr || {};
    const { coordinates } = location || {};
    const line1 = Object.values({ address_line1, address_line2, city }).join(", ");
    const line2 = Object.values({ city, pin_code }).join(", ");
    const line3 = Object.values({ landmark }).join(", ");
    function onSubmit(formdata) {
        const { store_uid } = formdata.data || {};
        if (store_uid)
            onAssign(store_uid);
    }
    return _jsx("div", Object.assign({ css: { marginTop: 20 } }, { children: _jsx(Form, Object.assign({ name: "data", onSubmit: onSubmit }, { children: _jsx(Row, { children: _jsxs(Col, { children: [_jsx(Field, Object.assign({ name: "store_uid", value: uid }, { children: _jsx(HiddenInputEx, {}, void 0) }), void 0), _jsx("div", { children: _jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { value: store_name, title: "Store Name" }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { value: uid || "", title: "uid" }, void 0) }, void 0)] }, void 0) }, void 0), _jsx("div", { children: _jsxs(Row, { children: [_jsxs(Col, { children: [" ", _jsx(CardNano, { value: `${distance || 0.0}km`, title: "distance" }, void 0)] }, void 0), _jsxs(Col, { children: [" ", _jsx(CardNano, { value: `${coordinates || "NA"}`, title: "Coordinates" }, void 0)] }, void 0)] }, void 0) }, void 0), _jsxs("div", Object.assign({ style: { marginTop: 10 } }, { children: [_jsx("div", { children: "Address" }, void 0), _jsxs("div", Object.assign({ css: row_basket }, { children: [_jsx("div", { children: line1 }, void 0), _jsx("div", { children: line2 }, void 0)] }), void 0)] }), void 0), _jsx("div", Object.assign({ css: { display: "flex", alignContent: "flex-end", justifyContent: "flex-end", marginTop: "12px" } }, { children: _jsx("button", Object.assign({ css: action_btn_ms }, { children: "Assign" }), void 0) }), void 0)] }, void 0) }, void 0) }), void 0) }), void 0);
};
export const StoreAssignment = (props) => {
    const { isOpen, close, onAssign, coordinates } = props;
    const queryParams = { coordinates: coordinates.join(","), search_radius: 30000, page_size: 10 };
    const { data, loadPage, paging } = usePagination("/trendutube/v1/admin-seller-management/stores/search", { queryParams });
    const stores = data;
    const filters = [
        { title: "Store Name", value: "store_name", filter: true },
        { title: "Business Name", value: "business_name", filter: false },
        { title: "Pin Code", value: "pin_code", filter: false },
        { title: "City", value: "city", filter: false },
    ];
    function onSearchChange(value, filters, sortBy) {
        if (value && value != "") {
            const params = {};
            filters.filter(f => f.filter).forEach(filter => {
                Object.defineProperty(params, filter.value, {
                    value, configurable: true,
                    enumerable: true
                });
            });
            // setParams(params)
            loadPage(0, params);
        }
        else {
            loadPage(0, {});
        }
    }
    return _jsx(SlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isOpen, title: "Assign Store to item", subtitle: "pick one store", width: "38%", onRequestClose: () => {
            // triggered on "<" on left top click or on outside click
            close();
        } }, { children: _jsxs("div", { children: [_jsx(SearchToolBar, { filters: filters, onChange: onSearchChange }, void 0), _jsx("div", Object.assign({ css: { marginTop: 16 } }, { children: _jsx(Pagination, Object.assign({}, { loadPage, paging }), void 0) }), void 0), data && stores.map(store => _jsx(StoreComponent, Object.assign({}, { store, onAssign }), void 0))] }, void 0) }), void 0);
};
