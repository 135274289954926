import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyleProvider, } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../../saga/saga-context";
import container from "./style";
import { useEffect, useState } from "react";
import { useSubmit } from "../../../saga/use-submit";
import { usePagination } from "../../../saga/use-pagination";
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import '../index.css';
import CustomPagination from "../../Shipments/custompagination";
import { localDatetime } from "../../../utils";
import { OrderStatusView } from "../status-view";
const ConfigInternal = (props) => {
    const { shipping_type } = props;
    const { submit, error, status } = useSubmit();
    const [shipmentstatus, setShipmentStatus] = useState("courier-not-assigned");
    const queryParams = { page_size: 10 };
    const { data: orders, loadPage, paging, loadUrl } = usePagination("/trendutube/v1/admin-order-processing/orders/details", { queryParams });
    console.log("called this page");
    const handlePageChange = (newPage) => {
        loadPage(newPage);
    };
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const columns = [
        {
            field: 'order_date', headerName: 'Date', flex: 0.2, headerClassName: 'custom-header', renderCell: (params) => (_jsx(_Fragment, { children: localDatetime(params.row.order_date) }, void 0))
        },
        {
            field: 'order_id', headerName: 'Order Id', flex: 0.1, headerClassName: 'custom-header',
            renderCell: (params) => (_jsxs(NavLink, Object.assign({ to: `/dashboard/orders/order-full-info/${params.row.id}` }, { children: [" ", params.row.order_id, " "] }), void 0)),
        },
        {
            field: 'status', headerName: 'Status', flex: 0.2, headerClassName: 'custom-header', renderCell: (params) => (OrderStatusView(params.row.status))
        },
        { field: 'payment_mode', headerName: 'Payment Mode', flex: 0.1, headerClassName: 'custom-header' },
        { field: 'net_amount', headerName: 'Net Amount', flex: 0.1, headerClassName: 'custom-header' },
        {
            field: '', headerName: 'Buyer Info', flex: 0.1, headerClassName: 'custom-header',
            renderCell: (params) => {
                var _a, _b, _c, _d;
                return (_jsx("div", { children: _jsx("div", { children: _jsx("b", { children: `${((_b = (_a = params.row.buyer) === null || _a === void 0 ? void 0 : _a.personal_profile) === null || _b === void 0 ? void 0 : _b.first_name) || ""} ${((_d = (_c = params.row.buyer) === null || _c === void 0 ? void 0 : _c.personal_profile) === null || _d === void 0 ? void 0 : _d.last_name) || ""}` }, void 0) }, void 0) }, void 0));
            },
        },
    ];
    return (_jsx("div", { children: orders ? _jsx("div", Object.assign({ style: { width: '100%' } }, { children: _jsxs(Box, Object.assign({ sx: { width: "100%", backgroundColor: "white", padding: "20px" } }, { children: [_jsx(DataGrid, { getRowId: (row) => row.id, rows: orders, columns: columns, initialState: {
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }, hideFooterPagination: true, checkboxSelection: false, isRowSelectable: () => false }, void 0), _jsx(CustomPagination, { page: paging === null || paging === void 0 ? void 0 : paging.page, totalPages: paging === null || paging === void 0 ? void 0 : paging.total_pages, hasNext: (paging === null || paging === void 0 ? void 0 : paging.total_pages) > Number(paging === null || paging === void 0 ? void 0 : paging.page), onPageChange: handlePageChange }, void 0)] }), void 0) }), void 0) : null }, void 0));
};
export const OrderInfoList = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ConfigInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
