import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyleProvider, } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../../saga/saga-context";
import container from "./style";
import { useEffect, useState } from "react";
import { useSubmit } from "../../../saga/use-submit";
import { usePagination } from "../../../saga/use-pagination";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, FormControl, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import CustomPagination from "../custompagination";
import '../index.css';
const ConfigInternal = (props) => {
    const { submit, error, status } = useSubmit();
    const [shipmentstatus, setShipmentStatus] = useState("courier-not-assigned");
    const queryParams = { page_size: 10, status: shipmentstatus };
    const { data: shipments, loadPage, paging, loadUrl } = usePagination("/trendutube/v1/admin-order-processing/shipments/inward-shipments", { queryParams });
    const handlePageChange = (newPage) => {
        loadPage(newPage);
    };
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const columns = [
        { field: 'id', headerName: 'Shipment Id', flex: 0.1, headerClassName: 'custom-header' },
        { field: 'status', headerName: 'Status', flex: 0.1, headerClassName: 'custom-header' },
        { field: 'seller_name', headerName: 'Seller Name', flex: 0.1, headerClassName: 'custom-header' },
        { field: 'store_name', headerName: 'Store Name', flex: 0.1, headerClassName: 'custom-header' },
        {
            field: 'edit',
            headerName: '',
            flex: 0.1,
            renderCell: (params) => (_jsx(Button, Object.assign({ variant: "outlined" }, { children: _jsx(NavLink, Object.assign({ to: `/dashboard/inward-shipments/${params.row.id}` }, { children: " Edit/View " }), void 0) }), void 0)), headerClassName: 'custom-header'
        },
    ];
    const handleChange = (event) => {
        setShipmentStatus(event.target.value);
        const queryParams = { page_size: 10, status: event.target.value };
        loadUrl(`/trendutube/v1/admin-order-processing/shipments/inward-shipments`, 0, queryParams);
    };
    const handlePageSizeChange = (newSize) => { };
    return (_jsx("div", { children: shipments ?
            _jsx("div", Object.assign({ style: { width: '100%' } }, { children: _jsxs(Box, Object.assign({ sx: { width: "100%", backgroundColor: "white", padding: "20px" } }, { children: [_jsx(Box, Object.assign({ sx: { width: "100%", display: 'flex', justifyContent: 'flex-end', minWidth: 120, marginBottom: '10px' } }, { children: _jsx(FormControl, { children: _jsxs(Select, Object.assign({ labelId: "demo-simple-select-label", id: "demo-simple-select", value: shipmentstatus, onChange: handleChange }, { children: [_jsx(MenuItem, Object.assign({ value: "courier-not-assigned" }, { children: "Courier Not Assigned" }), void 0), _jsx(MenuItem, Object.assign({ value: "Pickup Scheduled" }, { children: "Pickup Scheduled" }), void 0), _jsx(MenuItem, Object.assign({ value: "delivery-completed" }, { children: "Delivery Completed" }), void 0), _jsx(MenuItem, Object.assign({ value: "delivery-verified" }, { children: "Delivery Verified" }), void 0)] }), void 0) }, void 0) }), void 0), _jsx(DataGrid, { getRowId: (row) => row.id, rows: shipments, columns: columns, initialState: {
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }, hideFooterPagination: true, checkboxSelection: false, isRowSelectable: () => false }, void 0), _jsx(CustomPagination, { page: paging === null || paging === void 0 ? void 0 : paging.page, totalPages: paging === null || paging === void 0 ? void 0 : paging.total_pages, hasNext: (paging === null || paging === void 0 ? void 0 : paging.total_pages) > Number(paging === null || paging === void 0 ? void 0 : paging.page), onPageChange: handlePageChange }, void 0)] }), void 0) }), void 0) : null }, void 0));
};
export const InwardShipments = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ConfigInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
