import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react  */
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import { NavLink } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col } from "../../../components/Column";
import { Confirm } from "../../../components/Confirm";
import { Field, Form } from "../../../components/Form";
import { InputEx } from "../../../components/Form/fields-extended";
import { useProgressBar } from "../../../components/ProgressBar";
import { Row } from "../../../components/Row";
import { SagaProvider } from "../../../saga/saga-context";
import { useRestSubmit } from "../../../saga/use-rest-submit";
import { useSaga } from "../../../saga/use-saga";
import { useSubmit } from "../../../saga/use-submit";
import { useAuth } from "../../../use-auth";
import { CardNano } from "../../orders/card-nano";
import { AddressComponent } from "../../views/address";
import container from "./style";
export const FranchiseListInternal = () => {
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { categoryid, categoryname, category, categorydescription, menutextstyle, namestyle, titlestyle, textstyle, ValStyle, franchisebox, button, } = useStyles("categorydesc", "category", "categoryname", "namestyle", "categorydescription", "menutextstyle", "titlestyle", "textstyle", "ValStyle", "franchisebox", "button");
    const { submit, status, error } = useSubmit();
    const { data, loadUrl, isFetching } = useSaga("/trendutube/v1/franchisee-management/franchisees/list", { headers });
    const [disp, setDisp] = useState("none");
    const confirmRef = useRef(null);
    const { franchise_list } = data || {};
    useEffect(() => {
        console.log("DATA++", data);
    }, [data]);
    const loading = useProgressBar();
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    const submitHandler = (formData) => {
        console.log(formData);
        const address = {
            address_line1: formData.add_franchise.address_line1
                ? formData.add_franchise.address_line1
                : "",
            address_line2: formData.add_franchise.address_line2
                ? formData.add_franchise.address_line2
                : "",
            city: formData.add_franchise.city ? formData.add_franchise.city : "",
            pin_code: formData.add_franchise.pin_code
                ? formData.add_franchise.pin_code
                : "",
            landmark: formData.add_franchise.landmark
                ? formData.add_franchise.landmark
                : "",
            location: {
                type: "Point",
                coordinates: [
                    formData.add_franchise.latitude
                        ? parseFloat(formData.add_franchise.latitude)
                        : "",
                    formData.add_franchise.longitude
                        ? parseFloat(formData.add_franchise.longitude)
                        : "",
                ],
            },
        };
        const req = {
            franchisee_name: formData.add_franchise.franchisee_name
                ? formData.add_franchise.franchisee_name
                : "",
            franchisee_code: formData.add_franchise.franchisee_code
                ? formData.add_franchise.franchisee_code
                : "",
            franchisee_location: formData.add_franchise.franchisee_location
                ? formData.add_franchise.franchisee_location
                : "",
            address,
        };
        submit(`/trendutube/v1/franchisee-management/franchisees/add`, {
            body: Object.assign({}, req),
        });
    };
    const submitEditHandler = (formData) => {
        console.log(formData);
        const address = {
            address_line1: formData.edit_franchise.address_line1
                ? formData.edit_franchise.address_line1
                : "",
            address_line2: formData.edit_franchise.address_line2
                ? formData.edit_franchise.address_line2
                : "",
            city: formData.edit_franchise.city ? formData.edit_franchise.city : "",
            pin_code: formData.edit_franchise.pin_code
                ? formData.edit_franchise.pin_code
                : "",
            landmark: formData.edit_franchise.landmark
                ? formData.edit_franchise.landmark
                : "",
            location: {
                type: "Point",
                coordinates: [
                    formData.edit_franchise.latitude
                        ? parseFloat(formData.edit_franchise.latitude)
                        : "",
                    formData.edit_franchise.longitude
                        ? parseFloat(formData.edit_franchise.longitude)
                        : "",
                ],
            },
        };
        const req = {
            franchisee_name: formData.edit_franchise.franchisee_name
                ? formData.edit_franchise.franchisee_name
                : "",
            franchisee_code: formData.edit_franchise.franchisee_code
                ? formData.edit_franchise.franchisee_code
                : "",
            franchisee_location: formData.edit_franchise.franchisee_location
                ? formData.edit_franchise.franchisee_location
                : "",
            address,
        };
        submit(`/trendutube/v1/franchisee-management/franchisees/${editView}/update`, {
            body: Object.assign({}, req),
        });
    };
    const divRef = useRef(null);
    const formRef = useRef();
    useEffect(() => {
        if (error && divRef.current) {
            divRef.current.innerText = error;
        }
    }, [error]);
    useEffect(() => {
        if (status && status == "ok" && divRef.current && formRef.current) {
            divRef.current.innerText = "";
            toast.success("Franchise added successfully!");
            setDisp("none");
            loadUrl("/trendutube/v1/franchisee-management/franchisees/list");
        }
    }, [status]);
    const [editDisplay, setEditDisplay] = useState(false);
    const [editView, setEditView] = useState(null);
    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });
    const deleteSubmit = useRestSubmit(() => {
        toast.success("Franchisee Deleted");
    }, (e) => {
        toast.error(`${e}`);
    });
    const deleteFranchise = (franchiseeUid) => {
        deleteSubmit(`/trendutube/v1/franchisee-management/franchisees/${franchiseeUid}/delete`, { method: "DELETE" });
    };
    return (_jsxs(Row, { children: [_jsx(Col, Object.assign({ css: category, cols: 12 }, { children: _jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", Object.assign({ style: {
                                    borderRadius: ".6em",
                                    textAlign: "right",
                                    backgroundColor: "#fff",
                                    margin: "5px",
                                    padding: "12px",
                                    // boxShadow:
                                    // " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                }, onClick: () => setDisp("block") }, { children: _jsx("button", Object.assign({ css: button }, { children: "Add New Franchise" }), void 0) }), void 0), disp == "none" ? null : (_jsxs("div", Object.assign({ style: {
                                    borderRadius: ".6em",
                                    backgroundColor: "#fff",
                                    margin: "5px",
                                    padding: "12px",
                                    //display: disp,
                                    boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                } }, { children: [_jsx("div", Object.assign({ style: { textAlign: "right" } }, { children: _jsx("span", Object.assign({ style: { cursor: "pointer" }, onClick: () => setDisp("none") }, { children: _jsx(X, {}, void 0) }), void 0) }), void 0), _jsx("h1", { children: "Franchise Details" }, void 0), _jsx("hr", { style: {
                                            border: "0",
                                            height: "0",
                                            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                            borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                            marginBottom: "5px",
                                        } }, void 0), _jsx("div", Object.assign({ ref: divRef, style: { padding: "25px", color: "#e62222" } }, { children: _jsx("p", {}, void 0) }), void 0), _jsx(Form, Object.assign({ name: "add_franchise", onSubmit: submitHandler, ref: formRef }, { children: _jsxs(StyleProvider, Object.assign({ value: { container } }, { children: [_jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Franchisee Name" }), void 0), _jsx(Field, Object.assign({ name: "franchisee_name" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Franchisee Location" }), void 0), _jsx(Field, Object.assign({ name: "franchisee_location" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 6 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Franchisee Code" }), void 0), _jsx(Field, Object.assign({ name: "franchisee_code" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0)] }, void 0), _jsx("h1", { children: "Address" }, void 0), _jsx("hr", { style: {
                                                        border: "0",
                                                        height: "0",
                                                        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                                        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                                        marginBottom: "5px",
                                                    } }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Address Line 1" }), void 0), _jsx(Field, Object.assign({ name: "address_line1" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Address Line 2" }), void 0), _jsx(Field, Object.assign({ name: "address_line2" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "City" }), void 0), _jsx(Field, Object.assign({ name: "city" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Pincode" }), void 0), _jsx(Field, Object.assign({ name: "pin_code" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Landmark" }), void 0), _jsx(Field, Object.assign({ name: "landmark" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0)] }, void 0), _jsx("h1", { children: "Location" }, void 0), _jsx("hr", { style: {
                                                        border: "0",
                                                        height: "0",
                                                        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                                        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                                        marginBottom: "5px",
                                                    } }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Latitude" }), void 0), _jsx(Field, Object.assign({ name: "latitude" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 9 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Longitude" }), void 0), _jsx(Field, Object.assign({ name: "longitude" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0)] }, void 0), _jsx(Row, { children: _jsx(Col, Object.assign({ cols: 12 }, { children: _jsx("div", Object.assign({ style: { marginTop: "10px", textAlign: "right" } }, { children: _jsx("input", { type: "submit", value: "Submit", css: button }, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0)] }), void 0))] }, void 0) }, void 0) }), void 0), data
                ? // JSON.stringify(items)
                    data.franchise_list &&
                        data.franchise_list.map((item, index) => (_jsxs(Col, Object.assign({ css: category, cols: 12 }, { children: [_jsxs("div", Object.assign({ style: {
                                        borderRadius: ".6em",
                                        backgroundColor: "#fff",
                                        margin: "5px",
                                        padding: "12px",
                                        // boxShadow:
                                        // " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                    } }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ style: { textAlign: "right" } }, { children: [_jsx(NavLink, Object.assign({ to: `distribution/${item.uid}` }, { children: _jsx("button", Object.assign({ style: {
                                                                    padding: "6px 20px",
                                                                    border: "0",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8b611",
                                                                    color: "#fff",
                                                                    boxShadow: "1px 1px 10px #f8e1aa",
                                                                } }, { children: "View Distribution Center" }), void 0) }), void 0), _jsx("button", Object.assign({ onClick: () => { var _a; return (_a = confirmRef.current) === null || _a === void 0 ? void 0 : _a.show(true); }, style: {
                                                                marginLeft: "5px",
                                                                padding: "6px 10px",
                                                                border: "0",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8b611",
                                                                color: "#fff",
                                                                boxShadow: "1px 1px 10px #f8e1aa",
                                                            } }, { children: "Delete" }), void 0), _jsx("button", Object.assign({ onClick: () => {
                                                                setEditView(item.uid);
                                                                setEditDisplay(true);
                                                            }, style: {
                                                                marginLeft: "5px",
                                                                padding: "6px 10px",
                                                                border: "0",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8b611",
                                                                color: "#fff",
                                                                boxShadow: "1px 1px 10px #f8e1aa",
                                                            } }, { children: "Edit" }), void 0)] }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx(Confirm, { message: "DO you want to continue", ref: confirmRef, buttons: {
                                                    ok: {
                                                        label: "Confirm Delete", callback: (c, close) => {
                                                            deleteFranchise(item.uid);
                                                            close();
                                                        }
                                                    },
                                                    cancel: {
                                                        label: "Cancel", css: { backgroundColor: "#f14545" },
                                                        callback: (c, close) => {
                                                            close();
                                                        }
                                                    }
                                                } }, void 0) }, void 0), item ? (_jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx(Col, Object.assign({ top: true, cols: 4 }, { children: _jsx(CardNano, { title: "Franchisee Name", value: item.franchisee_name }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: 2 }, { children: _jsx(CardNano, { title: "Franchisee Code", value: item.franchisee_code }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: 2 }, { children: _jsx(CardNano, { title: "Franchisee Uid", value: item.uid }, void 0) }), void 0)] }, void 0), item.franchisee_address ?
                                                    _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 4 }, { children: [_jsx(CardNano, { title: "Location", value: item.franchisee_location }, void 0), _jsx("a", Object.assign({ href: `https://maps.google.com/?q=${item.franchisee_address.location.coordinates[0]},${item.franchisee_address.location.coordinates[1]}`, target: "_blank" }, { children: "View In Map" }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 4 }, { children: [_jsx(CardNano, { title: "Address", value: "" }, void 0), _jsx(AddressComponent, { multiline: true, address: item.franchisee_address }, void 0)] }), void 0), _jsx(Col, Object.assign({ cols: 4 }, { children: _jsx(CardNano, { title: "Landmark", value: item.franchisee_address.landmark }, void 0) }), void 0)] }, void 0) : null] }, void 0)) : ("")] }), void 0), editDisplay && editView == item.uid ? (_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ style: {
                                                borderRadius: ".6em",
                                                backgroundColor: "#fff",
                                                margin: "5px",
                                                padding: "12px",
                                                // boxShadow:
                                                // " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                            } }, { children: [_jsx("div", Object.assign({ style: { textAlign: "right" } }, { children: _jsx("span", Object.assign({ style: { cursor: "pointer" }, onClick: () => setEditDisplay(false) }, { children: _jsx(X, {}, void 0) }), void 0) }), void 0), _jsx("h1", { children: "Edit Franchise Details" }, void 0), _jsx("hr", { style: {
                                                        border: "0",
                                                        height: "0",
                                                        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                                        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                                        marginBottom: "5px",
                                                    } }, void 0), _jsx("div", Object.assign({ ref: divRef, style: { padding: "25px", color: "#e62222" } }, { children: _jsx("p", {}, void 0) }), void 0), _jsx(Form, Object.assign({ name: "edit_franchise", onSubmit: submitEditHandler }, { children: _jsxs(StyleProvider, Object.assign({ value: { container } }, { children: [_jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Franchisee Name" }), void 0), _jsx(Field, Object.assign({ name: "franchisee_name", value: item.franchisee_name
                                                                                    ? item.franchisee_name
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Franchisee Location" }), void 0), _jsx(Field, Object.assign({ name: "franchisee_location", value: item.franchisee_location
                                                                                    ? item.franchisee_location
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 6 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Franchisee Code" }), void 0), _jsx(Field, Object.assign({ name: "franchisee_code", value: item.franchisee_code
                                                                                    ? item.franchisee_code
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0)] }, void 0), _jsx("h1", { children: "Address" }, void 0), _jsx("hr", { style: {
                                                                    border: "0",
                                                                    height: "0",
                                                                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                                                    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                                                    marginBottom: "5px",
                                                                } }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Address Line 1" }), void 0), _jsx(Field, Object.assign({ name: "address_line1", value: item.franchisee_address &&
                                                                                    item.franchisee_address.address_line1
                                                                                    ? item.franchisee_address.address_line1
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Address Line 2" }), void 0), _jsx(Field, Object.assign({ name: "address_line2", value: item.franchisee_address &&
                                                                                    item.franchisee_address.address_line2
                                                                                    ? item.franchisee_address.address_line2
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "City" }), void 0), _jsx(Field, Object.assign({ name: "city", value: item.franchisee_address &&
                                                                                    item.franchisee_address.city
                                                                                    ? item.franchisee_address.city
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Pincode" }), void 0), _jsx(Field, Object.assign({ name: "pin_code", value: item.franchisee_address &&
                                                                                    item.franchisee_address.pin_code
                                                                                    ? item.franchisee_address.pin_code
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Landmark" }), void 0), _jsx(Field, Object.assign({ name: "landmark", value: item.franchisee_address &&
                                                                                    item.franchisee_address.landmark
                                                                                    ? item.franchisee_address.landmark
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0)] }, void 0), _jsx("h1", { children: "Location" }, void 0), _jsx("hr", { style: {
                                                                    border: "0",
                                                                    height: "0",
                                                                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                                                    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                                                    marginBottom: "5px",
                                                                } }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 3 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Latitude" }), void 0), _jsx(Field, Object.assign({ name: "latitude", value: item.franchisee_address &&
                                                                                    item.franchisee_address.location &&
                                                                                    item.franchisee_address.location.coordinates
                                                                                    ? item.franchisee_address.location
                                                                                        .coordinates[0]
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 9 }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Longitude" }), void 0), _jsx(Field, Object.assign({ name: "longitude", value: item.franchisee_address &&
                                                                                    item.franchisee_address.location &&
                                                                                    item.franchisee_address.location.coordinates
                                                                                    ? item.franchisee_address.location
                                                                                        .coordinates[1]
                                                                                    : "" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0)] }, void 0), _jsx(Row, { children: _jsx(Col, Object.assign({ cols: 12 }, { children: _jsx("div", Object.assign({ style: {
                                                                            marginTop: "10px",
                                                                            textAlign: "right",
                                                                        } }, { children: _jsx("input", { type: "submit", value: "Submit", css: button }, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }), void 0)] }), void 0) }, void 0) }, void 0)) : ("")] }), void 0)))
                : ""] }, void 0));
};
export const FranchiseList = () => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(FranchiseListInternal, {}, void 0) }), void 0) }, void 0));
