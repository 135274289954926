var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleableComponent, styling } from "@exprzn/react-style-lib";
import React, { Children } from "react";
export class RadioButtonItem extends StyleableComponent {
    render() {
        const { onChange, value, label, name, checked } = this.props;
        return _jsxs("div", { children: [_jsx("input", { type: "radio", value: value, name: name, checked: checked, onChange: onChange }, void 0), " ", label, " \u00A0\u00A0\u00A0"] }, void 0);
    }
}
export class Radio extends StyleableComponent {
    render() {
        const { onChange, value, errors, readonly = false, name, children, align = "center" } = this.props;
        const { errorStyle, input } = this;
        const childs = Children.toArray(children);
        if (this.state == null && typeof value != "undefined")
            this.setState({ value });
        const childElements = childs.map(child => React.cloneElement(child, {
            name, onChange, checked: value == child.props.value
        }));
        return _jsx("div", Object.assign({ css: { display: "flex", justifyContent: align } }, { children: childElements }), void 0);
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], Radio.prototype, "errorStyle", void 0);
__decorate([
    styling("inputcheckbox"),
    __metadata("design:type", Object)
], Radio.prototype, "input", void 0);
