var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { registry } from '@exprzn/react-style-lib';
const { style, container } = registry("modal-x", true);
let ModalStyle = class ModalStyle {
};
ModalStyle.style = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 1000,
    overflow: "hidden",
    backgroundColor: "#221222af",
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
};
ModalStyle = __decorate([
    style("modalstyle")
], ModalStyle);
let CentreStyle = class CentreStyle {
};
CentreStyle.style = {
    "pointer-events": "all",
    zIndex: 2000
};
CentreStyle = __decorate([
    style("centrestyle")
], CentreStyle);
let CloseStyle = class CloseStyle {
};
CloseStyle.style = {
    width: "2.5em",
    height: "2.5em",
    backgroundColor: "white",
    padding: ".5em",
    position: "absolute",
    right: "20px",
    top: "20px",
};
CloseStyle = __decorate([
    style("closestyle")
], CloseStyle);
export default container;
