import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { Row } from "../Row";
import { SearchOutlined } from "@ant-design/icons";
import { useStyles } from "@exprzn/react-style-lib";
import { useState } from "react";
import { Col } from "../Column";
import { FormControl, InputAdornment, TextField } from "@mui/material";
export const SearchToolBar = (props) => {
    const { onChange, filters: filterArr = [], sortBy: SortByArr = [] } = props;
    const [value, setValue] = useState();
    const [filters, setFilters] = useState(filterArr);
    const [sortBy, setSortBy] = useState([]);
    const { productstyle } = useStyles("productstyle");
    const onInputChange = (e) => {
        setValue(e.target.value);
        onChange(e.target.value, filters, sortBy);
    };
    const onFilterChange = (index) => (e) => {
        filters[index].filter = e.target.checked || false;
        setFilters(filters);
        onChange(value, filters, sortBy);
    };
    return _jsxs("div", { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(FormControl, Object.assign({ fullWidth: true, sx: { height: '44px' } }, { children: _jsx(TextField, { fullWidth: true, size: "small", variant: "outlined", value: value, placeholder: 'Search', onChange: onInputChange, InputProps: {
                                startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchOutlined, {}, void 0) }), void 0)),
                            } }, void 0) }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: { marginTop: 10, display: "flex", justifyContent: "center", fontSize: ".91em" } }, { children: filterArr.map((filter, index) => _jsxs("div", Object.assign({ css: { marginRight: 10, fontWeight: 500, color: "#070808" } }, { children: [_jsx("input", { type: "checkbox", checked: filters[index].filter, css: { marginRight: "6px", fontSize: ".91em" }, onChange: onFilterChange(index) }, void 0), _jsx("span", { children: filter.title }, void 0)] }), void 0)) }), void 0) }, void 0)] }, void 0);
};
