import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { DELIVERY_STATUS, ORDER_ITEM_STATUS, ORDER_STATUS, SHIPMENT_STATUS } from "./status-definitions";
export var MessageType;
(function (MessageType) {
    MessageType["SUCCESS"] = "success";
    MessageType["FAILURE"] = "failure";
    MessageType["WARNING"] = "warning";
    MessageType["READY"] = "ready";
    MessageType["NOT_READY"] = "not-ready";
    MessageType["PENDING"] = "penging";
    MessageType["DANGER"] = "danger";
    MessageType["ERROR"] = "error";
    MessageType["PRIMARY"] = "primary";
    MessageType["SECONDARY"] = "secondary";
    MessageType["TERTIARY"] = "tertiary";
    MessageType["QUERTERNARY"] = "quarternary";
    MessageType["LIGHT"] = "light";
    MessageType["DARK"] = "dark";
    MessageType["INFO"] = "info";
    MessageType["CONFIRM"] = "confirm";
})(MessageType || (MessageType = {}));
export const getBadgeTypeCss = (type) => {
    let partialCss = {
        backgroundColor: "#FFFFFF",
        color: "#FFFFFF000", borderColor: "#FFFFFF00"
    };
    switch (type) {
        case MessageType.SUCCESS:
            partialCss = { backgroundColor: "#95DC45", color: "#154223", borderColor: "#FFFFFF00" };
            break;
        case MessageType.WARNING:
            partialCss = { backgroundColor: "#FEB212", color: "#8C2902", borderColor: "#FFFFFF00" };
            break;
        case MessageType.PENDING:
            partialCss = { backgroundColor: "#6AD9FB", color: "black", borderColor: "#6AD9FB" };
            break;
        case MessageType.FAILURE:
            partialCss = { backgroundColor: "#FF8CB1", color: "#A90908", borderColor: "#FFFFFF00" };
            break;
        case MessageType.CONFIRM:
            partialCss = { backgroundColor: "#95D2FF", color: "black", borderColor: "#95D2FF" };
            break;
        case MessageType.ERROR:
            partialCss = { backgroundColor: "#FF5C76", color: "#ffffff", borderColor: "#FFFFFF00" };
            break;
        case MessageType.DANGER:
            partialCss = { backgroundColor: "#ED3938", color: "#FFFFFF", borderColor: "#FFFFFF00" };
            break;
        case MessageType.PRIMARY:
            partialCss = { backgroundColor: "#0E70F0", color: "#FFFFFF", borderColor: "#FFFFFF00" };
            break;
        case MessageType.SECONDARY:
            partialCss = { backgroundColor: "#9B77FD", color: "#FFFFFF", borderColor: "#FFFFFF00" };
            break;
        case MessageType.TERTIARY:
            partialCss = { backgroundColor: "#00CDAC", color: "#FFFFFF", borderColor: "#FFFFFF00" };
            break;
        case MessageType.QUERTERNARY:
            partialCss = { backgroundColor: "#8FFDAE", color: "#000000", borderColor: "#8FFDAE" };
            break;
        case MessageType.NOT_READY:
            partialCss = { backgroundColor: "#F48A0C", color: "#FFFFFF", borderColor: "#FFFFFF00" };
            break;
        case MessageType.READY:
            partialCss = { backgroundColor: "#FEF074", color: "#C25404", borderColor: "#FFFFFF00" };
            break;
        case MessageType.INFO:
            partialCss = { backgroundColor: "#FFFFFFFFF00", color: "#252320", borderColor: "#FFFFFF00" };
            break;
        case MessageType.LIGHT:
            partialCss = { backgroundColor: "#FFFFFF", color: "#252320", borderColor: "#FFFFFF00" };
            break;
        case MessageType.DARK:
            partialCss = { backgroundColor: "#271F1F", color: "#FFFFFFFFF", borderColor: "#FFFFFF00" };
            break;
    }
    return partialCss;
};
export const Badge = (props) => {
    const { message, type } = props;
    const css = getBadgeTypeCss(type);
    const { badgestyle } = useStyles("badgestyle");
    return _jsx("div", Object.assign({ css: Object.assign(Object.assign({}, badgestyle), css) }, { children: message }), void 0);
};
export const OrderStatusBadge = (props) => {
    const { status } = props;
    let statusHumanReadable = status;
    let messageType = "success";
    switch (status) {
        case ORDER_STATUS.BUYER_CHECKED_OUT:
            statusHumanReadable = "Checked Out";
            messageType = MessageType.SECONDARY;
            break;
        case ORDER_STATUS.BUYER_COD_ORDER_CONFIRMED:
            statusHumanReadable = "Buyer Order Confirmed";
            messageType = MessageType.CONFIRM;
            break;
        case ORDER_STATUS.PARTIAL_ORDER_COMPLETED:
            statusHumanReadable = "Buyer Partial Order Delivery Completed";
            messageType = MessageType.QUERTERNARY;
            break;
        case ORDER_STATUS.BUYER_PAID_ORDER_CONFIRMED:
            statusHumanReadable = "Paid : Order Confirmed";
            messageType = MessageType.CONFIRM;
            break;
        case ORDER_STATUS.BUYER_PAYMENT_FAILED:
            statusHumanReadable = "Buyer Payment Failed";
            messageType = MessageType.FAILURE;
            break;
        case ORDER_STATUS.BUYER_PAYMENT_PENDING:
            statusHumanReadable = "Buyer Payment Pending";
            messageType = MessageType.PENDING;
            break;
        case ORDER_STATUS.DELIVERY_ACCEPTED:
            statusHumanReadable = "Delivery Person Accepted";
            messageType = MessageType.NOT_READY;
            break;
        case ORDER_STATUS.DELIVERY_PICKUP_CONFIRMED:
            statusHumanReadable = "Delivery Person Pickup Completed (All Stores)";
            messageType = MessageType.NOT_READY;
            break;
        case ORDER_STATUS.DELIVERY_COMPLETED:
            statusHumanReadable = "Delivery Completed";
            messageType = MessageType.QUERTERNARY;
            break;
        case ORDER_STATUS.DELIVERY_RELEASED:
            statusHumanReadable = "Delivery Person Released Order";
            messageType = MessageType.NOT_READY;
            break;
        case ORDER_STATUS.ADMIN_ORDER_CANCELLED:
            statusHumanReadable = "Admin Order Cancelled";
            messageType = MessageType.DANGER;
            break;
        case ORDER_STATUS.SELLERS_CONFIRMED:
            statusHumanReadable = "Sellers Confirmed";
            messageType = MessageType.SUCCESS;
            break;
        case ORDER_STATUS.BUYER_ORDER_CANCELLED:
            statusHumanReadable = "Order Cancelled By Buyer";
            messageType = MessageType.ERROR;
        //default : status (incoming)
    }
    return _jsx(Badge, { message: statusHumanReadable, type: messageType }, void 0);
};
export const OrderStatusView = (status) => {
    let statusHumanReadable = status;
    let messageType = "success";
    switch (status) {
        case ORDER_STATUS.BUYER_CHECKED_OUT:
            statusHumanReadable = "Checked Out";
            messageType = MessageType.SECONDARY;
            break;
        case ORDER_STATUS.BUYER_COD_ORDER_CONFIRMED:
            statusHumanReadable = "Buyer Order Confirmed";
            messageType = MessageType.CONFIRM;
            break;
        case SHIPMENT_STATUS.COURIER_NOT_ASSIGNED:
            statusHumanReadable = "Courier Not Assigned";
            messageType = MessageType.PRIMARY;
            break;
        case ORDER_STATUS.PARTIAL_ORDER_COMPLETED:
            statusHumanReadable = "Buyer Partial Order Delivery Completed";
            messageType = MessageType.QUERTERNARY;
            break;
        case ORDER_STATUS.BUYER_PAID_ORDER_CONFIRMED:
            statusHumanReadable = "Paid : Order Confirmed";
            messageType = MessageType.CONFIRM;
            break;
        case ORDER_STATUS.BUYER_PAYMENT_FAILED:
            statusHumanReadable = "Buyer Payment Failed";
            messageType = MessageType.FAILURE;
            break;
        case ORDER_STATUS.BUYER_PAYMENT_PENDING:
            statusHumanReadable = "Buyer Payment Pending";
            messageType = MessageType.NOT_READY;
            break;
        case ORDER_STATUS.DELIVERY_ACCEPTED:
            statusHumanReadable = "Delivery Person Accepted";
            messageType = MessageType.NOT_READY;
            break;
        case SHIPMENT_STATUS.PICKUP_SCHEDULED:
            statusHumanReadable = "Pickup Scheduled";
            messageType = MessageType.PRIMARY;
            break;
        case SHIPMENT_STATUS.PICKUP_COMPLETED:
            statusHumanReadable = "Pickup Completed";
            messageType = MessageType.PRIMARY;
            break;
        case SHIPMENT_STATUS.IN_TRANSIT:
            statusHumanReadable = "IN Transit";
            messageType = MessageType.PRIMARY;
            break;
        case ORDER_STATUS.DELIVERY_PICKUP_CONFIRMED:
            statusHumanReadable = "Delivery Person Pickup Completed (All Stores)";
            messageType = MessageType.NOT_READY;
            break;
        case ORDER_STATUS.DELIVERY_COMPLETED:
            statusHumanReadable = "Delivery Completed";
            messageType = MessageType.QUERTERNARY;
            break;
        case ORDER_STATUS.DELIVERY_RELEASED:
            statusHumanReadable = "Delivery Person Released Order";
            messageType = MessageType.NOT_READY;
            break;
        case ORDER_STATUS.ADMIN_ORDER_CANCELLED:
            statusHumanReadable = "Admin Order Cancelled";
            messageType = MessageType.DANGER;
            break;
        case ORDER_STATUS.SELLERS_CONFIRMED:
            statusHumanReadable = "Sellers Confirmed";
            messageType = MessageType.SUCCESS;
            break;
        case ORDER_STATUS.BUYER_ORDER_CANCELLED:
            statusHumanReadable = "Order Cancelled By Buyer";
            messageType = MessageType.ERROR;
        //default : status (incoming)
    }
    return statusHumanReadable;
};
export const OrderItemStatusBadge = (props) => {
    const { status } = props;
    let statusHumanReadable = status;
    let messageType = "success";
    switch (status) {
        case ORDER_ITEM_STATUS.BUYER_CHECKED_OUT:
            statusHumanReadable = "Checked Out";
            messageType = MessageType.PRIMARY;
            break;
        case ORDER_ITEM_STATUS.SELLER_ACCEPTED:
            statusHumanReadable = "Seller Accepted";
            messageType = MessageType.PRIMARY;
            break;
        case ORDER_ITEM_STATUS.SELLER_REJECTED:
            statusHumanReadable = "Seller Rejected";
            messageType = MessageType.PRIMARY;
            break;
        case ORDER_ITEM_STATUS.DELIVERY_PICKUP_RELEASED:
            statusHumanReadable = "Delivery Person Released";
            messageType = MessageType.NOT_READY;
            break;
        case ORDER_ITEM_STATUS.DELIVERY_COMPLETED:
            statusHumanReadable = "Delivery Completed";
            messageType = MessageType.QUERTERNARY;
            break;
        case ORDER_ITEM_STATUS.DELIVERY_PICKED_UP:
            statusHumanReadable = "Delivery Person Picked Up";
            messageType = MessageType.NOT_READY;
            break;
        case ORDER_ITEM_STATUS.ADMIN_ORDER_CANCELLED:
            statusHumanReadable = "Admin Order Cancelled";
            messageType = MessageType.DANGER;
            break;
        case ORDER_ITEM_STATUS.BUYER_ORDER_ITEM_CANCELLED:
            statusHumanReadable = "Order Cancelled By Buyer";
            messageType = MessageType.ERROR;
        //default : status (incoming)
    }
    return _jsx(Badge, { message: statusHumanReadable, type: messageType }, void 0);
};
export const DeliveryStatusBadge = (props) => {
    const { status } = props;
    let statusHumanReadable = status;
    let messageType = "success";
    switch (status) {
        case DELIVERY_STATUS.BUYER_CHECKED_OUT:
            statusHumanReadable = "Buyer Checked Out";
            messageType = MessageType.PRIMARY;
            break;
        case DELIVERY_STATUS.SELLER_CONFIRMED:
            statusHumanReadable = "Seller Confirmed";
            messageType = MessageType.PRIMARY;
            break;
        case DELIVERY_STATUS.DELIVERY_RELEASED:
            statusHumanReadable = "Delivery Released";
            messageType = MessageType.NOT_READY;
            break;
        case DELIVERY_STATUS.DELIVERY_COMPLETED:
            statusHumanReadable = "Delivery Completed";
            messageType = MessageType.QUERTERNARY;
            break;
        case DELIVERY_STATUS.DELIVERY_PICKUP_CONFIRMED:
            statusHumanReadable = "Delivery Picked Confirmed";
            messageType = MessageType.NOT_READY;
            break;
        case DELIVERY_STATUS.ADMIN_ORDER_CANCELLED:
            statusHumanReadable = "Admin Order Cancelled";
            messageType = MessageType.DANGER;
            break;
        case DELIVERY_STATUS.BUYER_STORE_ORDERS_CANCELLED:
            statusHumanReadable = "Buyer Cancelled Store Orders";
            messageType = MessageType.ERROR;
            break;
        case DELIVERY_STATUS.ADMIN_CANCELLED:
            statusHumanReadable = "Admin Order Cancelled";
            messageType = MessageType.ERROR;
        //default : status (incoming)
    }
    return _jsx(Badge, { message: statusHumanReadable, type: messageType }, void 0);
};
