var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from '@exprzn/react-style-lib';
import imageUrl from './background.png';
const { style, container } = registry("login", true);
let InputStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputStyle extends CssStyle {
};
InputStyle.style = {
    position: "relative",
    border: '1px solid #f8b63155',
    '&:hover': {
        backgroundColor: '#f8b63177',
        border: "1px solid #fefefe",
    },
    '&:focus': {
        backgroundColor: '#f8b63111'
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    backgroundColor: '#f8b63111',
    color: '#e8a631',
    marginTop: "1em",
    fontWeight: 600,
    fontSize: '1em'
};
InputStyle = __decorate([
    style("input")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputStyle);
let SignupBackgroundStyle = class SignupBackgroundStyle extends CssStyle {
};
SignupBackgroundStyle.style = {
    width: '100%',
    height: '91vh',
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
};
SignupBackgroundStyle = __decorate([
    style('loginbackground')
], SignupBackgroundStyle);
let BoxStyle = class BoxStyle extends CssStyle {
};
BoxStyle.style = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: 'none',
};
BoxStyle = __decorate([
    style("boxStyle")
], BoxStyle);
let SignupHeadingStyle = class SignupHeadingStyle extends CssStyle {
};
SignupHeadingStyle.style = {
    display: "flex",
    "justify-content": "center",
    padding: "5px",
    fontWeight: 600,
    fontSize: "1.6em",
    marginTop: "10%",
    marginBottom: "15%"
};
SignupHeadingStyle = __decorate([
    style('loginHeading')
], SignupHeadingStyle);
let ButtonStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ButtonStyle extends CssStyle {
};
ButtonStyle.style = {
    position: "relative",
    outline: 'none',
    color: 'white',
    marginTop: '20px',
    border: "0",
    '&:hover': {
        backgroundColor: '#045998'
    },
    '&:focus': {
        backgroundColor: '#045998'
    },
    padding: ".8em .2em",
    borderRadius: ".5em",
    width: "100%",
    backgroundColor: '#045998 '
};
ButtonStyle = __decorate([
    style("loginButton")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ButtonStyle);
let ErrorStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ErrorStyle extends CssStyle {
};
ErrorStyle.style = {
    margin: ".1em",
    fontSize: "1em",
    color: 'red'
};
ErrorStyle = __decorate([
    style("error")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ErrorStyle);
let RowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowStyle extends CssStyle {
};
RowStyle.style = {
    width: [300, 300],
    padding: ".5em",
    "box-sizing": 'border-box',
    outline: 0,
    outlineOffset: 0
};
RowStyle = __decorate([
    style("row")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowStyle);
let LoginTitleStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class LoginTitleStyle extends CssStyle {
};
LoginTitleStyle.style = {
    display: "flex",
    "justify-content": "center",
    padding: "2em",
    fontWeight: 1000,
    fontSize: "1.6em"
};
LoginTitleStyle = __decorate([
    style("logintitle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], LoginTitleStyle);
export default container;
