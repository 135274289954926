import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { Row } from "../../../components/Row";
import { useStream } from "../../../saga/use-stream";
import { SearchToolBar } from "../../../components/Search";
import { StreamNav } from "../../../components/StreamNav";
import { useProgressBar } from "../../../components/ProgressBar";
import { BasketOrderListComponent } from "../basket-order-list-component";
import { Table } from "../../../components/Table/table";
export const Basket = (props) => {
    const { data } = props;
    const { status, delivery_date, delivery_slot, basket_id, buyer } = data;
    //1st element or undefined
    const { rowstyle, productstyle } = useStyles("rowstyle", "productstyle");
    const deliveryDate = new Date(delivery_date);
    const { personal_profile, login_profile, buyer_profile, buyer_uid } = buyer || {};
    const { address } = buyer_profile;
    // const { first_name, last_name, full_name } = personal_profile
    // const buyer_info: BuyerInfo = { first_name, last_name, full_name, address }
    const [addr] = address;
    const { address_line1, address_line2, city, pin_code, landmark, location } = addr;
    const line1 = Object.values({ address_line1, address_line2, city }).join(", ");
    const line2 = Object.values({ city, pin_code }).join(", ");
    const line3 = Object.values({ landmark }).join(", ");
    const displayAddress = addr ? Object.values({ line1, line2, line3 }).join("\n") : "";
    return _jsx("div", Object.assign({ css: productstyle, tabIndex: 2 }, { children: _jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: [12, 1] }, { children: basket_id }), void 0), _jsx(Col, Object.assign({ cols: 2 }, { children: deliveryDate.toDateString() }), void 0), _jsx(Col, Object.assign({ cols: [12, 1] }, { children: status }), void 0), _jsxs(Col, Object.assign({ cols: [12, 4] }, { children: [_jsxs("div", { children: [" ", line1] }, void 0), _jsxs("div", { children: [" ", line2] }, void 0), _jsxs("div", { children: [" ", line3] }, void 0)] }), void 0), _jsx(Col, { children: _jsx(NavLink, Object.assign({ to: `/dashboard/basket-orders/` }, { children: "View" }), void 0) }, void 0), _jsx(Col, { children: _jsx(NavLink, Object.assign({ to: `/dashboard/basket-orders/` }, { children: "Assign" }), void 0) }, void 0)] }, void 0) }), void 0);
};
export const BasketOrderDelivered = (props) => {
    const { routerProps, path } = props;
    const { page: pageStr } = routerProps.match.params;
    const page = parseInt(`${pageStr}`);
    const [maxPage, setMaxPage] = useState(page);
    const loading = useProgressBar();
    const { bigtable } = useStyles("bigtable");
    const { data, stream, loadPage, loadNextPage, isFetching, loadUrl } = useStream("/trendutube/v1/admin-order-processing/basket-orders/list", { page });
    useEffect(() => {
        if (stream && (stream.page > maxPage))
            setMaxPage(stream.page);
    }, [stream]);
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    const { productstyle, pagestyle } = useStyles("productstyle", "pagestyle");
    function onSearchChange(name, filters) {
        const qp = { name };
        if (name && name.length > 2) {
            filters.forEach(filter => {
                if (filter.filter)
                    qp[filter.value] = name;
            });
            loadUrl("/trendutube/v1/admin-order-processing/basket-orders/list", 0, qp);
        }
        else {
            loadUrl("/trendutube/v1/admin-order-processing/basket-orders/list", 0, {});
        }
    }
    const filters = [
        { title: "Username (email/mobile number)", value: "username", filter: true },
        { title: "Basket Id", value: "basket_id", filter: false },
        // { title: "Buyer (First", value: "buyer_search_string", filter: false },
        // { title: "Store Uid", value: "store_uid", filter: false },
        { title: "Store Name", value: "store_name", filter: false },
        { title: "Buyer Uid", value: "buyer_uid", filter: false },
        // { title: "Order Id", value: "order_id", filter: true },
        { title: "Buyer First Name", value: "first_name", filter: false },
        { title: "Buyer Last Name", value: "last_name", filter: false }
        // { title: "Buyer Pincode", value: "pin_code", filter: false },
        // { title: "Buyer City", value: "city", filter: false },
    ];
    const header = {
        order_date: "Date",
        id: "Id",
        order_id: "Order Id",
        status: "Status",
        payment_mode: "Payment Mode",
        net_amount_with_tips: "Net Amount",
        buyer: "Buyer",
        sellers: "Sellers"
    };
    const keys = [
        "order_date",
        "order_id",
        "id",
        "status",
        "payment_mode",
        "net_amount_with_tips",
        "buyer",
        "sellers"
    ];
    return _jsxs("div", { children: [_jsx("div", Object.assign({ css: productstyle }, { children: _jsx(SearchToolBar, { onChange: onSearchChange, filters: filters }, void 0) }), void 0), _jsx(StreamNav, Object.assign({}, {
                loadNextPage, loadPage, stream, maxPage,
                getUrl: (page) => `${path}/basket-orders/delivered/${page}`
            }), void 0), _jsx("div", Object.assign({ css: productstyle }, { children: data ? _jsx(Table, { style: bigtable, body: data, header: header, keys: keys, component: BasketOrderListComponent, itemOnClick: (item) => {
                        console.log("PAGERTT", page);
                        // loadPage(page)
                    } }, void 0)
                    : null }), void 0)] }, void 0);
};
