var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
const { style, container } = registry("catlist", true);
let RowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowStyle extends CssStyle {
};
RowStyle.style = {
    backgroundColor: 'white',
    padding: ".5em",
    margin: '.5em',
};
RowStyle = __decorate([
    style("rowstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowStyle);
let ErrorStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ErrorStyle extends CssStyle {
};
ErrorStyle.style = {
    backgroundColor: 'white',
    padding: ".5em",
    margin: '.5em',
};
ErrorStyle = __decorate([
    style("error")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ErrorStyle);
let InputStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputStyle extends CssStyle {
};
InputStyle.style = {
    position: "relative",
    border: '1px solid #e6e6e6',
    '&:hover': {
        backgroundColor: '#f8b63177',
        border: "1px solid #fefefe",
    },
    '&:focus': {
        backgroundColor: '#f8b63111'
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    backgroundColor: '#f1f1f1',
    color: '#e8a631',
    marginTop: "1em",
    fontWeight: 600,
    fontSize: '1em'
};
InputStyle = __decorate([
    style("input")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputStyle);
let ButtonStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ButtonStyle extends CssStyle {
};
ButtonStyle.style = {
    position: "relative",
    outline: 'none',
    border: "0",
    '&:hover': {
        backgroundColor: '#e8b631'
    },
    '&:focus': {
        backgroundColor: '#f8b631'
    },
    padding: ".8em 2em",
    margin: "1.5em 0em",
    borderRadius: ".5em",
    backgroundColor: '#f8b611',
    boxShadow: "1px 1px 10px #f8e1aa",
};
ButtonStyle = __decorate([
    style("button")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ButtonStyle);
let TitleRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TitleRowStyle extends CssStyle {
};
TitleRowStyle.style = {
    fontWeight: 400,
    color: "#888888",
    margin: "8px 0px 0px 0px",
    fontSize: ".7em"
};
TitleRowStyle = __decorate([
    style("titlestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TitleRowStyle);
let TextStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TextStyle extends CssStyle {
};
TextStyle.style = {
    fontWeight: 700,
    color: "#555555",
    margin: "0px 0px 8px 0px",
    fonctSize: "1em"
};
TextStyle = __decorate([
    style("textstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TextStyle);
export default container;
