import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { Form, Field } from "../../../components/Form";
import { InputDiscountEx } from "../../../components/Form/fields-extended";
import { Row } from "../../../components/Row";
import { SagaProvider } from "../../../saga/saga-context";
import { useSubmit } from "../../../saga/use-submit";
import container from "./style";
import { useProgressBar } from "../../../components/ProgressBar";
import { handleError, handleSuccess } from "../../../utils";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { global_org_uid } from "../../../config";
export const CategoryAddInternal = () => {
    const { category, rowstyle, button, buttonbanner } = useStyles("category", "rowstyle", "button", "buttonbanner");
    const { submit, data, error, isFetching, status, reset } = useSubmit();
    const { submit: submitImage, data: dataImage, error: errorImage, status: statusImage, isFetching: isFetchingImage, reset: resetImage } = useSubmit();
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [image_uid, setImage] = useState();
    const submitHandler = (formData) => {
        if (!formData.product_class.product_class_name) {
            toast.error("Please class name ", { toastId: "cat3" });
        }
        else if (!image_uid)
            toast.error("Please upload an image, ensure image is uploaded ", { toastId: "cat3" });
        else {
            submit(`/trendutube/v1/product-catalog/organizations/${global_org_uid}/product-classes/add`, {
                body: Object.assign(Object.assign({}, formData.product_class), { image_uid }),
            });
        }
    };
    const loading = useProgressBar();
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    const history = useHistory();
    useEffect(() => {
        if (status == "ok") {
            handleSuccess("class added successfully");
            history.replace('/dashboard/product-class/list');
        }
    }, [status]);
    const inputOpenFileRef = useRef(null);
    function showOpenFileDlg() {
        inputOpenFileRef &&
            inputOpenFileRef.current &&
            inputOpenFileRef.current.click();
    }
    function handleChange(event) {
        const files = Object.keys(event.target.files).map((file) => URL.createObjectURL(event.target.files[file]));
        setFiles(files);
        setImages(event.target.files);
        event.preventDefault();
        // alert(files.length)
    }
    useEffect(() => {
        if (dataImage) {
            const { urls } = dataImage;
            setImage(urls[0].uid);
            // alert(urls[0].uid)
        }
    }, [dataImage]);
    useEffect(() => {
        if (status == "ok") {
            // handleSuccess("class created successfully")
            reset();
        }
        else if (status == "error") {
            handleError(error);
            reset();
        }
        if (statusImage == "ok") {
            handleSuccess("image uploaded successully");
            resetImage();
        }
    }, [status, statusImage]);
    useEffect(() => {
        loading(isFetching || isFetchingImage);
    }, [isFetching, isFetchingImage]);
    function handleImageSubmit(data) {
        submitImage("/image-service/images/upload?entity=product_class", {
            form: true,
            body: { images },
        });
        console.log(images);
    }
    return (_jsxs("div", Object.assign({ css: category }, { children: [_jsxs("div", { children: [" ", data && JSON.stringify(data || {})] }, void 0), _jsx("div", { children: error && JSON.stringify(error) }, void 0), _jsxs(Row, { children: [_jsx(Col, { children: _jsxs(Form, Object.assign({ name: "upload", onSubmit: handleImageSubmit }, { children: [_jsx(Row, { children: _jsx("div", Object.assign({ style: {
                                            height: "200px", backgroundColor: '#F4F5F6', border: 'none', width: '100%', marginBottom: '20px', display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: '10px'
                                        } }, { children: files.map((f) => (_jsx("img", { src: f, height: "200" }, void 0))) }), void 0) }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ size: 2 }, { children: [_jsx("input", { ref: inputOpenFileRef, type: "file", style: { display: "none" }, onChange: handleChange }, void 0), _jsx("button", Object.assign({ type: "button", onClick: showOpenFileDlg, css: buttonbanner }, { children: "Browse Image" }), void 0)] }), void 0), _jsx(Col, Object.assign({ size: 3 }, { children: _jsx("input", { type: "submit", value: "Upload Image", css: buttonbanner }, void 0) }), void 0)] }, void 0)] }), void 0) }, void 0), _jsx(Col, { children: _jsx(Row, { children: _jsx(Col, { children: _jsxs(Form, Object.assign({ name: "product_class", onSubmit: submitHandler }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "Class Name" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "product_class_name" }, { children: _jsx(InputDiscountEx, {}, void 0) }), void 0) }), void 0) }, void 0)] }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: rowstyle }, { children: _jsx("input", { type: "submit", value: "Add Product Class", css: buttonbanner }, void 0) }), void 0) }, void 0)] }), void 0) }, void 0) }, void 0) }, void 0)] }, void 0)] }), void 0));
};
export const ProductClassAdd = () => (_jsxs(SagaProvider, { children: [" ", _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(CategoryAddInternal, {}, void 0) }), void 0)] }, void 0));
