import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { Dropdown } from "../../../components/DropdownEx";
import { MapView } from "../../distribution-center/map";
export const DistributionCentreMap = (props) => {
    const { lngLat = [] } = props;
    const [coordinates, setCoord] = useState();
    const [places, setPredictions] = useState();
    const service = new google.maps.places.AutocompleteService();
    useEffect(() => {
        if (lngLat.length != 2) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCoord([position.coords.latitude, position.coords.longitude]);
            });
        }
        else {
            const [ln, lt] = lngLat;
            setCoord(lngLat.reverse());
        }
    }, []);
    const onCenterChange = (coordinates) => {
        const lngLatStr = (coordinates || []).reverse().join(",");
        //setCoordinates(lngLatStr)
    };
    const displaySuggestions = function (predictions, status) {
        if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
            alert(status);
            return;
        }
        setPredictions(predictions);
        // predictions.forEach((prediction) => {
        //   const li = document.createElement("li");
        //   li.appendChild(document.createTextNode(prediction.description));
        //   (document.getElementById("results") as HTMLUListElement).appendChild(li);
        // });
    };
    const onChange = (ev) => {
        service.getQueryPredictions({ input: ev.target.value }, displaySuggestions);
    };
    return _jsxs("div", { children: [_jsxs("div", { children: [JSON.stringify(places), _jsx("input", { onChange: onChange }, void 0), _jsx(Dropdown, { keyProperty: "geo", titleProperty: "name", items: [{ name: "test", geo: "" }], onSelectItem: () => { }, title: "Key in a place name", view: ({ item }) => _jsx("div", Object.assign({ css: { padding: "4px 10px" } }, { children: item.name }), void 0) }, void 0)] }, void 0), coordinates ?
                _jsx(MapView, { onCenterChange: onCenterChange, latLng: coordinates }, void 0) : ""] }, void 0);
};
