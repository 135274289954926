var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
const { container, style } = registry("orders", true);
let OrdersStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class OrdersStyle extends CssStyle {
};
OrdersStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1.2em",
    padding: "1.5em"
};
OrdersStyle = __decorate([
    style("ordersstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], OrdersStyle);
let OrdersTitleRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class OrdersTitleRowStyle extends CssStyle {
};
OrdersTitleRowStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em",
    padding: "1.5em"
};
OrdersTitleRowStyle = __decorate([
    style("orderstitlestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], OrdersTitleRowStyle);
let ImageStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ImageStyle extends CssStyle {
};
ImageStyle.style = {
    minWidth: "48px",
    maxWidth: "48px",
    minHeight: "48px",
    maxHeight: "48px",
    borderRadius: "50%",
    objectFit: "cover",
    minPosition: "relative"
};
ImageStyle = __decorate([
    style("imagestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ImageStyle);
let VCenterStyle = class VCenterStyle extends CssStyle {
};
VCenterStyle.style = {
    alignSelf: "center"
};
VCenterStyle = __decorate([
    style("vcenter")
], VCenterStyle);
let ProductStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ProductStyle extends CssStyle {
};
ProductStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em",
    padding: "1.5em"
};
ProductStyle = __decorate([
    style("productstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ProductStyle);
let ImageBGStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ImageBGStyle extends CssStyle {
};
ImageBGStyle.style = {
    backgroundColor: '#f8f8f8'
};
ImageBGStyle = __decorate([
    style("imagebg")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ImageBGStyle);
export default container;
