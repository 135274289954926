import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportsource @emotion/react */
import { useStyles } from '@exprzn/react-style-lib';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { RelPositioner } from '../RelPos/RelativePositioner';
const DropdownInternal = (props) => {
    const ref1 = useRef(null);
    const [_title, setTitle] = useState();
    const { title, items = [], view, multiSelect, onSelectItem, titleProperty, selectedIndex, keyProperty, selectedKey, width } = props;
    const [show, setShow] = useState(false);
    const { slideup, slidedown, dropboxmenu, rowhcenter, rowvcenter, rowhspacing, dropboxarrowstyle, dropboxstyle, dropboxitemstyle } = useStyles("slideup", "slidedown", "dropboxmenu", "rowhcenter", "rowvcenter", "rowhspacing", "dropboxstyle", "dropboxarrowstyle", "dropboxitemstyle", "rowhcenterrowhspacing");
    var titleToShow;
    if (selectedIndex && ((items === null || items === void 0 ? void 0 : items.length) > selectedIndex))
        titleToShow = selectedIndex ? items === null || items === void 0 ? void 0 : items[selectedIndex] : "";
    else if (selectedKey && keyProperty && titleProperty) {
        const itm = items === null || items === void 0 ? void 0 : items.find(it => it[keyProperty] == selectedKey);
        titleToShow = itm ? itm === null || itm === void 0 ? void 0 : itm[titleProperty] : title;
        console.log("YYY", titleToShow, itm);
    }
    else
        titleToShow = title;
    useLayoutEffect(() => setTitle(titleToShow || ""), [titleToShow]);
    const View = view;
    const clickHandler = (event) => {
        setShow(!show);
        //if this is not prevented, the event bubbles up
        //and if inside a form this avtually submits the form
        event.preventDefault();
    };
    const onItemsClickHandler = (item, index) => {
        return (ev) => {
            setTitle((titleProperty && item[titleProperty]) || title);
            onSelectItem === null || onSelectItem === void 0 ? void 0 : onSelectItem(item, index);
            if (!multiSelect)
                setShow(!show);
            ev.preventDefault();
            return true;
        };
    };
    function assignToRef() {
        return ref1.current;
    }
    const children = items && items.map((item, index) => {
        return _jsx("li", Object.assign({ onClick: onItemsClickHandler(item, index), css: dropboxitemstyle }, { children: _jsxs("div", Object.assign({ style: { display: 'flex', alignContent: "flex-start" } }, { children: [multiSelect ? _jsx("input", { type: "checkbox", style: { margin: "4px" } }, void 0) : null, _jsx("div", Object.assign({ style: { margin: "4px" } }, { children: _jsx(View, Object.assign({}, { item, index }), void 0) }), void 0)] }), void 0) }), `${keyProperty && item[keyProperty] || index}`);
    });
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ style: { maxHeight: "38px" } }, { children: _jsxs("button", Object.assign({ ref: ref1, css: [dropboxstyle], onClick: clickHandler, style: { width } }, { children: [_jsxs("div", Object.assign({ css: [rowvcenter] }, { children: [" ", _title] }), void 0), _jsx("div", Object.assign({ css: [rowvcenter, dropboxarrowstyle] }, { children: show ? _jsx(ChevronUp, { size: "24px" }, void 0) : _jsx(ChevronDown, { color: "#d8d8d8", size: "24px" }, void 0) }), void 0)] }), void 0) }), void 0), show ? _jsx("div", { children: _jsx(RelPositioner, Object.assign({ target: assignToRef, align: "left bottom", onClickedOutSide: () => setShow(false) }, { children: _jsx("ul", Object.assign({ css: show ? [dropboxmenu, slideup] : [dropboxmenu, slidedown] }, { children: children }), void 0) }), void 0) }, void 0) : null] }, void 0));
};
export const Dropdown = DropdownInternal;
