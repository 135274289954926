var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
const { container, style } = registry("seller-styles", true);
let SellerContainer = class SellerContainer extends CssStyle {
};
SellerContainer.style = {};
SellerContainer = __decorate([
    style("containerstyle")
], SellerContainer);
let SellerRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SellerRowStyle extends CssStyle {
};
SellerRowStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: ".5em",
    padding: ".5em 1.2em",
};
SellerRowStyle = __decorate([
    style("sellerrowstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SellerRowStyle);
let SellerStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SellerStyle extends CssStyle {
};
SellerStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em .5em",
    padding: "1em",
};
SellerStyle = __decorate([
    style("sellersstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SellerStyle);
let ButtonStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ButtonStyle extends CssStyle {
};
ButtonStyle.style = {
    position: "relative",
    outline: "none",
    border: "0",
    "&:hover": {
        backgroundColor: "#e8b631",
    },
    "&:focus": {
        backgroundColor: "#f8b631",
    },
    padding: ".8em 2em",
    margin: "0em 0em",
    borderRadius: "0em .5em",
    backgroundColor: "#f8b611",
    boxShadow: "1px 1px 10px #f8e1aa",
};
ButtonStyle = __decorate([
    style("button")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ButtonStyle);
let ProductStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ProductStyle extends CssStyle {
};
ProductStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em",
    padding: "1.5em",
};
ProductStyle = __decorate([
    style("productstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ProductStyle);
let PageStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PageStyle extends CssStyle {
};
PageStyle.style = {
    borderRadius: ".6em",
    margin: "3px",
    padding: "6px 13px",
    backgroundColor: "#f8f8f8",
    border: "1px solid #e8e8e8",
    "&:hover": {
        backgroundColor: "#e8a631",
        color: "#ffffff",
        border: "1px solid #e8a631",
    },
    "&:focus": {
        backgroundColor: "#e8a631",
        color: "#ffffff",
        border: "1px solid #e8a631"
    },
    outline: "none",
};
PageStyle = __decorate([
    style("pagestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], PageStyle);
let ImageStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ImageStyle extends CssStyle {
};
ImageStyle.style = {
    minWidth: "100%",
    maxWidth: "100%",
    minHeight: "300px",
    maxHeight: "300px",
    borderRadius: ".5em",
    objectFit: "cover",
    minPosition: "relative",
};
ImageStyle = __decorate([
    style("imagestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ImageStyle);
let InputStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputStyle extends CssStyle {
};
InputStyle.style = {
    position: "relative",
    border: "0px solid #dddddd00",
    width: "100%",
    backgroundColor: "#ffffff00",
    color: "#e8a631",
    fontWeight: 600,
    fontSize: "1.2em",
    paddingLeft: "4px",
};
InputStyle = __decorate([
    style("input")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputStyle);
let RowWrapStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowWrapStyle extends CssStyle {
};
RowWrapStyle.style = {
    position: "relative",
    border: "1px solid #dddddd",
    "&:hover": {
        backgroundColor: "#f8b63101",
    },
    "&:focus": {
        backgroundColor: "#fefefe",
        boxShadow: "4px 4px 7px #eeeeff",
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    height: "100%",
    backgroundColor: "#f3f3f3",
    color: "#e8a631",
    fontWeight: 600,
    fontSize: "1.1em",
    display: "flex",
};
RowWrapStyle = __decorate([
    style("rowwrap")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowWrapStyle);
let ErrorStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ErrorStyle extends CssStyle {
};
ErrorStyle.style = {
    margin: ".1em",
    fontSize: "1em",
    color: "red",
};
ErrorStyle = __decorate([
    style("error")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ErrorStyle);
let ImageBGStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ImageBGStyle extends CssStyle {
};
ImageBGStyle.style = {
    backgroundColor: "#f8f8f8",
};
ImageBGStyle = __decorate([
    style("imagebg")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ImageBGStyle);
let SearchBtnStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SearchBtnStyle extends CssStyle {
};
SearchBtnStyle.style = {
    // padding: '0px 0px',
    alignSelf: "center",
    padding: "0px .5rem",
};
SearchBtnStyle = __decorate([
    style("searchbtn")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SearchBtnStyle);
let InputWrapStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputWrapStyle extends CssStyle {
};
InputWrapStyle.style = {
    // padding: '0px 0px',
    flex: "1 1 100%",
};
InputWrapStyle = __decorate([
    style("inputwrap")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputWrapStyle);
let XButtonStyle = class XButtonStyle {
};
XButtonStyle.style = {
    display: "block",
    marginLeft: "auto",
    marginRight: "10px",
    marginTop: "20px",
    backgroundColor: "transparent",
    border: "0",
};
XButtonStyle = __decorate([
    style("xbtnstyle")
], XButtonStyle);
let BtnStyle = class BtnStyle {
};
BtnStyle.style = {
    padding: ".53em",
};
BtnStyle = __decorate([
    style("btnstyle")
], BtnStyle);
export default container;
