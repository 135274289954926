import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react  */
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col } from "../../../components/Column";
import { Confirm } from "../../../components/Confirm";
import { Field, Form } from "../../../components/Form";
import { InputEx } from "../../../components/Form/fields-extended";
import { useProgressBar } from "../../../components/ProgressBar";
import { Row } from "../../../components/Row";
import { SagaProvider } from "../../../saga/saga-context";
import { useRestSubmit } from "../../../saga/use-rest-submit";
import { useSaga } from "../../../saga/use-saga";
import { useAuth } from "../../../use-auth";
import { validateEmail, validateMobileNo } from "../../../utils";
import { CardNano } from "../../orders/card-nano";
import { DistributionCenterEdit } from "./distribution-edit";
import container from "./style";
import { global_org_uid } from "../../../config";
const InvitationComponent = (props) => {
    const { invite, dist_uid, onDelete } = props;
    const { uid, invitation_token, status, invited_phone, invited_email } = invite;
    const setLoading = useProgressBar();
    const resendInviteSubmit = useRestSubmit((data) => {
        toast.success("Invite Resent");
    }, (error) => {
        toast.error(error);
    }, (isLoading) => {
        setLoading(isLoading);
    });
    const resentInvite = () => {
        const body = Object.assign(Object.assign({ org_uid: global_org_uid }, (invited_email) && { invited_email }), (invited_phone) && { invited_phone });
        resendInviteSubmit(`/trendutube/v1/franchisee-management/distribution-centers/${dist_uid}/invites/resend`, {
            body
        });
    };
    const detach = useRestSubmit(() => {
        toast.success("Deleted", { toastId: "go to hell" });
        onDelete();
    }, (e) => { toast.error(`${e}`); });
    return _jsx("div", Object.assign({ css: { paddingTop: "20px" } }, { children: _jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { title: "Email/Phone", value: invited_email || invited_phone }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "Status", value: status }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "Invite Token (hash)", value: invitation_token }, void 0) }, void 0), _jsxs(Col, { children: [_jsx("button", Object.assign({ onClick: () => detach(`/trendutube/v1/franchisee-management/distribution-centers/${dist_uid}/invites/${uid}/delete`, { method: "DELETE" }), css: {
                                marginLeft: "8px",
                                marginTop: "0 12 ",
                                padding: "6px 20px",
                                border: "0",
                                borderRadius: "4px",
                                backgroundColor: "#f84545",
                                color: "#fff",
                                boxShadow: "1px 1px 10px #f86666",
                            } }, { children: "Delete" }), void 0), _jsx(_Fragment, { children: status == "invited" ? _jsx("button", Object.assign({ onClick: () => resentInvite(), css: {
                                    marginLeft: "8px",
                                    marginTop: 12,
                                    padding: "6px 20px",
                                    border: "0",
                                    borderRadius: "4px",
                                    backgroundColor: "#f8b611",
                                    color: "#fff",
                                    boxShadow: "1px 1px 10px #faa",
                                } }, { children: " Re Invite " }), void 0) : null }, void 0)] }, void 0)] }, void 0) }), void 0);
};
const InvitationList = (props) => {
    const { invites = [], dist_uid, onDelete } = props;
    return _jsxs("div", { children: [" ", invites.map((invite) => _jsx("div", { children: _jsx(InvitationComponent, { invite: invite, dist_uid: dist_uid, onDelete: onDelete }, void 0) }, void 0))] }, void 0);
};
const DeliveryPersonComponent = (props) => {
    const { person, dist_uid, onDelete } = props;
    const { delivery_person_uid, full_name, login_uid, username, first_name, last_name } = person;
    const confirmRef = useRef(null);
    const detach = useRestSubmit(() => {
        toast.success("Deleted", { toastId: "go to hell" });
        onDelete();
    }, (e) => { toast.error(`${e}`); });
    const detachDeliveryPerson = () => {
        detach(`/trendutube/v1/franchisee-management/distribution-centers/${dist_uid}/delivery-persons/${delivery_person_uid}/remove`, { method: "POST" });
    };
    return _jsx("div", Object.assign({ css: { paddingTop: "20px" } }, { children: _jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { title: "FullName", value: `${first_name} ${last_name}` }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "Email/Phone", value: username }, void 0) }, void 0), _jsxs(Col, { children: [_jsx("button", Object.assign({ onClick: () => { var _a; (_a = confirmRef.current) === null || _a === void 0 ? void 0 : _a.show(true); }, css: {
                                marginLeft: "8px",
                                marginTop: "0 12 ",
                                padding: "6px 20px",
                                border: "0",
                                borderRadius: "4px",
                                backgroundColor: "#f84545",
                                color: "#fff",
                                boxShadow: "1px 1px 10px #f86666",
                            } }, { children: "Detach" }), void 0), _jsx(Confirm, { message: "Are you sure you want to detach user ?", ref: confirmRef, buttons: {
                                ok: {
                                    label: "Confirm Detachment", callback: (c, close) => {
                                        close();
                                        detachDeliveryPerson();
                                    }
                                },
                                cancel: {
                                    label: "Cancel", css: { backgroundColor: "#f14545" },
                                    callback: (c, close) => {
                                        close();
                                    }
                                }
                            } }, void 0)] }, void 0)] }, void 0) }), void 0);
};
const DeliveryPersonList = (props) => {
    const { persons = [], dist_uid, onDelete } = props;
    return _jsxs("div", { children: [" ", persons.map((person) => _jsx("div", { children: _jsx(DeliveryPersonComponent, { person: person, dist_uid: dist_uid, onDelete: onDelete }, void 0) }, void 0))] }, void 0);
};
const InviteForm = (prop) => {
    const { onSubmit, dist_uid } = prop;
    const submit = useRestSubmit(() => {
        if (onSubmit)
            onSubmit();
        toast.success("Queued Invitation");
    }, (e) => {
        toast.error(e);
    });
    function sendInvite(data) {
        const { invitation } = data || {};
        const { username, full_name } = invitation || {};
        let invited_email, invited_phone;
        if (validateEmail(username)) {
            invited_email = username;
        }
        else if (validateMobileNo(username)) {
            invited_phone = username;
        }
        else {
            toast.warn("not a valid phone no. or mobile no.");
            return;
        }
        submit(`/trendutube/v1/franchisee-management/distribution-centers/${dist_uid}/invites/add`, {
            body: {
                invited_email, invited_phone, full_name, org_uid: global_org_uid
            }
        });
    }
    return _jsx("div", Object.assign({ css: { marginTop: 16 } }, { children: _jsx(Row, { children: _jsx(Col, { children: _jsx(Form, Object.assign({ name: "invitation", onSubmit: sendInvite }, { children: _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 5 }, { children: [_jsx("div", { children: "Email/Mobile No." }, void 0), _jsx(Field, Object.assign({ name: "username" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0), _jsx(Col, Object.assign({ cols: 5 }, { children: _jsxs("div", Object.assign({ css: { marginLeft: "10px" } }, { children: [_jsx("div", { children: "Full Name" }, void 0), _jsx(Field, Object.assign({ name: "full_name" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0) }), void 0), _jsx(Col, Object.assign({ cols: 2 }, { children: _jsxs("div", { children: [_jsx("div", { children: "\u00A0" }, void 0), _jsx("div", { children: _jsx("button", Object.assign({ css: {
                                                    paddingLeft: 10,
                                                    marginLeft: "8px",
                                                    marginTop: 2,
                                                    padding: "12px 20px",
                                                    border: "0",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#f8b611",
                                                    color: "#fff",
                                                    boxShadow: "1px 1px 10px #f8e1aa",
                                                }, type: "submit" }, { children: " Add  " }), void 0) }, void 0)] }, void 0) }), void 0)] }, void 0) }), void 0) }, void 0) }, void 0) }), void 0);
};
export const DistributionListInternal = (props) => {
    var _a, _b;
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const confirmRef = useRef(null);
    const { category, button, } = useStyles("categorydesc", "button");
    const { uid } = props.match.params;
    const { data, loadUrl, reload, isFetching } = useSaga(`/trendutube/v1/franchisee-management/franchisees/${uid}/distribution-centers/list`, { headers });
    const [disp, setDisp] = useState("none");
    const [editDisplay, setEditDisplay] = useState(false);
    const [editView, setEditView] = useState(null);
    useEffect(() => {
        console.log("DATA++", data);
    }, [data]);
    const loading = useProgressBar();
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    const onEditFinished = () => {
        setEditDisplay(false);
        loadUrl(`/trendutube/v1/franchisee-management/franchisees/${uid}/distribution-centers/list`);
    };
    const onCreateFinished = () => {
        setDisp("none");
        loadUrl(`/trendutube/v1/franchisee-management/franchisees/${uid}/distribution-centers/list`);
    };
    const deleteSubmit = useRestSubmit(() => {
        reload();
        toast.success("Franchisee Deleted");
    }, (e) => {
        toast.error(`${e}`);
    });
    const deleteDistCenter = (distCenterUid) => {
        deleteSubmit(`/trendutube/v1/franchisee-management/distribution-centers/${distCenterUid}/delete`, { method: "DELETE" });
    };
    return (_jsx("div", Object.assign({ css: { padding: "20px" } }, { children: _jsxs(Row, { children: [_jsx(Col, Object.assign({ css: category, cols: 12 }, { children: _jsxs(Row, { children: [_jsx(Col, { children: _jsxs("div", Object.assign({ css: { margin: 10 } }, { children: ["Distribution Centers of ", _jsx("span", Object.assign({ css: { fontWeight: 700 } }, { children: data && ((_b = (_a = data[0]) === null || _a === void 0 ? void 0 : _a.franchisee) === null || _b === void 0 ? void 0 : _b.franchisee_name) }), void 0)] }), void 0) }, void 0), _jsxs(Col, { children: [_jsx("div", Object.assign({ style: {
                                            borderRadius: ".6em",
                                            textAlign: "right"
                                        }, onClick: () => setDisp("block") }, { children: _jsx("button", Object.assign({ css: button }, { children: "Add New Distribution " }), void 0) }), void 0), disp == "none" ? null : _jsx(DistributionCenterEdit, { franchisee_uid: uid, onClose: onCreateFinished }, void 0)] }, void 0)] }, void 0) }), void 0), data
                    ? // JSON.stringify(items)
                        data &&
                            data.map((item, index) => (_jsx(Col, Object.assign({ css: category, cols: 12 }, { children: _jsxs("div", Object.assign({ style: {
                                        borderRadius: ".6em",
                                        backgroundColor: "#fff",
                                        margin: "5px",
                                        padding: "20px",
                                        // boxShadow:
                                        // " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                    } }, { children: [!editDisplay ?
                                            _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ style: { textAlign: "right" } }, { children: [_jsx("button", Object.assign({ onClick: () => {
                                                                    setEditView(item.uid);
                                                                    setEditDisplay(true);
                                                                }, style: {
                                                                    marginLeft: "5px",
                                                                    padding: "6px 16px",
                                                                    border: "0",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8b611",
                                                                    color: "#fff",
                                                                    boxShadow: "1px 1px 10px #f8e1aa",
                                                                } }, { children: "Edit" }), void 0), _jsx("button", Object.assign({ onClick: () => {
                                                                    var _a;
                                                                    (_a = confirmRef.current) === null || _a === void 0 ? void 0 : _a.show(true);
                                                                }, style: {
                                                                    marginLeft: "5px",
                                                                    padding: "6px 16px",
                                                                    border: "0",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8b611",
                                                                    color: "#fff",
                                                                    boxShadow: "1px 1px 10px #f8e1aa",
                                                                } }, { children: "Delete" }), void 0), _jsx(Confirm, { message: "DO you want to continue", ref: confirmRef, buttons: {
                                                                    ok: {
                                                                        label: "Confirm Delete", callback: (c, close) => {
                                                                            close();
                                                                            deleteDistCenter(item === null || item === void 0 ? void 0 : item.uid);
                                                                        }
                                                                    },
                                                                    cancel: {
                                                                        label: "Cancel", css: { backgroundColor: "#f14545" },
                                                                        callback: (c, close) => {
                                                                            close();
                                                                        }
                                                                    }
                                                                } }, void 0)] }), void 0) }, void 0) }, void 0) : null, editDisplay && editView == item.uid ?
                                            _jsx(Row, { children: _jsx(Col, { children: _jsx(DistributionCenterEdit, { distributionCenter: item, onClose: onEditFinished, franchisee_uid: uid }, void 0) }, void 0) }, void 0) : _jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { value: item.distribution_center_name, title: "Distribution Name" }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { value: item.distribution_center_code, title: "Distribution Code" }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { value: item.uid, title: "Distribution Centre Uid" }, void 0) }, void 0)] }, void 0), item.distribution_center_address ?
                                                    _jsxs(_Fragment, { children: [_jsx("hr", { style: {
                                                                    border: "0",
                                                                    height: "0",
                                                                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                                                    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                                                } }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx("div", Object.assign({ css: { fontSize: "1.1em", marginTop: 18 } }, { children: _jsx("strong", { children: "Distribution Address:" }, void 0) }), void 0) }, void 0) }, void 0), _jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { title: "Address Line 1", value: item.distribution_center_address.address_line1 }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "Address Line 2", value: item.distribution_center_address.address_line2 }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "City", value: item.distribution_center_address.city }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "Pincode", value: item.distribution_center_address.pin_code }, void 0) }, void 0)] }, void 0), _jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { title: "Landmark", value: item.distribution_center_address.landmark }, void 0) }, void 0), _jsx(Col, { children: item.distribution_center_address.location &&
                                                                            item.distribution_center_address.location
                                                                                .coordinates ? (_jsx(_Fragment, { children: _jsx("a", Object.assign({ href: `https://maps.google.com/?q=${item.distribution_center_address.location.coordinates[0]},${item.distribution_center_address.location.coordinates[1]}`, target: "_blank" }, { children: "View In Map" }), void 0) }, void 0)) : ("") }, void 0)] }, void 0), _jsx("hr", { style: {
                                                                    border: "0",
                                                                    height: "0",
                                                                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                                                    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                                                } }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx("div", Object.assign({ css: { fontSize: "1.1em", marginTop: 18 } }, { children: _jsx("strong", { children: " Delivery Persons" }, void 0) }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx(DeliveryPersonList, { persons: item.delivery_persons, dist_uid: item.uid, onDelete: () => { reload(); } }, void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx("div", Object.assign({ css: { fontSize: "1.1em", marginTop: 18 } }, { children: _jsx("strong", { children: " Invitations" }, void 0) }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx("div", { children: _jsx(InviteForm, { dist_uid: item.uid, onSubmit: () => { reload(); } }, void 0) }, void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx(InvitationList, { invites: item.invitations, dist_uid: item.uid, onDelete: () => { reload(); } }, void 0) }, void 0) }, void 0)] }, void 0)
                                                    : null] }, void 0)] }), void 0) }), void 0)))
                    : ""] }, void 0) }), void 0)
    // </StyleProvider>
    );
};
export const DistributionList = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(DistributionListInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
