import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { AddressComponent } from "../address";
import { Box, Paper, styled, Typography } from "@mui/material";
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const BuyerComponent = (props) => {
    const { buyer, shipping_address_info } = props;
    const { address = [], email, full_name, first_name, last_name, mobile_no, username } = buyer || {};
    let username_email, username_mobile_no;
    const { cardnanotitle, cardnanovalue } = useStyles("cardnanovalue", "cardnanotitle");
    const Item = styled(Paper)(({ theme }) => (Object.assign(Object.assign(Object.assign({ backgroundColor: '#fff' }, theme.typography.body2), { padding: theme.spacing(1), textAlign: 'center', border: 'None', boxShadow: 'None', color: theme.palette.text.secondary }), theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }))));
    if (emailRegex.test(username))
        username_email = username;
    else
        username_mobile_no = username;
    return _jsx("div", Object.assign({ css: { margin: "30px 0px" } }, { children: _jsxs(Box, Object.assign({ sx: { display: 'flex', flexDirection: 'column', border: '1px solid #CDDBE0', marginTop: '15px', padding: '15px', borderRadius: '10px' } }, { children: [_jsx(Typography, Object.assign({ sx: { fontWeight: 500 } }, { children: "Buyer Details" }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 } }, { children: [_jsxs(Item, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                fontWeight: 500,
                                                marginRight: '8px'
                                            } }, { children: "Full Name :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                color: '#000000'
                                            } }, { children: full_name }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                fontWeight: 500,
                                                marginRight: '8px'
                                            } }, { children: "Email :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                color: '#000000'
                                            } }, { children: email }), void 0)] }), void 0)] }, void 0), _jsxs(Item, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                fontWeight: 500,
                                                marginRight: '8px'
                                            } }, { children: "User Name :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                color: '#000000'
                                            } }, { children: username }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                fontWeight: 500,
                                                marginRight: '8px'
                                            } }, { children: "Address :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                color: '#000000'
                                            } }, { children: shipping_address_info ? _jsx(AddressComponent, { address: shipping_address_info, multiline: false }, void 0) : null }), void 0)] }), void 0)] }, void 0), _jsx(Item, { children: _jsxs(Box, Object.assign({ sx: { display: 'flex', } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                            fontWeight: 500,
                                            marginRight: '8px'
                                        } }, { children: "Phone Number :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                            color: '#000000'
                                        } }, { children: mobile_no ? mobile_no : '----------' }), void 0)] }), void 0) }, void 0)] }), void 0), _jsx(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }, { children: _jsx(Item, { children: _jsxs(Box, Object.assign({ sx: { display: 'flex', } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                        fontWeight: 500,
                                        marginRight: '8px'
                                    } }, { children: "Delivery Instruction :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                        color: '#000000'
                                    } }, { children: shipping_address_info && shipping_address_info.delivery_instructions ? shipping_address_info.delivery_instructions : '----------' }), void 0)] }), void 0) }, void 0) }), void 0)] }), void 0) }), void 0);
};
