import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Col } from "../../../components/Column";
import { Row } from "../../../components/Row";
import { Tray } from "../../../components/Tray";
import { usePagination } from "../../../saga/use-pagination";
import { MapView } from "../../distribution-center/map";
import { SearchToolBar } from "../../../components/Search";
export const StoreComponent = (props) => {
    const { data: store } = props || {};
    const { uid, store_name, address, distance } = store || {};
    const [addr] = address || [];
    const { address_line1, address_line2, city, pin_code, landmark, location } = addr || {};
    const { coordinates } = location || {};
    const line1 = Object.values({ address_line1, address_line2, city }).join(", ");
    const line2 = Object.values({ city, pin_code }).join(", ");
    const line3 = Object.values({ landmark }).join(", ");
    return _jsxs(_Fragment, { children: [_jsx("td", { children: store_name }, void 0), _jsx("td", { children: `${distance || 0.0}` }, void 0), _jsx("td", { children: _jsx("table", { children: _jsxs("tbody", { children: [_jsx("tr", { children: _jsx("td", { children: line1 }, void 0) }, void 0), _jsx("tr", { children: _jsx("td", { children: line2 }, void 0) }, void 0), _jsx("tr", { children: _jsx("td", { children: line3 }, void 0) }, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0);
};
export const StoreListGeo = (props) => {
    const { lngLat, onSelect, currentSelection } = props;
    const [storeList, setStoreList] = useState([]);
    const [ln, lt] = lngLat;
    const [ltLn, setLtLg] = useState([lt, ln]);
    const [selectedUids, setSelectedUids] = useState([]);
    const [selectedStores, setSelectedStores] = useState(currentSelection);
    const initialLngLat = lngLat.join(",");
    const [km, setKm] = useState(30.00);
    const [coordinates, setCoordinates] = useState(initialLngLat);
    const ref = useRef(null);
    const [show, setshow] = useState(true);
    const [places, setPredictions] = useState();
    const [placesService, setPlacesService] = useState();
    const mRef = useRef(null);
    const { storetablestyle, action_btn_ms } = useStyles("storetablestyle", "action_btn_ms");
    const autoCompleteService = new google.maps.places.AutocompleteService();
    useLayoutEffect(() => {
        if (ref.current != null) {
            const service = new google.maps.places.PlacesService(ref.current);
            setPlacesService(service);
        }
    }, []);
    const queryParams = {};
    const { data, loadPage, paging, loadUrl } = usePagination("/trendutube/v1/admin-seller-management/stores/search", { queryParams });
    // const { data, loadUrl } = useSaga<Store[]>(`/trendutube/v1/auth/users/list?coordinates=${coordinates}&search_radius=${km * 1000}&app_type=BUYER&page_size=5000&page=1`, {}, false)
    const onCenterChange = (coordinates) => {
        const lngLatStr = (coordinates || []).reverse().join(",");
        setCoordinates(lngLatStr);
    };
    useEffect(() => {
        const radius = km * 1000;
        const _queryParams = { coordinates: coordinates, search_radius: 30000, page_size: 1000 };
        loadUrl(`/trendutube/v1/admin-seller-management/stores/search`, 0, _queryParams);
    }, []);
    useEffect(() => {
        const radius = km * 1000;
        const _queryParams = { coordinates: coordinates, search_radius: 30000, page_size: 1000 };
        loadUrl(`/trendutube/v1/admin-seller-management/stores/search`, 0, _queryParams);
    }, [coordinates, km]);
    useEffect(() => {
        if (data) {
            setStoreList(data);
        }
        else {
            setStoreList([]);
        }
    }, [data]);
    function onRadiusChange(e) {
        setKm(parseFloat(e.target.value) || 0);
    }
    const onPlaceChange = (ev) => {
        if (!ev.target.value || ev.target.value == "") {
            setshow(false);
            return;
        }
        autoCompleteService.getQueryPredictions({ input: ev.target.value }, displaySuggestions);
    };
    const displaySuggestions = function (predictions, status) {
        if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
            alert(status);
            return;
        }
        setPredictions(predictions);
        if (predictions.length > 0) {
            setshow(true);
        }
    };
    const onPlaceSelected = (item) => {
        placesService === null || placesService === void 0 ? void 0 : placesService.getDetails({ placeId: item.place_id, fields: ["geometry"] }, (place) => {
            var _a, _b, _c, _d, _e;
            const lat = (_b = (_a = place === null || place === void 0 ? void 0 : place.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lat();
            const lng = (_d = (_c = place === null || place === void 0 ? void 0 : place.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.lng();
            if (lat != null && lng != null) {
                // setLtLg([lat, lng])
                // alert(lat)
                (_e = mRef.current) === null || _e === void 0 ? void 0 : _e.setLatLng(lat, lng);
                setCoordinates(`${lng},${lat}`);
            }
        });
    };
    const onCheckChange = (uid) => {
        return (event) => {
            const index = selectedUids.findIndex(i => i == uid);
            if (event.target.checked) {
                if (index < 0)
                    setSelectedUids([...selectedUids, uid]);
            }
            else {
                if (index >= 0) {
                    selectedUids.splice(index, 1);
                    setSelectedUids([...selectedUids]);
                }
            }
        };
    };
    const onCheckChange2 = (store) => {
        return (event) => {
            const index = selectedStores.findIndex(s => s.uid == store.uid);
            if (event.target.checked) {
                if (index < 0)
                    setSelectedStores([...selectedStores, store]);
            }
            else {
                if (index >= 0) {
                    selectedStores.splice(index, 1);
                    setSelectedStores([...selectedStores]);
                }
            }
        };
    };
    const selectOrDeSelectAll = (event) => {
        if (event.target.checked) {
            const allStoreUids = storeList.map(i => i.uid);
            setSelectedStores(storeList);
        }
        else
            setSelectedStores([]);
    };
    const goBack = () => {
        onSelect(selectedStores);
    };
    const [value, setValue] = useState();
    const onInputChange = (e) => {
        setValue(e.target.value);
        // onSearchChange(e.target.value)
    };
    const filters = [
        { title: "Store Name", value: "store_name", filter: false },
        // { title: "Mobile Number", value: "mobile_no", filter: false },
        // { title: "Pin Code", value: "pin_code", filter: false },
        // { title: "City", value: "city", filter: false },
    ];
    function onSearchChange(value, filters, sortBy) {
        if (value && value != "") {
            const params = {};
            filters.filter(f => f.filter).forEach(filter => {
                Object.defineProperty(params, filter.value, {
                    value, configurable: true,
                    enumerable: true
                });
            });
            loadUrl(`/trendutube/v1/admin-seller-management/stores/search`, 0, params);
        }
        else {
            const _queryParams = { coordinates: coordinates, search_radius: 30000, page_size: 1000 };
            loadUrl(`/trendutube/v1/admin-seller-management/stores/search`, 0, _queryParams);
        }
    }
    return _jsxs("div", Object.assign({ css: { height: '80vh' } }, { children: [_jsx("div", { ref: ref }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ top: true }, { children: [_jsxs(Row, { children: [_jsx(Col, { children: " Distance " }, void 0), _jsxs(Col, { children: [" ", _jsxs("div", Object.assign({ css: { padding: 6 } }, { children: [_jsx("input", { css: { marginRight: 5, padding: 2, textAlign: "end" }, value: km, onChange: onRadiusChange }, void 0), "Km"] }), void 0)] }, void 0), _jsx(Col, {}, void 0)] }, void 0), _jsx(Row, { children: _jsx("input", { css: { width: "100%", marginBottom: 4, padding: 2 }, onChange: onPlaceChange }, void 0) }, void 0), _jsx(Row, { children: show && places ? _jsx(Tray, { items: places, titleProperty: "description", keyProperty: "place_id", width: "100%", onSelectItem: onPlaceSelected, view: (place) => _jsx("div", Object.assign({ css: { paddingLeft: 6, paddingRight: 6 } }, { children: place.item.description }), void 0) }, void 0) : null }, void 0), ltLn ? _jsx(MapView, { onCenterChange: onCenterChange, latLng: ltLn, ref: mRef }, void 0) : null] }), void 0), _jsxs(Col, Object.assign({ top: true }, { children: [_jsx(Row, { children: _jsxs(Col, Object.assign({ right: true }, { children: [_jsx("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: _jsxs("button", Object.assign({ css: action_btn_ms, onClick: goBack }, { children: [" ", selectedStores.length > 0 ? "Select" : "Go Back", " "] }), void 0) }), void 0), _jsx("div", Object.assign({ css: {
                                                display: "flex", flexGrow: 1, alignItems: "center",
                                                backgroundColor: 'white', border: "none", borderRadius: 4,
                                                padding: "0px 6px 0px 12px", color: "#fff", margin: '8px'
                                            } }, { children: _jsx(SearchToolBar, { filters: filters, onChange: onSearchChange }, void 0) }), void 0)] }), void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx("div", Object.assign({ css: { height: "80vh", overflowY: "scroll", paddingLeft: 20 } }, { children: (data || coordinates) && (storeList != null && storeList.length > 0) ?
                                            _jsxs("table", Object.assign({ css: storetablestyle }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: " Store Name" }, void 0), _jsx("th", { children: " Distance" }, void 0), _jsx("th", { children: " Address" }, void 0), _jsxs("th", { children: [" ", _jsx("input", { type: "checkbox", onChange: selectOrDeSelectAll, checked: storeList.every(s1 => selectedStores.some(s2 => s2.uid == s1.uid)) }, void 0)] }, void 0), _jsx("th", { children: "\u00A0\u00A0\u00A0\u00A0\u00A0" }, void 0)] }, void 0) }, void 0), _jsx("tbody", { children: storeList.map(store => _jsxs("tr", { children: [_jsx(StoreComponent, { data: store }, void 0), _jsxs("td", { children: [" ", _jsx("input", { type: "checkbox", onChange: onCheckChange2(store), checked: selectedStores.some(st => st.uid == store.uid) }, void 0)] }, void 0), _jsx("td", { children: "\u00A0\u00A0\u00A0\u00A0\u00A0" }, void 0)] }, void 0)) }, void 0)] }), void 0) : null }), void 0) }, void 0) }, void 0)] }), void 0)] }, void 0)] }), void 0);
};
