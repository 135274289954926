import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect } from "react";
import { useProgressBar } from "../../../components/ProgressBar";
import { useSubmit } from "../../../saga/use-submit";
import { BasketItemViewComponent } from "./basket-item-view-component";
import { toast } from 'react-toastify';
import { rs } from "../../../utils";
export const BasketStoreCart = (props) => {
    const { basket_uid, cart, onUnAssigned, onAssigned, deleteBasketItemFn, buyer_info } = props;
    const { basket_items = [], store, status, cart_uid, estimate_amount } = cart;
    const { images, store_uid, store_name } = store;
    const loading = useProgressBar();
    const { action_btn_ms } = useStyles("action_btn_ms");
    const store_details = `${store_name} (${store_uid})`;
    const { submit, isFetching, isCompleted, status: confirmStatus, error } = useSubmit();
    const clickHandler = () => {
        submit(`/trendutube/v1/basket-management/admin/baskets/${basket_uid}/carts/${cart_uid}/confirm`, {});
    };
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (confirmStatus == "ok")
            toast.success("Basket order sent to seller");
    }, [confirmStatus]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    return _jsxs("div", { children: [store_uid ?
                _jsxs("div", Object.assign({ css: { margin: "20px 10px 10px 0px", fontWeight: 600 } }, { children: [_jsx("span", { children: store_details }, void 0), status == "cart-assigned" ? _jsx("button", Object.assign({ css: [action_btn_ms, { marginLeft: "13px" }], onClick: clickHandler }, { children: "Sent" }), void 0) : null, status == "seller-accepted" ?
                            _jsxs("span", Object.assign({ css: { marginLeft: "10px", color: "green" } }, { children: ["Seller Accepted, Estimated Price ", rs(estimate_amount || 0)] }), void 0) :
                            _jsx("span", {}, void 0)] }), void 0) : null, _jsx("div", { children: 
                // carts.map(cart =>
                basket_items.map(basket_item => _jsx(BasketItemViewComponent, Object.assign({}, { status, buyer_info, basket_item, basket_uid, onAssigned, onUnAssigned, deleteBasketItemFn }), void 0)) }, void 0)] }, void 0);
};
