import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { FieldContextImpl } from './field';
import { FieldContext } from './type';
export class FieldList extends Component {
    componentDidMount() {
        const items = [];
        if (this.context) {
            const { path } = this.context;
            for (let i = 0; i < this.props.count; i++)
                items.push({ key: `${path}[${items.length}]` });
        }
        this.setState({ items });
    }
    add() {
        const { items } = this.state;
        let { path } = this.context;
        items.push({ key: `${path}[${items.length}]` });
        this.setState(Object.assign(Object.assign({}, this.state), { items }));
    }
    remove(path) {
        return (index) => {
            if (typeof index === "undefined")
                return;
            const { form } = this.context;
            const { items } = this.state;
            const newitems = items.splice(index, 1);
            if (newitems.length > 0) {
                this.setState({ items });
                form.removeArrayElement(path, index);
            }
        };
    }
    getChildren() {
        const { props, context } = this;
        const { name, children } = props;
        const { path } = context;
        const thisPath = path ? `${path}.${name}` : name;
        const add = this.add.bind(this);
        const remove = this.remove(thisPath);
        const fieldContext = new FieldContextImpl(thisPath, context.form, true);
        const list = this.state.items.map(({ key }) => {
            return { key };
        });
        //we could have provided a property "component" and 
        //recieve the fragment
        //but add function is common and 
        //its UI element is separate to the list
        //function child also mean the fields can be better organised
        //though a weird syntax is placed there
        return _jsx(FieldContext.Provider, Object.assign({ value: Object.assign({}, fieldContext) }, { children: typeof children === "function" ? children(list, add, remove) : null }), void 0);
    }
    render() {
        return _jsxs(_Fragment, { children: [" ", this.state && this.getChildren(), " "] }, void 0);
    }
}
FieldList.contextType = FieldContext;
