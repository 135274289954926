import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react  */
import React, { useEffect, useState } from "react";
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../../saga/saga-context";
import { useSaga } from "../../../saga/use-saga";
import { useAuth } from "../../../use-auth";
import container from "./style";
import { Trash, Edit } from "react-feather";
import { useSubmit } from "../../../saga/use-submit";
import { NavLink } from "react-router-dom";
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { SearchOutlined } from "@mui/icons-material";
const BannerItem = (props) => {
    var _a, _b, _c, _d;
    const { submit, data, error, status } = useSubmit();
    const { categoryid, categoryname, category, categorydescription, menutextstyle, namestyle, titlestyle, textstyle, productstyle } = useStyles("categorydesc", "category", "categoryname", "namestyle", "categorydescription", "menutextstyle", "titlestyle", "textstyle", "productstyle");
    const { uid, title, banner_for, images, onDelete } = props;
    const deleteCat = (uid) => {
        submit(`/trendutube/v1/admin-offers-management/admin/banners/${uid}/delete`, { method: "DElETE" });
    };
    useEffect(() => {
        if (onDelete)
            onDelete();
    }, [data]);
    return (_jsx(Box, Object.assign({ css: category, padding: 2, borderRadius: 2, boxShadow: 2, bgcolor: "#f9f9f9" }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2, alignItems: "center" }, { children: [((_a = images[0]) === null || _a === void 0 ? void 0 : _a.uid) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 5 }, { children: _jsx("img", { src: images[0].url, alt: "Banner", style: {
                            borderRadius: 8,
                            objectFit: 'cover',
                            width: '100%',
                            maxWidth: 400,
                            height: 300,
                        } }, void 0) }), void 0)), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 7 }, { children: [_jsx(Typography, Object.assign({ css: titlestyle, variant: "h6" }, { children: "Banner Name" }), void 0), _jsx(Typography, Object.assign({ css: textstyle, variant: "body1", gutterBottom: true }, { children: title }), void 0), _jsx(Typography, Object.assign({ css: titlestyle, variant: "h6" }, { children: "Banner For" }), void 0), _jsx(Typography, Object.assign({ css: textstyle, variant: "body1", gutterBottom: true }, { children: (_b = banner_for === null || banner_for === void 0 ? void 0 : banner_for.discount_code) !== null && _b !== void 0 ? _b : "---" }), void 0), _jsxs(Grid, Object.assign({ container: true, spacing: 2, style: { marginTop: 16 } }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Button, Object.assign({ startIcon: _jsx(Trash, {}, void 0), variant: "outlined", color: "error", onClick: () => deleteCat(uid), sx: { textTransform: 'none' } }, { children: "Delete" }), void 0) }), void 0), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(NavLink, Object.assign({ to: `edit/${uid}?name=${title}&discid=${(_c = banner_for === null || banner_for === void 0 ? void 0 : banner_for.uid) !== null && _c !== void 0 ? _c : "0"}&image=${((_d = images[0]) === null || _d === void 0 ? void 0 : _d.uid) ? images[0].url : ""}`, style: { textDecoration: 'none' } }, { children: _jsx(Button, Object.assign({ startIcon: _jsx(Edit, {}, void 0), variant: "contained", color: "primary", sx: { textTransform: 'none' } }, { children: "Edit" }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export const BannerListInternal = () => {
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    /*
    const { data, loadUrl } = useSaga<D>(
    `/trendutube/v1/seller-management/seller-registrations/list`,
    { headers }
    );
    */
    const { productstyle } = useStyles("productstyle");
    const { data, loadUrl } = useSaga(`/trendutube/v1/admin-offers-management/admin/banners/list`, { headers }, false);
    const { banner_list } = data || {};
    const deleteHandler = (index) => () => {
        // items.splice(index, 1)
        // alert(index)
        // loadUrl(`/trendutube/v1/public/categories/list`)
    };
    useEffect(() => {
        console.log("DATA++", data);
    }, [data]);
    console.log("Banner List:", banner_list);
    const [value, setValue] = useState();
    const onInputChange = (e) => {
        setValue(e.target.value);
        loadUrl(`/trendutube/v1/admin-offers-management/admin/banners/list?banner_name=${e.target.value}&`);
    };
    return (_jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsxs("div", { children: [_jsx("div", Object.assign({ css: productstyle }, { children: _jsx(FormControl, Object.assign({ fullWidth: true, sx: { height: '44px' } }, { children: _jsx(TextField, { fullWidth: true, size: "small", variant: "outlined", value: value, placeholder: 'Search', onChange: onInputChange, InputProps: {
                                startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchOutlined, {}, void 0) }), void 0)),
                            } }, void 0) }), void 0) }), void 0), banner_list &&
                    banner_list.map((item, index) => (_jsx(BannerItem, Object.assign({}, item, { onDelete: deleteHandler(index) }), item.uid)))] }, void 0) }), void 0));
};
export const BannersList = () => (_jsx(SagaProvider, { children: _jsx(BannerListInternal, {}, void 0) }, void 0));
