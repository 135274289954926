import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from "react";
import randomcolor from "randomcolor";
import faker from "faker";
import data from "./data.json";
const Card = (props) => {
    const levelColor = randomcolor();
    return (_jsx("ul", { children: props.data.map((item) => {
            var _a;
            return (_jsx(Fragment, { children: _jsxs("li", { children: [_jsxs("div", Object.assign({ className: "card" }, { children: [_jsx("div", Object.assign({ className: "image" }, { children: _jsx("img", { src: faker.image.avatar(), alt: "Profile", style: { borderColor: levelColor } }, void 0) }), void 0), _jsxs("div", Object.assign({ className: "card-body" }, { children: [_jsx("h4", { children: faker.name.findName() }, void 0), _jsx("p", { children: faker.name.jobTitle() }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: "card-footer", style: { background: levelColor } }, { children: [_jsx("img", { src: "https://www.flaticon.com/svg/static/icons/svg/2950/2950657.svg", alt: "Chat" }, void 0), _jsx("img", { src: "https://www.flaticon.com/svg/static/icons/svg/1034/1034131.svg", alt: "Call" }, void 0), _jsx("img", { src: "https://www.flaticon.com/svg/static/icons/svg/570/570387.svg", alt: "Video" }, void 0)] }), void 0), _jsx("div", {}, void 0)] }), void 0), ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) && _jsx(Card, { data: item.children }, void 0)] }, void 0) }, item.name));
        }) }, void 0));
};
const Chart = () => {
    return (_jsx("div", Object.assign({ className: "org-tree" }, { children: _jsx(Card, { data: data }, void 0) }), void 0));
};
export default Chart;
