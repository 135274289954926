import { useReducerInjector } from "./use-reducer-injector";
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { SagaContext } from './saga-context';
import { uuidv4 } from "./use-saga";
import { useAuth } from "../use-auth";
const UPDATE_RESULT = "UPDATE_RESULT";
const UPDATE_REQUEST = "UPDATE_REQUEST";
const UPDATE_ERROR = "UPDATE_ERROR";
const UPDATE_SUBMITTED = "UPDATE_SUBMITTED";
const UPDATE_RESET = "UPDATE_RESET";
function createReducer(prefix) {
    return function (state, action) {
        var _a, _b;
        const [act, pr] = (_a = action === null || action === void 0 ? void 0 : action.type) === null || _a === void 0 ? void 0 : _a.split(":");
        if (prefix !== pr)
            return state || {};
        let newState = {};
        switch (act) {
            case UPDATE_RESULT:
                newState = { data: (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data, status: "ok" };
                break;
            case UPDATE_ERROR:
                const { error } = action === null || action === void 0 ? void 0 : action.payload;
                newState = { error, status: "error" };
                break;
            case UPDATE_SUBMITTED:
                newState = { status: "submitted" };
                break;
            case UPDATE_RESET:
                newState = { status: "reset" };
                break;
        }
        return Object.assign(Object.assign({}, state), newState);
    };
}
export function useSubmit(options = {}, noauth = false) {
    const dispatch = useDispatch();
    const { inject, expel } = useReducerInjector();
    const [_prefix] = useState(uuidv4());
    const { sagaMiddleware } = useContext(SagaContext);
    const { idAuthValid, org_uid, user_uid, access_token } = useAuth(noauth);
    const [isFetching, setisFetching] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    function* sagaWorkerForSave(action) {
        yield put({ type: `${UPDATE_SUBMITTED}:${_prefix}` });
        const { url, body: b, query: q, form, method: m } = action.payload;
        let body;
        const method = m || "POST";
        const query = new URLSearchParams(q).toString();
        const Authorization = `Bearer ${access_token}`;
        // const requestUrl = `${url}?${query}`;
        const requestUrl = `${url}`;
        let headers = noauth ? {} : { Authorization };
        if (!form) {
            headers = Object.assign(Object.assign({}, headers), { "Content-Type": "application/json" });
            body = JSON.stringify(b);
        }
        else {
            var formData = new FormData();
            for (var p in b) {
                //b[p] is not an array, likely to be iterator
                Array.from(b[p]).forEach(elem => formData.append("images", elem));
            }
            body = formData;
            // this is useless
            // headers = { ...headers, "Content-Type": 'multipart/form-data' }
        }
        try {
            //it would be appropriate to set isFetching here wrapepd around the remote calls
            setisFetching(true);
            const resp = yield call(fetch, requestUrl, { method, body, headers });
            const result = yield resp.json();
            const { status, error } = result;
            setisFetching(false);
            setIsCompleted(true);
            if (status === "ok") {
                yield put({ type: `${UPDATE_RESULT}:${_prefix}`, payload: result });
            }
            else {
                yield put({ type: `${UPDATE_ERROR}:${_prefix}`, payload: { error } });
            }
        }
        catch (error) {
            yield put({ type: `${UPDATE_ERROR}:${_prefix}`, payload: { error } });
        }
    }
    useEffect(() => {
        const reducer = createReducer(_prefix);
        inject(_prefix, reducer);
        const task = sagaMiddleware.run(saga);
        //no preloads
        // if (url && url != "") dispatch({ type: `${UPDATE_REQUEST}:${_prefix}`, payload: { url } })
        //called on unmount !
        return () => {
            task.cancel();
            expel(_prefix);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const data = useSelector((state) => {
        if (state[_prefix]) {
            return state[_prefix].data;
        }
        //should we return anything else ? like null or state ? : TODO
        return;
    });
    const error = useSelector((state) => {
        if (state[_prefix] && state[_prefix].error) {
            return state[_prefix].error;
        }
        //should we return anything else ? like null or state ? : TODO
        return;
    });
    const status = useSelector((state) => {
        if (state[_prefix] && state[_prefix].status) {
            return state[_prefix].status;
        }
        //should we return anything else ? like null or state ? : TODO
        return "init";
    });
    function reset() {
        dispatch({ type: `${UPDATE_RESET}:${_prefix}`, payload: {} });
    }
    function submit(url, options) {
        setIsCompleted(false);
        dispatch({ type: `${UPDATE_REQUEST}:${_prefix}`, payload: Object.assign({ url }, options) });
    }
    function* saga() {
        yield takeEvery(`${UPDATE_REQUEST}:${_prefix}`, sagaWorkerForSave);
    }
    return { status, submit, reset, data, error, isFetching, isCompleted };
}
