import { BASKET_STATUS, DELIVERY_STATUS, ORDER_ITEM_STATUS, ORDER_STATUS } from "./status-definitions";
//concat array of array to single array
export const getOrderItems = (deliveryItems) => [].concat.apply([], deliveryItems.map(d => d.items));
export const checkAllStoreOrderStatus = (delivery, status) => delivery.every(d => d.status == status);
export const checkAllOrderItemStatus = (orderItems, status) => orderItems.every(item => item.status == status);
export const getNextPossibleOrderStatuses = (order) => {
    const currentOrderStatus = order.status;
    let status = [];
    switch (currentOrderStatus) {
        case ORDER_STATUS.BUYER_CHECKED_OUT:
            status.push(ORDER_STATUS.ADMIN_ORDER_CANCELLED);
            break;
        case ORDER_STATUS.BUYER_COD_ORDER_CONFIRMED:
        case ORDER_STATUS.BUYER_PAID_ORDER_CONFIRMED:
            status.push(ORDER_STATUS.ADMIN_ORDER_CANCELLED);
            break;
        case ORDER_STATUS.BUYER_PAYMENT_FAILED:
        case ORDER_STATUS.BUYER_PAYMENT_PENDING:
            status.push(ORDER_STATUS.ADMIN_ORDER_CANCELLED);
            break;
        case ORDER_STATUS.DELIVERY_ACCEPTED:
        case ORDER_STATUS.DELIVERY_PICKUP_CONFIRMED:
            status.push(ORDER_STATUS.ADMIN_ORDER_CANCELLED);
            break;
        case ORDER_STATUS.DELIVERY_COMPLETED:
            status.push(ORDER_STATUS.ADMIN_ORDER_CANCELLED);
            break;
        case ORDER_STATUS.DELIVERY_ACCEPTED:
            status.push(ORDER_STATUS.ADMIN_ORDER_CANCELLED);
            status.push(ORDER_STATUS.DELIVERY_PICKUP_CONFIRMED);
    }
    return status.map(sts => ({ status: sts.toString(), readable: orderStatusToHumanReadable(sts) }));
};
export const getPossibleStoreOrderStatuses = (storeOrder) => {
    const currentOrderStatus = storeOrder.status;
    let statuses = [];
    switch (currentOrderStatus) {
        case DELIVERY_STATUS.DELIVERY_ACCEPTED:
            statuses.push(DELIVERY_STATUS.ADMIN_CANCELLED);
            break;
        default:
            statuses.push(DELIVERY_STATUS.ADMIN_CANCELLED);
            statuses.push(DELIVERY_STATUS.ADMIN_ORDER_CANCELLED);
    }
    return statuses.map(sts => ({ status: sts.toString(), readable: storeOrderStatusToHumanReadable(sts) }));
};
export const getPossibleOrderItemStatuses = (item) => {
    const currentOrderStatus = item.status;
    let statuses = [];
    switch (currentOrderStatus) {
        case DELIVERY_STATUS.DELIVERY_ACCEPTED:
            statuses.push(DELIVERY_STATUS.ADMIN_CANCELLED);
            break;
        default:
            statuses.push(DELIVERY_STATUS.ADMIN_CANCELLED);
            statuses.push(DELIVERY_STATUS.ADMIN_ORDER_CANCELLED);
    }
    return statuses.map(sts => ({ status: sts.toString(), readable: storeOrderStatusToHumanReadable(sts) }));
};
export const orderStatusToHumanReadable = (status) => {
    let statusHumanReadable = status;
    switch (status) {
        case ORDER_STATUS.BUYER_CHECKED_OUT:
            statusHumanReadable = "Checked Out";
            break;
        case ORDER_STATUS.BUYER_COD_ORDER_CONFIRMED:
            statusHumanReadable = "Buyer Order Confirmed";
            break;
        case ORDER_STATUS.BUYER_PAID_ORDER_CONFIRMED:
            statusHumanReadable = "Paid : Order Confirmed";
            break;
        case ORDER_STATUS.BUYER_PAYMENT_FAILED:
            statusHumanReadable = "Buyer Payment Failed";
            break;
        case ORDER_STATUS.BUYER_PAYMENT_PENDING:
            statusHumanReadable = "Buyer Payment Pending";
            break;
        case ORDER_STATUS.DELIVERY_ACCEPTED:
            statusHumanReadable = "Delivery Person Accepted";
            break;
        case ORDER_STATUS.DELIVERY_PICKUP_CONFIRMED:
            statusHumanReadable = "Delivery Person Pickup Completed (All Stores)";
            break;
        case ORDER_STATUS.DELIVERY_COMPLETED:
            statusHumanReadable = "Delivery Completed";
            break;
        case ORDER_STATUS.DELIVERY_RELEASED:
            statusHumanReadable = "Delivery Person Released Order";
            break;
        case ORDER_STATUS.ADMIN_ORDER_CANCELLED:
            statusHumanReadable = "Admin Order Cancelled";
            break;
        case ORDER_STATUS.SELLERS_CONFIRMED:
            statusHumanReadable = "Seller Confirmed";
            break;
        case ORDER_STATUS.BUYER_ORDER_CANCELLED:
            statusHumanReadable = "Order Cancelled By Buyer";
        //default : status (incoming)
    }
    return statusHumanReadable;
};
export const orderItemStatusToHumanReadable = (status) => {
    let statusHumanReadable = status;
    switch (status) {
        case ORDER_ITEM_STATUS.BUYER_CHECKED_OUT:
            statusHumanReadable = "Buyer Checked Out ";
            break;
        case ORDER_ITEM_STATUS.SELLER_ACCEPTED:
            statusHumanReadable = "Seller Accepted Item";
            break;
        case ORDER_ITEM_STATUS.SELLER_REJECTED:
            statusHumanReadable = "Sellere Rejected Item";
            break;
        case ORDER_ITEM_STATUS.DELIVERY_PICKED_UP:
            statusHumanReadable = "Delivery Person Picked Up Item";
            break;
        case ORDER_ITEM_STATUS.DELIVERY_PICKUP_RELEASED:
            statusHumanReadable = "Delivery Pickup Released";
            break;
        case ORDER_ITEM_STATUS.DELIVERY_COMPLETED:
            statusHumanReadable = "Delivery Completed";
            break;
        case ORDER_ITEM_STATUS.ADMIN_ORDER_CANCELLED:
            statusHumanReadable = "Admin Order Cancelled";
            break;
        //default status (incoming)
    }
    return statusHumanReadable;
};
export const storeOrderStatusToHumanReadable = (status) => {
    let statusHumanReadable;
    switch (status) {
        case DELIVERY_STATUS.SELLER_CONFIRMED:
            statusHumanReadable = "Seller Confirmed Order (Ready For Pickup)";
            break;
        case DELIVERY_STATUS.DELIVERY_ACCEPTED:
            statusHumanReadable = "Delivery Person Accepted For Delivery";
            break;
        case DELIVERY_STATUS.DELIVERY_RELEASED:
            statusHumanReadable = "Delivery Person Released (Previously Accepted for delivery)";
            break;
        case DELIVERY_STATUS.DELIVERY_PICKUP_CONFIRMED:
            statusHumanReadable = "Delivery Person Picked Up All Items From Store)";
            break;
        case DELIVERY_STATUS.DELIVERY_COMPLETED:
            statusHumanReadable = "Delivery Person Completed Delivery";
            break;
        case DELIVERY_STATUS.ADMIN_CANCELLED:
            statusHumanReadable = "Admin Cancelled Store Order";
            break;
        case DELIVERY_STATUS.ADMIN_ORDER_CANCELLED:
            statusHumanReadable = "Admin Cancelled Order";
            break;
        default:
            statusHumanReadable = status;
    }
    return statusHumanReadable;
};
export const basketOrderStatusToHumanReadable = (status) => {
    let statusHumanReadable;
    switch (status) {
        case BASKET_STATUS.CREATED:
            statusHumanReadable = "Buyer Created Basket Order";
            break;
        case BASKET_STATUS.BUYER_CHECKED_OUT:
            statusHumanReadable = "Buyer Checked Out";
            break;
        case BASKET_STATUS.BUYER_COD_ORDER_CONFIRMED:
            statusHumanReadable = "Buyer COD : Order Confirmed";
            break;
        case BASKET_STATUS.BUYER_PAID_ORDER_CONFIRMED:
            statusHumanReadable = "Buyer Paid : Order Confirmed";
            break;
        case BASKET_STATUS.BUYER_PAYMENT_FAILED:
            statusHumanReadable = "Buyer Payment Failed";
            break;
        case BASKET_STATUS.BUYER_PAYMENT_PENDING:
            statusHumanReadable = "Buyer Payment Confirmed";
            break;
        case BASKET_STATUS.DELIVERY_COMPLETED:
            statusHumanReadable = "Delivery Completed";
            break;
        case BASKET_STATUS.ADMIN_ORDER_ACCEPTED:
            statusHumanReadable = "Admin Accepted Basket Order";
            break;
        case BASKET_STATUS.ADMIN_ORDER_CANCELLED:
            statusHumanReadable = "Admin Cancelled Basket Order";
            break;
        default:
            statusHumanReadable = status;
    }
    return statusHumanReadable;
};
export const getPossibleBasketStatuses = (currentOrderStatus) => {
    let statuses = [];
    switch (currentOrderStatus) {
        case BASKET_STATUS.CREATED:
            statuses.push(BASKET_STATUS.ADMIN_ORDER_ACCEPTED);
            statuses.push(BASKET_STATUS.ADMIN_ORDER_CANCELLED);
            break;
        case BASKET_STATUS.BUYER_COD_ORDER_CONFIRMED:
        case BASKET_STATUS.BUYER_PAID_ORDER_CONFIRMED:
            statuses.push(BASKET_STATUS.DELIVERY_COMPLETED);
            statuses.push(BASKET_STATUS.ADMIN_ORDER_ACCEPTED);
            statuses.push(BASKET_STATUS.ADMIN_ORDER_CANCELLED);
            break;
        case BASKET_STATUS.DELIVERY_COMPLETED:
            statuses.push(BASKET_STATUS.ADMIN_ORDER_CANCELLED);
            break;
        default:
            statuses.push(BASKET_STATUS.ADMIN_ORDER_ACCEPTED);
            statuses.push(BASKET_STATUS.ADMIN_ORDER_CANCELLED);
    }
    return statuses.map(sts => ({ status: sts.toString(), readable: basketOrderStatusToHumanReadable(sts) }));
};
