import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { LoginPage } from './pages/login';
import { DashboardPage } from './pages/dashboard';
const Login = ({ cb, history }) => {
    return _jsx("div", { children: _jsx(LoginPage, { onLogin: (auth) => {
                auth && cb && cb();
                history.push("/");
            } }, void 0) }, void 0);
};
const Dashboard = ({ cb, location }) => {
    return _jsx("div", { children: _jsx(DashboardPage, { cb: () => cb && cb() }, void 0) }, void 0);
};
export class Application extends Component {
    constructor() {
        super(...arguments);
        this.state = { isLogin: false };
    }
    render() {
        return _jsx(BrowserRouter, { children: _jsxs(Switch, { children: [_jsx(Route, Object.assign({ path: "/", exact: true }, { children: this.state.isLogin ? (props => _jsx(Dashboard, Object.assign({}, props, { cb: () => {
                                this.setState({ isLogin: false });
                            } }), void 0)) : _jsx(Redirect, { to: "/login" }, void 0) }), void 0), _jsx(Route, Object.assign({ path: "/login", exact: true }, { children: props => _jsx(Login, Object.assign({}, props, { cb: () => {
                                this.setState({ isLogin: true });
                            } }), void 0) }), void 0), _jsx(Route, Object.assign({ path: "/dashboard" }, { children: props => _jsx(Dashboard, Object.assign({}, props, { cb: () => {
                                this.setState({ isLogin: false });
                            } }), void 0) }), void 0)] }, void 0) }, void 0);
    }
}
