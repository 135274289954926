var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleableComponent, styling } from "@exprzn/react-style-lib";
import { Dropdown } from ".";
export class DropdownEx extends StyleableComponent {
    render() {
        const { onChange, value, errors, readonly = false, view, items, title, keyProperty, selectedKey, selectedIndex, titleProperty, width } = this.props;
        const { errorStyle } = this;
        function onSelectItem(item, index) {
            onChange === null || onChange === void 0 ? void 0 : onChange({ target: { value: item } });
        }
        return _jsxs(_Fragment, { children: ["  ", _jsx(Dropdown, Object.assign({}, { view, width, onSelectItem, titleProperty, selectedKey, selectedIndex, items, keyProperty, title, readonly }), void 0), errors && errors.length > 0 ?
                    _jsx("div", Object.assign({ css: errorStyle }, { children: errors.reduce((acc, cval) => `${acc}${acc ? "," : ""}${cval.message}`, "") }), void 0) : null] }, void 0);
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], DropdownEx.prototype, "errorStyle", void 0);
