import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Switch } from '@mui/material';
const ToggleSwitch = ({ uid, handleChangeToggleValue, value }) => {
    const [checked, setChecked] = useState(value);
    const handleChange = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        handleChangeToggleValue(uid, isChecked);
    };
    return (_jsx(Switch, { checked: checked, onChange: handleChange, color: "primary", inputProps: { 'aria-label': 'controlled' } }, void 0));
};
export default ToggleSwitch;
