import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import Card from './chart';
import './styles.css';
// const Card = (props: any) => {
//   return (
//     <ul>
//       {props.data.map((item: any) => (
//           <li className="card">
//             {item.name}
//             {item.children?.length && <Card data={item.children} />}
//           </li>
//       ))}
//     </ul>
//   );
// };
const EmployeeChart = (props) => {
    return (_jsxs("div", Object.assign({ style: { position: 'relative' } }, { children: ["Employee Chart", _jsx("div", Object.assign({ style: { position: 'relative' } }, { children: _jsx("div", Object.assign({ className: "org-tree" }, { children: _jsx(Card, {}, void 0) }), void 0) }), void 0)] }), void 0));
};
export default EmployeeChart;
