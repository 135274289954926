import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect } from "react";
import { Pagination } from "../../../components/Pagination";
import { useProgressBar } from "../../../components/ProgressBar";
import { Table } from "../../../components/Table/table";
import { usePagination } from "../../../saga/use-pagination";
export const PayoutComponent = (props) => {
    const { data: payout } = props;
    const {} = useStyles("");
    return _jsxs(_Fragment, { children: [_jsx("td", { children: payout.id }, void 0), _jsx("td", { children: payout.store_name }, void 0), _jsx("td", { children: payout.sale_date_from }, void 0), _jsx("td", { children: payout.sale_date_to }, void 0), _jsx("td", { children: payout.amount_paid }, void 0), _jsx("td", { children: payout.payout_amount }, void 0)] }, void 0);
};
export const Payouts = (props) => {
    const { data, paging, loadPage, isFetching } = usePagination(`/trendutube/v1/admin-payout-processing/stores/payouts/search`);
    const { productstyle } = useStyles("productstyle");
    const progress = useProgressBar();
    useEffect(() => progress(isFetching), [isFetching]);
    const header = {
        id: "Id",
        store_name: "Store Name",
        amount_paid: "Amount Paid",
        sale_date_from: "From Sale Date",
        sale_date_to: "To Date",
        payout_amount: "Payable Amount",
    };
    const keys = [
        "id",
        "store_name",
        "sale_date_from",
        "sale_date_to",
        "amount_paid",
        "payout_amount"
    ];
    function onSearchChange(value, filters, sortBy) {
        if (value && value != "") {
            const params = {};
            filters.filter(f => f.filter).forEach(filter => {
                Object.defineProperty(params, filter.value, {
                    value, configurable: true,
                    enumerable: true
                });
            });
            // setParams(params)
            loadPage(0, params);
        }
        else {
            loadPage(0, {});
        }
    }
    const filters = [
        { title: "Username", value: "username", filter: true },
        { title: "First Name", value: "first_name", filter: false },
        { title: "Last Name", value: "last_name", filter: false },
    ];
    const sorts = [
        { title: "Userame", value: "username", sort: "asc" },
        { title: "First Name", value: "firstname", sort: "asc" }
    ];
    return _jsxs("div", { children: [_jsx("div", Object.assign({ css: productstyle }, { children: _jsx(Pagination, { loadPage: loadPage, paging: paging }, void 0) }), void 0), 
            // data ? (data as Payout[]).map(payout => <PayoutComponent payout={payout} />) : null
            data ? _jsx("div", Object.assign({ css: productstyle }, { children: _jsx(Table, { body: data, header: header, keys: keys, component: PayoutComponent }, void 0) }), void 0) : null] }, void 0);
};
