import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { Form, Field } from "../../../components/Form";
import { InputDiscountEx } from "../../../components/Form/fields-extended";
import { Row } from "../../../components/Row";
import { Select } from "../../../components/Select";
import { SagaProvider } from "../../../saga/saga-context";
import { useSaga } from "../../../saga/use-saga";
import { useAuth } from "../../../use-auth";
import { useSubmit } from "../../../saga/use-submit";
import container from "./style";
import { useProgressBar } from "../../../components/ProgressBar";
import { handleError, handleSuccess } from "../../../utils";
import { useHistory } from "react-router-dom";
export const BannerAddInternal = () => {
    const { category, rowstyle, button, buttonbanner } = useStyles("category", "rowstyle", "button", "buttonbanner");
    const { submit, data, error, status, reset, isFetching } = useSubmit();
    const { submit: submitImage, data: dataImage, error: errorImage, isFetching: isFetchingImage, status: statusImage, reset: resetImage } = useSubmit();
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [banner_image_uid, setImage] = useState();
    const submitHandler = (formData) => {
        submit("/trendutube/v1/admin-offers-management/admin/banners/add", {
            body: Object.assign(Object.assign({}, formData.add_banner), { discount_uid, banner_image_uid }),
        });
    };
    const inputOpenFileRef = useRef(null);
    function showOpenFileDlg() {
        inputOpenFileRef &&
            inputOpenFileRef.current &&
            inputOpenFileRef.current.click();
    }
    function handleChange(event) {
        // console.log(event.target.files, typeof event.target.files, Array.isArray(event.target.files))
        // alert('change >' + JSON.stringify(event.target.files))
        const files = Object.keys(event.target.files).map((file) => URL.createObjectURL(event.target.files[file]));
        setFiles(files);
        setImages(event.target.files);
        event.preventDefault();
        // alert(files.length)
    }
    useEffect(() => {
        if (dataImage) {
            const { urls } = dataImage;
            setImage(urls[0].uid);
            // alert(urls[0].uid)
        }
    }, [dataImage]);
    function handleImageSubmit(data) {
        // alert(JSON.stringify(data))
        // const { images } = data?.upload
        submitImage("/image-service/images/upload?entity=banner", {
            form: true,
            body: { images },
        });
    }
    /*Load Discount List*/
    const [discountList, setdiscountList] = useState();
    const loadingImage = useProgressBar();
    useEffect(() => {
        loadingImage(isFetchingImage || isFetching);
    }, [isFetchingImage || isFetching]);
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { data: classData, status: classStatus, isFetching: isFetchingClass } = useSaga(
    //  "/trendutube/v1/admin-offers-management/sellers/0x4f18/stores/0x3139/discounts/list",
    `/trendutube/v1/admin-offers-management/discounts/list`, { headers }, false);
    useEffect(() => {
        if (classData) {
            //  const { discounts } = classData as any;
            setdiscountList(classData);
        }
    }, [classData]);
    const [discount_uid, setSelectValue] = useState();
    let sel_params = discountList === null || discountList === void 0 ? void 0 : discountList.map((item, key) => {
        return { 'text': item.discount_name, 'value': item.uid };
    });
    const history = useHistory();
    useEffect(() => {
        if (status == "ok") {
            handleSuccess("banner created successfully");
            history.replace('/dashboard/banners/list');
        }
        else if (status == "error") {
            handleError(error);
            reset();
        }
        if (statusImage == "ok") {
            handleSuccess("image uploaded successully");
            resetImage();
        }
    }, [status, statusImage]);
    return (_jsxs("div", Object.assign({ css: category }, { children: [_jsxs("div", { children: [" ", data && JSON.stringify(data || {})] }, void 0), _jsx("div", { children: error && JSON.stringify(error) }, void 0), _jsxs(Row, { children: [_jsx(Col, { children: _jsx(Row, { children: _jsx(Col, { children: _jsxs(Form, Object.assign({ name: "upload", onSubmit: handleImageSubmit }, { children: [_jsx(Row, { children: _jsx("div", Object.assign({ style: {
                                                    height: "200px", backgroundColor: '#F4F5F6', border: 'none', width: '100%', marginBottom: '20px', display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: '10px'
                                                } }, { children: files.map((f) => (_jsx("img", { src: f, height: "200" }, void 0))) }), void 0) }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ size: 2 }, { children: [_jsx("input", { ref: inputOpenFileRef, type: "file", style: { display: "none" }, onChange: handleChange }, void 0), _jsx("button", Object.assign({ type: "button", onClick: showOpenFileDlg, css: buttonbanner }, { children: "Browse" }), void 0)] }), void 0), _jsx(Col, Object.assign({ size: 3 }, { children: _jsx("input", { type: "submit", value: "Upload", css: buttonbanner }, void 0) }), void 0)] }, void 0)] }), void 0) }, void 0) }, void 0) }, void 0), _jsx(Col, { children: _jsxs(Form, Object.assign({ name: "add_banner", onSubmit: submitHandler }, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "Banner Name" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "title" }, { children: _jsx(InputDiscountEx, {}, void 0) }), void 0) }), void 0) }, void 0)] }), void 0), _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "Discount Select" }, void 0), _jsx("div", { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "discount_uid" }, { children: _jsx(Select, { selecttext: "Please select a discount", title: "discount_uid", items: sel_params, onSelectItem: setSelectValue, keyval: "" }, void 0) }), void 0) }), void 0) }, void 0)] }), void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: rowstyle }, { children: _jsx("input", { type: "submit", value: "Add Banner", css: buttonbanner }, void 0) }), void 0) }, void 0)] }), void 0) }, void 0)] }, void 0)] }), void 0));
};
export const BannerAdd = () => (_jsxs(SagaProvider, { children: [" ", _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(BannerAddInternal, {}, void 0) }), void 0)] }, void 0));
