import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import { useJwt } from 'react-jwt';
import { useHistory } from 'react-router-dom';
const AuthContext = createContext({ auth: {} });
export const AuthProvider = ({ children }) => _jsx(AuthContext.Provider, Object.assign({ value: { auth: {} } }, { children: _jsx(AuthContext.Consumer, { children: ({ auth }) => _jsxs(_Fragment, { children: [children, " "] }, void 0) }, void 0) }), void 0);
// export function useSaga() {
// }
export function useAuth(noauth = false) {
    let history = useHistory();
    let access_token = localStorage.getItem("access_token") || "";
    const { decodedToken, isExpired } = useJwt(access_token);
    const { sub: user_uid, org_uid } = (decodedToken || {});
    let idAuthValid = false, message;
    if (!isExpired && decodedToken)
        idAuthValid = true;
    if (access_token === "")
        message = "invalid token";
    if (isExpired)
        message = "Token Expired ";
    if (!noauth && message)
        history.push("/login");
    return { isExpired, idAuthValid, user_uid, org_uid, access_token };
}
export function updateAuth(options) {
    const { first_name, last_name, access_token } = options;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("first_name", first_name);
    localStorage.setItem("last_name", last_name);
}
