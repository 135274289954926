var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
const { style, container } = registry("cat-list", true);
let CategoryStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CategoryStyle extends CssStyle {
};
CategoryStyle.style = {
    backgroundColor: "white",
    padding: "2em",
    margin: "1em",
};
CategoryStyle = __decorate([
    style("category")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], CategoryStyle);
let ProductStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ProductStyle extends CssStyle {
};
ProductStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em",
    padding: "1.5em"
};
ProductStyle = __decorate([
    style("productstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ProductStyle);
let CategoryIdStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CategoryIdStyle extends CssStyle {
};
CategoryIdStyle.style = {
    padding: ".5em",
};
CategoryIdStyle = __decorate([
    style("categoryid")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], CategoryIdStyle);
let CategoryNameStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CategoryNameStyle extends CssStyle {
};
CategoryNameStyle.style = {
    padding: ".5em",
};
CategoryNameStyle = __decorate([
    style("categoryname")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], CategoryNameStyle);
let CategoryDesc1Style = class CategoryDesc1Style extends CssStyle /* eslint-disable-line @typescript-eslint/no-unused-vars */ {
};
CategoryDesc1Style.style = {
    display: "flex",
    padding: "1.2rem",
    paddingLeft: 0,
    justifyContent: "start",
    alignContent: "center",
};
CategoryDesc1Style = __decorate([
    style("namestyle")
], CategoryDesc1Style);
let CategoryDescStyle = class CategoryDescStyle extends CssStyle /* eslint-disable-line @typescript-eslint/no-unused-vars */ {
};
CategoryDescStyle.style = {
    padding: ".5em",
};
CategoryDescStyle = __decorate([
    style("categorydesc")
], CategoryDescStyle);
let MenuTextStyle = class MenuTextStyle extends CssStyle /* eslint-disable-line @typescript-eslint/no-unused-vars */ {
};
MenuTextStyle.style = {
    paddingLeft: ".5em",
    alignSelf: "center",
};
MenuTextStyle = __decorate([
    style("menutextstyle")
], MenuTextStyle);
let TitleRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TitleRowStyle extends CssStyle {
};
TitleRowStyle.style = {
    fontWeight: 400,
    color: "#888888",
    margin: "8px 0px 0px 0px",
    fontSize: ".7em",
};
TitleRowStyle = __decorate([
    style("titlestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TitleRowStyle);
let TextStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TextStyle extends CssStyle {
};
TextStyle.style = {
    fontWeight: 700,
    color: "#555555",
    margin: "0px 0px 18px 0px",
    fontSize: "1.3em",
    width: "400px",
};
TextStyle = __decorate([
    style("textstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TextStyle);
export default container;
