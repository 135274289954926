import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import { useProgressBar } from "../../components/ProgressBar";
import { useSubmit } from "../../saga/use-submit";
import { emailRegex, formatCurrency, localDatetime } from "../../utils";
import { OrderStatusBadge } from "./status-view";
export const BasketOrderListComponent = (props) => {
    const { data: basketOrder } = props;
    const { productstyle } = useStyles("productstyle");
    const [expanded, setExpanded] = useState(false);
    const { id, basket_id, basket_uid, order_date, created_timestamp, status, delivery_slot, customer_uid, payment_mode, payment_order_id, tip_amount, items, buyer, net_payable_amount } = basketOrder;
    const { personal_profile, login_profile, buyer_profile } = buyer || {};
    const { first_name, last_name, full_name } = personal_profile || {};
    const { address: addresses, email, mobile_no } = buyer_profile || {};
    const { username } = login_profile || {};
    let username_email, username_mobile_no;
    const [address] = addresses || [];
    const { address_line1, address_line2, city, pin_code, landmark, location } = address || {};
    if (emailRegex.test(username))
        username_email = username || email;
    else
        username_mobile_no = username || mobile_no;
    const { submit, isFetching, error, status: reqStatus } = useSubmit();
    const setLoading = useProgressBar();
    useEffect(() => setLoading(isFetching), [isFetching]);
    useEffect(() => {
        if (error)
            toast.error(error.message);
    }, [error]);
    useEffect(() => {
        if (reqStatus == "ok") {
            toast.success("Order Item Status Updated", {});
        }
    }, [reqStatus]);
    // const nextStatuses = getNextPossibleOrderStatuses(basketOrder)
    // nextStatuses.push({ status: basketOrder.status as ORDER_STATUS, readable: orderStatusToHumanReadable(status) })
    // console.log(basketOrder)
    return _jsxs(_Fragment, { children: [_jsx("td", { children: _jsx("b", { children: localDatetime(order_date) }, void 0) }, void 0), _jsx("td", { children: _jsx("b", { children: _jsx(NavLink, Object.assign({ to: `/dashboard/basket-orders/${basket_uid}/details` }, { children: basket_id }), void 0) }, void 0) }, void 0), _jsx("td", { children: id }, void 0), _jsxs("td", { children: [" ", _jsx(OrderStatusBadge, { status: status }, void 0)] }, void 0), _jsx("td", { children: payment_mode }, void 0), _jsx("td", { children: formatCurrency(net_payable_amount) }, void 0), _jsx("td", { children: _jsxs("div", Object.assign({ css: { display: "flex", alignContent: "start", flexDirection: "column", margin: 0, lineHeight: "1.6em" } }, { children: [_jsx("div", { children: _jsx("b", { children: `${first_name} ${last_name}` }, void 0) }, void 0), _jsx("div", { children: `${address_line2}, ${city}` }, void 0)] }), void 0) }, void 0), _jsx("td", { children: _jsx("div", Object.assign({ css: {
                        display: "flex",
                        alignContent: "start",
                        flexDirection: "column",
                        margin: 0,
                        lineHeight: "1.6em",
                        fontWeight: 600
                    } }, { children: (items || []).map(item => _jsxs("div", { children: [" ", item.store.store_name, " \u00A0 "] }, void 0)) }), void 0) }, void 0)] }, void 0);
};
