import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Field, Form } from "../../../components/Form";
import { InputDate } from "../../../components/Form/fields-extended";
import { useSaga } from "../../../saga/use-saga";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const SalesComponent = (props) => {
    const { data } = props;
    const { month, sale_amount } = data || {};
    return _jsxs(_Fragment, { children: [_jsx("td", { children: month }, void 0), _jsx("td", { children: sale_amount }, void 0)] }, void 0);
};
const headers = {
    month: "Month",
    sale_amount: "Total Sale Amount"
};
const keys = ["month", "sale_amount"];
const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Monthly Sales',
        },
    },
};
export const MonthlySales = (props) => {
    const today = new Date();
    const firstMonth = new Date(today.getFullYear(), 1, 1).toLocaleDateString('sv-SE', { timeZone: 'Asia/Calcutta' }).substring(0, 10);
    const uptoMonth = today.toLocaleDateString('sv-SE', { timeZone: 'Asia/Calcutta' }).substring(0, 10);
    const [startDate, setStartDate] = useState(firstMonth);
    const [endDate, setEndDate] = useState(uptoMonth);
    const { data, loadUrl } = useSaga(`/trendutube/v1/admin-payout-processing/sales/monthly?from_date=${firstMonth}&to_date=${uptoMonth}`, {}, false);
    const { bigtable, panelstyle } = useStyles("bigtable", "panelstyle");
    function onSubmit() {
        loadUrl(`/trendutube/v1/admin-payout-processing/sales/monthly?from_date=${startDate}&to_date=${endDate}`);
    }
    function onFromDateChange(e) {
        const date = new Date(e).toLocaleDateString('sv-SE', { timeZone: 'Asia/Calcutta' }).substring(0, 10);
        setStartDate(date);
    }
    function onToDateChange(e) {
        const date = new Date(e).toLocaleDateString('sv-SE', { timeZone: 'Asia/Calcutta' }).substring(0, 10);
        setEndDate(date);
    }
    const salesData = {
        labels: (data || []).map(value => value.month),
        datasets: [{
                label: "Monthly Sales",
                data: (data || []).map(value => value.sale_amount),
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            }]
    };
    return _jsxs("div", Object.assign({ css: panelstyle }, { children: [_jsx(Form, Object.assign({ name: "estimate", onSubmit: onSubmit }, { children: _jsxs("div", Object.assign({ css: { maxWidth: 1000, display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15 } }, { children: [_jsxs("div", { children: [_jsx("div", { children: "From Date (Month) [Pick any date] " }, void 0), _jsx("div", { children: _jsx(Field, Object.assign({ name: "from_date", onChange: onFromDateChange, value: startDate }, { children: _jsx(InputDate, {}, void 0) }), void 0) }, void 0)] }, void 0), _jsxs("div", { children: [_jsx("div", { children: "Upto Date (Month)" }, void 0), _jsx("div", { children: _jsx(Field, Object.assign({ name: "target_date", onChange: onToDateChange, value: endDate }, { children: _jsx(InputDate, {}, void 0) }), void 0) }, void 0)] }, void 0), _jsx("button", Object.assign({ css: { padding: 10 } }, { children: " Get Sales " }), void 0)] }), void 0) }), void 0), _jsx("h3", Object.assign({ css: { padding: 15 } }, { children: "Orders" }), void 0), data ?
                _jsxs("div", Object.assign({ css: { maxWidth: 1000 } }, { children: ["   ", _jsx(Bar, { data: salesData, options: options }, void 0), "  "] }), void 0) : null] }), void 0);
};
