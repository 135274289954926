import { useContext } from 'react';
import { combineReducers } from 'redux';
import { ReactReduxContext } from 'react-redux';
import { SagaContext } from './saga-context';
export function useReducerInjector() {
    const { store } = useContext(ReactReduxContext);
    const { reducers } = useContext(SagaContext);
    const inject = (key, asyncReducer) => {
        reducers[key] = asyncReducer;
        const reducer = combineReducers(reducers);
        store.replaceReducer(reducer);
        return reducer;
    };
    const expel = (key) => {
        delete reducers[key];
    };
    return { inject, expel };
}
