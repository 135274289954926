import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { Edit } from "react-feather";
import ReactSlidingPane from "react-sliding-pane";
import { Col } from "../../../components/Column";
import { Field, Form } from "../../../components/Form";
import { InputArea } from "../../../components/Form/fields-extended";
import { Row } from "../../../components/Row";
import { AttributeSetListComponent } from "./attribute-set";
export const ProductAttributesEditComponent = (props) => {
    const { isOpen, onClose } = props;
    const { product, updateAttribFn, addAttribFn, addAttributeGroupFn, addAttributeSetFn, updateGroupFn } = props;
    const { attribute_sets = [] } = product || {};
    return _jsx("div", { children: _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isOpen, title: "Attributes", subtitle: "", width: "60%", onRequestClose: () => {
                onClose(false);
            } }, { children: _jsx("div", { children: _jsx(AttributesEdit, { attribute_sets: attribute_sets, product: product, updateAttribFn: updateAttribFn, updateGroupFn: updateGroupFn, addAttribFn: addAttribFn, addAttributeGroupFn: addAttributeGroupFn, addAttributeSetFn: addAttributeSetFn }, void 0) }, void 0) }), void 0) }, void 0);
};
export const AttributesEdit = (props) => {
    const { attribute_sets = [], updateAttribFn, addAttribFn, addAttributeGroupFn, addAttributeSetFn, product, updateGroupFn } = props || {};
    return _jsxs("div", { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(AttributeSetListComponent, { addAttributeSetFn: addAttributeSetFn, product: product }, void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: attribute_sets.map(attrSet => _jsxs("div", { children: [_jsx("div", Object.assign({ css: { marginTop: 24, fontSize: 19, fontWeight: 600, color: "#f6b200" } }, { children: attrSet.template && attrSet.template.attribute_set_name }), void 0), attrSet.attribute_groups && attrSet.attribute_groups.map(group => _jsx("div", { children: _jsxs("div", Object.assign({ css: { marginTop: 20 } }, { children: [_jsx("div", { children: _jsx(AttributeGroupEditComponent, { attributeSetUid: attrSet.uid, mode: "edit", attribute_group: group, updateGroupFn: updateGroupFn }, void 0) }, void 0), _jsxs("table", Object.assign({ width: "100%" }, { children: [group.attributes && group.attributes.map(attrib => {
                                                    return _jsx("tr", Object.assign({ css: { borderBottom: "1px solid #dfdfdf" } }, { children: _jsx(AttributeEditComponent, { mode: "edit", attribute: attrib, updateAttribFn: updateAttribFn }, void 0) }), void 0);
                                                }), _jsx("tr", { children: _jsx("td", { children: _jsx(AttributeEditComponent, { mode: "add", attribGroupUid: group.uid, addAttribFn: addAttribFn }, void 0) }, void 0) }, void 0)] }), void 0)] }), void 0) }, void 0)), _jsx("div", { children: _jsx(AttributeGroupEditComponent, { attributeSetUid: attrSet.uid, mode: "add", addAttributeGroupFn: addAttributeGroupFn }, void 0) }, void 0)] }, void 0)) }, void 0) }, void 0)] }, void 0);
};
export const AttributeEditComponent = (props) => {
    const { attribute: attrib, attribGroupUid, updateAttribFn, addAttribFn, mode } = props;
    const { button } = useStyles("button");
    const [isAddAttrib, setIsAddAttrib] = useState(false);
    useEffect(() => {
        setIsAddAttrib(false);
    }, []);
    function onSubmit(formData) {
        const { attribute } = formData || {};
        // alert(attribGroupUid)
        if (attribute) {
            const { attribute_name, attribute_description, attribute_value } = attribute;
            if (mode == "add" && attribGroupUid) {
                if (!attribute_name)
                    alert("Atribute name is mandatory");
                else if (!attribute_description)
                    alert("Atribute description is mandatory");
                else if (!attribute_value)
                    alert("Atribute value is mandatory");
                else {
                    addAttribFn === null || addAttribFn === void 0 ? void 0 : addAttribFn(attribGroupUid, [{
                            attribute_name,
                            attribute_description,
                            attribute_value
                        }]);
                }
            }
            else if (mode == "edit") {
                if (attribute_name || attribute_description || attribute_value) {
                    updateAttribFn === null || updateAttribFn === void 0 ? void 0 : updateAttribFn([{
                            uid: attrib === null || attrib === void 0 ? void 0 : attrib.uid,
                            attribute_name,
                            attribute_description,
                            attribute_value
                        }]);
                }
            }
        }
    }
    return _jsxs("div", { children: [!isAddAttrib && mode == "add" ? _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: _jsx("button", Object.assign({ css: button, type: "button", onClick: () => setIsAddAttrib(true) }, { children: "Add Attribute" }), void 0) }), void 0) : null, isAddAttrib === true && mode == "add" ?
                _jsx("div", { children: _jsx("span", Object.assign({ css: { fontSize: 18, fontWeight: 600, margin: "10px 0px" } }, { children: "Add Attribute" }), void 0) }, void 0) : null
            // <div><span css={{ fontSize: 18, fontWeight: 600, margin: "10px 0px" }}>Edit Attribute</span></div>
            , isAddAttrib === true && mode == "edit" ?
                _jsx("div", { children: _jsx("span", Object.assign({ css: { fontSize: 18, fontWeight: 600, margin: "10px 0px" } }, { children: "Edit Attribute" }), void 0) }, void 0) : null, isAddAttrib === false ? _jsxs(_Fragment, { children: [_jsxs("td", Object.assign({ css: { padding: "8px 20px 20px 0px", width: "400px" } }, { children: [_jsx("div", Object.assign({ css: { fontSize: 16, fontWeight: 600, color: "#676767" } }, { children: attrib === null || attrib === void 0 ? void 0 : attrib.attribute_name }), void 0), _jsx("div", Object.assign({ css: { fontSize: 14 } }, { children: attrib === null || attrib === void 0 ? void 0 : attrib.attribute_description }), void 0)] }), void 0), _jsx("td", Object.assign({ css: { padding: "8px 20px 20px 0px", width: "400px" } }, { children: attrib === null || attrib === void 0 ? void 0 : attrib.attribute_value }), void 0), _jsx("td", Object.assign({ css: { padding: "8px 20px 20px 0px", width: "50px" } }, { children: mode == "edit" ? _jsxs("div", Object.assign({ css: {
                                margin: "6px 10px", fontWeight: 600,
                                display: "flex", justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer"
                            }, onClick: () => {
                                setIsAddAttrib(true);
                            } }, { children: [_jsx(Edit, { size: 16 }, void 0), "\u00A0", _jsx("u", { children: "Edit  " }, void 0)] }), void 0) : null }), void 0)] }, void 0) : null, isAddAttrib === true ? _jsxs(Form, Object.assign({ name: "attribute", onSubmit: onSubmit }, { children: [_jsx("div", { children: "Attribute Name" }, void 0), _jsx(Field, Object.assign({ name: "attribute_name", value: attrib === null || attrib === void 0 ? void 0 : attrib.attribute_name }, { children: _jsx(InputArea, {}, void 0) }), void 0), _jsx("div", { children: "Attribute Description" }, void 0), _jsx(Field, Object.assign({ name: "attribute_description", value: attrib === null || attrib === void 0 ? void 0 : attrib.attribute_description }, { children: _jsx(InputArea, {}, void 0) }), void 0), _jsx("div", { children: "Attribute Value" }, void 0), _jsx(Field, Object.assign({ name: "attribute_value", value: attrib === null || attrib === void 0 ? void 0 : attrib.attribute_value }, { children: _jsx(InputArea, {}, void 0) }), void 0), _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: [isAddAttrib === true ?
                                _jsx("button", Object.assign({ onClick: () => setIsAddAttrib(false), type: "button", css: button, style: { margin: "10px 0px 10px 10px" } }, { children: "Cancel" }), void 0) : null, _jsx("button", Object.assign({ css: button, style: { margin: "10px 0px 10px 10px" } }, { children: "Save Attribute" }), void 0)] }), void 0)] }), void 0) : null] }, void 0);
};
export const AttributeGroupEditComponent = (props) => {
    const { attribute_group, addAttributeGroupFn, mode, attributeSetUid, updateGroupFn } = props;
    const { button } = useStyles("button");
    const [isAddGroup, setIsAddGroup] = useState(false);
    const { attribute_group_name: group_name, attribute_group_description: group_description, uid } = attribute_group || {};
    function onSubmit(formData) {
        const { group } = formData || {};
        const { attribute_group_name, attribute_group_description } = group || {};
        if (mode == "add") {
            if (!attribute_group_name)
                alert("Attribute group name is mandatory");
            else if (!attribute_group_description)
                alert("Attribute group description is mandatory");
            else {
                addAttributeGroupFn === null || addAttributeGroupFn === void 0 ? void 0 : addAttributeGroupFn(attributeSetUid, {
                    attribute_group_name,
                    attribute_group_description
                });
            }
        }
        else if (uid && mode == "edit") {
            if (attribute_group_name || attribute_group_description) {
                updateGroupFn === null || updateGroupFn === void 0 ? void 0 : updateGroupFn(attributeSetUid, uid, {
                    attribute_group_name,
                    attribute_group_description
                });
            }
        }
    }
    useEffect(() => {
        setIsAddGroup(false);
    }, []);
    return _jsxs("div", { children: [!isAddGroup && mode == "add" ? _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "stretch" } }, { children: _jsx("button", Object.assign({ css: button, type: "button", onClick: () => setIsAddGroup(true) }, { children: "Add Attribute Group" }), void 0) }), void 0) : null, !isAddGroup ? _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ css: { fontSize: 17, fontWeight: 600 } }, { children: [group_name, " "] }), void 0), _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "space-between", borderBottom: "1px solid black", alignContent: "center" } }, { children: [_jsx("div", Object.assign({ css: { color: "#a1a1af", fontSize: 14, marginBottom: "8px", fontWeight: 600, } }, { children: group_description }), void 0), mode == "edit" ? _jsxs("div", Object.assign({ css: {
                                    margin: "6px 10px", fontWeight: 600,
                                    display: "flex", justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer"
                                }, onClick: () => {
                                    setIsAddGroup(true);
                                } }, { children: [_jsx(Edit, { size: 16 }, void 0), "\u00A0", _jsx("u", { children: "Edit Group" }, void 0)] }), void 0) : null] }), void 0)] }, void 0) : null, isAddGroup ?
                _jsxs("div", { children: [_jsx("div", { children: mode == "add" ? _jsx("span", Object.assign({ css: { fontSize: 18, fontWeight: 600, margin: "10px 0px" } }, { children: "Add Group" }), void 0) : null }, void 0), _jsxs(Form, Object.assign({ name: "group", onSubmit: onSubmit }, { children: [_jsx("div", { children: "Attribute Group Name" }, void 0), _jsx(Field, Object.assign({ name: "attribute_group_name", value: group_name }, { children: _jsx(InputArea, {}, void 0) }), void 0), _jsx("div", { children: "Attribute Group Description" }, void 0), _jsx(Field, Object.assign({ name: "attribute_group_description", value: group_description }, { children: _jsx(InputArea, {}, void 0) }), void 0), _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: [_jsx("button", Object.assign({ css: button, style: { margin: "10px 0px 10px 10px" }, type: "button", onClick: () => setIsAddGroup(false) }, { children: "Cancel" }), void 0), _jsx("button", Object.assign({ css: button, style: { margin: "10px 0px 10px 10px" } }, { children: "Save" }), void 0)] }), void 0)] }), void 0)] }, void 0) : null] }, void 0);
};
