import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react  */
import { toast } from "react-toastify";
import { DropdownField } from "../../../components/DropdownEx/dropdown-field-2";
import { Field, Form } from "../../../components/Form";
import { LabelledField } from "../../../components/Form/labelled-field";
import { useRestSubmit } from "../../../saga/use-rest-submit";
export const EditRefund = (props) => {
    const { mode, refund, refund_amount, cancellation_id, onFinishEdit } = props;
    const { payment_reference, status: in_status, refund_type: in_refund_type } = refund || {};
    const refund_types = [
        { label: "Manual", value: "manual" },
        { label: "Online", value: "online" },
    ];
    const refund_statuses = [
        { label: "COD : Refund Not Applicable", value: "cod-refund-not-applicable" },
        { label: "Payout Started", value: "initiated" },
        { label: "Processing", value: "uninitiated" },
        { label: "In Progress", value: "in-progress" },
        { label: "Paid", value: "paid" },
    ];
    const saveRefund = useRestSubmit(() => {
        toast.success("saved refund");
        onFinishEdit();
    });
    const updateRefund = useRestSubmit(() => {
        toast.success("updated refund");
        onFinishEdit();
    });
    const onSubmit = (formData) => {
        const { refund } = formData;
        const { payment_reference, refund_type, refund_status } = refund;
        if (mode == "create") {
            if (payment_reference == null || payment_reference == "")
                toast.error("Payment Reference is mandatory");
            else if (refund_type == null || refund_type == "")
                toast.error("Refund Type mandatory");
            else if (refund_status == null || refund_status == "")
                toast.error("Refund Status is mandatory");
            else
                saveRefund(`/trendutube/v1/admin-order-processing/cancellations/${cancellation_id}/refunds/create`, { body: refund });
        }
        else {
            if (refund_status == null || refund_status == "")
                toast.error("Refund Status is mandatory");
            else
                updateRefund(`/trendutube/v1/admin-order-processing/cancellations/${cancellation_id}/refunds/update`, { body: refund });
        }
    };
    return _jsx("div", { children: _jsxs(Form, Object.assign({ name: "refund", onSubmit: onSubmit }, { children: [_jsx(LabelledField, { name: "payment_reference", label: "Payment Reference", value: payment_reference }, void 0), _jsx("div", { children: "Refund Type" }, void 0), _jsx(Field, Object.assign({ name: "refund_type" }, { children: _jsx(DropdownField, { keyProperty: "value", items: refund_types, title: "Select Type", width: "100%", titleProperty: "label", selectedKey: in_refund_type, view: ({ item }) => _jsx("div", { children: item.label }, void 0) }, void 0) }), void 0), _jsx("div", { children: "Refund Status" }, void 0), _jsx(Field, Object.assign({ name: "refund_status" }, { children: _jsx(DropdownField, { keyProperty: "value", items: refund_statuses, title: "Select Status", titleProperty: "label", width: "100%", selectedKey: in_status, view: ({ item }) => _jsx("div", { children: item.label }, void 0) }, void 0) }), void 0), _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "right", padding: "10px 0px" } }, { children: _jsx("button", Object.assign({ type: "submit" }, { children: "Save" }), void 0) }), void 0)] }), void 0) }, void 0);
};
