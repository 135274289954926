import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Typography, Box, TextField, DialogActions, Button, } from "@mui/material";
import { useRestSubmit } from "../../saga/use-rest-submit";
import { toast } from "react-toastify";
export const ActionLoges = ({ open, handleClose, handlePerformAction, action, action_ids, loges }) => {
    const [newNote, setNewNote] = useState("");
    const confirmHandler = () => {
        submitConfirm(`/trendutube/v1/admin-order-processing/action-loges-add`, { body: { notes: { action_log: newNote, action_performed_by: "0x2397", action: action, action_ids } } });
    };
    const submitConfirm = useRestSubmit(() => {
        toast.success("Action Log Created", { toastId: Math.random() });
        setNewNote("");
        handlePerformAction();
    }, (e) => { toast.error(e); });
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, maxWidth: "sm", fullWidth: true }, { children: [_jsx(DialogTitle, Object.assign({ sx: { backgroundColor: "#f5f5f5", textAlign: "center", fontWeight: "bold" } }, { children: "Action Log" }), void 0), _jsxs(DialogContent, Object.assign({ sx: { padding: 3 } }, { children: [_jsx(Typography, Object.assign({ variant: "h6", gutterBottom: true, sx: { fontWeight: "600", color: "#0545BE" } }, { children: "Existing Logs" }), void 0), _jsx(Box, Object.assign({ sx: {
                            maxHeight: "200px",
                            overflowY: "auto",
                            backgroundColor: "#f9f9f9",
                            padding: 2,
                            borderRadius: "8px",
                            boxShadow: "inset 0 0 5px rgba(0,0,0,0.1)",
                            marginBottom: 2,
                        } }, { children: _jsx("ul", Object.assign({ style: { paddingLeft: "16px" } }, { children: loges && (loges === null || loges === void 0 ? void 0 : loges.length) > 0 ? (loges === null || loges === void 0 ? void 0 : loges.map((log, index) => (_jsx("li", Object.assign({ style: { marginBottom: "8px", lineHeight: "1.5" } }, { children: log.log || log.action_log }), index)))) : (_jsx(Typography, Object.assign({ variant: "body2", color: "textSecondary" }, { children: "No logs available." }), void 0)) }), void 0) }), void 0), _jsx(TextField, { margin: "dense", label: "Add New Note", type: "text", fullWidth: true, value: newNote, onChange: (e) => setNewNote(e.target.value), variant: "outlined", multiline: true, rows: 3, sx: {
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            marginBottom: 2,
                        } }, void 0)] }), void 0), _jsxs(DialogActions, Object.assign({ sx: { padding: 2 } }, { children: [_jsx(Button, Object.assign({ onClick: handleClose, color: "secondary", variant: "outlined" }, { children: "Cancel" }), void 0), _jsx(Button, Object.assign({ onClick: confirmHandler, color: "primary", variant: "contained" }, { children: "Save Note" }), void 0)] }), void 0)] }), void 0));
};
