import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { useProgressBar } from "../../../components/ProgressBar";
import { SearchToolBar } from "../../../components/Search";
import { Table } from "../../../components/Table/table";
import { usePagination } from "../../../saga/use-pagination";
import { UserRowComponent } from "./users-row";
export const LoginUserComponentList = (props) => {
    // props.
    // const { match } = props || {}
    // const queryParams = {}
    const [params, setParams] = useState({});
    const { productstyle, storetablestyle } = useStyles("productstyle", "storetablestyle");
    const [users, setUsers] = useState([]);
    const { data, loadUrl, loadPage, paging, isFetching } = usePagination(`/trendutube/v1/auth/users/list`, params, true);
    const setProgress = useProgressBar();
    useEffect(() => {
        setProgress(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (Array.isArray(data)) {
            setUsers(data);
        }
    }, [data]);
    function onSearchChange(value, filters, sortBy) {
        if (value && value != "") {
            const params = {};
            filters.filter(f => f.filter).forEach(filter => {
                Object.defineProperty(params, filter.value, {
                    value, configurable: true,
                    enumerable: true
                });
            });
            setParams(params);
            loadPage(0, params);
        }
        else {
            loadPage(0, {});
        }
    }
    const filters = [
        { title: "Username", value: "username", filter: true },
        { title: "First Name", value: "first_name", filter: false },
        { title: "Last Name", value: "last_name", filter: false },
    ];
    const sorts = [
        { title: "Userame", value: "username", sort: "asc" },
        { title: "First Name", value: "firstname", sort: "asc" }
    ];
    return _jsxs("div", { children: [_jsx("div", Object.assign({ css: productstyle }, { children: _jsx(SearchToolBar, { filters: filters, sortBy: sorts, onChange: onSearchChange }, void 0) }), void 0), _jsx("div", Object.assign({ css: productstyle }, { children: _jsx(Pagination, Object.assign({}, {
                    paging, loadPage: (page) => {
                        loadPage(page, params);
                    }
                }), void 0) }), void 0), _jsx("div", Object.assign({ css: productstyle }, { children: 
                // users.map(loginUser => <LoginUserComponent key={loginUser.login_uid} {...loginUser} />)
                _jsx(Table, { style: storetablestyle, body: data, itemOnClick: (item) => {
                        loadPage(paging.page, params);
                    }, header: { username: "Username", first_name: "First Name", last_name: "Last Name", applications: "Applications" }, component: UserRowComponent, keys: ["username", "first_name", "last_name", "applications"] }, void 0) }), void 0)] }, void 0);
};
