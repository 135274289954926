import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { StyleProvider, } from "@exprzn/react-style-lib";
import container from "./style";
import { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import CustomPagination from "../Shipments/custompagination";
import { useSubmit } from "../../saga/use-submit";
import { usePagination } from "../../saga/use-pagination";
import { SagaProvider } from "../../saga/saga-context";
import { formatTime } from "../../utils";
const ConfigInternal = (props) => {
    const { submit, error, status } = useSubmit();
    const [shipmentstatus, setShipmentStatus] = useState("courier-not-assigned");
    const queryParams = { page_size: 10 };
    const { data: searchKeywords, loadPage, paging, loadUrl } = usePagination("/trendutube/v1/product-catalog/products/keyword-search-list", { queryParams });
    const handlePageChange = (newPage) => {
        loadPage(newPage);
    };
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const columns = [
        { field: 'id', headerName: 'Id', flex: 0.1, headerClassName: 'custom-header' },
        { field: 'search_key', headerName: 'Search Key', flex: 0.1, headerClassName: 'custom-header' },
        {
            field: 'date',
            headerName: 'Date',
            flex: 0.1,
            headerClassName: 'custom-header',
            renderCell: (params) => {
                return formatTime(params.value);
            }
        }
    ];
    return (_jsx("div", { children: searchKeywords ? _jsx("div", Object.assign({ style: { width: '100%' } }, { children: _jsxs(Box, Object.assign({ sx: { width: "100%", backgroundColor: "white", padding: "20px" } }, { children: [_jsx(DataGrid, { getRowId: (row) => row.id, rows: searchKeywords, columns: columns, initialState: {
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }, hideFooterPagination: true, checkboxSelection: false, isRowSelectable: () => false }, void 0), _jsx(CustomPagination, { page: paging === null || paging === void 0 ? void 0 : paging.page, totalPages: paging === null || paging === void 0 ? void 0 : paging.total_pages, hasNext: (paging === null || paging === void 0 ? void 0 : paging.total_pages) > Number(paging === null || paging === void 0 ? void 0 : paging.page), onPageChange: handlePageChange }, void 0)] }), void 0) }), void 0) : null }, void 0));
};
export const SearchKeywordPage = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ConfigInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
