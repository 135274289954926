var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
const { style, container } = registry();
let ContianerStyle = class ContianerStyle {
};
ContianerStyle.style = {};
ContianerStyle = __decorate([
    style()
], ContianerStyle);
let SelectStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SelectStyle extends CssStyle {
};
SelectStyle.style = {
    position: "relative",
    border: "1px solid #e6e6e6",
    padding: "0.8em 0.8em",
    borderRadius: "0.5em",
    width: "100%",
    backgroundColor: "#f1f1f1",
    color: "#e8a631",
    marginTop: "1em",
    fontWeight: "600",
    fontSize: "1em",
};
SelectStyle = __decorate([
    style("Select")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SelectStyle);
export default container;
