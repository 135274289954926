import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/* @jsxImportSource @emotion/react */
import { NavLink } from "react-router-dom";
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../saga/saga-context";
import { useSaga } from "../../saga/use-saga";
import container from './style';
import { global_org_uid } from "../../config";
const AttribSetInternal = () => {
    const { data } = useSaga(`/trendutube/v1/product-catalog/attribute-set-templates/list?org_uid=${global_org_uid}`, {}, false);
    const { attribtitlestyle, attribstyle } = useStyles("attribtitlestyle", "attribstyle");
    let att;
    if (data && data.length > 0) {
        att = data[0].attribute_set_templates;
    }
    return _jsx("div", { children: att && att.map((item) => _jsxs("div", Object.assign({ css: attribstyle }, { children: [_jsxs("div", Object.assign({ css: attribtitlestyle }, { children: [_jsx(NavLink, Object.assign({ to: `attribute-set-templates/${item.uid}` }, { children: item.attribute_set_name }), void 0), _jsx(NavLink, Object.assign({ to: `attribute-set-templates-edit/${item.uid}` }, { children: _jsx("span", Object.assign({ style: { paddingLeft: "1em", fontSize: ".7em" } }, { children: "Edit" }), void 0) }), void 0)] }), void 0), _jsxs("div", { children: [item.attribute_set_description, " "] }, void 0)] }), void 0)) }, void 0);
};
export const AttributeSetList = () => {
    return _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(SagaProvider, { children: _jsx(AttribSetInternal, {}, void 0) }, void 0) }), void 0);
};
