import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { StyleableComponent } from '@exprzn/react-style-lib';
import { MainLayout } from '../../layout/content';
import 'antd/dist/antd.css';
export class DashboardPage extends StyleableComponent {
    render() {
        return _jsx("div", { children: _jsx(MainLayout, Object.assign({}, this.props), void 0) }, void 0);
    }
}
