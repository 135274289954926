import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/* @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, Form } from "../../../components/Form";
import { InputDate } from "../../../components/Form/fields-extended";
import { useProgressBar } from "../../../components/ProgressBar";
import { Table } from "../../../components/Table/table";
import { useRestSubmit } from "../../../saga/use-rest-submit";
import { useSaga } from "../../../saga/use-saga";
import { formatCurrency } from "../../../utils";
import { CardNano } from "../../orders/card-nano";
const header = {
    date: "Date",
    order_id: "Order Id",
    tip_amount: "Tip Amount",
    processed_datetime: "Processed Date Time",
    tips_payout_amount: "Tips Payout Amount",
    delivery_distance: "Distance",
    status: "Status",
    date_from: "From Date",
    date_to: " To Date",
    orders: "Orders",
    total_delivery_distance: "Distance"
};
const keys_order = ["date", "order_id", "delivery_distance", "tip_amount"];
const keys_payout = ["processed_datetime", "total_delivery_distance", "tips_payout_amount", "status", "date_from", "date_to", "orders"];
export const OrderDisplayComponent = (props) => {
    const { data: order } = props;
    return _jsxs(_Fragment, { children: [_jsx("td", { children: order.order_date }, void 0), _jsx("td", { children: _jsx("b", { children: _jsx(NavLink, Object.assign({ to: `/dashboard/orders/${order.id}/details` }, { children: order.order_id }), void 0) }, void 0) }, void 0), _jsx("td", { children: Number(order.delivery_distance) / 1000 }, void 0), _jsx("td", Object.assign({ css: { fontWeight: 700 } }, { children: formatCurrency(order.tip_amount) }), void 0)] }, void 0);
};
export const PayoutDisplayComponent = (props) => {
    const { data: tipPayout } = props;
    const orders = tipPayout.order_tips_payouts.map(otps => otps.order);
    const { bigtable, panelstyle } = useStyles("bigtable", "panelstyle");
    return _jsxs(_Fragment, { children: [_jsx("td", Object.assign({ css: { fontWeight: 600 } }, { children: tipPayout.processed_datetime }), void 0), _jsxs("td", Object.assign({ css: { fontWeight: 600 } }, { children: [Number(tipPayout.total_delivery_distance) / 1000, " Km"] }), void 0), _jsx("td", Object.assign({ css: { fontWeight: 600 } }, { children: formatCurrency(tipPayout.tips_payout_amount) }), void 0), _jsx("td", { children: tipPayout.status }, void 0), _jsx("td", { children: tipPayout.date_from }, void 0), _jsx("td", { children: tipPayout.date_to }, void 0), _jsx("td", { children: _jsx(Table, { style: bigtable, body: orders, component: OrderDisplayComponent, header: header, keys: keys_order }, void 0) }, void 0)] }, void 0);
};
export const DeliveryPersonPayout = (props) => {
    const progress = useProgressBar();
    const [payouts, setPayouts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [totalTipAmount, setTotalTipAmount] = useState();
    const [targetDate, setTargetDate] = useState(new Date().toISOString());
    const [fromDate, setFromDate] = useState();
    const { routerProps } = props;
    // const delivery_person_uid = new URLSearchParams(routerProps.location.search).get("delivery_person_uid")
    const { delivery_person_uid } = routerProps.match.params;
    console.log({ delivery_person_uid });
    const { bigtable, panelstyle } = useStyles("bigtable", "panelstyle");
    const { data, isFetching, reload } = useSaga(`/trendutube/v1/admin-payout-processing/delivery-persons/${delivery_person_uid}/tips-payouts/list`, {
    // const { data, isFetching } = useSaga<PayoutEstimateResponse>(`/trendutube/v1/admin-payout-processing/tips-payouts/estimate`, {
    }, false);
    const submitEstimate = useRestSubmit((data) => {
        // toast.success("")
        const { total_tip_amount } = data || {};
        setTotalTipAmount(total_tip_amount || 0);
        // alert(JSON.stringify(data))
    });
    const submitCreate = useRestSubmit((data) => {
        toast.success("payout successfully created");
        // alert(JSON.stringify(data))
        reload();
    }, (e) => {
        toast.error(`could not create payout due to: ${e}`);
    });
    useEffect(() => {
        progress(isFetching);
    }, []);
    useEffect(() => {
        if (data) {
            const { payouts = [], total_tip_amount, orders = [] } = data;
            setPayouts(payouts);
            setOrders(orders);
            setTotalTipAmount(total_tip_amount || 0);
        }
    }, [data]);
    const onTargetDateChange = (e) => {
        const date = new Date(e).toISOString();
        setTargetDate(date);
        submitEstimate(`/trendutube/v1/admin-payout-processing/tips-payouts/estimate`, {
            body: { delivery_person_uid, target_date: date, from_date: fromDate }
        });
    };
    const onFromDateChange = (e) => {
        const date = new Date(e).toISOString();
        setFromDate(date);
        submitEstimate(`/trendutube/v1/admin-payout-processing/tips-payouts/estimate`, {
            body: { delivery_person_uid, target_date: targetDate, from_date: date }
        });
    };
    const onSubmit = (formData) => {
        submitCreate(`/trendutube/v1/admin-payout-processing/tips-payouts/create`, {
            body: { delivery_person_uid, target_date: targetDate, from_date: fromDate }
        });
    };
    return _jsxs("div", Object.assign({ css: panelstyle }, { children: [_jsx(Form, Object.assign({ name: "estimate", onSubmit: onSubmit }, { children: _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15 } }, { children: [_jsx("div", { children: "From Date" }, void 0), _jsx("div", { children: _jsx(Field, Object.assign({ name: "from_date", onChange: onFromDateChange }, { children: _jsx(InputDate, {}, void 0) }), void 0) }, void 0), _jsx("div", { children: "Target Date" }, void 0), _jsx("div", { children: _jsx(Field, Object.assign({ name: "target_date", onChange: onTargetDateChange, value: new Date().toISOString().substring(0, 10) }, { children: _jsx(InputDate, {}, void 0) }), void 0) }, void 0), _jsx(CardNano, { title: "Total Tip Amount", value: `${totalTipAmount}` }, void 0), _jsx("button", Object.assign({ css: { padding: 10 } }, { children: " Create Payout " }), void 0)] }), void 0) }), void 0), _jsx("h3", Object.assign({ css: { padding: 15 } }, { children: "Orders" }), void 0), _jsx(Table, { style: bigtable, body: orders, component: OrderDisplayComponent, header: header, keys: keys_order }, void 0), orders.length <= 0 ? _jsx("div", Object.assign({ css: { padding: 15 } }, { children: "No Order available for Payout" }), void 0) : null, _jsx("h3", Object.assign({ css: { padding: 15 } }, { children: "Payouts" }), void 0), _jsx(Table, { style: bigtable, body: payouts, component: PayoutDisplayComponent, header: header, keys: keys_payout }, void 0), payouts.length <= 0 ? _jsx("div", Object.assign({ css: { padding: 15 } }, { children: "No Payouts have been made so far" }), void 0) : null] }), void 0);
};
