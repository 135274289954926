import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useRef } from "react";
import { toast } from "react-toastify";
import { Confirm } from "../../../components/Confirm";
import { useRestSubmit } from "../../../saga/use-rest-submit";
// export type LoginUser
export const UserRowComponent = (props) => {
    const { data, itemOnClick } = props;
    const { username, subjects, login_uid, status } = data || {};
    const confirmLoginDeleteRef = useRef(null);
    const confirmUserDeleteRef = useRef(null);
    const suspendUserSubmit = useRestSubmit(() => {
        toast.success(`user ${username} suspended`);
        itemOnClick === null || itemOnClick === void 0 ? void 0 : itemOnClick();
    }, (message, code) => {
        toast.error(message);
    });
    const unSuspendUserSubmit = useRestSubmit(() => {
        toast.success(`user ${username} un suspended`);
        itemOnClick === null || itemOnClick === void 0 ? void 0 : itemOnClick();
    }, (message, code) => {
        toast.error(message);
    });
    const deleteUserSubmit = useRestSubmit(() => {
        toast.success(`user ${username} deleted`);
        itemOnClick === null || itemOnClick === void 0 ? void 0 : itemOnClick();
    }, (message, code) => {
        toast.error(message);
    });
    const suspendLoginSubmit = useRestSubmit(() => {
        toast.success(`user suspended`);
        itemOnClick === null || itemOnClick === void 0 ? void 0 : itemOnClick();
    }, (message, code) => {
        toast.error(message);
    });
    const unSuspendLoginSubmit = useRestSubmit(() => {
        toast.success(`user  un suspended`);
        itemOnClick === null || itemOnClick === void 0 ? void 0 : itemOnClick();
    }, (message, code) => {
        toast.error(message);
    });
    const deleteLoginSubmit = useRestSubmit(() => {
        toast.success(`user  deleted`);
        itemOnClick === null || itemOnClick === void 0 ? void 0 : itemOnClick();
    }, (message, code) => {
        toast.error(message);
    });
    const suspendLogin = () => {
        suspendLoginSubmit(`/trendutube/v1/auth/logins/${login_uid}/suspend`, { method: "DELETE" });
    };
    const unSuspendLogin = () => {
        unSuspendLoginSubmit(`/trendutube/v1/auth/logins/${login_uid}/unsuspend`, { method: "POST" });
    };
    const deleteLogin = () => {
        deleteLoginSubmit(`/trendutube/v1/auth/logins/${login_uid}/delete`, { method: "DELETE" });
    };
    const suspendUser = (user_uid) => () => suspendUserSubmit(`/trendutube/v1/auth/users/${user_uid}/suspend`, { method: "DELETE" });
    const unSuspendUser = (user_uid) => () => unSuspendUserSubmit(`/trendutube/v1/auth/users/${user_uid}/unsuspend`, { method: "POST" });
    const deleteUser = (user_uid) => deleteUserSubmit(`/trendutube/v1/auth/users/${user_uid}/delete`, { method: "DELETE" });
    const confirmDeleteLogin = () => {
        var _a;
        (_a = confirmLoginDeleteRef === null || confirmLoginDeleteRef === void 0 ? void 0 : confirmLoginDeleteRef.current) === null || _a === void 0 ? void 0 : _a.show(true);
    };
    const confirmDeleteUser = () => {
        var _a;
        (_a = confirmUserDeleteRef === null || confirmUserDeleteRef === void 0 ? void 0 : confirmUserDeleteRef.current) === null || _a === void 0 ? void 0 : _a.show(true);
    };
    return _jsxs(_Fragment, { children: [_jsxs("td", { children: [_jsx("div", { children: _jsx("b", { children: username }, void 0) }, void 0), _jsxs("div", { children: [_jsx("span", Object.assign({ css: { color: "#ff2222", cursor: "pointer" }, onClick: confirmDeleteLogin }, { children: "Delete \u00A0" }), void 0), _jsx(Confirm, { message: "Are you sure you want to delete login ?", ref: confirmLoginDeleteRef, buttons: {
                                    delete: {
                                        label: "Confirm Delete", callback: (c, close) => {
                                            close();
                                            deleteLogin();
                                        }
                                    },
                                    cancel: {
                                        label: "Cancel", css: { backgroundColor: "#f14545" },
                                        callback: (c, close) => {
                                            close();
                                        }
                                    }
                                } }, void 0), status != "login_suspended" ?
                                _jsx("span", Object.assign({ css: { color: "#2222ff", cursor: "pointer" }, onClick: suspendLogin }, { children: "Suspend \u00A0" }), void 0) :
                                _jsx("span", Object.assign({ css: { color: "#22ff22", cursor: "pointer" }, onClick: unSuspendLogin }, { children: "Un Suspend \u00A0" }), void 0)] }, void 0)] }, void 0), (subjects || []).map(sub => {
                const { first_name = "", last_name = "" } = sub || {};
                return _jsxs(_Fragment, { children: [_jsxs("td", { children: [first_name, " "] }, void 0), _jsxs("td", { children: [last_name, " "] }, void 0), _jsx("td", { children: _jsx("table", { children: _jsx("tbody", { children: _jsx("tr", { children: (sub.user_ids || []).map(user => _jsxs("td", Object.assign({ css: { padding: 4 } }, { children: [(user.applications || []).map(app => app.app_type.map(at => _jsx("b", { children: _jsx("div", { children: at }, void 0) }, void 0))), user.user_type.length == 1 ?
                                                    _jsxs("div", { children: [_jsx(Confirm, { message: "Are you sure you want to delete user ?", ref: confirmUserDeleteRef, buttons: {
                                                                    delete: {
                                                                        label: "Confirm Delete User", callback: (c, close) => {
                                                                            close();
                                                                            deleteUser(user.user_uid);
                                                                        }
                                                                    },
                                                                    cancel: {
                                                                        label: "Cancel", css: { backgroundColor: "#f14545" },
                                                                        callback: (c, close) => {
                                                                            close();
                                                                        }
                                                                    }
                                                                } }, void 0), _jsxs("div", { children: [user.status == "user_suspended" ?
                                                                        _jsx("span", Object.assign({ css: { color: "#22ff22", cursor: "pointer" }, onClick: unSuspendUser(user.user_uid) }, { children: "UnSuspend \u00A0" }), void 0) :
                                                                        _jsx("span", Object.assign({ css: { color: "#2222ff", cursor: "pointer" }, onClick: suspendUser(user.user_uid) }, { children: "Suspend \u00A0" }), void 0), _jsx("span", Object.assign({ css: { color: "#ff2222", cursor: "pointer" }, onClick: confirmDeleteUser }, { children: "Delete" }), void 0)] }, void 0)] }, void 0) : null] }), void 0)) }, void 0) }, void 0) }, void 0) }, void 0)] }, void 0);
            })] }, void 0);
};
