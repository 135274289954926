import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { useProgressBar } from "../../../components/ProgressBar";
import { SearchToolBar } from "../../../components/Search";
import { StreamNav } from "../../../components/StreamNav";
import { useStream } from "../../../saga/use-stream";
import { OrderListComponent } from "../order-list-component";
import { CancelOrderListComponent } from "../order-cancle-shipment-list";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
export const filters = [
    { title: "Order Id", value: "order_id", filter: true },
    { title: "Username (email/mobile number)", value: "username", filter: false },
    { title: "Store Name", value: "store_name", filter: false },
    { title: "Buyer First Name", value: "first_name", filter: false },
    { title: "Buyer Last Name", value: "last_name", filter: false },
    { title: "Buyer Pincode", value: "pin_code", filter: false },
    { title: "Buyer City", value: "city", filter: false },
    { title: "Store Uid", value: "store_uid", filter: false },
];
export const OrderList = (props) => {
    const { routerProps, path, statuses, mode } = props;
    // const queryParams = { status: "buyer-cod-order-confirmed" }
    const { page: pageStr } = routerProps.match.params;
    const page = parseInt(`${pageStr}`);
    const { status, stream, isFetching, data, loadPage, loadNextPage, loadUrl } = useStream("/trendutube/v1/admin-order-processing/orders/list", { page, queryParams: { status: statuses } });
    const [maxPage, setMaxPage] = useState(page);
    const setLoading = useProgressBar();
    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (stream && (stream.page > maxPage))
            setMaxPage(stream.page);
    }, [stream]);
    const { productstyle, bigtable } = useStyles("productstyle", "bigtable");
    const orders = data;
    function onSearchChange(name, filters) {
        const qp = { name };
        if (name && name.length > 2) {
            filters.forEach(filter => {
                if (filter.filter)
                    qp[filter.value] = name;
                if (filter.value == "mobile_no" && filter.filter)
                    delete qp["name"];
            });
            loadUrl("/trendutube/v1/admin-order-processing/orders/list", 0, Object.assign(Object.assign({}, qp), { status: statuses }));
        }
        else {
            loadUrl("/trendutube/v1/admin-order-processing/orders/list", 0, { status: statuses });
        }
    }
    const header = {
        order_date: "Date",
        id: "Id",
        order_id: "Order Id",
        status: "Status",
        payment_mode: "Payment Mode",
        net_amount_with_tips: "Net Amount",
        buyer: "Buyer",
        sellers: "Sellers"
    };
    const keys = [
        "order_date",
        "order_id",
        "id",
        "status",
        "payment_mode",
        "net_amount_with_tips",
        "buyer",
        "sellers"
    ];
    const { storetablestyle } = useStyles("storetablestyle", "panelstyle");
    const frag = (mode == "pending" ? "/orders/in-progress/" : "/orders/delivered/");
    return _jsxs("div", { children: [_jsx("div", Object.assign({ css: productstyle }, { children: _jsx(SearchToolBar, { onChange: onSearchChange, filters: filters }, void 0) }), void 0), _jsx(StreamNav, Object.assign({}, {
                loadNextPage, loadPage, stream, maxPage,
                getUrl: (page) => `${path}${frag}${page}`
            }), void 0), _jsx("div", Object.assign({ css: productstyle }, { children: orders ? mode == "pending" ?
                    _jsx(TableContainer, Object.assign({ component: Paper }, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, Object.assign({ sx: { '& th': { fontWeight: 'bold', color: '#000000', fontSize: '15px' } } }, { children: [_jsx(TableCell, { children: "Date" }, void 0), _jsx(TableCell, { children: "Id" }, void 0), _jsx(TableCell, { children: "Order Id" }, void 0), _jsx(TableCell, { children: "Status" }, void 0), _jsx(TableCell, { children: "Payment Mode" }, void 0), _jsx(TableCell, { children: "Net Amount" }, void 0), _jsx(TableCell, { children: "Buyer" }, void 0), _jsx(TableCell, { children: "Sellers" }, void 0)] }), void 0) }, void 0), _jsx(TableBody, { children: data && data.map((order, index) => (_jsx(OrderListComponent, { data: order, index: index }, void 0))) }, void 0)] }, void 0) }), void 0)
                    // <Table
                    //     style={bigtable}
                    //     body={data as Order[]}
                    //     header={header} keys={keys}
                    //     component={OrderListComponent}
                    //     itemOnClick={(item) => {
                    //         console.log("PAGERTT", page)
                    //         // loadPage(page)
                    //     }} /> 
                    :
                        //  <Table
                        //     style={bigtable}
                        //     body={data as Order[]}
                        //     header={header} keys={keys}
                        //     component={CancelOrderListComponent}
                        //     itemOnClick={(item) => {
                        //         console.log("PAGERTT", page)
                        //         // loadPage(page)
                        //     }} />
                        _jsx(TableContainer, Object.assign({ component: Paper }, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, Object.assign({ sx: { '& th': { fontWeight: 'bold', color: '#000000', fontSize: '15px' } } }, { children: [_jsx(TableCell, { children: "Date" }, void 0), _jsx(TableCell, { children: "Id" }, void 0), _jsx(TableCell, { children: "Order Id" }, void 0), _jsx(TableCell, { children: "Status" }, void 0), _jsx(TableCell, { children: "Payment Mode" }, void 0), _jsx(TableCell, { children: "Net Amount" }, void 0), _jsx(TableCell, { children: "Buyer" }, void 0), _jsx(TableCell, { children: "Sellers" }, void 0)] }), void 0) }, void 0), _jsx(TableBody, { children: data && data.map((order, index) => (_jsx(CancelOrderListComponent, { data: order, index: index }, void 0))) }, void 0)] }, void 0) }), void 0)
                    : null }), void 0)] }, void 0);
};
