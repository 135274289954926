import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Children, Component } from 'react';
import { FieldContextImpl, } from './field';
import { FieldContext } from './type';
export class FieldSet extends Component {
    // context!: FieldContextProps
    getChildren() {
        const { props, context } = this;
        const { children, name } = props;
        let { path } = context;
        const childs = Children.map(children, child => {
            const thisPath = path ? `${path}.${name}` : name;
            const fieldContext = new FieldContextImpl(thisPath, this.context.form);
            const elements = React.cloneElement(child);
            return _jsx(FieldContext.Provider, Object.assign({ value: fieldContext }, { children: elements }), void 0);
        });
        return _jsxs(_Fragment, { children: [" ", childs, " "] }, void 0);
    }
    render() {
        return this.getChildren();
    }
}
FieldSet.contextType = FieldContext;
