var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { NavLink } from "react-router-dom";
import { StyleableComponent, StyleProvider, styling, useStyles, } from "@exprzn/react-style-lib";
import { Col } from "../../components/Column";
import { Row } from "../../components/Row";
import { Form, Field } from "../../components/Form";
import { useSaga } from "../../saga/use-saga";
import { container } from "./style";
import { SearchOutlined } from "@ant-design/icons";
import { Table } from "../../components/Table/table";
import { Button } from '@mui/material';
export class InputSearch extends StyleableComponent {
    render() {
        const { onChange, value, errors } = this.props;
        const { errorStyle, input, rowwrap, searchbtn, inputwrap } = this;
        return (_jsx(Row, { children: _jsxs("div", Object.assign({ css: rowwrap }, { children: [_jsx("div", Object.assign({ css: searchbtn }, { children: _jsx(SearchOutlined, { style: { fontSize: "1.6em" } }, void 0) }), void 0), _jsx("div", Object.assign({ style: inputwrap }, { children: _jsx("input", { css: input, onChange: onChange, value: value }, void 0) }), void 0)] }), void 0) }, void 0));
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], InputSearch.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], InputSearch.prototype, "input", void 0);
__decorate([
    styling("rowwrap"),
    __metadata("design:type", Object)
], InputSearch.prototype, "rowwrap", void 0);
__decorate([
    styling(),
    __metadata("design:type", Object)
], InputSearch.prototype, "searchbtn", void 0);
__decorate([
    styling(),
    __metadata("design:type", Object)
], InputSearch.prototype, "inputwrap", void 0);
const Store = (props) => {
    const { storeitemtyle } = useStyles("storeitemtyle");
    //   const { data, loadUrl } = useSaga<Product>(
    //     "/trendutube/v1/product-catalog/products/search?like=sony",
    //     {},
    //     false
    //   );
    const { data: store } = props;
    const isSuspended = store.status === "store-suspended";
    return (
    // <div css={storeitemtyle}>
    // <Row>
    _jsxs(_Fragment, { children: [_jsx("td", Object.assign({ style: { padding: 10 } }, { children: store.store_name }), void 0), _jsx("td", { children: store.open_time }, void 0), _jsx("td", { children: store.close_time }, void 0), _jsx("td", { children: _jsx(Button, Object.assign({ color: "primary", sx: { textTransform: 'none' }, disabled: isSuspended }, { children: _jsx(NavLink, Object.assign({ to: `/dashboard/stores/${store.uid}/products/add`, style: { textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' } }, { children: "Add Product" }), void 0) }), void 0) }, void 0), _jsx("td", { children: _jsx(NavLink, Object.assign({ to: `/dashboard/stores/${store.uid}/products/list` }, { children: "View Products" }), void 0) }, void 0), _jsx("td", { children: _jsx(Button, Object.assign({ color: "primary", sx: { textTransform: 'none' }, disabled: isSuspended }, { children: _jsx(NavLink, Object.assign({ to: `/dashboard/discount/create/store/${store.uid}`, style: { textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' } }, { children: "Add Discounts" }), void 0) }), void 0) }, void 0), _jsx("td", { children: _jsx(NavLink, Object.assign({ to: `/dashboard/stores/${store.uid}/sales-and-payouts/list` }, { children: "View Sales And Payouts" }), void 0) }, void 0)] }, void 0)
    // </Row>
    // </div>
    );
};
const StoresListInternal = (props) => {
    const { uid } = props.match.params;
    const { data, loadUrl } = useSaga(`/trendutube/v1/seller-management/sellers/${uid}/stores/get-list`);
    const { storeitemtyle, productstyle } = useStyles("storeitemtyle", "productstyle");
    const { stores = [] } = (data || {});
    const keys = ["store_name", "open_time", "close_time", "add_product", "view_products", "discounts", "sales_and_payouts"];
    const headers = {
        store_name: "Store Name",
        open_time: "Open Time",
        close_time: "Close Time",
        add_product: "Add Product",
        discounts: "Discounts",
        view_products: "View Products",
        sales_and_payouts: "Sales And Payouts"
    };
    return (_jsxs("div", { children: [_jsx(Row, { children: _jsx(Col, Object.assign({ cols: 12 }, { children: _jsx("div", Object.assign({ css: productstyle }, { children: _jsx(Form, Object.assign({ name: "productsearch" }, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Field, Object.assign({ name: "search", onChange: (type_text) => {
                                        loadUrl(`/trendutube/v1/admin-seller-management/stores/list`);
                                        // data &&
                                        //   stores.map((store) => {
                                        //     if (store.store_name.includes(type_text)) {
                                        //       console.log("Store Name", store.store_name);
                                        //     }
                                        //   });
                                    } }, { children: _jsx(InputSearch, {}, void 0) }), void 0) }), void 0) }), void 0) }), void 0) }), void 0) }, void 0), _jsx("div", Object.assign({ css: storeitemtyle }, { children: data && stores ? _jsx(Table, { keys: keys, header: headers, component: Store, body: stores }, void 0) : null }), void 0)] }, void 0));
};
// export const StoreList = (props: RouteComponentProps) => (
//   <SagaProvider>
//     <StyleProvider value={{ container }}>
//       <StoresListInternal {...props} />
//     </StyleProvider>
//   </SagaProvider>
// );
export const StoreList = (props) => (_jsx(StoresListInternal, Object.assign({}, props), void 0));
