import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { StyleProvider, useStyles } from '@exprzn/react-style-lib';
import styleContainer from './style';
import { Field, Form } from '../../components/Form';
import { LogoIcon } from '../../components/Icon';
import { useSubmit } from '../../saga/use-submit';
import { SagaProvider } from '../../saga/saga-context';
import { updateAuth } from '../../use-auth';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { global_realm } from '../../config';
const Login = (props) => {
    const { loginButton, loginHeading, container, button, row, logintitle, loginbackground, boxStyle } = useStyles("loginButton", "loginHeading", "container", "button", "row", "logintitle", "loginbackground", "boxStyle");
    const { onLogin } = props;
    const { submit, data, error } = useSubmit({}, true);
    function handleSubmit(data) {
        const realm = global_realm;
        const body = Object.assign(Object.assign({}, loginData), { realm });
        submit(`/trendutube/v1/auth/organizations/${process.env.REACT_APP_ORG_UID}/login`, {
            body
        });
    }
    useEffect(() => {
        console.log("DATAAA", data);
        if (data && data.authenticated) {
            updateAuth(Object.assign({}, data));
            onLogin && onLogin(true);
        }
    }, [data]);
    const [loginData, setLoginData] = useState({ username: '', password: '' });
    const changeLoginData = (e) => {
        setLoginData(Object.assign(Object.assign({}, loginData), { [e.target.name]: e.target.value }));
    };
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((prev) => !prev);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
        if (error && typeof error === "object" && error !== null && "message" in error) {
            toast.error(error.message, { autoClose: 10000 });
        }
    }, [error]);
    return _jsxs("div", Object.assign({ css: container }, { children: [_jsx("header", Object.assign({ style: { height: '9vh' } }, { children: _jsx(LogoIcon, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: [loginbackground] }, { children: _jsx("div", Object.assign({ css: [css({
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        })] }, { children: _jsxs("div", Object.assign({ css: [boxStyle], style: { minHeight: '527px', minWidth: '780' } }, { children: [_jsx("div", Object.assign({ css: [loginHeading] }, { children: "Sign In To Admin" }), void 0), _jsxs(Form, Object.assign({ name: "login" }, { children: [_jsxs("div", Object.assign({ css: [row] }, { children: [_jsx("div", Object.assign({ style: { fontWeight: 600, fontSize: '14px', marginBottom: "2%" } }, { children: "Email" }), void 0), _jsx(TextField, { value: loginData.username, name: 'username', fullWidth: true, onChange: (event) => {
                                                    changeLoginData(event);
                                                }, inputProps: {
                                                    style: {
                                                        WebkitBoxShadow: '0 0 0 1000px white inset',
                                                        height: '10px',
                                                        border: '1px solid #045998'
                                                    },
                                                } }, void 0)] }), void 0), _jsxs("div", Object.assign({ css: [row] }, { children: [_jsx("div", Object.assign({ style: { fontWeight: 600, fontSize: '14px', marginBottom: "2%" } }, { children: "Password" }), void 0), _jsx(TextField, { value: loginData.password, name: 'password', type: showPassword ? 'text' : 'password', fullWidth: true, onChange: (event) => {
                                                    changeLoginData(event);
                                                }, inputProps: {
                                                    style: {
                                                        WebkitBoxShadow: '0 0 0 1000px white inset',
                                                        height: '10px',
                                                        border: '1px solid #045998'
                                                    },
                                                    endAdornment: (_jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end" }, { children: showPassword ? _jsx(VisibilityOff, {}, void 0) : _jsx(Visibility, {}, void 0) }), void 0) }), void 0)),
                                                } }, void 0)] }), void 0), _jsx("div", Object.assign({ css: [row] }, { children: _jsx(Field, Object.assign({ name: "g", value: "Login" }, { children: _jsx("button", Object.assign({ css: [loginButton], type: "submit", value: "Login", style: { fontWeight: 700 }, onClick: handleSubmit }, { children: "Login" }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0);
    // <div css={container}>
    //     <Row>
    //         <Col>
    //             <div css={[logintitle]}>
    //                 Sign in to Admin
    //             </div>
    //         </Col>
    //     </Row>
    //     <Row>
    //         <Col>
    //             <div css={[css({ display: "flex", "justify-content": "center", "align-items": "center" })]}>
    //                 <Form name="login" onSubmit={handleSubmit}>
    //                     <div css={[row]}>
    //                         <div >Email</div>
    //                         <StyleProvider value={{ container: styleContainer }} >
    //                             <Field name="username" email >
    //                                 <InputEx></InputEx>
    //                             </Field>
    //                         </StyleProvider>
    //                     </div>
    //                     <div css={[row]}>
    //                         <div >Password</div>
    //                         <StyleProvider value={{ container: styleContainer }} >
    //                             <Field name="password" >
    //                                 <PasswordInputEx></PasswordInputEx>
    //                             </Field>
    //                         </StyleProvider>
    //                     </div>
    //                     <div css={[row]}>
    //                         <Field name="g" value="Login">
    //                             <button css={button} type="submit" value="Login">Login
    //                             </button>
    //                         </Field>
    //                     </div>
    //                 </Form>
    //             </div></Col>
    //     </Row>
    // </div>
};
export const LoginPage = (props) => _jsxs(SagaProvider, { children: [" ", _jsx(StyleProvider, Object.assign({ value: { container: styleContainer, theme: {} } }, { children: _jsx(Login, Object.assign({}, props), void 0) }), void 0)] }, void 0);
