import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import reportWebVitals from './reportWebVitals';
const w = window;
w.React2 = require('react');
console.log("REACTEQUALITY", w.React1 != null, w.React2 != null, w.React1 === w.React2);
ReactDOM.render(_jsxs(React.StrictMode, { children: [_jsx(App, {}, void 0), _jsx(ToastContainer, {}, void 0)] }, void 0), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
