var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
const { style, container } = registry("product", true);
let ProductStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ProductStyle extends CssStyle {
};
ProductStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em",
    padding: "1.5em"
};
ProductStyle = __decorate([
    style("productstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ProductStyle);
let ImageStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ImageStyle extends CssStyle {
};
ImageStyle.style = {
    minWidth: "100%",
    maxWidth: "100%",
    minHeight: "300px",
    maxHeight: "300px",
    borderRadius: ".5em",
    objectFit: "cover",
    minPosition: "relative",
};
ImageStyle = __decorate([
    style("imagestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ImageStyle);
let InputStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputStyle extends CssStyle {
};
InputStyle.style = {
    position: "relative",
    border: '0px solid #dddddd00',
    width: "100%",
    backgroundColor: '#ffffff00',
    color: '#e8a631',
    fontWeight: 600,
    fontSize: '1.2em',
    paddingLeft: "4px"
};
InputStyle = __decorate([
    style("input")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputStyle);
let RowWrapStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowWrapStyle extends CssStyle {
};
RowWrapStyle.style = {
    position: "relative",
    border: '1px solid #dddddd',
    '&:hover': {
        backgroundColor: '#f8b63101',
    },
    '&:focus': {
        backgroundColor: '#fefefe',
        boxShadow: '4px 4px 7px #eeeeff'
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    height: "100%",
    backgroundColor: '#f3f3f3',
    color: '#e8a631',
    fontWeight: 600,
    fontSize: '1.1em',
    display: 'flex'
};
RowWrapStyle = __decorate([
    style("rowwrap")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowWrapStyle);
let ErrorStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ErrorStyle extends CssStyle {
};
ErrorStyle.style = {
    margin: ".1em",
    fontSize: "1em",
    color: 'red'
};
ErrorStyle = __decorate([
    style("error")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ErrorStyle);
let ImageBGStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ImageBGStyle extends CssStyle {
};
ImageBGStyle.style = {
    backgroundColor: '#f8f8f8'
};
ImageBGStyle = __decorate([
    style("imagebg")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ImageBGStyle);
let SearchBtnStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SearchBtnStyle extends CssStyle {
};
SearchBtnStyle.style = {
    // padding: '0px 0px',
    alignSelf: "center",
    padding: "0px .5rem"
};
SearchBtnStyle = __decorate([
    style("searchbtn")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SearchBtnStyle);
let InputWrapStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputWrapStyle extends CssStyle {
};
InputWrapStyle.style = {
    // padding: '0px 0px',
    flex: "1 1 100%",
};
InputWrapStyle = __decorate([
    style("inputwrap")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputWrapStyle);
export default container;
