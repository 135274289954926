import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleProvider, useStyles } from '@exprzn/react-style-lib';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col } from '../../../components/Column';
import { DragDrop } from '../../../components/DragDrop';
import { Form } from '../../../components/Form';
import { LabelledField, LabelledNewField } from '../../../components/Form/labelled-field';
import { useProgressWatch } from '../../../components/ProgressBar';
import { Row } from '../../../components/Row';
import { SagaProvider } from '../../../saga/saga-context';
import { useSaga } from '../../../saga/use-saga';
import { useSubmit } from '../../../saga/use-submit';
import { CardNano } from '../../orders/card-nano';
import container from './style';
export const CategoryAddSubCatInternal = (props) => {
    const { category, rowstyle, button } = useStyles("category", "rowstyle", "button");
    const { submit, data, error, isFetching, status } = useSubmit();
    const { submit: submitImage, data: dataImage, error: errorImage } = useSubmit();
    const [category_image_uid, setImage] = useState();
    const { uid } = props.match.params;
    const [catName, setCatName] = useState();
    const [catDesc, setCatDesc] = useState();
    const history = useHistory();
    const { isFetching: isFetchingCat, reload, data: dataCategory } = useSaga(`/trendutube/v1/public/categories/${uid}/info?sub_category=false`, {}, false);
    useProgressWatch(isFetching);
    useProgressWatch(isFetchingCat);
    useEffect(() => {
        if (status == "ok") {
            toast.success("Sub category created", { toastId: "success_sub_cat" });
            history.goBack();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    useEffect(() => {
        if (dataCategory) {
            const { category_name, category_description } = dataCategory;
            setCatName(category_name);
            setCatDesc(category_description);
        }
    }, [dataCategory]);
    const submitHandler = (formData) => {
        submit(`/trendutube/v1/admin/categories/${uid}/add`, { body: Object.assign(Object.assign({}, formData.category), { category_image_uid }) });
    };
    function handleImageSubmit([image]) {
        setImage(image.uid);
    }
    useEffect(() => {
        if (dataImage) {
            alert(JSON.stringify(dataImage));
            const { urls } = dataImage;
            setImage(urls[0].uid);
        }
    }, [dataImage]);
    return _jsxs("div", Object.assign({ css: category }, { children: [_jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: 2 }, { children: _jsx(CardNano, { title: "Category Name", value: catName || "" }, void 0) }), void 0), _jsx(Col, { children: _jsx(CardNano, { title: "Category Uid", value: uid || "" }, void 0) }, void 0)] }, void 0), error ? _jsx("div", { children: error && JSON.stringify(error) }, void 0) : "", _jsxs(Row, { children: [_jsx(Col, Object.assign({ top: true }, { children: _jsx("div", Object.assign({ css: { marginRight: 10, padding: 10 } }, { children: _jsxs(Form, Object.assign({ name: "category", onSubmit: submitHandler }, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsx(LabelledField, { name: "category_name", value: "", label: "Sub Category Name" }, void 0), _jsx(LabelledField, { name: "category_description", value: "", label: "Sub Category Description" }, void 0), _jsx(LabelledField, { name: "gravity", value: "", label: "Position" }, void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: rowstyle }, { children: _jsx("input", { type: "submit", value: "Add Category", css: button }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0) }), void 0), _jsx(Col, { children: _jsx(Row, { children: _jsx(Col, { children: _jsx(DragDrop, { onImagesUpload: handleImageSubmit, entity: "category" }, void 0) }, void 0) }, void 0) }, void 0)] }, void 0)] }), void 0);
};
export const CategorySubCatInternal = (props) => {
    const { category, rowstyle, button } = useStyles("category", "rowstyle", "button");
    const { submit, data, error, isFetching, status } = useSubmit();
    const { submit: submitImage, data: dataImage, error: errorImage } = useSubmit();
    const [category_image_uid, setImage] = useState();
    const { uid, pageReload, handleCloseDrawer } = props;
    const [catName, setCatName] = useState();
    const [catDesc, setCatDesc] = useState();
    const history = useHistory();
    const { isFetching: isFetchingCat, reload, data: dataCategory } = useSaga(`/trendutube/v1/public/categories/${uid}/info?sub_category=false`, {}, false);
    useProgressWatch(isFetching);
    useProgressWatch(isFetchingCat);
    useEffect(() => {
        if (status == "ok") {
            toast.success("Sub category created", { toastId: "success_sub_cat" });
            handleCloseDrawer();
            pageReload();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    useEffect(() => {
        if (dataCategory) {
            const { category_name, category_description } = dataCategory;
            setCatName(category_name);
            setCatDesc(category_description);
        }
    }, [dataCategory]);
    const submitHandler = (formData) => {
        submit(`/trendutube/v1/admin/categories/${uid}/add`, { body: Object.assign(Object.assign({}, formData.category), { category_image_uid }) });
    };
    function handleImageSubmit([image]) {
        setImage(image.uid);
    }
    useEffect(() => {
        if (dataImage) {
            alert(JSON.stringify(dataImage));
            const { urls } = dataImage;
            setImage(urls[0].uid);
        }
    }, [dataImage]);
    return _jsxs("div", Object.assign({ style: { border: "0.6px solid #045998", marginTop: '10px', padding: '15px' } }, { children: [_jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { title: "Category Name", value: catName || "" }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "Category Uid", value: uid || "" }, void 0) }, void 0)] }, void 0), error ? _jsx("div", { children: error && JSON.stringify(error) }, void 0) : "", _jsx(Row, { children: _jsx(Col, Object.assign({ top: true }, { children: _jsx("div", Object.assign({ css: { marginRight: 10, padding: 10 } }, { children: _jsxs(Form, Object.assign({ name: "category", onSubmit: submitHandler }, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsx(LabelledNewField, { name: "category_name", value: "", label: "Sub Category Name" }, void 0), _jsx(LabelledNewField, { name: "category_description", value: "", label: "Sub Category Description" }, void 0), _jsx(LabelledNewField, { name: "gravity", value: "", label: "Position" }, void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: rowstyle }, { children: _jsx("input", { type: "submit", value: "Add Category", style: { backgroundColor: '#045998', border: '1px solid #045998', color: 'white', padding: '5px' } }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0) }), void 0) }, void 0), _jsx(Row, { children: _jsx(DragDrop, { onImagesUpload: handleImageSubmit, entity: "category" }, void 0) }, void 0)] }), void 0);
};
export const CategoryAddSubCat = (props) => _jsxs(SagaProvider, { children: [" ", _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(CategoryAddSubCatInternal, Object.assign({}, props), void 0) }), void 0)] }, void 0);
