import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { loadMapApi } from "./utils";
import { useMapScript } from "../../map/map-context";
import { DragDrop } from "../../components/DragDrop";
export const MapComponent = (props) => {
    const ref = useRef(null);
    let map;
    const [mapRef, setMap] = useState();
    const [latLng, setLatLng] = useState();
    // useEffect(()=>{
    //     client
    //     .geocode({
    //       params: {
    //         // latlng:{ lat: 45, lng: -110 },
    //         place_id:"ss",
    //         key: "AIzaSyDOuI_UUhawLWmHgQ-bgLIG-XGVF_gyf-k",
    //       },
    //       timeout: 5000, // milliseconds
    //     })
    //     .then((r) => {
    //       console.log(r.data.results[0].address_components);
    //     })
    //     .catch((e) => {
    //         alert(JSON.stringify(e))
    //       console.log(e);
    //     });
    // },[])
    useEffect(() => {
        const googleMapScript = loadMapApi();
        googleMapScript.addEventListener("load", () => {
            map = new google.maps.Map(ref.current, {
                zoom: 8,
                panControl: true,
                center: new google.maps.LatLng(-34.397, 150.644),
            });
            setMap(map);
            if (map) {
                // map.addListener( "center_changed", function() { 
                //   setLatLng( map.getCenter())
                //  })
                // alert("set listemer")
                map.addListener("center_changed", function () { setLatLng(map.getCenter()); });
            }
        });
    }, []);
    useEffect(() => {
        mapRef === null || mapRef === void 0 ? void 0 : mapRef.addListener("center_changed", function () {
            setLatLng(mapRef.getCenter());
            console.log("Center change", mapRef.getCenter());
        });
    }, [mapRef]);
    return _jsxs("div", Object.assign({ style: { position: "relative", height: "100%" } }, { children: ["Lat ", latLng && (latLng === null || latLng === void 0 ? void 0 : latLng.lat()), "Lng ", latLng && (latLng === null || latLng === void 0 ? void 0 : latLng.lng()), _jsx("button", Object.assign({ onClick: () => {
                    if (map) {
                        const latlng = map.getCenter();
                        setLatLng(latlng);
                    }
                } }, { children: "LatLng" }), void 0), _jsx("div", Object.assign({ style: { width: 400, height: 760 }, ref: ref }, { children: _jsx(DragDrop, { entity: "", onImagesUpload: () => { } }, void 0) }), void 0)] }), void 0);
};
const _MapView = (props, ref) => {
    const ref1 = useRef(null);
    const isMapLoaded = useMapScript();
    const { latLng: initialLatLng, onCenterChange } = props;
    const [lat, lng] = initialLatLng;
    const [map, setMap] = useState();
    useImperativeHandle(ref, () => ({
        setLatLng: (lt, ln) => {
            map === null || map === void 0 ? void 0 : map.setCenter(new google.maps.LatLng(lt, ln));
        }
    }));
    useEffect(() => {
        const googleMapScript = loadMapApi();
        // googleMapScript.addEventListener("load", () => {
        if (isMapLoaded) {
            const googleMap = new google.maps.Map(ref1.current, {
                zoom: 17,
                panControl: true,
                center: new google.maps.LatLng(lat, lng),
            });
            setMap(googleMap);
        }
        // })
    }, [isMapLoaded]);
    useEffect(() => {
        map === null || map === void 0 ? void 0 : map.addListener("center_changed", function () {
            const latLng = map.getCenter();
            if (latLng)
                onCenterChange([latLng.lat(), latLng.lng()]);
        });
    }, [map]);
    return _jsx("div", { children: _jsx("div", { style: { height: "80vh" }, ref: ref1 }, void 0) }, void 0);
};
export const MapView = forwardRef(_MapView);
