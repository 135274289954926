import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { useProgressBar } from "../../../components/ProgressBar";
import { localDatetime } from "../../../utils";
import { EditRefund } from "./create-refund";
import { Box, Button, FormControl, MenuItem, Select } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "../../Shipments/custompagination";
import { usePagination } from "../../../saga/use-pagination";
import { REFUND_STATUS } from "../../orders/status-definitions";
export const RefundComponent = (props) => {
    const { refund, refund_amount, return_order_id, onFinishEdit } = props;
    const [isShowCreateRefund, setShowCreateRefund] = useState(false);
    const [isShowEditRefund, setShowEditRefund] = useState(false);
    const { button } = useStyles("button");
    const onFinish = () => {
        setShowCreateRefund(false);
        setShowEditRefund(false);
        onFinishEdit();
    };
    return _jsxs(_Fragment, { children: [" ", refund ?
                _jsx(Button, Object.assign({ variant: "outlined", size: "small", onClick: () => { setShowEditRefund(true); } }, { children: "Edit" }), void 0)
                :
                    _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, onClick: () => { setShowCreateRefund(true); }, size: "small" }, { children: "Create" }), void 0), isShowCreateRefund ?
                _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isShowCreateRefund, title: "Add Refund", subtitle: "", width: "40%", onRequestClose: () => {
                        setShowCreateRefund(false);
                    } }, { children: _jsx(EditRefund, { mode: "create", refund_amount: refund_amount, refund_maximum_amount: refund_amount, cancellation_id: return_order_id, onFinishEdit: onFinish }, void 0) }), void 0) : null, isShowEditRefund ?
                _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isShowEditRefund, title: "Edit Refund", subtitle: "", width: "40%", onRequestClose: () => {
                        setShowEditRefund(false);
                    } }, { children: _jsx(EditRefund, { onFinishEdit: onFinish, mode: "edit", refund: refund, refund_maximum_amount: 0, refund_amount: refund_amount, cancellation_id: return_order_id }, void 0) }), void 0) : null] }, void 0);
};
export const CancellationRefundPage = (props) => {
    const queryParams = { page_size: 10, fullfillment_type: 2, status: "initiated" };
    const { data, loadPage, paging, loadUrl, isFetching } = usePagination("/trendutube/v1/admin-order-processing/cancellations-2/list", { queryParams });
    const { productstyle } = useStyles("productstyle");
    const setLoading = useProgressBar();
    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);
    const columns = [
        {
            field: 'order_id', headerName: 'Order Id', minWidth: 30, headerClassName: 'custom-header',
            renderCell: (params) => {
                return (params.row.order.order_id);
            }
        },
        {
            field: 'cancellation_date', headerName: 'Date', minWidth: 190, headerClassName: 'custom-header',
            renderCell: (params) => {
                return localDatetime(params.value);
            }
        },
        { field: 'cancelled_total_amount', headerName: 'Cancelled Amount', minWidth: 130, headerClassName: 'custom-header' },
        { field: 'cancelled_shipping_amount', headerName: 'Shipping Amount', minWidth: 130, headerClassName: 'custom-header' },
        { field: 'cancelled_net_amount', headerName: 'Refundable Amount', minWidth: 150, headerClassName: 'custom-header' },
        {
            field: 'payment_reference', headerName: 'Payment Reference', minWidth: 190, headerClassName: 'custom-header',
            renderCell: (params) => {
                const return_order = params.row;
                if (return_order.refund) {
                    return return_order.refund.payment_reference;
                }
            }
        },
        {
            field: 'refund_status', headerName: 'Status', minWidth: 80, headerClassName: 'custom-header',
            renderCell: (params) => {
                const value = params.value;
                // Determine the text color based on the value
                const color = value === 'paid' ? 'green' : 'red';
                return (_jsx("span", Object.assign({ style: { color } }, { children: value }), void 0));
            }
        },
        {
            field: 'edit',
            headerName: '',
            flex: 0.1,
            renderCell: (params) => {
                const return_order = params.row;
                return (_jsx(RefundComponent, { refund: return_order.refund, onFinishEdit: () => loadPage(paging.page), refund_amount: return_order.refund ? return_order.refund_amount : return_order.cancelled_total_amount + return_order.cancelled_shipping_amount, return_order_id: return_order.id }, void 0));
            },
            headerClassName: 'custom-header'
        },
    ];
    const handlePageChange = (newPage) => {
        const queryParams = { page_size: 10, fullfillment_type: 2, status: refundStatus };
        loadUrl(`/trendutube/v1/admin-order-processing/cancellations-2/list`, newPage, queryParams);
    };
    const [refundStatus, setRefundStatus] = useState("initiated");
    const handleChange = (event) => {
        setRefundStatus(event.target.value);
        const queryParams = { page_size: 10, status: event.target.value };
        loadUrl(`/trendutube/v1/admin-order-processing/cancellations-2/list`, 0, queryParams);
    };
    return _jsx("div", { children: data ? _jsxs("div", Object.assign({ css: productstyle }, { children: [_jsx(Box, Object.assign({ sx: { width: "100%", display: 'flex', justifyContent: 'flex-end', minWidth: 120, marginBottom: '10px' } }, { children: _jsx(FormControl, { children: _jsxs(Select, Object.assign({ labelId: "demo-simple-select-label", id: "demo-simple-select", value: refundStatus, onChange: handleChange }, { children: [_jsx(MenuItem, Object.assign({ value: REFUND_STATUS.REFUND_INITATED }, { children: "Refund Initiated" }), void 0), _jsx(MenuItem, Object.assign({ value: REFUND_STATUS.REFUND_PAID }, { children: "Paid" }), void 0)] }), void 0) }, void 0) }), void 0), _jsx(DataGrid, { getRowId: (row) => row.id, rows: data, columns: columns, initialState: {
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }, hideFooterPagination: true, checkboxSelection: false, isRowSelectable: () => false }, void 0), _jsx(CustomPagination, { page: paging === null || paging === void 0 ? void 0 : paging.page, totalPages: paging === null || paging === void 0 ? void 0 : paging.total_pages, hasNext: (paging === null || paging === void 0 ? void 0 : paging.total_pages) > Number(paging === null || paging === void 0 ? void 0 : paging.page), onPageChange: handlePageChange }, void 0)] }), void 0) : null }, void 0);
};
