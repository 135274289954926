var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { registry } from "@exprzn/react-style-lib";
const { style, container } = registry("form_style", true);
let FieldContainerStle = class FieldContainerStle {
};
FieldContainerStle.style = {
    position: "relative",
    margin: "8px 10px 8px 0px",
    borderRadius: "4px",
    // display:"flex",
    // flexDirection:"column",
    // justifyContent:"start"
};
FieldContainerStle = __decorate([
    style("field_container")
], FieldContainerStle);
let FieldLabelStyle = class FieldLabelStyle {
};
FieldLabelStyle.style = {
    position: "relative",
    color: "#888888"
};
FieldLabelStyle = __decorate([
    style("field_label")
], FieldLabelStyle);
export default container;
