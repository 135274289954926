var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { useAuth } from '../use-auth';
import { convertToQueryUrl } from '../utils';
import { createReducer } from './reducer';
import { SagaContext } from './saga-context';
import { useReducerInjector } from './use-reducer-injector';
import { uuidv4 } from './uuid';
export function useStreamPost(url, options = {}) {
    const [_prefix] = useState(uuidv4());
    const dispatch = useDispatch();
    const { inject, expel } = useReducerInjector();
    const { sagaMiddleware } = useContext(SagaContext);
    const { access_token } = useAuth(true);
    const { page_size = 5, page: initialPage = 1, queryParams: initialQueryParams, body: initialBody } = options, useable_options = __rest(options, ["page_size", "page", "queryParams", "body"]);
    const [state, setState] = useState();
    const [isFetching, setIsFetching] = useState(false);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization, 'Content-Type': 'application/json' };
    const method = "POST";
    useEffect(() => {
        const reducer = createReducer(_prefix);
        inject(_prefix, reducer);
        const task = sagaMiddleware.run(saga);
        setState({ url, page: initialPage, queryParams: initialQueryParams, body: initialBody });
        // return function, which is called on unmount!
        return () => {
            task.cancel();
            expel(_prefix);
        };
    }, []);
    useEffect(() => {
        if (state) {
            const { page, url, queryParams, body } = state;
            dispatch({ type: `FETCH_PAGE_REQUESTED:${_prefix}`, payload: { page, url, queryParams, body, page_size: 5 } });
        }
    }, [state]);
    function* sagaWorkerForPageLoad(action) {
        yield put({ type: `FETCH_STARTED:${_prefix}` });
        setIsFetching(true);
        const { url, page, queryParams, body } = action.payload;
        const params = Object.assign(Object.assign({}, queryParams), { page: page || 0, page_size: 5 });
        const paramString = convertToQueryUrl(params);
        const _url = `${url}?${paramString}`;
        console.log(_url);
        const resp = yield call(fetch, _url, Object.assign(Object.assign({}, useable_options), { headers,
            method, body: JSON.stringify(body) }));
        const data = yield resp.json();
        setIsFetching(false);
        yield put({ type: `FETCH_META:${_prefix}`, payload: data });
        yield put({ type: `FETCH_DATA:${_prefix}`, payload: data });
    }
    function* saga() {
        yield takeEvery(`FETCH_PAGE_REQUESTED:${_prefix}`, sagaWorkerForPageLoad);
    }
    const data = useSelector((state) => {
        if (state[_prefix]) {
            return state[_prefix].items;
        }
        return [];
    });
    const stream = useSelector((state) => {
        if (state[_prefix]) {
            return state[_prefix].meta;
        }
        return null;
    });
    const status = useSelector((state) => {
        if (state[_prefix]) {
            return state[_prefix].status;
        }
        return null;
    });
    function loadPage(page) {
        const { url, queryParams, body } = state;
        setState({ url, page, queryParams, body });
    }
    function loadNextPage() {
        const { url, queryParams, page, body } = state;
        if (stream.has_next)
            setState({ url, page: page + 1, queryParams, body });
    }
    function loadUrl(url, page, queryParams = {}, body = {}) {
        setState({ url, page, queryParams, body });
    }
    return { data, prefix: _prefix, loadPage, loadNextPage, loadUrl, stream, status, isFetching };
}
