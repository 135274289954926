import { useEffect } from "react";
import { useProgressBar } from "../components/ProgressBar";
import { useSubmit } from "./use-submit";
export const useRestSubmit = (successFn, errorFn, loadingFn) => {
    const { isCompleted, isFetching, status, submit, data, error } = useSubmit();
    //after all this is saga driven
    const loading = useProgressBar();
    useEffect(() => {
        if (loadingFn)
            loadingFn(isFetching);
        else
            loading(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (status == "ok") {
            {
                successFn(data);
            }
        }
        else if (status == "error") {
            if (typeof error == "object")
                errorFn === null || errorFn === void 0 ? void 0 : errorFn(error.message, error.code);
            else if (typeof error == "string")
                errorFn === null || errorFn === void 0 ? void 0 : errorFn(error);
            errorFn === null || errorFn === void 0 ? void 0 : errorFn(`${error}`);
        }
        // alert(status)
    }, [status]);
    return submit;
};
