import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { StyleProvider } from '@exprzn/react-style-lib';
import { Application } from './Application';
import './global.css';
import { SagaProvider } from './saga/saga-context';
import container from './styles';
import { MapProvider } from './map/map-context';
import { loadMapApi } from './pages/distribution-center/utils';
function App() {
    const [isScriptLoaded, setIIsScriptLoaded] = useState(false);
    const googleMapScript = loadMapApi();
    googleMapScript.addEventListener("load", () => {
        setIIsScriptLoaded(true);
    });
    return (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(MapProvider, Object.assign({ value: isScriptLoaded }, { children: _jsx(Application, {}, void 0) }), void 0) }), void 0) }, void 0));
}
export default App;
