import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { Col } from "../../../components/Column";
import { DragDrop, DragDropVideo } from "../../../components/DragDrop";
import { Field, Form } from "../../../components/Form";
import { Checkbox } from "../../../components/Form/fields-extended";
import { useProgressBar } from "../../../components/ProgressBar";
import { Row } from "../../../components/Row";
import { useRestSubmit } from "../../../saga/use-rest-submit";
import { useSaga } from "../../../saga/use-saga";
import { ProductAttributesComponent } from "../product-attributes";
import { ProductAttributesEditComponent } from "../product-attributes/attributes-edit";
import { ProductVariantComponent } from "../product-variants";
import { ProductVariantAddComponent } from "../product-variants/product-variant-add";
import { ProductVariantEditComponent } from "../product-variants/product-variant-edit";
import { VariantTypeAdd, VariantTypesComponent } from "../variants/variant-types";
import { ProductProps } from "./product-properties-edit";
import { ProductAddProps } from "./product-properties-add";
import { useAuth } from "../../../use-auth";
import { global_org_uid } from "../../../config";
export const ProductAddView = (props) => {
    const { uid } = props.match.params;
    const initMode = new URLSearchParams(props.location.search).get("mode") || "add";
    const [mode, setMode] = useState(initMode);
    const { containerstyle, action_btn_ms, } = useStyles("containerstyle", "action_btn_ms");
    const [product, setProduct] = useState();
    const [image_uids, setImageUids] = useState([]);
    const [video_uids, setVideoUids] = useState([]);
    const [nocloud_image_uids, setNoCloudImageUids] = useState([]);
    const [nocloud_video_uids, setNoCloudVideoUids] = useState([]);
    const [variantTypeAddVisibility, showVariantTypeAdd] = useState(false);
    const [productVariantVisibility, showProductVariant] = useState(false);
    const [productVariantAddVisibility, showProductVariantAdd] = useState(false);
    const [productVariantEditUid, setProductVariantEdituid] = useState(null);
    const [hasVariant, setHasVariant] = useState();
    const [attributesVisibility, setAttributesVisibility] = useState();
    const [addAttributesVisibility, setAddAttributesVisibility] = useState();
    const history = useHistory();
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { data: deliveryOptions, loadUrl: loadDeliveryOptionsUrl } = useSaga(`/trendutube/v1/config-management/admin/organization/${global_org_uid}/delivery-option/get`, { headers });
    const submitPublish = useRestSubmit(() => {
        loadProduct();
    }, (error) => {
        toast.error(`${error}`, { toastId: "123456" });
    });
    const bref = useRef(null);
    const saveProduct = useRestSubmit((data) => {
        if (data && mode == "add") {
            toast.success("Product Saved", { toastId: "saved123" });
            history.replace(`/dashboard/stores/${uid}/products/list`);
        }
        if (mode != "add" && (product === null || product === void 0 ? void 0 : product.store)) {
            toast.success("Product Updated", { toastId: "saved123" });
            history.replace(`/dashboard/stores/${product === null || product === void 0 ? void 0 : product.store[0].store_uid}/products/list`);
        }
    }, (error) => [
        toast.error(error, { toastId: "error123" })
    ]);
    const updateProductListing = useRestSubmit((data) => {
        toast.success("Updated Listing", { toastId: "product-listing" });
        loadProduct();
    }, (error) => [
        toast.error(error)
    ]);
    const updateListing = (listing) => {
        if ((product === null || product === void 0 ? void 0 : product.has_variant) === false) {
            //non variant products have one variant node
            const variant = product === null || product === void 0 ? void 0 : product.variants[0];
            if (!variant || !variant.product_variant_uid)
                toast.error("Error  variant not found ", { toastId: "variat-listing-update" });
            else
                updateProductListing(`/trendutube/v1/product-catalog/products/${product === null || product === void 0 ? void 0 : product.uid}/variants/${variant.product_variant_uid}/update-listing?listing=${listing}`, {});
        }
    };
    const updateTrueVariantListing = (product_variant_uid, listing) => {
        if (!(product === null || product === void 0 ? void 0 : product.uid) || !product_variant_uid)
            toast.error("Error variant not found ", { toastId: "variant-listing-update" });
        else
            updateProductListing(`/trendutube/v1/product-catalog/products/${product === null || product === void 0 ? void 0 : product.uid}/variants/${product_variant_uid}/update-listing?listing=${listing}`, {});
    };
    const { loadUrl, data, isFetching } = useSaga(``, {}, false);
    const loadProduct = () => loadUrl(`/trendutube/v1/product-catalog/products/${uid}/info`);
    const setProgress = useProgressBar();
    const onSuccess = () => {
        toast.success("Product variant added", { toastId: "pvariant134" });
        loadProduct();
    };
    const onError = (e) => {
        toast.error(`Product variant could not be added `, { toastId: "error4134" });
    };
    const saveProductVariant = useRestSubmit(onSuccess, onError);
    const onSuccess1 = () => {
        toast.success("Product variant updated", { toastId: "successvariantup11" });
        loadProduct();
    };
    const onError1 = (e) => {
        toast.error(`Product variant could not be updated`, { toastId: "errorvariantup11" });
    };
    const updateProductVariant1 = useRestSubmit(onSuccess1, onError1);
    useEffect(() => {
        setProgress(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (mode == "edit")
            loadProduct();
    }, [mode]);
    useEffect(() => {
        if (data != null && data.uid) {
            setProduct(data);
        }
    }, [data]);
    const submitHandler = (product) => {
        const body = Object.assign(Object.assign({}, product), { image_uids,
            video_uids, has_variant: !!hasVariant });
        // alert(JSON.stringify(body))
        if (mode == "edit") {
            delete body.product_stock;
            delete body.product_price;
            saveProduct(`/trendutube/v1/product-catalog/products/${uid}/update`, {
                body
            });
        }
        else {
            saveProduct(`/trendutube/v1/product-catalog/stores/${uid}/products/add`, {
                body
            });
        }
    };
    const onSaveVariantTypeSuccess = () => {
        if (mode == "edit")
            loadProduct();
        toast.success("Saved Variant Type");
    };
    const onSaveVariantTypeError = (error) => {
        toast.error(error);
    };
    const saveVariantType = useRestSubmit(onSaveVariantTypeSuccess, onSaveVariantTypeError);
    const onDeleteSuccess = () => {
        toast.success("Image deleted successfully");
        loadProduct();
    };
    const onVideoDeleteSuccess = () => {
        toast.success("Video deleted successfully");
        loadProduct();
    };
    const onDeleteError = () => {
        toast.error("Error deleting image");
    };
    const onVideoDeleteError = () => {
        toast.error("Error deleting image");
    };
    const submitImageDelete = useRestSubmit(onDeleteSuccess, onDeleteError);
    const submitVideoDelete = useRestSubmit(onVideoDeleteSuccess, onVideoDeleteError);
    const handleImages = (images) => {
        const image_uids = images.map(i => i.uid);
        if (mode == "add")
            setImageUids(image_uids);
        else
            loadProduct();
        setNoCloudImageUids(image_uids);
    };
    const handleVideo = (videos) => {
        const video_uids = videos.map(i => i.uid);
        if (mode == "add")
            setVideoUids(video_uids);
        else
            loadProduct();
        setNoCloudVideoUids(video_uids);
    };
    function onProductVariantDeleteSuccess() {
        toast.success("Product variant Deleted");
        loadProduct();
    }
    function onProductVariantDeleteError(error) {
        toast.error(error.message || error);
    }
    const submitProductVariantDelete = useRestSubmit(onProductVariantDeleteSuccess, onProductVariantDeleteError);
    function handleProductVariantDelete(index, product_variant_uid) {
        if (product === null || product === void 0 ? void 0 : product.uid)
            submitProductVariantDelete(`/trendutube/v1/product-catalog/products/${product === null || product === void 0 ? void 0 : product.uid}/variants/${product_variant_uid}/delete`, { method: "DELETE" });
    }
    function handleSaveProduct() {
        var _a;
        bref.current && ((_a = bref.current) === null || _a === void 0 ? void 0 : _a.click());
    }
    function onProductDeleteSuccess() {
        toast.success("Product variant Deleted");
        history.goBack();
    }
    function onProductDeleteError(error) {
        toast.success("Product variant Deleted");
    }
    const submitProductDelete = useRestSubmit(onProductDeleteSuccess, onProductDeleteError);
    function handleDeleteProduct() {
        if (product === null || product === void 0 ? void 0 : product.uid)
            submitProductDelete(`/trendutube/v1/product-catalog/products/${product === null || product === void 0 ? void 0 : product.uid}/delete`, { method: "DELETE" });
    }
    function handlePublish(event) {
        const toPublish = !!!(product === null || product === void 0 ? void 0 : product.published);
        submitPublish(`/trendutube/v1/product-catalog/products/${uid}/update-publish?publish=${toPublish}`, {});
        event.preventDefault();
    }
    function addVariantType(variantType) {
        saveVariantType(`/trendutube/v1/product-catalog/products/${uid}/variant-types/add`, { body: Object.assign(Object.assign({}, variantType), { values: [] }) });
    }
    const reWriteUrl = (im) => (nocloud_image_uids === null || nocloud_image_uids === void 0 ? void 0 : nocloud_image_uids.findIndex(item => item == im.uid)) > -1 ? `${im.url}?nocloud=1` : im.url;
    const reWriteVideoUrl = (im) => (nocloud_video_uids === null || nocloud_video_uids === void 0 ? void 0 : nocloud_video_uids.findIndex(item => item == im.uid)) > -1 ? `${im.url}?nocloud=1` : im.url;
    function handleImageDelete(imageUid) {
        return () => {
            submitImageDelete(`/image-service/images/${imageUid}/unlink`, { method: "DELETE" });
        };
    }
    function handleVideoDelete(videoUid) {
        return () => {
            submitVideoDelete(`/image-service/videos/${videoUid}/unlink`, { method: "DELETE" });
        };
    }
    function addProductVariant(productVariantRequest) {
        if (product === null || product === void 0 ? void 0 : product.uid)
            saveProductVariant(`/trendutube/v1/product-catalog/products/${product.uid}/variants/add`, {
                body: productVariantRequest
            });
    }
    function updateProductVariant(productVariantuid, productVariantRequest) {
        if (product === null || product === void 0 ? void 0 : product.uid)
            updateProductVariant1(`/trendutube/v1/product-catalog/products/${product.uid}/variants/${productVariantuid}/update`, {
                body: productVariantRequest
            });
    }
    function onUpdateVariantSuccess() {
        toast.success("Variant flag updated successfully", { toastId: "variant-updated" });
        loadProduct();
    }
    function onUpdateVariantError(error) {
        if (typeof error == "object" && error.message == "Invalid variant flag update")
            toast.error("Error: Please remove all variant types and product variants and then update has_variant", {
                toastId: Math.random().toString()
            });
        else
            toast.error(`Error: ${error.message}`, { toastId: Math.random().toString() });
    }
    const UpdateProductVariantFlag = useRestSubmit(onUpdateVariantSuccess, onUpdateVariantError);
    useEffect(() => {
        if (typeof hasVariant != 'undefined' && product && product.uid) {
            UpdateProductVariantFlag(`/trendutube/v1/product-catalog/products/${product === null || product === void 0 ? void 0 : product.uid}/update-variant-flag?has_variant=${hasVariant}`, {});
        }
    }, [hasVariant]);
    function handleUpdateVariantFlag(value) {
        setHasVariant(value);
    }
    const saveAttribute = useRestSubmit(() => {
        toast.success("saved");
        loadProduct();
    }, () => {
        toast.error("could not save attribute");
    });
    function handleUpdateAttributes(attributes) {
        if (product)
            saveAttribute(`/trendutube/v1/product-catalog/products/${product.uid}/attributes/update`, {
                body: { attributes }
            });
    }
    const addAttribute = useRestSubmit(() => {
        toast.success("saved");
        loadProduct();
    }, () => {
        toast.error("could not save attribute");
    });
    function handleAddAttribute(groupUid, attributes) {
        if (product)
            addAttribute(`/trendutube/v1/product-catalog/attribute-groups/${groupUid}/attributes/add`, {
                body: { attributes }
            });
    }
    const addAttributeGroup = useRestSubmit(() => {
        toast.success("saved");
        loadProduct();
    }, () => {
        toast.error("could not save attribute");
    });
    function handleAddAttributeGroup(attributeSetUid, attributeGroup) {
        if (product)
            addAttributeGroup(`/trendutube/v1/product-catalog/attribute-sets/${attributeSetUid}/attribute-groups/add`, {
                body: Object.assign({}, attributeGroup)
            });
    }
    const updateAttributeGroup = useRestSubmit(() => {
        toast.success("saved");
        loadProduct();
    }, () => {
        toast.error("could not save attribute");
    });
    function handleUpdateAttributeGroup(attributeSetUid, attributeGroupUid, attributeGroup) {
        if (product)
            updateAttributeGroup(`/trendutube/v1/product-catalog/attribute-sets/${attributeSetUid}/attribute-groups/${attributeGroupUid}/update`, {
                body: Object.assign({}, attributeGroup)
            });
    }
    const addAttributeSet = useRestSubmit(() => {
        toast.success("Attribute set added");
        loadProduct();
    }, () => {
        toast.error("could not save attribute");
    });
    function handleAttribSetAdd(template_uid) {
        if (product === null || product === void 0 ? void 0 : product.uid)
            addAttributeSet(`/trendutube/v1/product-catalog/products/${product.uid}/attribute-sets/add-from-template`, {
                body: { template_uid }
            });
    }
    const loadFunction = () => {
        loadProduct();
    };
    return _jsxs("div", Object.assign({ css: containerstyle }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "space-between", width: "100%" } }, { children: [_jsx("div", Object.assign({ css: { fontWeight: 600, fontSize: 16 } }, { children: mode == "add" ?
                                    _jsx("span", { children: "Add Product" }, void 0) : _jsx("span", { children: "Edit Product" }, void 0) }), void 0), _jsxs("div", { children: [mode == "edit" ? _jsxs(_Fragment, { children: [_jsx("button", Object.assign({ css: action_btn_ms, onClick: () => { setAttributesVisibility(true); }, style: { marginRight: 10 } }, { children: "Attributes" }), void 0), _jsx("button", Object.assign({ css: action_btn_ms, onClick: () => { setAddAttributesVisibility(true); }, style: { marginRight: 10 } }, { children: "Edit Attributes" }), void 0)] }, void 0) : null, mode == "edit" ? _jsx(_Fragment, { children: (product === null || product === void 0 ? void 0 : product.published) ? _jsx("button", Object.assign({ onClick: handlePublish, css: action_btn_ms, style: { marginRight: 10 } }, { children: "Un Publish (Draft)" }), void 0) :
                                            _jsx("button", Object.assign({ onClick: (handlePublish), css: action_btn_ms, style: { marginRight: 10 } }, { children: "Publish" }), void 0) }, void 0) : null, mode == "edit" ?
                                        _jsx("button", Object.assign({ type: "button", css: action_btn_ms, style: { marginRight: 10 }, onClick: handleDeleteProduct }, { children: "Delete" }), void 0) : null, _jsx("button", Object.assign({ type: "button", css: action_btn_ms, onClick: handleSaveProduct }, { children: "Save" }), void 0)] }, void 0)] }), void 0) }, void 0) }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 6, top: true }, { children: [mode == "edit" && product != null && deliveryOptions ?
                                _jsx(ProductProps, { ref: bref, mode: mode, product: product, saveProduct: submitHandler, updateListing: updateListing, loadProduct: loadProduct, admin_delivery_options: deliveryOptions }, void 0) : null, mode == "add" && deliveryOptions ? _jsx(ProductAddProps, { ref: bref, mode: mode, product: product, saveProduct: submitHandler, delivery_options: deliveryOptions }, void 0) : null] }), void 0), _jsxs(Col, Object.assign({ cols: 6, top: true }, { children: [_jsxs("div", Object.assign({ css: { marginLeft: "20px", marginTop: "30px" } }, { children: [_jsx(DragDrop, { entity: "product_image", onImagesUpload: handleImages, uid: product === null || product === void 0 ? void 0 : product.uid }, void 0), _jsx("div", Object.assign({ css: { display: 'flex', justifyContent: "space-between", flexWrap: "wrap", overflow: "hidden", border: "none", backgroundColor: '#F6F8F9', padding: "1em", borderRadius: ".5em" } }, { children: product && (product.images || []).map(image => _jsxs("div", Object.assign({ css: { marginBottom: "10px", height: 124, width: 124, position: "relative" } }, { children: [_jsx("img", { height: 124, width: 124, css: { borderRadius: "6px" }, src: reWriteUrl(image) }, void 0), _jsx("div", Object.assign({ onClick: handleImageDelete(image.uid), css: {
                                                        cursor: "pointer",
                                                        position: "absolute", padding: 3, right: 8, bottom: 8, height: 20,
                                                        borderRadius: 4, backgroundColor: "#222",
                                                        display: "flex", alignContent: "center"
                                                    } }, { children: _jsx(X, { size: 14, color: "white" }, void 0) }), void 0)] }), void 0)) }), void 0), ((product === null || product === void 0 ? void 0 : product.videos) || []).length == 0 && _jsx(DragDropVideo, { entity: "product_video", onVideoUpload: handleVideo, uid: product === null || product === void 0 ? void 0 : product.uid }, void 0), _jsx("div", Object.assign({ css: { display: 'flex', justifyContent: "space-between", flexWrap: "wrap", overflow: "hidden", border: "1px solid #bababa", padding: "1em", borderRadius: ".5em" } }, { children: product && (product.videos || []).map(video => _jsxs("div", Object.assign({ css: { marginBottom: "10px", height: 124, width: 124, position: "relative" } }, { children: [_jsxs("video", Object.assign({ height: 124, width: 124, controls: true, css: { borderRadius: "6px" }, style: { objectFit: 'cover' } }, { children: [_jsx("source", { src: reWriteVideoUrl(video), type: "video/mp4" }, void 0), "Your browser does not support the video tag."] }), void 0), _jsx("div", Object.assign({ onClick: handleVideoDelete(video.uid), css: {
                                                        cursor: "pointer",
                                                        position: "absolute", padding: 3, right: 8, bottom: 8, height: 20,
                                                        borderRadius: 4, backgroundColor: "#222",
                                                        display: "flex", alignContent: "center"
                                                    } }, { children: _jsx(X, { size: 14, color: "white" }, void 0) }), void 0)] }), void 0)) }), void 0)] }), void 0), _jsx("div", Object.assign({ css: { marginLeft: 20, marginBottom: "20px", marginTop: "10px", fontSize: "1.2em", fontWeight: 400, color: "#8d8d8d" } }, { children: _jsxs(Form, Object.assign({ name: "variant" }, { children: ["Does the product have variants ?", _jsx(Field, Object.assign({ name: "has_variant", value: product === null || product === void 0 ? void 0 : product.has_variant, onChange: handleUpdateVariantFlag }, { children: _jsx(Checkbox, {}, void 0) }), void 0)] }), void 0) }), void 0), product && product.has_variant ?
                                _jsxs("div", Object.assign({ css: { marginLeft: 20 } }, { children: [_jsxs("div", Object.assign({ css: { marginBottom: 10, display: "flex", justifyContent: "start" } }, { children: [_jsx("button", Object.assign({ css: action_btn_ms, onClick: () => { showProductVariant(true); }, style: { marginRight: 10 } }, { children: "Product Variants" }), void 0), _jsx("button", Object.assign({ css: action_btn_ms, onClick: () => { showProductVariantAdd(true); }, style: { marginRight: 10 } }, { children: "Add a Product Variant" }), void 0), !product.variant_types || (product.variant_types.length < 3) ?
                                                    _jsx("button", Object.assign({ css: action_btn_ms, type: "button", onClick: () => { showVariantTypeAdd(true); }, style: { marginRight: 10 } }, { children: "Add Variant Type" }), void 0)
                                                    : null] }), void 0), _jsx(VariantTypesComponent, { variant_types: product === null || product === void 0 ? void 0 : product.variant_types, mode: "edit", loadFunction: loadFunction }, void 0)] }), void 0)
                                : null] }), void 0)] }, void 0), variantTypeAddVisibility ? _jsx(VariantTypeAdd, { isOpen: variantTypeAddVisibility, addVaraintType: addVariantType, onClose: (flag) => showVariantTypeAdd(flag) }, void 0) : null, product ? _jsx(ProductVariantComponent, { variants: product.variants, onEdit: (index, uid) => { setProductVariantEdituid(uid); }, onDelete: handleProductVariantDelete, isOpen: productVariantVisibility, updateVariantListing: updateTrueVariantListing, onClose: (flag) => showProductVariant(flag) }, void 0) : null, product ?
                _jsx(ProductVariantAddComponent, { isOpen: productVariantAddVisibility, addProductVariant: addProductVariant, onClose: (flag) => showProductVariantAdd(flag), product: product }, void 0) : null, product && productVariantEditUid ?
                _jsx(ProductVariantEditComponent, { isOpen: productVariantEditUid != null, updateProductVariant: updateProductVariant, updateVariantListing: updateTrueVariantListing, productVariantUid: productVariantEditUid, onClose: (flag) => {
                        showProductVariantAdd(flag);
                        setProductVariantEdituid(null);
                    }, product: product, onImageDelete: handleImageDelete, onVideoDelete: handleVideoDelete, onReloadProduct: () => loadProduct() }, void 0) : null, product && attributesVisibility ?
                _jsx(ProductAttributesComponent, { onClose: () => setAttributesVisibility(false), isOpen: attributesVisibility, product: product }, void 0) : null, product && addAttributesVisibility ? _jsx(ProductAttributesEditComponent, { onClose: () => setAddAttributesVisibility(false), updateAttribFn: handleUpdateAttributes, addAttribFn: handleAddAttribute, deleteAttribFn: () => { }, addAttributeGroupFn: handleAddAttributeGroup, updateGroupFn: handleUpdateAttributeGroup, deleteGroupFn: () => { }, addAttributeSetFn: handleAttribSetAdd, isOpen: addAttributesVisibility, product: product }, void 0) : null] }), void 0);
};
