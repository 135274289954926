export var ORDER_STATUS;
(function (ORDER_STATUS) {
    ORDER_STATUS["BUYER_CHECKED_OUT"] = "buyer-checked-out";
    ORDER_STATUS["BUYER_PAYMENT_PENDING"] = "buyer-payment-pending";
    ORDER_STATUS["BUYER_PAID_ORDER_CONFIRMED"] = "buyer-paid-order-confirmed";
    ORDER_STATUS["BUYER_PAYMENT_FAILED"] = "buyer-payment-failed";
    ORDER_STATUS["BUYER_COD_ORDER_CONFIRMED"] = "buyer-cod-order-confirmed";
    ORDER_STATUS["SELLERS_CONFIRMED"] = "sellers-confirmed";
    ORDER_STATUS["DELIVERY_ACCEPTED"] = "delivery-accepted";
    ORDER_STATUS["DELIVERY_RELEASED"] = "delivery-released";
    ORDER_STATUS["DELIVERY_PICKUP_CONFIRMED"] = "delivery-pickup-confirmed";
    ORDER_STATUS["DELIVERY_COMPLETED"] = "delivery-completed";
    ORDER_STATUS["ADMIN_ORDER_CANCELLED"] = "admin-order-cancelled";
    ORDER_STATUS["BUYER_ORDER_CANCELLED"] = "buyer-order-cancelled";
})(ORDER_STATUS || (ORDER_STATUS = {}));
export var ORDER_ITEM_STATUS;
(function (ORDER_ITEM_STATUS) {
    ORDER_ITEM_STATUS["BUYER_CHECKED_OUT"] = "buyer-checked-out";
    ORDER_ITEM_STATUS["SELLER_ACCEPTED"] = "seller-accepted";
    ORDER_ITEM_STATUS["SELLER_REJECTED"] = "seller-rejected";
    ORDER_ITEM_STATUS["DELIVERY_PICKED_UP"] = "delivery-picked-up";
    ORDER_ITEM_STATUS["DELIVERY_PICKUP_RELEASED"] = "delivery-pickup-released";
    ORDER_ITEM_STATUS["DELIVERY_COMPLETED"] = "delivery-completed";
    ORDER_ITEM_STATUS["ADMIN_ORDER_CANCELLED"] = "admin-order-cancelled";
})(ORDER_ITEM_STATUS || (ORDER_ITEM_STATUS = {}));
//Store level order status
export var DELIVERY_STATUS;
(function (DELIVERY_STATUS) {
    DELIVERY_STATUS["SELLER_CONFIRMED"] = "seller-confirmed";
    DELIVERY_STATUS["DELIVERY_ACCEPTED"] = "delivery-accepted";
    DELIVERY_STATUS["DELIVERY_RELEASED"] = "delivery-released";
    DELIVERY_STATUS["DELIVERY_PICKUP_CONFIRMED"] = "delivery-pickup-confirmed";
    DELIVERY_STATUS["DELIVERY_COMPLETED"] = "delivery-completed";
    DELIVERY_STATUS["ADMIN_CANCELLED"] = "admin-cancelled";
    DELIVERY_STATUS["ADMIN_ORDER_CANCELLED"] = "admin-order-cancelled";
})(DELIVERY_STATUS || (DELIVERY_STATUS = {}));
export var BASKET_STATUS;
(function (BASKET_STATUS) {
    BASKET_STATUS["CREATED"] = "created";
    BASKET_STATUS["BUYER_CHECKED_OUT"] = "buyer-checked-out";
    BASKET_STATUS["BUYER_PAYMENT_PENDING"] = "buyer-payment-pending";
    BASKET_STATUS["BUYER_PAID_ORDER_CONFIRMED"] = "buyer-paid-order-confirmed";
    BASKET_STATUS["BUYER_COD_ORDER_CONFIRMED"] = "buyer-cod-order-confirmed";
    BASKET_STATUS["BUYER_PAYMENT_FAILED"] = "buyer-payment-failed";
    BASKET_STATUS["DELIVERY_COMPLETED"] = "delivery-completed";
    BASKET_STATUS["ADMIN_ORDER_ACCEPTED"] = "admin-order-accepted";
    BASKET_STATUS["ADMIN_ORDER_CANCELLED"] = "admin-cancelled";
})(BASKET_STATUS || (BASKET_STATUS = {}));
