import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useRef, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { toast } from "react-toastify";
import { DragDrop, DragDropVideo } from "../../../components/DragDrop";
import { Field, Form } from "../../../components/Form";
import { Checkbox, Input } from "../../../components/Form/fields-extended";
export const ProductVariantAddComponent = (props) => {
    const { product, isOpen, onClose, addProductVariant } = props;
    return _jsx("div", { children: _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isOpen, title: "Add Product Variant", subtitle: "", width: "30%", onRequestClose: () => {
                onClose(false);
            } }, { children: _jsx("div", { children: _jsx(ProductVariantAdd, { product: product, addProductVariant: addProductVariant }, void 0) }, void 0) }), void 0) }, void 0);
};
export const ProductVariantAdd = (props) => {
    const { product, addProductVariant } = props;
    const { variant_types = [], uid } = product;
    const [variation, setVariation] = useState();
    const [image_uids, setImageUids] = useState([]);
    const [video_uids, setVideoUids] = useState([]);
    const ref = useRef(null);
    const onChange = (value) => {
        setVariation(Object.assign(Object.assign({}, variation), value));
    };
    const onSubmit = (formData) => {
        const { product_variant } = formData || {};
        const { product_variant_price, product_variant_stock, variant_listing_flag } = product_variant || {};
        const keys = Object.keys(variation || {});
        if (keys.length != variant_types.length)
            toast.error(`Please choose all types: ${keys.join(",")}`, { toastId: "t1" });
        else if (!product_variant_price)
            toast.error("Please specify price", { toastId: "t2" });
        else if (!product_variant_stock)
            toast.error("Please specify stock", { toastId: "t3" });
        else {
            const body = { variation, product_variant_price, product_variant_stock, image_uids, variant_listing_flag, video_uids };
            addProductVariant(body);
            // alert(JSON.stringify(body))
        }
    };
    const { button } = useStyles("button");
    return _jsxs("div", { children: [variant_types.map(variantType => _jsx(VariantRadioComponent, { onChange: onChange, variantType: variantType }, void 0)), _jsx("div", { children: _jsxs(Form, Object.assign({ name: "product_variant", onSubmit: onSubmit }, { children: [_jsx("div", Object.assign({ css: { marginTop: 1 } }, { children: "Product Variant Price" }), void 0), _jsx(Field, Object.assign({ name: "product_variant_price" }, { children: _jsx(Input, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: { marginTop: 1 } }, { children: "Product Variant Stock" }), void 0), _jsx(Field, Object.assign({ name: "product_variant_stock" }, { children: _jsx(Input, {}, void 0) }), void 0), _jsxs("div", Object.assign({ css: { marginTop: 1 } }, { children: ["List this product variant ?", _jsx(Field, Object.assign({ name: "variant_listing_flag" }, { children: _jsx(Checkbox, {}, void 0) }), void 0)] }), void 0), _jsx("button", Object.assign({ css: { display: "none", backgroundColor: '#045998' }, ref: ref }, { children: "Save" }), void 0)] }), void 0) }, void 0), _jsxs("div", { children: [_jsx("div", Object.assign({ css: { marginTop: 20, fontSize: 18, fontWeight: 600, color: "#222" } }, { children: "Product Variant Images" }), void 0), _jsx("div", Object.assign({ css: { marginTop: 10 } }, { children: _jsx(DragDrop, { onImagesUpload: (images) => {
                                setImageUids(images.map(im => im.uid));
                            }, entity: "product_variant" }, void 0) }), void 0), _jsx("div", Object.assign({ css: { marginTop: 20, fontSize: 18, fontWeight: 600, color: "#222" } }, { children: "Product Variant Videos" }), void 0), _jsx("div", Object.assign({ css: { marginTop: 10 } }, { children: _jsx(DragDropVideo, { onVideoUpload: (videos) => {
                                setVideoUids(videos.map(im => im.uid));
                            }, entity: "product_variant" }, void 0) }), void 0)] }, void 0), _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: _jsx("button", Object.assign({ css: button, onClick: () => { var _a; (_a = ref.current) === null || _a === void 0 ? void 0 : _a.click(); }, style: { backgroundColor: '#045998', boxShadow: 'none' } }, { children: "Save" }), void 0) }), void 0)] }, void 0);
};
export const VariantRadioComponent = (props) => {
    const { variantType, onChange } = props;
    const handleRadio = (e) => {
        onChange({ [variantType.variant_type_name]: e.currentTarget.value });
    };
    return _jsxs("div", { children: [_jsx("div", Object.assign({ css: { color: "#111", fontWeight: 600, fontSize: "18px", marginTop: 10 } }, { children: variantType.variant_type_name }), void 0), _jsxs("div", Object.assign({ css: { marginLeft: 120 } }, { children: [variantType.variant_type == "color" ?
                        (variantType.variant_values || []).map(variantValue => _jsx(ColorRadioComponent, { onChange: handleRadio, variantTypeName: variantType.variant_type_name, variantValue: variantValue }, void 0)) : null, variantType.variant_type == "string" ?
                        (variantType.variant_values || []).map(variantValue => _jsx(StringRadioComponent, { onChange: handleRadio, variantTypeName: variantType.variant_type_name, variantValue: variantValue }, void 0)) : null, variantType.variant_type == "image" ?
                        (variantType.variant_values || []).map(variantValue => _jsx(ImageRadioComponent, { onChange: handleRadio, variantTypeName: variantType.variant_type_name, variantValue: variantValue }, void 0)) : null] }), void 0)] }, void 0);
};
export const ColorRadioComponent = (props) => {
    const { variantValue, variantTypeName, onChange } = props;
    return _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "start", alignItems: "center" } }, { children: [_jsx("input", { name: variantTypeName, value: variantValue.name, css: { marginRight: "12px" }, type: "radio", onChange: onChange }, void 0), _jsx("div", { css: {
                    width: 20,
                    height: 20,
                    fontWeight: 500,
                    margin: "4px 12px 4px 4px",
                    borderRadius: 4,
                    backgroundColor: variantValue.color
                } }, void 0), _jsx("div", { children: variantValue.name }, void 0)] }), void 0);
};
export const StringRadioComponent = (props) => {
    const { variantValue, variantTypeName, onChange } = props;
    return _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "start", alignItems: "center" } }, { children: [_jsx("input", { name: variantTypeName, value: variantValue.name, css: { marginRight: "4px" }, onChange: onChange, type: "radio" }, void 0), _jsx("div", { children: variantValue.name }, void 0)] }), void 0);
};
export const ImageRadioComponent = (props) => {
    const { variantValue, variantTypeName, onChange } = props;
    return _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "start", alignItems: "center" } }, { children: [_jsx("input", { name: variantTypeName, value: variantValue.name, css: { marginRight: "12px" }, type: "radio", onChange: onChange }, void 0), _jsx("div", { css: {
                    width: 20,
                    height: 20,
                    fontWeight: 500,
                    margin: "4px 12px 4px 4px",
                    borderRadius: 4,
                    backgroundImage: `url(${variantValue.image.url})`
                } }, void 0), _jsx("div", { children: variantValue.name }, void 0)] }), void 0);
};
