import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, useEffect } from 'react';
import { RelPositioner } from '../RelPos/RelativePositioner';
import './styles.css';
export const MultiSelect = (props) => {
    const { title, items, view, selectView, onSelectItem, onUnSelectItem, onSelectedListChange, selectIndices, titleProperty } = props;
    const [show, setShow] = useState(false);
    const [_title, setTitle] = useState(title);
    const [selItems, setSelItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const View = view;
    const SelectView = selectView || view;
    const ref1 = useRef(null);
    useEffect(() => {
        let selItems = [];
        //TODO : find a better way
        if (selectIndices && items) {
            selectIndices.forEach(index => {
                if (index >= (items === null || items === void 0 ? void 0 : items.length) && index < 0)
                    return;
                const item = items === null || items === void 0 ? void 0 : items[index];
                selItems.push({ item, index });
                selectedItems.push(item);
                onSelectItem === null || onSelectItem === void 0 ? void 0 : onSelectItem(item, index);
            });
            onSelectedListChange === null || onSelectedListChange === void 0 ? void 0 : onSelectedListChange(selectedItems);
            setSelectedItems(selectedItems);
            setSelItems([...selItems]);
        }
    }, []);
    useEffect(() => {
        setTitle(selItems.length <= 0 ? title : undefined);
    }, [selItems]);
    const clickHandler = (event) => {
        setShow(!show);
    };
    function getRemoveFn(index) {
        return function () {
            const remIndex = selItems === null || selItems === void 0 ? void 0 : selItems.findIndex(item => item.index === index);
            if (remIndex > -1) {
                const item = selItems.splice(remIndex, 1);
                const afterRemoval = selItems.map(i => i.item);
                onUnSelectItem === null || onUnSelectItem === void 0 ? void 0 : onUnSelectItem(item[0].item, index);
                onSelectedListChange === null || onSelectedListChange === void 0 ? void 0 : onSelectedListChange(selectedItems);
                setSelectedItems(afterRemoval);
                setSelItems([...selItems]);
            }
        };
    }
    const onItemsClickHandler = (item, index) => {
        return (ev) => {
            if ((selItems === null || selItems === void 0 ? void 0 : selItems.findIndex(item => item.index === index)) == -1) {
                selectedItems.push(item);
                setSelItems([...selItems, { item, index }]);
                onSelectedListChange === null || onSelectedListChange === void 0 ? void 0 : onSelectedListChange(selectedItems);
                setSelectedItems(selectedItems);
            }
            onSelectItem === null || onSelectItem === void 0 ? void 0 : onSelectItem(item, index);
        };
    };
    function assignToRef() {
        return ref1.current;
    }
    const children = items && items.map((item, index) => {
        return _jsx("li", Object.assign({ style: { listStyle: "none" }, onClick: onItemsClickHandler(item, index), className: "itemlist" }, { children: _jsx(View, Object.assign({}, { item, index }), void 0) }), index);
    });
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "flex flex-col", ref: ref1, style: { backgroundColor: '#F6F8F9', padding: '10px' } }, { children: _jsx("div", { children: (selItems && selItems.length > 0) ? _jsxs("div", Object.assign({ style: { display: "flex" } }, { children: [selItems.map((item, index) => _jsx(SelectView, Object.assign({}, Object.assign(Object.assign({}, item), { unselect: getRemoveFn(item.index) })), void 0)), _jsx("div", Object.assign({ style: { flex: "1 0 0", padding: ".3rem .6rem" }, onClick: clickHandler }, { children: " ..." }), void 0)] }), void 0) : _jsxs("div", Object.assign({ onClick: clickHandler }, { children: [" ", _title, "  ", _jsx("span", Object.assign({ onClick: clickHandler }, { children: " ..." }), void 0), " "] }), void 0) }, void 0) }), void 0), show ? _jsx("div", { children: _jsx(RelPositioner, Object.assign({ target: assignToRef, align: "left bottom", onClickedOutSide: () => setShow(false) }, { children: _jsx("ul", Object.assign({ style: { background: 'white', boxShadow: "0px 2px 5px rgba(0,0,0,0.3)" }, className: show ? "list shadow slideup100 visible" : "list shadow slidedown10" }, { children: children }), void 0) }), void 0) }, void 0) : null] }, void 0));
};
