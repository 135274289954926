import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { useAuth } from '../use-auth';
import { SagaContext } from './saga-context';
import { useReducerInjector } from './use-reducer-injector';
export function uuidv4() {
    // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    return 'xxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}
function createReducer(prefix) {
    return function (state, action) {
        var _a, _b, _c;
        const [act, pr] = (_a = action === null || action === void 0 ? void 0 : action.type) === null || _a === void 0 ? void 0 : _a.split(":");
        if (prefix !== pr)
            return state || {};
        let newState = {};
        switch (act) {
            case 'FETCH_DATA':
                newState = { items: (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data, status: "done" };
                break;
            case 'FETCH_ERROR':
                const { error } = action === null || action === void 0 ? void 0 : action.payload;
                newState = { error, status: "error" };
                break;
            case 'FETCH_STARTED':
                newState = { status: "started" };
                break;
            case 'FETCH_META':
                newState = { meta: (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.meta, status: "done" };
                break;
        }
        return Object.assign(Object.assign({}, state), newState);
    };
}
//ReactReduxContext
export function useSaga(url, options = {}, paged = true) {
    const [_prefix] = useState(uuidv4());
    const dispatch = useDispatch();
    const { inject, expel } = useReducerInjector();
    const { sagaMiddleware } = useContext(SagaContext);
    const [isFetching, setIsFetching] = useState(false);
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    useEffect(() => {
        const reducer = createReducer(_prefix);
        inject(_prefix, reducer);
        const task = sagaMiddleware.run(saga);
        if (url && url != "")
            if (paged)
                dispatch({ type: `FETCH_PAGE_REQUESTED:${_prefix}`, payload: { page: 0 } });
            else
                dispatch({ type: `FETCH_URL_REQUESTED:${_prefix}`, payload: { page: 0, url } });
        //called on unmount !
        return () => {
            task.cancel();
            expel(_prefix);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function* sagaWorkerForPageLoad(action) {
        yield put({ type: `FETCH_STARTED:${_prefix}` });
        setIsFetching(true);
        const _url = `${url}?page=${action.payload.page || 0}&page_size=10`;
        const resp = yield call(fetch, _url, Object.assign({ headers }, options));
        const data = yield resp.json();
        setIsFetching(false);
        yield put({ type: `FETCH_META:${_prefix}`, payload: data });
        yield put({ type: `FETCH_DATA:${_prefix}`, payload: data });
    }
    function* sagaWorkerForUrlLoad(action) {
        yield put({ type: `FETCH_STARTED:${_prefix}` });
        const { url: urlToLoad, page } = action.payload;
        const _url = paged ? `${urlToLoad}?page=${page || 0}&page_size=10` : urlToLoad;
        try {
            setIsFetching(true);
            const resp = yield call(fetch, _url, Object.assign({ headers }, options));
            const data = yield resp.json();
            setIsFetching(false);
            yield put({ type: `FETCH_META:${_prefix}`, payload: data });
            yield put({ type: `FETCH_DATA:${_prefix}`, payload: data });
        }
        catch (error) {
            yield put({ type: `FETCH_ERROR:${_prefix}`, payload: { error } });
        }
    }
    function* saga() {
        yield takeEvery(`FETCH_PAGE_REQUESTED:${_prefix}`, sagaWorkerForPageLoad);
        yield takeEvery(`FETCH_URL_REQUESTED:${_prefix}`, sagaWorkerForUrlLoad);
    }
    const data = useSelector((state) => {
        if (state[_prefix]) {
            return state[_prefix].items;
        }
        //should we return anything else ? like null or state ? : TODO
        return [];
    });
    const meta = useSelector((state) => {
        if (state[_prefix]) {
            return state[_prefix].meta;
        }
        //should we return anything else ? like null or state ? : TODO
        return null;
    });
    const status = useSelector((state) => {
        if (state[_prefix]) {
            return state[_prefix].status;
        }
        //should we return anything else ? like null or state ? : TODO
        return null;
    });
    function loadPage(page) {
        dispatch({ type: `FETCH_PAGE_REQUESTED:${_prefix}`, payload: { page } });
    }
    function loadUrl(url, page = 0) {
        dispatch({ type: `FETCH_URL_REQUESTED:${_prefix}`, payload: { url, page } });
    }
    function reload() {
        dispatch({ type: `FETCH_URL_REQUESTED:${_prefix}`, payload: { url, page: 0 } });
    }
    return { data, prefix: _prefix, loadPage, loadUrl, meta, status, reload, isFetching };
}
