var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleableComponent, StyleProvider, styling, useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { Row } from "../../../components/Row";
import container from "./style";
import { SearchOutlined } from '@ant-design/icons';
import { useSaga } from "../../../saga/use-saga";
import { SagaProvider } from "../../../saga/saga-context";
import { Sidebar } from "react-feather";
import { NavLink } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { Box, Card, CardActions, CardContent, FormControl, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSubmit } from "../../../saga/use-submit";
import { toast } from "react-toastify";
import ToggleSwitch from "./toggle";
const { Sider } = Layout;
export class InputSearch extends StyleableComponent {
    render() {
        const { onChange, value, errors } = this.props;
        const { errorStyle, input, rowwrap, searchbtn, inputwrap } = this;
        return _jsx(Row, { children: _jsxs("div", Object.assign({ css: rowwrap }, { children: [_jsx("div", Object.assign({ css: searchbtn }, { children: _jsx(SearchOutlined, { style: { fontSize: "1.6em" } }, void 0) }), void 0), _jsx("div", Object.assign({ style: inputwrap }, { children: _jsx("input", { css: input, onChange: onChange, value: value }, void 0) }), void 0)] }), void 0) }, void 0);
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], InputSearch.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], InputSearch.prototype, "input", void 0);
__decorate([
    styling("rowwrap"),
    __metadata("design:type", Object)
], InputSearch.prototype, "rowwrap", void 0);
__decorate([
    styling(),
    __metadata("design:type", Object)
], InputSearch.prototype, "searchbtn", void 0);
__decorate([
    styling(),
    __metadata("design:type", Object)
], InputSearch.prototype, "inputwrap", void 0);
const ProductListInternal = (props) => {
    const { productstyle, imagestyle, imagebg } = useStyles("productstyle", "imagestyle", "imagebg");
    const { data, loadUrl, isFetching } = useSaga("/trendutube/v1/product-catalog/products/search", {}, false);
    const { submit, status, error } = useSubmit();
    const [checked, setChecked] = useState(true);
    const [value, setValue] = useState();
    const handleChangeToggleValue = (uid, priority) => {
        const url = `/trendutube/v1/product-catalog/products/${uid}/update-priority?priority=${priority}`;
        submit(url, {});
    };
    useEffect(() => {
        if (status === "ok") {
            toast.success("Product Priority Updated");
            loadUrl("/trendutube/v1/product-catalog/products/search");
        }
    }, [status]);
    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);
    const onInputChange = (e) => {
        setValue(e.target.value);
        loadUrl(`/trendutube/v1/product-catalog/products/search?like=${e.target.value}`);
    };
    return _jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(Col, Object.assign({ cols: 12 }, { children: _jsx("div", Object.assign({ css: productstyle }, { children: _jsx(FormControl, Object.assign({ fullWidth: true, sx: { height: '44px' } }, { children: _jsx(TextField, { fullWidth: true, size: "small", variant: "outlined", value: value, placeholder: 'Search', onChange: onInputChange, InputProps: {
                                    startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchOutlined, {}, void 0) }), void 0)),
                                } }, void 0) }), void 0) }), void 0) }), void 0) }, void 0), _jsx(Row, { children: _jsx(Grid, Object.assign({ container: true, spacing: 3, sx: { padding: "1.5em", boxShadow: 'none' } }, { children: data && data.map((prd, index) => _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 3 }, { children: _jsxs(Card, Object.assign({ sx: { height: '100%', display: 'flex', flexDirection: 'column' } }, { children: [_jsxs(CardContent, Object.assign({ sx: { flexGrow: 1, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' } }, { children: [_jsx("div", { children: prd.images && prd.images.length > 0 ? (_jsx("img", { src: prd.images[0].url, alt: "Product", style: {
                                                    width: '100%',
                                                    height: '200px',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                    marginBottom: '16px',
                                                } }, void 0)) : (_jsx("img", { src: "http://data.exprzn.com:3050/image-service/images/retrieve/0x3364", alt: "Default", style: {
                                                    maxWidth: '100%',
                                                    height: '150px',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                    marginBottom: '16px',
                                                } }, void 0)) }, void 0), _jsx(Typography, Object.assign({ variant: "body2", sx: { marginTop: '8px', color: 'gray', textAlign: 'left', } }, { children: prd.product_name || prd.product_description }), void 0)] }), void 0), _jsxs(CardActions, Object.assign({ sx: { justifyContent: 'space-between' } }, { children: [_jsx(NavLink, Object.assign({ to: `/dashboard/products/${prd.uid}/edit?mode=edit` }, { children: "Edit" }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center', justifyContent: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: { marginRight: '8px' } }, { children: "Priority" }), void 0), _jsx(ToggleSwitch, { uid: prd.uid, handleChangeToggleValue: handleChangeToggleValue, value: prd.product_priority }, void 0)] }), void 0)] }), void 0)] }), void 0) }), index)) }), void 0) }, void 0)] }, void 0);
};
//To remove ! we use sliding 
const ListWithSiderBar = () => _jsxs(Sider, Object.assign({ collapsible: true, collapsed: false, theme: "light" }, { children: [_jsx(Sidebar, {}, void 0), _jsx(Menu, Object.assign({ theme: "light", mode: "inline" }, { children: _jsx(Menu.Item, Object.assign({ style: { height: '64px' } }, { children: "Some value here" }), "1") }), void 0)] }), void 0);
export const ProductList = () => _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(SagaProvider, { children: _jsx(ProductListInternal, {}, void 0) }, void 0) }), void 0);
