import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { useProgressBar } from "../../../components/ProgressBar";
import { StreamNav } from "../../../components/StreamNav";
import { Table } from "../../../components/Table/table";
import { useStream } from "../../../saga/use-stream";
import { PayoutDisplayComponent } from "../delivery-person-tips-payouts";
export const TipsPayoutComponent = (props) => {
};
const header = {
    date: "Date",
    order_id: "Order Id",
    tip_amount: "Tip Amount",
    processed_datetime: "Processed Date Time",
    tips_payout_amount: "Tips Payout Amount",
    status: "Status",
    date_from: "From Date",
    date_to: " To Date",
    orders: "Orders",
    total_delivery_distance: "Distance"
};
const keys = ["processed_datetime", "total_delivery_distance", "tips_payout_amount", "status", "date_from", "date_to", "orders"];
export const TipsPayoutList = (props) => {
    const { routerProps, path } = props;
    const { page: pageStr } = routerProps.match.params;
    const page = parseInt(`${pageStr}`);
    const { data, stream, loadPage, loadUrl, loadNextPage, isFetching } = useStream("/trendutube/v1/admin-payout-processing/tips-payouts/list", { page });
    const [maxPage, setMaxPage] = useState(page);
    const { productstyle, pagestyle } = useStyles("productstyle", "pagestyle");
    const loading = useProgressBar();
    useEffect(() => {
        if (stream && (stream.page > maxPage))
            setMaxPage(stream.page);
    }, [stream]);
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    return _jsxs("div", { children: [_jsx("div", { children: _jsx(StreamNav, Object.assign({}, {
                    loadNextPage, loadPage, stream, maxPage, data,
                    getUrl: (page) => `${path}/basket-orders/in-progress/${page}`
                }), void 0) }, void 0), _jsx("div", Object.assign({ css: productstyle }, { children: _jsx(Table, { header: header, body: data, component: PayoutDisplayComponent, keys: keys }, void 0) }), void 0)] }, void 0);
};
