import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from '@exprzn/react-style-lib';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, ArrowLeft } from 'react-feather';
import { Col } from '../../../components/Column';
import { useProgressBar } from '../../../components/ProgressBar';
import { RelPositioner } from '../../../components/RelPos/RelativePositioner';
import { Row } from '../../../components/Row';
import { useSaga } from '../../../saga/use-saga';
const CategoryItem = (props) => {
    const { category, onClick, onSelect } = props || {};
    const { uid, category_name, category_description, image } = category;
    return _jsxs("div", Object.assign({ css: category }, { children: [_jsx(Row, { children: _jsxs("div", Object.assign({ css: {
                        padding: 10,
                        display: "flex",
                        "flex-direction": "column",
                        alignItems: "center",
                        justifyItems: "top"
                    }, onClick: () => onClick(uid) }, { children: [_jsx("div", { children: image && _jsx("img", { src: image.url, width: 120, height: 120, style: { objectFit: "contain" } }, void 0) }, void 0), _jsx("div", Object.assign({ css: { fontWeight: 600, marginTop: 10, textAlign: "center" } }, { children: category_name }), void 0)] }), void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx("div", Object.assign({ css: { padding: 10, marginBottom: 10 } }, { children: _jsx("button", Object.assign({ onClick: () => onSelect(uid), css: {
                                borderRadius: 4,
                                border: 0,
                                color: "#fff",
                                padding: 2,
                                backgroundColor: "#f6b200",
                                width: "100%",
                                bottom: 0,
                            } }, { children: "Select" }), void 0) }), void 0) }, void 0) }, void 0)] }), void 0);
};
export const CategoryComponent = (props) => {
    const { categoryUid, width, onSelectItem } = props;
    const [categories, setCategories] = useState();
    const [ancestor, setAncestor] = useState();
    const { data, loadUrl, isFetching } = useSaga(undefined, {}, false);
    const { data: catData, loadUrl: catLoadUrl, isFetching: catIsFEtching } = useSaga(undefined, {}, false);
    const ref1 = useRef(null);
    const [_title, setTitle] = useState();
    const [show, setShow] = useState(false);
    const { slideup, slidedown, dropboxmenu, rowhcenter, rowvcenter, rowhspacing, dropboxarrowstyle, dropboxstyle, dropboxcatstyle, dropboxitemstyle } = useStyles("slideup", "slidedown", "dropboxmenu", "rowhcenter", "rowvcenter", "rowhspacing", "dropboxstyle", "dropboxcatstyle", "dropboxarrowstyle", "dropboxitemstyle", "rowhcenterrowhspacing");
    const loading = useProgressBar();
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    useEffect(() => {
        loadUrl(`/trendutube/v1/public/categories/list`);
    }, []);
    useEffect(() => {
        catLoadUrl(`/trendutube/v1/public/categories/${categoryUid}/info`);
    }, [categoryUid]);
    useEffect(() => {
        var _a;
        if (data && data.categories)
            setCategories(data.categories);
        else if (data && data.sub_categories)
            setCategories(data.sub_categories);
        setAncestor((_a = data) === null || _a === void 0 ? void 0 : _a.ancestor);
    }, [data]);
    const loadSubCategories = (catUid) => loadUrl(`/trendutube/v1/public/categories/${catUid}/info?sub_category=true`);
    const clickHandler = (event) => {
        setShow(!show);
        //if this is not prevented, the event bubbles up
        //and if inside a form this avtually submits the form
        event.preventDefault();
    };
    const onItemsClickHandler = (item) => {
        onSelectItem === null || onSelectItem === void 0 ? void 0 : onSelectItem(item);
        setShow(!show);
    };
    function assignToRef() {
        return ref1.current;
    }
    const children = categories && categories.map((item, index) => _jsx(CategoryItem, { category: item, onClick: (categUid) => loadSubCategories(categUid), onSelect: onItemsClickHandler }, item.uid));
    const handleBackButton = () => {
        if (ancestor) {
            loadSubCategories(ancestor === null || ancestor === void 0 ? void 0 : ancestor.uid);
        }
        else
            loadUrl(`/trendutube/v1/public/categories/list`);
    };
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ style: { maxHeight: "38px" } }, { children: _jsxs("button", Object.assign({ ref: ref1, css: [dropboxcatstyle], onClick: clickHandler, style: { width } }, { children: [catData ? _jsxs("div", Object.assign({ css: [] }, { children: [" ", catData.category_name] }), void 0) : null, _jsx("div", Object.assign({ css: [rowvcenter, dropboxarrowstyle] }, { children: show ? _jsx(ChevronUp, { size: "24px" }, void 0) : _jsx(ChevronDown, { color: "#d8d8d8", size: "24px" }, void 0) }), void 0)] }), void 0) }), void 0), show ? _jsx("div", { children: _jsx(RelPositioner, Object.assign({ target: assignToRef, align: "left bottom", onClickedOutSide: () => setShow(false) }, { children: _jsxs("div", Object.assign({ css: show ? [dropboxmenu, slideup] : [dropboxmenu, slidedown] }, { children: [_jsx("div", Object.assign({ css: { padding: 10, cursor: "pointer" }, onClick: handleBackButton }, { children: !(data.categories) ? _jsx(ArrowLeft, { color: "#d8d8d8", size: "24px" }, void 0) : null }), void 0), _jsx("div", Object.assign({ css: {
                                    padding: 10,
                                    display: "grid",
                                    "grid-template-columns": "140px 140px 140px 140px"
                                } }, { children: children }), void 0)] }), void 0) }), void 0) }, void 0) : null] }, void 0));
};
