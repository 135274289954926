import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { useEffect, useRef, useState } from "react";
import { UploadCloud } from 'react-feather';
import { useSubmit } from "../../saga/use-submit";
import { Form } from "../Form";
import { useProgressBar } from "../ProgressBar";
import container from "./style";
import { toast } from 'react-toastify';
export const DragDropClass = (props) => {
    const { entity, onImagesUpload, uid, exclusive = false } = props;
    const { divcontainer, browseclick, button, removebtn } = useStyles("divcontainer", "browseclick", "removebtn", "button");
    const { submit: submitImage, data: dataImage, error: errorImage, isFetching: isFetchingImage, status: statusImage } = useSubmit();
    const inputOpenFileRef = useRef(null);
    const openFile = () => {
        inputOpenFileRef &&
            inputOpenFileRef.current &&
            inputOpenFileRef.current.click();
    };
    const [showText, setShowText] = useState(true);
    const [saveButton, setSaveButton] = useState(true);
    const [changeButton, setChangeButton] = useState(false);
    const [images, setUpoadImages] = useState();
    const [imageFiles, setImageFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const loading = useProgressBar();
    function processFileList(fileList) {
        const im = Array.from(fileList);
        const im1 = im.map(f => URL.createObjectURL(f));
        setImageFiles(im1);
        setUpoadImages(fileList);
        setShowText(false);
    }
    function handleChange(event) {
        event.preventDefault();
        processFileList(event.target.files);
    }
    const dragOver = (e) => {
        e.preventDefault();
    };
    const dragEnter = (e) => {
        e.preventDefault();
    };
    const dragLeave = (e) => {
        e.preventDefault();
    };
    function onDrop(event) {
        event.preventDefault();
        processFileList(event.dataTransfer.files);
    }
    const changeButtonClick = () => {
        setShowText(true);
        setSaveButton(true);
        setChangeButton(false);
        setImageFiles([]);
        setUpoadImages(null);
    };
    function handleImageSubmit(data) {
        const uidFrag = uid ? `&uid=${uid}` : "";
        const url = exclusive ? `/image-service/images/upload-exclusive?entity=${entity}${uidFrag}` :
            `/image-service/images/upload?entity=${entity}${uidFrag}`;
        submitImage(url, {
            form: true,
            body: { images },
        });
        setSaveButton(false);
        setChangeButton(true);
    }
    useEffect(() => {
        if (dataImage && dataImage.urls)
            onImagesUpload === null || onImagesUpload === void 0 ? void 0 : onImagesUpload(dataImage.urls);
    }, [dataImage]);
    useEffect(() => {
        loading(isFetchingImage);
    }, [isFetchingImage]);
    useEffect(() => {
        if (statusImage == "ok")
            toast.success(`${dataImage.urls.length} Image(s) uploaded successfully `);
    }, [statusImage]);
    return (_jsx("div", { children: _jsxs(Form, Object.assign({ name: "upload", onSubmit: handleImageSubmit }, { children: [_jsxs("div", Object.assign({ css: divcontainer, onDrop: onDrop, onDragOver: dragOver, onDragEnter: dragEnter, onDragLeave: dragLeave }, { children: [showText === true ?
                            _jsxs("div", Object.assign({ css: {
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    padding: '5px'
                                } }, { children: [_jsx(UploadCloud, {}, void 0), _jsx("div", Object.assign({ css: { fontSize: 16 } }, { children: "Drag and drop a .png, .jpg or .svg  file" }), void 0), _jsx("div", Object.assign({ css: { fontSize: 16 } }, { children: "or" }), void 0), _jsx("input", { ref: inputOpenFileRef, type: "file", multiple: true, style: { display: "none" }, onChange: handleChange }, void 0), _jsx("div", Object.assign({ css: browseclick, onClick: openFile }, { children: _jsx("u", { children: _jsx("strong", { children: "Browse" }, void 0) }, void 0) }), void 0)] }), void 0) : null, showText === false ? _jsx("div", Object.assign({ style: {
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "space-evenly"
                            } }, { children: imageFiles.map(i => _jsx("div", { css: {
                                    backgroundSize: "cover",
                                    backgroundImage: `url(${i})`, width: "100%", height: "100%",
                                } }, void 0)) }), void 0) : null] }), void 0), _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "end", marginTop: 10 } }, { children: [saveButton === true ? _jsx("div", Object.assign({ css: {} }, { children: _jsxs("div", Object.assign({ css: { marginBottom: 10, display: "flex", justifySelf: "end", alignSelf: "bottom" } }, { children: [imageFiles.length > 0 ? _jsx("button", Object.assign({ type: "button", onClick: changeButtonClick, css: removebtn }, { children: imageFiles.length == 1 ? "Remove Image" : "Remove Images" }), void 0) : null, _jsx("button", Object.assign({ type: "button", onClick: handleImageSubmit, css: button }, { children: "Upload" }), void 0)] }), void 0) }), void 0) : null, changeButton === true ?
                            _jsx(_Fragment, { children: _jsx("div", { children: _jsx("button", Object.assign({ type: "button", onClick: changeButtonClick, css: button }, { children: "Change" }), void 0) }, void 0) }, void 0) : null] }), void 0), _jsx("p", { children: errorMessage }, void 0)] }), void 0) }, void 0));
};
export const DragDrop = (props) => _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(DragDropClass, Object.assign({}, props), void 0) }), void 0);
export const DragDropVideoClass = (props) => {
    const { entity, onVideoUpload, uid, exclusive = false } = props;
    const { divcontainer, browseclick, button, removebtn } = useStyles("divcontainer", "browseclick", "removebtn", "button");
    const { submit: submitVideo, data: dataVideo, error: errorVideo, isFetching: isFetchingVideo, status: statusVideo } = useSubmit();
    const inputOpenFileRef = useRef(null);
    const openFile = () => {
        inputOpenFileRef &&
            inputOpenFileRef.current &&
            inputOpenFileRef.current.click();
    };
    const [showText, setShowText] = useState(true);
    const [saveButton, setSaveButton] = useState(true);
    const [changeButton, setChangeButton] = useState(false);
    const [videos, setUpoadVideos] = useState();
    const [videoFiles, setVideoFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const loading = useProgressBar();
    function processFileList(fileList) {
        const im = Array.from(fileList);
        const im1 = im.map(f => URL.createObjectURL(f));
        setVideoFiles(im1);
        setUpoadVideos(fileList);
        setShowText(false);
    }
    function handleChange(event) {
        event.preventDefault();
        processFileList(event.target.files);
    }
    const dragOver = (e) => {
        e.preventDefault();
    };
    const dragEnter = (e) => {
        e.preventDefault();
    };
    const dragLeave = (e) => {
        e.preventDefault();
    };
    function onDrop(event) {
        event.preventDefault();
        processFileList(event.dataTransfer.files);
    }
    const changeButtonClick = () => {
        setShowText(true);
        setSaveButton(true);
        setChangeButton(false);
        setVideoFiles([]);
        setUpoadVideos(null);
    };
    function handleVideoSubmit(data) {
        const uidFrag = uid ? `&uid=${uid}` : "";
        const url = exclusive ? `/image-service/videos/upload-exclusive?entity=${entity}${uidFrag}` :
            `/image-service/videos/upload?entity=${entity}${uidFrag}`;
        submitVideo(url, {
            form: true,
            body: { images: videos },
        });
        setSaveButton(false);
        setChangeButton(true);
    }
    useEffect(() => {
        if (dataVideo && dataVideo.urls)
            onVideoUpload === null || onVideoUpload === void 0 ? void 0 : onVideoUpload(dataVideo.urls);
    }, [dataVideo]);
    useEffect(() => {
        loading(isFetchingVideo);
    }, [isFetchingVideo]);
    useEffect(() => {
        if (statusVideo == "ok")
            toast.success(`${dataVideo.urls.length} Video(s) uploaded successfully `);
    }, [statusVideo]);
    return (_jsx("div", { children: _jsxs(Form, Object.assign({ name: "upload", onSubmit: handleVideoSubmit }, { children: [_jsxs("div", Object.assign({ css: divcontainer, onDrop: onDrop, onDragOver: dragOver, onDragEnter: dragEnter, onDragLeave: dragLeave }, { children: [showText === true ?
                            _jsxs("div", Object.assign({ css: { justifySelf: "center", padding: '10px' } }, { children: [_jsx(UploadCloud, {}, void 0), _jsx("div", Object.assign({ css: { fontSize: 16 } }, { children: "Drag and drop a .mp4  file" }), void 0), _jsx("div", Object.assign({ css: { fontSize: 16 } }, { children: "or" }), void 0), _jsx("input", { ref: inputOpenFileRef, type: "file", multiple: true, style: { display: "none" }, onChange: handleChange }, void 0), _jsx("div", Object.assign({ css: browseclick, onClick: openFile }, { children: _jsx("u", { children: _jsx("strong", { children: "Browse" }, void 0) }, void 0) }), void 0)] }), void 0) : null, showText === false ? _jsx("div", Object.assign({ style: {
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "space-evenly"
                            } }, { children: videoFiles.map(i => _jsx("div", Object.assign({ style: {
                                    width: "45%",
                                    height: "auto",
                                    margin: "10px",
                                } }, { children: _jsxs("video", Object.assign({ width: "100%", height: "100%", controls: true, style: { objectFit: 'cover' } }, { children: [_jsx("source", { src: i, type: "video/mp4" }, void 0), "Your browser does not support the video tag."] }), void 0) }), void 0)) }), void 0) : null] }), void 0), _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "end", marginTop: 10 } }, { children: [saveButton === true ? _jsx("div", Object.assign({ css: {} }, { children: _jsxs("div", Object.assign({ css: { marginBottom: 10, display: "flex", justifySelf: "end", alignSelf: "bottom" } }, { children: [videoFiles.length > 0 ? _jsx("button", Object.assign({ type: "button", onClick: changeButtonClick, css: removebtn }, { children: videoFiles.length == 1 ? "Remove Video" : "Remove Videos" }), void 0) : null, _jsx("button", Object.assign({ type: "button", onClick: handleVideoSubmit, css: button }, { children: "Upload" }), void 0)] }), void 0) }), void 0) : null, changeButton === true ?
                            _jsx(_Fragment, { children: _jsx("div", { children: _jsx("button", Object.assign({ type: "button", onClick: changeButtonClick, css: button }, { children: "Change" }), void 0) }, void 0) }, void 0) : null] }), void 0), _jsx("p", { children: errorMessage }, void 0)] }), void 0) }, void 0));
};
export const DragDropVideo = (props) => _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(DragDropVideoClass, Object.assign({}, props), void 0) }), void 0);
