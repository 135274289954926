import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { Field, Form } from "../../../components/Form";
import { InputEx } from "../../../components/Form/fields-extended";
import { Row } from "../../../components/Row";
import { SagaProvider } from "../../../saga/saga-context";
import container from "./style";
import { Modal } from "../../../components/Modal";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { useSubmit } from "../../../saga/use-submit";
export const createGroupUrl = (uid) => `/trendutube/v1/product-catalog/attribute-set-templates/${uid}/attribute-group-templates/add`;
export const createAttributeUrl = (uid) => `/trendutube/v1/product-catalog/attribute-group-templates/${uid}/attribute-templates/add`;
const AttributeGroupForm = (props) => {
    const { uid } = props;
    const { data, isFetching, status, submit } = useSubmit();
    const { titlestyle, input: inputstyle, btnstyle } = useStyles("titlestyle", "input", "btnstyle");
    const handleCreatesubmit = (data) => {
        const { creategroup } = data;
        const { name, description } = creategroup;
        const body = { name, description };
        submit(createGroupUrl(uid), { body });
    };
    useEffect(() => {
        if (data) {
        }
    }, [data]);
    return _jsxs(Row, { children: [_jsx(Col, { cols: 1 }, void 0), _jsx(Col, Object.assign({ cols: 10 }, { children: _jsxs(Form, Object.assign({ name: "creategroup", onSubmit: handleCreatesubmit }, { children: [status, _jsxs(StyleProvider, Object.assign({ value: { container } }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: " Attribute Group Set Name " }), void 0), _jsx(Field, Object.assign({ name: "name" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }, void 0), _jsxs("div", { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "  Attribute Group Set Description " }), void 0), _jsx(Field, Object.assign({ name: "description" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }, void 0), _jsx("input", { css: btnstyle, type: "submit", value: "Create" }, void 0)] }), void 0)] }), void 0) }), void 0), _jsx(Col, { cols: 1 }, void 0)] }, void 0);
};
const AttributeForm = (props) => {
    const { uid } = props;
    const { data, isFetching, status, submit } = useSubmit();
    const { titlestyle, input: inputstyle, btnstyle } = useStyles("titlestyle", "input", "btnstyle");
    const handleCreatesubmit = (data) => {
        const { createattribute } = data;
        const { name, description } = createattribute;
        const body = { name, description };
        submit(createAttributeUrl(uid), { body });
    };
    useEffect(() => {
        if (data) {
            alert("done");
        }
    }, [data]);
    return _jsxs(Row, { children: [_jsx(Col, { cols: 1 }, void 0), _jsx(Col, Object.assign({ cols: 10 }, { children: _jsxs(Form, Object.assign({ name: "createattribute", onSubmit: handleCreatesubmit }, { children: [status, _jsxs(StyleProvider, Object.assign({ value: { container } }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Attribute Name " }), void 0), _jsx(Field, Object.assign({ name: "name" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }, void 0), _jsxs("div", { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "  Attribute Description " }), void 0), _jsx(Field, Object.assign({ name: "description" }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }, void 0), _jsx("input", { css: btnstyle, type: "submit", value: "Create Attribute" }, void 0)] }), void 0)] }), void 0) }), void 0), _jsx(Col, { cols: 1 }, void 0)] }, void 0);
};
const AttributeGroup = (props) => {
    const [createAttribute, setCreateAttribute] = useState(false);
    const { attribtitlestyle, attribstyle, textstyle, btnstyle, gridstyle, groupstyle, grouptitlestyle, grouptextstyle, attribitemstyle, xbtnstyle, groupcontainerstyle } = useStyles("xbtnstyle", "attribtitlestyle", "attribstyle", "textstyle", "titlestyle", "gridstyle", "groupstyle", "btnstyle", "grouptextstyle", "grouptitlestyle", "groupcontainerstyle", "attribitemstyle");
    const { group } = props;
    const { attributes, uid } = group;
    if (!group)
        return _jsx(_Fragment, {}, void 0);
    return _jsx(_Fragment, { children: _jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ css: attribstyle }, { children: [_jsxs("div", Object.assign({ css: groupcontainerstyle }, { children: [_jsxs("div", Object.assign({ css: groupstyle }, { children: [_jsx("div", Object.assign({ css: grouptitlestyle }, { children: group.attribute_group_name }), void 0), _jsx("div", Object.assign({ css: grouptextstyle }, { children: group.attribute_group_description }), void 0), _jsx("div", Object.assign({ style: { fontSize: ".75em" } }, { children: _jsx("button", Object.assign({ style: { padding: "2px 10px", color: "#778855" }, onClick: () => setCreateAttribute(true), type: "button" }, { children: "Add Attribute" }), void 0) }), void 0)] }), void 0), _jsx("div", Object.assign({ css: gridstyle }, { children: attributes && attributes.map(attrib => {
                                        return _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ css: attribitemstyle }, { children: [" ", attrib.attribute_name, "(", attrib.attribute_value_type, ",", attrib.attribute_unit, ")"] }), void 0), _jsx("div", Object.assign({ css: attribitemstyle }, { children: attrib.attribute_description }), void 0)] }, void 0);
                                    }) }), void 0)] }), void 0), createAttribute ?
                            _jsx(Modal, { children: _jsxs("div", Object.assign({ style: { backgroundColor: "white", width: "600px", height: "400px" } }, { children: [_jsx("button", Object.assign({ css: [btnstyle, xbtnstyle], type: "button", onClick: () => setCreateAttribute(false) }, { children: _jsx(X, {}, void 0) }), void 0), _jsx(AttributeForm, Object.assign({}, { uid }), void 0)] }), void 0) }, void 0) : null] }), void 0) }, void 0) }, void 0) }, void 0);
};
const AttributeGroupAddInt = (props) => {
    const { attribute_group_templates = [], uid } = props;
    const { titlestyle, btnstyle, xbtnstyle } = useStyles("titlestyle", "btnstyle", "xbtnstyle");
    const [create, setCreate] = useState(false);
    const handleGroupAdd = () => {
        setCreate(true);
    };
    return _jsxs("div", { children: [_jsx("div", { children: "Attribute Group Templates" }, void 0), _jsx("button", Object.assign({ css: btnstyle, type: "button", onClick: handleGroupAdd }, { children: "Add Group Temp" }), void 0), create ?
                _jsx(Modal, { children: _jsxs("div", Object.assign({ style: { backgroundColor: "white", width: "600px", height: "400px" } }, { children: [_jsx("button", Object.assign({ css: [btnstyle, xbtnstyle], type: "button", onClick: () => setCreate(false) }, { children: _jsx(X, {}, void 0) }), void 0), _jsx(AttributeGroupForm, Object.assign({}, { uid }), void 0)] }), void 0) }, void 0) : null, attribute_group_templates && attribute_group_templates.map((group, index) => _jsx(AttributeGroup, Object.assign({}, { group }), void 0))] }, void 0);
};
export const AttributeGroupAdd = (props) => _jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(AttributeGroupAddInt, Object.assign({}, props), void 0) }), void 0) }, void 0);
