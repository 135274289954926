import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { SagaProvider } from "../../saga/saga-context";
import { useSaga } from "../../saga/use-saga";
import container from "./style";
import { Row } from '../../components/Row';
import { Col } from '../../components/Column';
const AttribSetTmpltGetInt = (props) => {
    const { attribtitlestyle, attribstyle, textstyle, titlestyle, gridstyle, groupstyle, grouptitlestyle, grouptextstyle, attribitemstyle, groupcontainerstyle } = useStyles("attribtitlestyle", "attribstyle", "textstyle", "titlestyle", "gridstyle", "groupstyle", "grouptextstyle", "grouptitlestyle", "groupcontainerstyle", "attribitemstyle");
    const { attrib_set_tmplt_uid: uid } = props.match.params;
    const { data } = useSaga(`/trendutube/v1/product-catalog/attribute-set-templates/get?uid=${uid}`, {}, false);
    const attribSetTmplt = data;
    const { attribute_group_templates } = attribSetTmplt || {};
    return _jsx(Row, { children: _jsx(Col, Object.assign({ cols: 6 }, { children: _jsxs("div", Object.assign({ css: attribstyle }, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: " Attribute Set Name " }), void 0), _jsxs("div", Object.assign({ css: textstyle }, { children: [" ", data && attribSetTmplt.attribute_set_name, " "] }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: " Attribute Set Description" }), void 0), _jsxs("div", Object.assign({ css: textstyle }, { children: ["  ", (data && attribSetTmplt.attribute_set_description) || _jsx("span", { children: "(No Desc)" }, void 0), " "] }), void 0), _jsx("div", { children: attribute_group_templates && attribute_group_templates.map(group => {
                            return _jsxs("div", Object.assign({ css: groupcontainerstyle }, { children: [_jsxs("div", Object.assign({ css: groupstyle }, { children: [_jsx("div", Object.assign({ css: grouptitlestyle }, { children: group.attribute_group_name }), void 0), _jsx("div", Object.assign({ css: grouptextstyle }, { children: group.attribute_group_description }), void 0)] }), void 0), _jsx("div", Object.assign({ css: gridstyle }, { children: group.attributes && group.attributes.map(attrib => {
                                            return _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ css: attribitemstyle }, { children: [" ", attrib.attribute_name, "(", attrib.attribute_value_type, ",", attrib.attribute_unit, ")"] }), void 0), _jsx("div", Object.assign({ css: attribitemstyle }, { children: attrib.attribute_description }), void 0)] }, void 0);
                                        }) }), void 0)] }), void 0);
                        }) }, void 0)] }), void 0) }), void 0) }, void 0);
};
export const AttribSetTmpltDetail = (props) => _jsx(SagaProvider, { children: _jsxs(StyleProvider, Object.assign({ value: { container } }, { children: [_jsx(AttribSetTmpltGetInt, Object.assign({}, props), void 0), " "] }), void 0) }, void 0);
