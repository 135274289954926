var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
import { keyframes } from "@emotion/react";
const { style, container } = registry("global", true);
let RowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowStyle extends CssStyle {
};
RowStyle.style = {
    backgroundColor: 'white',
    padding: ".5em",
    margin: '.5em',
    borderRadius: ".6em",
};
RowStyle = __decorate([
    style("rowstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowStyle);
let ProductStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ProductStyle extends CssStyle {
};
ProductStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em",
    padding: "1.5em",
    position: "relative"
};
ProductStyle = __decorate([
    style("productstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ProductStyle);
let PanelStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PanelStyle extends CssStyle {
};
PanelStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em",
    padding: "1.5em",
    position: "relative"
};
PanelStyle = __decorate([
    style("panelstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], PanelStyle);
let StoreStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class StoreStyle extends CssStyle {
};
StoreStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em",
    position: "relative"
};
StoreStyle = __decorate([
    style("storestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], StoreStyle);
let ErrorStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ErrorStyle extends CssStyle {
};
ErrorStyle.style = {
    backgroundColor: 'white',
    fontWeight: 400,
    fontSize: ".8em",
    padding: ".5em",
    margin: '.5em',
};
ErrorStyle = __decorate([
    style("error")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ErrorStyle);
let PageStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PageStyle extends CssStyle {
};
PageStyle.style = {
    borderRadius: ".6em",
    margin: "3px",
    padding: "6px 13px",
    backgroundColor: "#fffbf9",
    border: "1px solid #ffebc6",
    "&:hover": {
        backgroundColor: "#ffda99",
        color: "#ff8f00",
        border: "1px solid #ffc763",
    },
    "&:focus": {
        backgroundColor: "#ffda99",
        color: "#ff8f00",
        border: "1px solid #ffc763",
    },
    outline: "none",
};
PageStyle = __decorate([
    style("pagestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], PageStyle);
let PageSelectStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PageSelectStyle extends CssStyle {
};
PageSelectStyle.style = {
    borderRadius: ".6em",
    margin: "3px",
    padding: "6px 13px",
    backgroundColor: "#e8a631",
    border: "1px solid #e8a631",
    color: "#ffffff",
    "&:hover": {
        backgroundColor: "#ffda99",
        color: "#ff8f00",
        border: "1px solid #ffc763",
    },
    "&:focus": {
        backgroundColor: "#e8a631",
        color: "#ffffff",
        border: "1px solid #e8a631"
    },
    outline: "none",
};
PageSelectStyle = __decorate([
    style("pageselectstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], PageSelectStyle);
let StreamStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class StreamStyle extends CssStyle {
};
StreamStyle.style = {
    borderRadius: ".6em",
    margin: "3px",
    padding: "6px 13px",
    backgroundColor: "white",
    border: " 1px solid #95C3FF",
    fontSize: ".88em",
    "&:hover": {
        backgroundColor: "#0081E1",
        color: "#ffffff",
        border: "1.5px solid #0081E1",
    },
    outline: "none",
};
StreamStyle = __decorate([
    style("streampagestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], StreamStyle);
let StreamSelectedStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class StreamSelectedStyle extends CssStyle {
};
StreamSelectedStyle.style = {
    borderRadius: ".6em",
    margin: "3px",
    padding: "6px 13px",
    backgroundColor: "#0081E1",
    color: "#ffffff",
    border: "1.5px solid #0081E1"
};
StreamSelectedStyle = __decorate([
    style("selectedstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], StreamSelectedStyle);
let InputStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputStyle extends CssStyle {
};
InputStyle.style = {
    position: "relative",
    border: '1px solid #e6e6e6',
    '&:hover': {
        backgroundColor: '#f8b63177',
        border: "1px solid #fefefe",
    },
    '&:focus': {
        backgroundColor: '#f8b63111'
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    backgroundColor: '#f1f1f1',
    fontWeight: 600,
    fontSize: '1em'
};
InputStyle = __decorate([
    style("input")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputStyle);
let InputCheckboxStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputCheckboxStyle extends CssStyle {
};
InputCheckboxStyle.style = {
    position: "relative",
    border: '1px solid #e6e6e6',
    '&:hover': {
        backgroundColor: '#f8b63177',
        border: "1px solid #fefefe",
    },
    '&:focus': {
        backgroundColor: '#f8b63111'
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    backgroundColor: '#f1f1f1',
    color: '#e8a631',
    marginTop: "1em",
    fontWeight: 600,
    fontSize: '1em'
};
InputCheckboxStyle = __decorate([
    style("inputcheckbox")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputCheckboxStyle);
let InputPlainStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputPlainStyle extends CssStyle {
};
InputPlainStyle.style = {
    position: "relative",
    border: 'none',
    '&:hover': {
        backgroundColor: '#CBE7FC',
        border: "none",
    },
    '&:focus': {
        backgroundColor: ' #CBE7FC'
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    backgroundColor: '#F6F8F9',
    color: '#111111',
    marginTop: "1em",
    fontWeight: 600,
    fontSize: '1em'
};
InputPlainStyle = __decorate([
    style("inputstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputPlainStyle);
let ButtonStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ButtonStyle extends CssStyle {
};
ButtonStyle.style = {
    position: "relative",
    fontSize: "12px",
    fontWeight: "600",
    outline: "none",
    border: "0",
    "&:hover": {
        backgroundColor: "#6464c9",
    },
    "&:focus": {
        backgroundColor: "#6464c9",
    },
    color: "#f4f4ff",
    padding: ".8em 2em",
    borderRadius: ".5em",
    backgroundColor: "#045998",
    boxShadow: "none",
};
ButtonStyle = __decorate([
    style("button")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ButtonStyle);
let ButtonLgStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ButtonLgStyle extends CssStyle {
};
ButtonLgStyle.style = {
    position: "relative",
    outline: 'none',
    border: "0",
    '&:hover': {
        backgroundColor: '#e8b631'
    },
    '&:focus': {
        backgroundColor: '#f8b631'
    },
    padding: ".8em 1.6em",
    marginTop: "1em",
    color: 'white',
    borderRadius: ".5em",
    backgroundColor: '#f8b611',
    boxShadow: "1px 1px 10px #f8e1aa",
};
ButtonLgStyle = __decorate([
    style("buttonlg")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ButtonLgStyle);
let ActionButtonMsStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ActionButtonMsStyle extends CssStyle {
};
ActionButtonMsStyle.style = {
    position: "relative",
    fontSize: "12px",
    fontWeight: "600",
    outline: "none",
    border: "0",
    color: "#f4f4ff",
    padding: ".5em .5em",
    borderRadius: ".5em",
    backgroundColor: "#045998",
};
ActionButtonMsStyle = __decorate([
    style("action_btn_ms")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ActionButtonMsStyle);
let ButtonSmallStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ButtonSmallStyle extends CssStyle {
};
ButtonSmallStyle.style = {
    position: "relative",
    outline: 'none',
    border: "0",
    '&:hover': {
        backgroundColor: '#e8b631'
    },
    '&:focus': {
        backgroundColor: '#f8b631'
    },
    borderRadius: ".3em",
    color: "white",
    padding: ".1em 1em",
    margin: "1em",
    backgroundColor: '#f8b611',
    boxShadow: "1px 1px 10px #f8e1aa",
};
ButtonSmallStyle = __decorate([
    style("btn_small")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ButtonSmallStyle);
let TitleRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TitleRowStyle extends CssStyle {
};
TitleRowStyle.style = {
    fontWeight: 600,
    color: "#888888",
    margin: "18px 0px 0px 0px",
    fontSize: ".9em"
};
TitleRowStyle = __decorate([
    style("titlestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TitleRowStyle);
let MainStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class MainStyle extends CssStyle {
};
MainStyle.style = {
    padding: "2em",
    backgroundColor: 'white',
    borderRadius: "10px"
};
MainStyle = __decorate([
    style('containerstyle')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], MainStyle);
let MainStyle2 = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class MainStyle2 extends CssStyle {
};
MainStyle2.style = {
    padding: "1em",
    borderRadius: "10px"
};
MainStyle2 = __decorate([
    style('containerstyle2')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], MainStyle2);
let TextStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TextStyle extends CssStyle {
};
TextStyle.style = {
    fontWeight: 700,
    color: "#555555",
    margin: "0px 0px 8px 0px",
    fontSize: "1em"
};
TextStyle = __decorate([
    style("textstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TextStyle);
let CategoryStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CategoryStyle extends CssStyle {
};
CategoryStyle.style = {
    backgroundColor: 'white',
    padding: "2em",
    margin: '1em',
};
CategoryStyle = __decorate([
    style("category")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], CategoryStyle);
let RowMSStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowMSStyle extends CssStyle {
};
RowMSStyle.style = {
    borderRadius: "4px",
    margin: "0px",
    padding: "6px 13px",
    backgroundColor: "#f8f8f8",
    border: "1px solid #e8e8e8",
    "&:hover": {
        // backgroundColor: "#e8a631",
        // color: "#ffffff",
        border: "1px solid #e8a631",
    },
    outline: "none",
};
RowMSStyle = __decorate([
    style("row_basket")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowMSStyle);
let GridStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class GridStyle extends CssStyle {
};
GridStyle.style = {
    display: "grid"
};
GridStyle = __decorate([
    style("grid")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], GridStyle);
let JustifyRightStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class JustifyRightStyle extends CssStyle {
};
JustifyRightStyle.style = {
    justifySelf: "flex-end"
};
JustifyRightStyle = __decorate([
    style("justifyright")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], JustifyRightStyle);
let GridRightStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class GridRightStyle extends CssStyle {
};
GridRightStyle.style = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
};
GridRightStyle = __decorate([
    style("grid_right")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], GridRightStyle);
let DropboxButtonCatStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class DropboxButtonCatStyle extends CssStyle {
};
DropboxButtonCatStyle.style = {
    position: "relative",
    border: '1px solid #bababa',
    '&:hover': {
        backgroundColor: '#f8b63177',
        border: "1px solid #fefefe",
    },
    '&:focus': {
        backgroundColor: '#f8b63111'
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    backgroundColor: '#fff',
    color: '#111111',
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    outline: '0px',
    display: "flex",
    marginBottom: "18px",
    marginTop: "1em",
    fontWeight: 600,
    fontSize: '1em'
};
DropboxButtonCatStyle = __decorate([
    style("dropboxcatstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], DropboxButtonCatStyle);
let DropboxButtonStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class DropboxButtonStyle extends CssStyle {
};
DropboxButtonStyle.style = {
    outline: '0px',
    display: "flex",
    margin: "0px",
    padding: "4px 8px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f8f8f8",
    border: "1px solid #e8e8e8",
    borderRadius: "3px"
};
DropboxButtonStyle = __decorate([
    style("dropboxstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], DropboxButtonStyle);
/*
@style("dropboxstyle")
class DropboxTitleStyle extends CssStyle {
    static style = {
        outline: '0px',
        display: "flex",
        margin: "0px 3px",
        padding: "0px 8px",
        alignContent: "center",
        backgroundColor: "#f8f8f8",
        border: "1px solid #e8e8e8",
        borderRadius: "3px"
    }
}*/
const keyframesSlideUp = keyframes `
    from {
        transform: translate3d(0, -10px, 0);
    }
    to {
        transform: translate3d(0px, 1px, 0);
    }
`;
let SlideUp = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SlideUp extends CssStyle {
};
SlideUp.style = {
    // animation: `${keyframesSlideUp} 1s ease infinite;`
    animationName: `${keyframesSlideUp}`,
    animationDuration: "0.5s",
    animationTimeFunction: "ease-out",
    animationFillMode: "forwards",
    animationDelay: "0s"
};
SlideUp = __decorate([
    style("slideup")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SlideUp);
const keyframesSlideDown = keyframes `
    from {
        transform: translate3d(0, -10px, 0);
    }
    to {
        transform: translate3d(0px, 1px, 0);
    }
`;
let SlideDown = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SlideDown extends CssStyle {
};
SlideDown.style = {
    // animation: `${keyframesSlideDown} 1s ease infinite;`,
    animationName: `${keyframesSlideDown}`,
    animationDuration: "0.2s",
    animationTimeFunction: "ease-out",
    animationFillMode: "forwards",
    animationDelay: "0s"
};
SlideDown = __decorate([
    style("slidedown")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SlideDown);
let DropboxMenu = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class DropboxMenu extends CssStyle {
};
DropboxMenu.style = {
    background: 'white',
    borderRadius: "2px",
    // padding: "7px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.3)"
};
DropboxMenu = __decorate([
    style("dropboxmenu")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], DropboxMenu);
let VerticalCenterStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class VerticalCenterStyle extends CssStyle {
};
VerticalCenterStyle.style = {
    display: "flex",
    alignContent: "center"
};
VerticalCenterStyle = __decorate([
    style("rowvcenter")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], VerticalCenterStyle);
let HorizontalCenterStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class HorizontalCenterStyle extends CssStyle {
};
HorizontalCenterStyle.style = {
    display: "flex",
    justifyContent: "center",
};
HorizontalCenterStyle = __decorate([
    style("rowhcenter")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], HorizontalCenterStyle);
let RowCenterSpacingStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowCenterSpacingStyle extends CssStyle {
};
RowCenterSpacingStyle.style = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "spacing-between"
};
RowCenterSpacingStyle = __decorate([
    style("rowhspacing")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowCenterSpacingStyle);
let ArrowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ArrowStyle extends CssStyle {
};
ArrowStyle.style = {
    marginLeft: "10px"
};
ArrowStyle = __decorate([
    style("dropboxarrowstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ArrowStyle);
let DropBoxItemStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class DropBoxItemStyle extends CssStyle {
};
DropBoxItemStyle.style = {
    paddingTop: "5px",
    paddingBottom: "5px",
    listStyle: "none",
    borderBottom: "1px solid rgab(236, 236, 236,0.16)",
    '&:hover': {
        backgroundColor: "#f86677"
    }
};
DropBoxItemStyle = __decorate([
    style("dropboxitemstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], DropBoxItemStyle);
let CardNanoTitleStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CardNanoTitleStyle extends CssStyle {
};
CardNanoTitleStyle.style = {
    fontSize: ".8em",
    color: "#a1a1a1",
    // marginTop: "4px"
};
CardNanoTitleStyle = __decorate([
    style("cardnanotitle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], CardNanoTitleStyle);
let CardNanoValueStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CardNanoValueStyle extends CssStyle {
};
CardNanoValueStyle.style = {
    fontSize: "1.2em",
    color: "#000000",
};
CardNanoValueStyle = __decorate([
    style("cardnanovalue")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], CardNanoValueStyle);
let CardPicoTitleStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CardPicoTitleStyle extends CssStyle {
};
CardPicoTitleStyle.style = {
    fontSize: ".8em",
    color: "#a1a1a1",
    // marginTop: "4px"
};
CardPicoTitleStyle = __decorate([
    style("cardpicotitle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], CardPicoTitleStyle);
let CardPicoValueStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CardPicoValueStyle extends CssStyle {
};
CardPicoValueStyle.style = {
    fontSize: ".8em",
    fontWeight: 600,
    color: "#000000",
};
CardPicoValueStyle = __decorate([
    style("cardpicovalue")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], CardPicoValueStyle);
let CardNanoValueSStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CardNanoValueSStyle extends CssStyle {
};
CardNanoValueSStyle.style = {
    fontSize: ".8em",
    color: "#000000",
};
CardNanoValueSStyle = __decorate([
    style("cardnanovalue_s")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], CardNanoValueSStyle);
let OrderItemStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class OrderItemStyle extends CssStyle {
};
OrderItemStyle.style = {
    borderRadius: "3px",
    padding: "6px 13px",
    backgroundColor: "#fefefe",
    border: "1px solid #e8e8e8",
    "&:hover": {
        // backgroundColor: "#e8a631",
        // color: "#ffffff",
        border: "1px solid #e8a631",
    },
    outline: "none",
};
OrderItemStyle = __decorate([
    style("orderitemstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], OrderItemStyle);
let RowWrapStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowWrapStyle extends CssStyle {
};
RowWrapStyle.style = {
    position: "relative",
    border: "1px solid #dddddd",
    "&:hover": {
        backgroundColor: "#f8b63101",
    },
    "&:focus": {
        backgroundColor: "#fefefe",
        boxShadow: "4px 4px 7px #eeeeff",
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    height: "100%",
    backgroundColor: "#f3f3f3",
    color: "#e8a631",
    fontWeight: 600,
    fontSize: "1.1em",
    display: "flex",
};
RowWrapStyle = __decorate([
    style("rowwrap")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowWrapStyle);
let SearchBtnStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SearchBtnStyle extends CssStyle {
};
SearchBtnStyle.style = {
    // padding: '0px 0px',
    alignSelf: "center",
    padding: "0px .5rem",
};
SearchBtnStyle = __decorate([
    style("searchbtn")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SearchBtnStyle);
let InputWrapStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputWrapStyle extends CssStyle {
};
InputWrapStyle.style = {
    // padding: '0px 0px',
    flex: "1 1 100%",
};
InputWrapStyle = __decorate([
    style("inputwrap")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputWrapStyle);
let CheckboxStyle = class CheckboxStyle extends CssStyle {
};
CheckboxStyle.style = {
    marginLeft: 10,
    padding: '30px',
    "&:checked": {
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")`
    },
    // "&:checked:after": {
    // width:"10px"
    // backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")`
    // }
};
CheckboxStyle = __decorate([
    style("checkboxstyle")
], CheckboxStyle);
let OptionStyle = class OptionStyle {
};
OptionStyle.style = {
    padding: ".6em",
    margin: "0em",
    ":hover": {
        backgroundColor: "#FFE7B6",
    },
};
OptionStyle = __decorate([
    style("optionstyle")
], OptionStyle);
let TagStyle = class TagStyle extends CssStyle {
};
TagStyle.style = {
    borderRadius: "23px",
    backgroundColor: "#CBE7FC",
    padding: "6px 8px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 10
};
TagStyle = __decorate([
    style("tagstyle")
], TagStyle);
let TagBtnStyle = class TagBtnStyle extends CssStyle {
};
TagBtnStyle.style = {
    borderRadius: "50%",
    backgroundColor: "#045998",
    width: 24,
    height: 24,
    border: "0px",
    color: 'white'
};
TagBtnStyle = __decorate([
    style("tagbtnstyle")
], TagBtnStyle);
let SellerRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SellerRowStyle extends CssStyle {
};
SellerRowStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: ".5em",
    padding: "1.5em 1.2em",
};
SellerRowStyle = __decorate([
    style("sellerrowstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SellerRowStyle);
let TableStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TableStyle extends CssStyle {
};
TableStyle.style = {
    width: "100%",
    margin: ".5em",
    padding: "1.5em 1.5em",
    lineHeight: "2.5em",
    borderColor: "#e9e9ef",
    borderImageWidth: 1,
    borderWidth: 1,
    td: {
        "text-align": "center",
    },
    tr: {
        borderImageWidth: 1,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#e9e9ef"
    }
};
TableStyle = __decorate([
    style("tablestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TableStyle);
let StoreTableStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class StoreTableStyle extends CssStyle {
};
StoreTableStyle.style = {
    width: "100%",
    margin: ".5em",
    padding: "1.5em 1.5em",
    lineHeight: "2.5em",
    borderColor: "#e9e9ef",
    borderImageWidth: 1,
    borderWidth: 1,
    td: {
        "text-align": "left",
    },
    th: {
        "text-align": "left",
        "line-height": "1.2em"
    },
    tr: {
        borderImageWidth: 1,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#e9e9ef"
    }
};
StoreTableStyle = __decorate([
    style("storetablestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], StoreTableStyle);
let BigTableStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class BigTableStyle extends CssStyle {
};
BigTableStyle.style = {
    width: "100%",
    margin: ".5em",
    padding: "1.5em 1.5em",
    lineHeight: "2.5em",
    borderColor: "#e9e9ef",
    borderImageWidth: 1,
    borderWidth: 1,
    td: {
        "text-align": "left",
        padding: ".9375rem .625rem",
        fontSize: ".76rem"
    },
    th: {
        "text-align": "left",
        lineHeight: "1.5em",
        padding: ".8rem .625rem",
        "text-transform": "uppercase",
    },
    tr: {
        borderImageWidth: 1,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#e9e9ef"
    }
};
BigTableStyle = __decorate([
    style("bigtable")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], BigTableStyle);
let StoreContStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class StoreContStyle {
};
StoreContStyle.style = {
    backgroundColor: "white",
};
StoreContStyle = __decorate([
    style("storecontstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], StoreContStyle);
let StoreItemStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class StoreItemStyle {
};
StoreItemStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1.2em",
    padding: "2em",
};
StoreItemStyle = __decorate([
    style("storeitemtyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], StoreItemStyle);
let StoreHeaderStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class StoreHeaderStyle {
};
StoreHeaderStyle.style = {};
StoreHeaderStyle = __decorate([
    style("storeheaderstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], StoreHeaderStyle);
let ColFixedStyle = class ColFixedStyle extends CssStyle {
};
ColFixedStyle.style = {
    maxWidth: "24px",
    height: "24px",
    width: "24px",
    borderRadius: "4px",
    display: "block",
    position: "relative"
    // flex: "1 0 30px",
    // justifySelf: "start",
};
ColFixedStyle = __decorate([
    style("colfixedstyle")
], ColFixedStyle);
let ColDynamicStyle = class ColDynamicStyle {
};
ColDynamicStyle.style = {
    padding: ".3em 1em",
    // justifySelf: "start",
    // alignSelf: "center",
    fontSize: ".8em",
    fontWeight: 600,
};
ColDynamicStyle = __decorate([
    style("coldynamicstyle")
], ColDynamicStyle);
let ColorStyle = class ColorStyle {
};
ColorStyle.style = {
    padding: ".5em",
    border: "1px solid #efefef",
    margin: "10px",
    borderRadius: "4px",
    // display:"flex",
    // flexDirection:"column",
    // justifyContent:"start"
};
ColorStyle = __decorate([
    style("colorstyle")
], ColorStyle);
let BadgeStyle = class BadgeStyle {
};
BadgeStyle.style = {
    padding: "0em .5em",
    border: "1px solid #232323",
    margin: "0px",
    fontSize: ".9em",
    borderRadius: "15px",
    textAlign: "center",
    lineHeight: "2.1em"
    // display:"flex",
    // flexDirection:"column",
    // justifyContent:"start"
};
BadgeStyle = __decorate([
    style("badgestyle")
], BadgeStyle);
let ActionButtonPositiveStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ActionButtonPositiveStyle extends CssStyle {
};
ActionButtonPositiveStyle.style = {
    position: "relative",
    outline: 'none',
    border: "0",
    margin: 10,
    '&:hover': {
        backgroundColor: '#209F82'
    },
    '&:focus': {
        backgroundColor: '#209F82'
    },
    padding: ".1em 1em",
    borderRadius: ".2em",
    color: "white",
    backgroundColor: '#17B28E',
    boxShadow: "1px 1px 10px #17B28E",
};
ActionButtonPositiveStyle = __decorate([
    style("btnpositive")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ActionButtonPositiveStyle);
let ActionButtonNegativeStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ActionButtonNegativeStyle extends CssStyle {
};
ActionButtonNegativeStyle.style = {
    position: "relative",
    outline: 'none',
    border: "0",
    margin: 10,
    '&:hover': {
        backgroundColor: '#D9435B'
    },
    '&:focus': {
        backgroundColor: '#D9435B'
    },
    padding: ".1em 1em",
    borderRadius: ".3em",
    color: "white",
    backgroundColor: '#FF5C76',
    boxShadow: "1px 1px 10px #FF5C76",
};
ActionButtonNegativeStyle = __decorate([
    style("btnnegative")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ActionButtonNegativeStyle);
export default container;
