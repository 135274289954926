var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
const { style, container } = registry("global", true);
let SwitchCheckboxStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SwitchCheckboxStyle extends CssStyle {
};
SwitchCheckboxStyle.style = {
    height: 0,
    width: 0,
    visibility: "hidden",
    "&:checked + .switchlabel .switchbutton": {
        left: "calc(100% - 2px)",
        transform: "translateX(-100%)",
    }
};
SwitchCheckboxStyle = __decorate([
    style("switcheckboxstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SwitchCheckboxStyle);
let SwitchLabelStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SwitchLabelStyle extends CssStyle {
};
SwitchLabelStyle.style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "70px",
    height: '35px',
    background: "grey",
    borderRadius: "100px",
    position: "relative",
    transition: "background-color .4s"
};
SwitchLabelStyle = __decorate([
    style("switchlabelstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SwitchLabelStyle);
let SwitchButtonStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SwitchButtonStyle extends CssStyle {
};
SwitchButtonStyle.style = {
    // content: '""',
    position: "absolute",
    top: "2px",
    left: "2px",
    width: "31px",
    height: "31px",
    borderRadius: "45px",
    transition: "0.2s",
    background: "#fff",
    boxShadow: "0 0 2px 0 rgba(10, 10, 10, 0.29)"
};
SwitchButtonStyle = __decorate([
    style("switchbuttonstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SwitchButtonStyle);
export default container;
