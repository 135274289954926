import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactSlidingPane from "react-sliding-pane";
import { useProgressBar } from "../../../components/ProgressBar";
import { StreamNav } from "../../../components/StreamNav";
import { Table } from "../../../components/Table/table";
import { useStream } from "../../../saga/use-stream";
import { useAuth } from "../../../use-auth";
import { localDatetime } from "../../../utils";
import { EditRefund } from "./create-refund";
export const RefundComponent = (props) => {
    const { refund, refund_amount, cancellation_id, onFinishEdit } = props;
    const [isShowCreateRefund, setShowCreateRefund] = useState(false);
    const [isShowEditRefund, setShowEditRefund] = useState(false);
    const { btn_small } = useStyles("btn_small");
    const onFinish = () => {
        setShowCreateRefund(false);
        setShowEditRefund(false);
        onFinishEdit();
    };
    return _jsxs(_Fragment, { children: [" ", refund ?
                _jsxs(_Fragment, { children: [_jsx("td", { children: refund.payment_reference }, void 0), _jsx("td", { children: refund.refund_type }, void 0), _jsx("td", { children: refund.status }, void 0), _jsx("td", { children: _jsx("button", Object.assign({ css: btn_small, onClick: () => { setShowEditRefund(true); } }, { children: "Edit" }), void 0) }, void 0)] }, void 0) :
                _jsxs(_Fragment, { children: [_jsx("td", {}, void 0), _jsx("td", {}, void 0), _jsx("td", {}, void 0), _jsx("td", { children: _jsx("button", Object.assign({ css: btn_small, onClick: () => { setShowCreateRefund(true); } }, { children: " Refund" }), void 0) }, void 0)] }, void 0), isShowCreateRefund ?
                _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isShowCreateRefund, title: "Add Refund", subtitle: "", width: "40%", onRequestClose: () => {
                        setShowCreateRefund(false);
                    } }, { children: _jsx(EditRefund, { mode: "create", refund_amount: refund_amount, cancellation_id: cancellation_id, onFinishEdit: onFinish }, void 0) }), void 0) : null, isShowEditRefund ?
                _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isShowEditRefund, title: "Edit Refund", subtitle: "", width: "40%", onRequestClose: () => {
                        setShowEditRefund(false);
                    } }, { children: _jsx(EditRefund, { onFinishEdit: onFinish, mode: "edit", refund: refund, refund_amount: refund_amount, cancellation_id: cancellation_id }, void 0) }), void 0) : null] }, void 0);
};
export const CancellationComponent = (props) => {
    const { data: cancellation, itemOnClick } = props;
    return _jsxs(_Fragment, { children: [_jsx("td", { children: _jsx("b", { children: localDatetime(cancellation.cancellation_date) }, void 0) }, void 0), _jsx("td", { children: _jsx("b", { children: _jsxs(NavLink, Object.assign({ to: `/dashboard/orders/${cancellation.order.id}/details` }, { children: [cancellation.order.order_id, " "] }), void 0) }, void 0) }, void 0), _jsx("td", { children: cancellation.cancellation_type }, void 0), _jsx("td", { children: cancellation.cancelled_total_amount }, void 0), _jsx("td", { children: cancellation.cancelled_shipping_amount }, void 0), _jsx("td", { children: cancellation.cancelled_net_amount }, void 0), _jsx("td", { children: cancellation.order.payment_mode }, void 0), _jsx(RefundComponent, { refund: cancellation.refund, onFinishEdit: () => itemOnClick === null || itemOnClick === void 0 ? void 0 : itemOnClick(), refund_amount: cancellation.refund_amount, cancellation_id: cancellation.id }, void 0)] }, void 0);
};
export const CancellationList = (props) => {
    // const [data, setData] = useState<OrderCancellation[]>([])
    // useGet(`/trendutube/v1/admin-order-processing/cancellations-2/list`, {}, (data) => {
    //     setData(data)
    // }, (e) => {
    //     toast.error(e)
    // })
    const { bigtable } = useStyles("bigtable");
    const setProgress = useProgressBar();
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { routerProps, path } = props;
    const queryParams = { status: "buyer-cod-order-confirmed" };
    const { page: pageStr } = routerProps.match.params;
    const page = parseInt(`${pageStr}`);
    //   const { data, loadUrl, isFetching } = useSaga<OrderCancellation[]>(`/trendutube/v1/admin-order-processing/cancellations-2/list`, { headers }, false)
    //     useEffect(()=>{
    //         setProgress(isFetching)
    //     },[isFetching])  
    const { status, stream, isFetching, data, loadPage, loadNextPage, loadUrl } = useStream("/trendutube/v1/admin-order-processing/cancellations-2/list", { page });
    const { productstyle } = useStyles("productstyle");
    const [maxPage, setMaxPage] = useState(page);
    const setLoading = useProgressBar();
    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (stream && (stream.page > maxPage))
            setMaxPage(stream.page);
    }, [stream]);
    const header = {
        id: "Order Id",
        cancellation_date: "Date",
        cancellation_type: "Type",
        cancelled_total_amount: "Cancelled Amount",
        cancelled_shipping_amount: "Cancelled Shipping Amount",
        cancelled_net_amount: "Refundable Amount",
        payment_reference: "Payment Reference",
        refund_type: "Refund Mode",
        refund_status: "Refund Status",
        payment_mode: "Payment Mode"
    };
    const keys = [
        "id",
        "cancellation_date",
        "cancellation_type",
        "cancelled_total_amount",
        "cancelled_shipping_amount",
        "cancelled_net_amount",
        "payment_mode",
        "payment_reference",
        "refund_type",
        "refund_status"
    ];
    return _jsxs("div", { children: [_jsx(StreamNav, Object.assign({}, {
                loadNextPage, loadPage, stream, maxPage,
                getUrl: (page) => `${path}/orders/cancellations-2/${page}`
            }), void 0), data ? _jsx("div", Object.assign({ css: productstyle }, { children: _jsx(Table, { style: bigtable, body: data, header: header, keys: keys, component: CancellationComponent, itemOnClick: (item) => {
                        console.log("PAGERTT", page);
                        loadPage(page);
                    } }, void 0) }), void 0) : null] }, void 0);
};
