import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { Field } from "./field";
import { InputDate, InputDiscountEx, InputEx, InputNewEx } from "./fields-extended";
export const LabelledField = (props) => {
    const { name, label, value } = props;
    const { field_container, field_label } = useStyles("field_container", "field_label");
    return _jsxs("div", Object.assign({ css: field_container }, { children: [_jsx("div", Object.assign({ css: field_label }, { children: label }), void 0), _jsx(Field, Object.assign({ name: name, value: value }, { children: _jsx(InputEx, {}, void 0) }), void 0)] }), void 0);
};
export const LabelledDiscountField = (props) => {
    const { name, label, value } = props;
    const { field_container, field_label } = useStyles("field_container", "field_label");
    return _jsxs("div", { children: [_jsx("div", Object.assign({ style: { marginBottom: '1rem', color: '#000000', fontSize: '13px', marginTop: '1rem' } }, { children: label }), void 0), _jsx(Field, Object.assign({ name: name, value: value }, { children: _jsx(InputDiscountEx, {}, void 0) }), void 0)] }, void 0);
};
export const LabelledNewField = (props) => {
    const { name, label, value } = props;
    const { field_container, field_label } = useStyles("field_container", "field_label");
    return _jsxs("div", Object.assign({ style: { padding: '10px' } }, { children: [_jsx("div", Object.assign({ style: { color: 'gray' } }, { children: label }), void 0), _jsx(Field, Object.assign({ name: name, value: value }, { children: _jsx(InputNewEx, {}, void 0) }), void 0)] }), void 0);
};
export const LabelledDateField = (props) => {
    const { name, label, value } = props;
    const { field_container, field_label } = useStyles("field_container", "field_label");
    return _jsxs("div", Object.assign({ css: field_container }, { children: [_jsx("div", Object.assign({ css: field_label }, { children: label }), void 0), _jsx(Field, Object.assign({ name: name, value: value }, { children: _jsx(InputDate, {}, void 0) }), void 0)] }), void 0);
};
