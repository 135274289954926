import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react  */
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useRestSubmit } from "../../../saga/use-rest-submit";
import { useAuth } from "../../../use-auth";
import container from './admin-style';
import { Box, Button, Dialog, FormControl, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { usePagination } from "../../../saga/use-pagination";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FilterListIcon from '@mui/icons-material/FilterList';
import { discountType, formatTime } from "../../../utils";
const isDiscountForType = (discount_for, dtype) => {
    return discount_for.some(d => d["dgraph.type"].some(item => item == dtype));
};
const DiscountComponent = (props) => {
    const { data, itemOnClick } = props;
    const { uid, discount_code, discount_name, discount_type, discount_value, enable_flag, effective_date, expiry_date, discount_for = [], max_apply_count_per_person } = data || {};
    const doEnable = useRestSubmit(() => {
        itemOnClick === null || itemOnClick === void 0 ? void 0 : itemOnClick();
        if (enable_flag) {
            toast.success("Discount Disabled");
        }
        else {
            toast.success("Discount Enabled");
        }
    });
    const onEnableChange = (event) => {
        doEnable(`/trendutube/v1/admin-offers-management/discounts/${uid}/update-status?enable=${event.target.checked}`, {});
    };
    return _jsxs(TableRow, Object.assign({ sx: { '& td, & th': { paddingLeft: '2%' } } }, { children: [_jsx(TableCell, { children: discount_name }, void 0), _jsx(TableCell, { children: discount_code }, void 0), _jsx(TableCell, { children: discountType(discount_type) }, void 0), _jsx(TableCell, { children: discount_value }, void 0), _jsx(TableCell, { children: formatTime(effective_date) }, void 0), _jsx(TableCell, { children: formatTime(expiry_date) }, void 0), _jsx(TableCell, { children: enable_flag ? _jsx(Switch, { checked: enable_flag, onChange: onEnableChange, inputProps: { 'aria-label': 'controlled' } }, void 0) : _jsx(Switch, { checked: enable_flag, onChange: onEnableChange, inputProps: { 'aria-label': 'controlled' } }, void 0) }, void 0), _jsx(TableCell, { children: _jsx(Button, Object.assign({ variant: "text", sx: { color: '#045998', textTransform: 'none', paddingRight: '5px' } }, { children: _jsx(NavLink, Object.assign({ to: `/dashboard/admin-discount/edit/${uid}` }, { children: "Edit" }), void 0) }), void 0) }, void 0)] }), uid);
};
export const AdminDiscountListInternal = (props) => {
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { panelstyle, storetablestyle } = useStyles("panelstyle", "storetablestyle");
    const history = useHistory();
    const { data, loadUrl } = usePagination(`/trendutube/v1/admin-offers-management/discounts/list`, { headers });
    const header = {
        discount_name: "Discount Name",
        discount_code: "Discount Code",
        discount_type: "Discount Type",
        discount_value: "Discount Value",
        effective_date: "Effective Date",
        expiry_date: "Expiry Date",
        enable_flag: "Enabled ?"
    };
    const keys = [
        "discount_name",
        "discount_code",
        "discount_type",
        "discount_value",
        "effective_date",
        "expiry_date",
        "enable_flag"
    ];
    const handleAddGlobalDiscount = () => {
        history.push(`/dashboard/stores/discount/create?type=org`);
    };
    const handleGlobalStoreDiscount = () => {
        history.push(`/dashboard/stores/discount/create`);
    };
    const [dealType, setDealType] = useState("all");
    const handleChange = (event) => {
        setDealType(event.target.value);
        const queryParams = { page_size: 10, dealType: event.target.value, date: value && dayjs(value).isValid() ? value.format('YYYY-MM-DD') : '' };
        loadUrl(`/trendutube/v1/admin-offers-management/discounts/list`, 0, queryParams);
    };
    const reload = () => {
        const queryParams = { page_size: 10, dealType: dealType, date: value && dayjs(value).isValid() ? value.format('YYYY-MM-DD') : '' };
        loadUrl(`/trendutube/v1/admin-offers-management/discounts/list`, 0, queryParams);
    };
    const handleDateChange = (newValue) => {
        if (newValue !== null) {
            setValue(newValue);
            const queryParams = { page_size: 10, dealType: dealType, date: newValue.format('YYYY-MM-DD') };
            loadUrl(`/trendutube/v1/admin-offers-management/discounts/list`, 0, queryParams);
        }
    };
    const [value, setValue] = useState(dayjs(null));
    const [filterPopup, setFilterPopup] = useState(false);
    const handleClose = () => {
        setFilterPopup(false);
    };
    return _jsxs("div", Object.assign({ css: panelstyle }, { children: [_jsxs("div", Object.assign({ css: { display: "flex", marginBottom: 40 } }, { children: [_jsx("div", Object.assign({ css: {
                            padding: "14px 18px",
                            borderRadius: 5,
                            fontSize: 16,
                            fontWeight: 600,
                            justifyContent: 'left',
                            color: "Black",
                        } }, { children: "Admin Discounts" }), void 0), _jsxs("div", Object.assign({ style: {
                            display: "flex",
                            marginLeft: "auto",
                            gap: "8px",
                        } }, { children: [_jsx("button", Object.assign({ onClick: handleAddGlobalDiscount, css: {
                                    marginTop: 12,
                                    padding: "8px 20px",
                                    border: "0",
                                    borderRadius: "4px",
                                    backgroundColor: "#045998",
                                    color: "#fff",
                                    fontWeight: 500,
                                } }, { children: "Add Global Discounts" }), void 0), _jsx("button", Object.assign({ onClick: handleGlobalStoreDiscount, css: {
                                    marginTop: 12,
                                    padding: "8px 20px",
                                    border: "0",
                                    borderRadius: "4px",
                                    backgroundColor: "#045998",
                                    fontWeight: 500,
                                    color: "#fff",
                                } }, { children: "Add Discount for Stores (Area)" }), void 0), _jsx("button", Object.assign({ onClick: () => setFilterPopup(true), css: {
                                    marginTop: 12,
                                    padding: "1px 1px",
                                    border: "0",
                                    borderRadius: "4px",
                                    backgroundColor: "#045998",
                                    fontWeight: 500,
                                    color: "#fff",
                                } }, { children: _jsx(FilterListIcon, {}, void 0) }), void 0)] }), void 0), _jsx(Dialog, Object.assign({ onClose: handleClose, open: filterPopup, sx: { padding: '30px' } }, { children: _jsxs(Box, Object.assign({ sx: {
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                padding: '20px',
                            } }, { children: [_jsxs(FormControl, Object.assign({ sx: { width: '100%' } }, { children: [_jsx("label", Object.assign({ htmlFor: "deal-type", style: { marginBottom: '8px', fontSize: '14px', color: '#333' } }, { children: "Discount Type" }), void 0), _jsxs(Select, Object.assign({ id: "deal-type", value: dealType, onChange: handleChange, sx: { width: '100%' } }, { children: [_jsx(MenuItem, Object.assign({ value: "all" }, { children: "All" }), void 0), _jsx(MenuItem, Object.assign({ value: "flash-deals" }, { children: "Flash Deals" }), void 0), _jsx(MenuItem, Object.assign({ value: "today-deals" }, { children: "Today Deals" }), void 0)] }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { width: '100%' } }, { children: [_jsx("label", Object.assign({ htmlFor: "date-picker", style: { marginBottom: '8px', fontSize: '14px', color: '#333' } }, { children: "Date" }), void 0), _jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: value, onChange: (newValue) => handleDateChange(newValue), sx: { width: '100%' } }, void 0) }), void 0)] }), void 0), _jsx(Box, Object.assign({ sx: {
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: '10px',
                                        marginTop: '20px',
                                    } }, { children: _jsx("button", Object.assign({ onClick: handleClose, style: {
                                            padding: '10px 20px',
                                            borderRadius: '5px',
                                            border: '1px solid #ccc',
                                            backgroundColor: '#045998',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                        } }, { children: "Close" }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), data ?
                _jsx(TableContainer, Object.assign({ component: Paper }, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, Object.assign({ sx: { '& th': { fontWeight: 'bold', color: '#000000', fontSize: '15px' } } }, { children: [_jsx(TableCell, { children: "Discount Name" }, void 0), _jsx(TableCell, { children: "Discount Code" }, void 0), _jsx(TableCell, { children: "Discount Type" }, void 0), _jsx(TableCell, { children: "Discount Value" }, void 0), _jsx(TableCell, { children: "Effective Date" }, void 0), _jsx(TableCell, { children: "Expiry Date" }, void 0), _jsx(TableCell, { children: "Enabled" }, void 0)] }), void 0) }, void 0), _jsx(TableBody, { children: data && data.map((discount, index) => (_jsx(DiscountComponent, { data: discount, itemOnClick: () => {
                                        reload();
                                    } }, void 0))) }, void 0)] }, void 0) }), void 0)
                : null] }), void 0);
};
export const AdminDiscountList = (props) => _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(AdminDiscountListInternal, Object.assign({}, props), void 0) }), void 0);
