import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { Col } from "../../components/Column";
import { Row } from "../../components/Row";
import { useSaga } from "../../saga/use-saga";
import { CardNano } from "../orders/card-nano";
import { AddressComponent } from "../views/address";
import { MapView } from "./map";
export const DistributionCenterItem = (props) => {
    const { distributionCenter, onSelect } = props;
    const { uid, distribution_center_name, distribution_center_code, distribution_center_address } = distributionCenter;
    const { button } = useStyles("button");
    return _jsxs("div", Object.assign({ css: { padding: 20 } }, { children: [_jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { title: "Distribution Name", value: distribution_center_name }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "Distribution Code", value: distribution_center_code }, void 0) }, void 0)] }, void 0), _jsxs(Row, { children: [_jsx(Col, { children: _jsx(AddressComponent, { multiline: true, address: distribution_center_address }, void 0) }, void 0), _jsx(Col, { children: _jsx("div", Object.assign({ css: { display: "grid", justifyItems: "end" } }, { children: _jsx("button", Object.assign({ css: button, onClick: () => onSelect(distributionCenter) }, { children: "Select" }), void 0) }), void 0) }, void 0)] }, void 0)] }), void 0);
};
export const DistributionCentre = (props) => {
    const { lngLat, onSelect } = props;
    const [distList, setDistList] = useState([]);
    const [ln, lt] = lngLat;
    const initialLngLat = lngLat.join(",");
    const [km, setKm] = useState(30.00);
    const [coordinates, setCoordinates] = useState(initialLngLat);
    const { data, loadUrl } = useSaga(`/trendutube/v1/franchisee-management/distribution-centers/list?coordinates=${initialLngLat}&radius=30000`, {}, false);
    const onCenterChange = (coordinates) => {
        const lngLatStr = (coordinates || []).reverse().join(",");
        setCoordinates(lngLatStr);
    };
    useEffect(() => {
        const radius = km * 1000;
        loadUrl(`/trendutube/v1/franchisee-management/distribution-centers/list?coordinates=${coordinates}&radius=${radius}`);
    }, [coordinates, km]);
    useEffect(() => {
        if (data) {
            const { distribution_centers } = data;
            setDistList(distribution_centers);
        }
    }, [data]);
    function onChange(e) {
        setKm(parseFloat(e.target.value) || 0);
    }
    return _jsxs("div", Object.assign({ css: { height: '80vh' } }, { children: [_jsxs(Row, { children: [_jsx(Col, { children: " Distance " }, void 0), _jsxs(Col, { children: [" ", _jsxs("div", Object.assign({ css: { padding: 10 } }, { children: [_jsx("input", { value: km, onChange: onChange }, void 0), "Km"] }), void 0)] }, void 0), _jsx(Col, {}, void 0)] }, void 0), _jsxs(Row, { children: [_jsx(Col, Object.assign({ top: true }, { children: _jsx(MapView, { onCenterChange: onCenterChange, latLng: [lt, ln] }, void 0) }), void 0), _jsx(Col, Object.assign({ top: true }, { children: _jsx("div", Object.assign({ css: { height: "80vh", overflowY: "scroll" } }, { children: distList ? distList.map(dist => _jsx(DistributionCenterItem, { distributionCenter: dist, onSelect: onSelect }, void 0)) : null }), void 0) }), void 0)] }, void 0)] }), void 0);
};
