var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { StyleableComponent, StyleContext, StyleProvider, styling } from '@exprzn/react-style-lib';
import thisContainer from './style';
export class RowInternal extends StyleableComponent {
    render() {
        const { rowstyle } = this;
        const { style } = this.props;
        return _jsx("div", Object.assign({ css: [rowstyle, style] }, { children: this.props.children }), void 0);
    }
}
__decorate([
    styling("rowflexstyle"),
    __metadata("design:type", Object)
], RowInternal.prototype, "rowstyle", void 0);
export const Row = (props) => {
    const { container } = useContext(StyleContext);
    container === null || container === void 0 ? void 0 : container.merge(thisContainer);
    return _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(RowInternal, Object.assign({}, props, { children: props.children }), void 0) }), void 0);
};
