import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Col } from "../../components/Column";
import { Row } from "../../components/Row";
import { Tray } from "../../components/Tray";
import { useSaga } from "../../saga/use-saga";
import { MapView } from "../distribution-center/map";
import { UserList } from "../users/list/users-list";
function filterBuyers(loginUserList) {
    const listUser = [];
    loginUserList.forEach(user => {
        const [subject] = user.subjects || [];
        const buyer_user = subject.user_ids.find(id => {
            const isBuyer = (id.applications || []).some(a => a.app_type.some(b => b == "BUYER"));
            return isBuyer;
        });
        if (buyer_user != null) {
            const app = ((buyer_user === null || buyer_user === void 0 ? void 0 : buyer_user.applications) || []).find(a => a.app_type.some(b => b == "BUYER"));
            const user_uid = app === null || app === void 0 ? void 0 : app.app_uid;
            const [subject] = user.subjects || [];
            const username = user.username;
            listUser.push({ user_uid, subject, username });
        }
    });
    return listUser;
}
export const UserListGeo = (props) => {
    const { lngLat, onSelect, selected } = props;
    const [userList, setUserList] = useState([]);
    const [ln, lt] = lngLat;
    const [ltLn, setLtLg] = useState([lt, ln]);
    const [selectedUids, setSelectedUids] = useState(selected || []);
    const initialLngLat = lngLat.join(",");
    const [km, setKm] = useState(30.00);
    const [coordinates, setCoordinates] = useState(initialLngLat);
    const ref = useRef(null);
    const [show, setshow] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [places, setPredictions] = useState();
    const [placesService, setPlacesService] = useState();
    const mRef = useRef(null);
    const { storetablestyle, action_btn_ms } = useStyles("storetablestyle", "action_btn_ms");
    const autoCompleteService = new google.maps.places.AutocompleteService();
    useLayoutEffect(() => {
        if (ref.current != null) {
            const service = new google.maps.places.PlacesService(ref.current);
            setPlacesService(service);
        }
    }, []);
    const { data, loadUrl } = useSaga(`/trendutube/v1/auth/users/list?coordinates=${coordinates}&search_radius=${km * 1000}&app_type=BUYER&page_size=5000&page=1`, {}, false);
    const onCenterChange = (coordinates) => {
        const lngLatStr = (coordinates || []).reverse().join(",");
        setCoordinates(lngLatStr);
    };
    useEffect(() => {
        const radius = km * 1000;
        loadUrl(`/trendutube/v1/auth/users/list?coordinates=${coordinates}&search_radius=${radius}&app_type=BUYER&page_size=5000&page=1`);
        console.log("coordinates", coordinates);
    }, [coordinates, km]);
    useEffect(() => {
        console.log("data", data);
        if (data) {
            setUserList(data);
        }
        else {
            setUserList([]);
        }
    }, [data]);
    function onRadiusChange(e) {
        setKm(parseFloat(e.target.value) || 0);
    }
    const onPlaceChange = (ev) => {
        if (!ev.target.value || ev.target.value == "") {
            setshow(false);
            return;
        }
        autoCompleteService.getQueryPredictions({ input: ev.target.value }, displaySuggestions);
    };
    const displaySuggestions = function (predictions, status) {
        if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
            alert(status);
            return;
        }
        console.log("predictions", predictions);
        setPredictions(predictions);
        if (predictions.length > 0) {
            setshow(true);
        }
    };
    const onPlaceSelected = (item) => {
        placesService === null || placesService === void 0 ? void 0 : placesService.getDetails({ placeId: item.place_id, fields: ["geometry"] }, (place) => {
            var _a, _b, _c, _d, _e;
            const lat = (_b = (_a = place === null || place === void 0 ? void 0 : place.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lat();
            const lng = (_d = (_c = place === null || place === void 0 ? void 0 : place.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.lng();
            if (lat != null && lng != null) {
                // setLtLg([lat, lng])
                // alert(lat)
                (_e = mRef.current) === null || _e === void 0 ? void 0 : _e.setLatLng(lat, lng);
                setCoordinates(`${lng},${lat}`);
            }
        });
    };
    const onCheckChange = (uid) => {
        return (event) => {
            const index = selectedUids.findIndex(i => i == uid);
            if (event.target.checked) {
                if (index < 0)
                    setSelectedUids([...selectedUids, uid]);
            }
            else {
                if (index >= 0) {
                    selectedUids.splice(index, 1);
                    setSelectedUids([...selectedUids]);
                }
            }
        };
    };
    const selectOrDeSelectAll = (event) => {
        if (event.target.checked) {
            const allUserUids = filterBuyers(userList).map(i => i.user_uid);
            setSelectedUids(allUserUids);
        }
        else
            setSelectedUids([]);
    };
    const goBack = () => {
        const selectedUsers = filteredUsers.filter(user => selectedUids.some(user_uid => user_uid == user.user_uid));
        onSelect(selectedUids, selectedUsers);
    };
    useEffect(() => {
        setFilteredUsers(filterBuyers(userList));
    }, [userList]);
    return _jsxs("div", Object.assign({ css: { height: '80vh' } }, { children: [_jsx("div", { ref: ref }, void 0), _jsxs(Row, { children: [_jsxs(Col, Object.assign({ top: true }, { children: [_jsxs(Row, { children: [_jsx(Col, { children: " Distance " }, void 0), _jsxs(Col, { children: [" ", _jsxs("div", Object.assign({ css: { padding: 6 } }, { children: [_jsx("input", { css: { marginRight: 5, padding: 2, textAlign: "end" }, value: km, onChange: onRadiusChange }, void 0), "Km"] }), void 0)] }, void 0), _jsx(Col, {}, void 0)] }, void 0), _jsx(Row, { children: _jsx("input", { css: { width: "100%", marginBottom: 4, padding: 2 }, onChange: onPlaceChange }, void 0) }, void 0), _jsx(Row, { children: show && places ? _jsx(Tray, { items: places, titleProperty: "description", keyProperty: "place_id", width: "100%", onSelectItem: onPlaceSelected, view: (place) => _jsx("div", Object.assign({ css: { paddingLeft: 6, paddingRight: 6 } }, { children: place.item.description }), void 0) }, void 0) : null }, void 0), ltLn ? _jsx(MapView, { onCenterChange: onCenterChange, latLng: ltLn, ref: mRef }, void 0) : null] }), void 0), _jsxs(Col, Object.assign({ top: true }, { children: [_jsx(Row, { children: _jsx(Col, Object.assign({ right: true }, { children: _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: _jsxs("button", Object.assign({ css: action_btn_ms, onClick: goBack }, { children: [" ", selectedUids.length > 0 ? "Select" : "Go Back", " "] }), void 0) }), void 0) }), void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: { height: "80vh", overflowY: "scroll", paddingLeft: 20 } }, { children: (data || coordinates) && (userList != null && userList.length > 0) ?
                                        _jsxs("table", Object.assign({ css: storetablestyle }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: " Name" }, void 0), _jsx("th", { children: " Username" }, void 0), _jsxs("th", { children: [" ", _jsx("input", { type: "checkbox", onChange: selectOrDeSelectAll }, void 0)] }, void 0), _jsx("th", { children: "\u00A0\u00A0\u00A0\u00A0\u00A0" }, void 0)] }, void 0) }, void 0), _jsx("tbody", { children: filteredUsers.map(user => _jsxs("tr", { children: [_jsx(UserList, { data: user }, void 0), _jsxs("td", { children: [" ", _jsx("input", { type: "checkbox", onChange: onCheckChange(user.user_uid), checked: selectedUids.some(uid => uid == user.user_uid) }, void 0)] }, void 0)] }, void 0)) }, void 0)] }), void 0) : null }), void 0) }, void 0)] }), void 0)] }, void 0)] }), void 0);
};
