import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SagaProvider } from "../../../saga/saga-context";
import { StyleProvider } from "@exprzn/react-style-lib";
import container from "../style";
import { Box, Paper, styled, Typography } from "@mui/material";
import { useSaga } from "../../../saga/use-saga";
import { useAuth } from "../../../use-auth";
import { useEffect, useState } from "react";
import { useSubmit } from "../../../saga/use-submit";
import { toast } from "react-toastify";
import { formatCurrency, formatTime, paymentModeToHumanReadable, paymentStatusToHumanReadable } from "../../../utils";
import { OrderStatusView } from "../status-view";
import { BuyerComponent } from "../../views/buyer";
import { ShipmentItemInfoComponent } from "./shipment-info";
const textFieldProps = {
    sx: {
        backgroundColor: 'white',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Remove the border
        },
    },
};
const Orderinfo = (props) => {
    const { id } = props.match.params;
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { submit, error, status: logesAddingStatus } = useSubmit();
    const { submit: CourierInfoSubmit, error: courierInfoError, status: courierSubmitStatus } = useSubmit();
    const [open, setOpen] = useState(false);
    const [newNote, setNewNote] = useState('');
    const [courierInfo, setCourierInfo] = useState({ courier: null, tracking_number: null, source_address_uid: null });
    const { data: order, loadUrl: loadDeliveryModeUrl } = useSaga(`/trendutube/v1/admin-order-processing/orders/${id}/full-info`, { headers });
    const handleLoad = () => {
        loadDeliveryModeUrl(`/trendutube/v1/admin-order-processing/orders/${id}/full-info`);
    };
    useEffect(() => {
        if (logesAddingStatus === "ok") {
            loadDeliveryModeUrl(`/trendutube/v1/admin-order-processing/shipments/${id}`);
        }
    }, [logesAddingStatus]);
    useEffect(() => {
        if (courierSubmitStatus === "ok") {
            toast.success("Shipment Details Updated");
            loadDeliveryModeUrl(`/trendutube/v1/admin-order-processing/shipments/${id}`);
        }
    }, [courierSubmitStatus]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setNewNote('');
        setOpen(false);
    };
    const handleAddNote = () => {
        if (newNote != null && newNote.length > 0) {
            submit(`/trendutube/v1/admin-order-processing/shipments/${id}/loges-add`, { body: { notes: { log: newNote, type: "Inward-Shipment" } } });
            handleClose();
            setNewNote('');
        }
        else {
            toast.error("Please Provide Log Details");
        }
    };
    const handleInputChange = (event) => {
        setCourierInfo(Object.assign(Object.assign({}, courierInfo), { [event.target.name]: event.target.value }));
    };
    const Item = styled(Paper)(({ theme }) => (Object.assign(Object.assign(Object.assign({ backgroundColor: '#fff' }, theme.typography.body2), { padding: theme.spacing(1), textAlign: 'center', border: 'None', boxShadow: 'None', color: theme.palette.text.secondary }), theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }))));
    return (_jsx("div", { children: _jsx(Box, Object.assign({ sx: { backgroundColor: "white", padding: "25px" } }, { children: order ?
                _jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsx(Typography, Object.assign({ sx: { opacity: 0.5 } }, { children: "Order Id" }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 } }, { children: [_jsx(Typography, Object.assign({ sx: { fontWeight: 400, fontSize: '22px', color: '#000000' } }, { children: order.order_id }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center', mr: 2 } }, { children: [_jsx(Typography, Object.assign({ sx: { opacity: 0.5 } }, { children: "Updated Date:" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                                fontWeight: 400,
                                                                color: '#000000',
                                                                marginLeft: '10px',
                                                            } }, { children: formatTime(order.order_date) }), void 0)] }), void 0), _jsx(Box, Object.assign({ sx: {
                                                        backgroundColor: '#04599833',
                                                        padding: '4px 8px',
                                                        borderRadius: '4px',
                                                    } }, { children: _jsx(Typography, Object.assign({ sx: { fontWeight: 600, color: '#045998' } }, { children: OrderStatusView(order.status) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', flexDirection: 'column', border: '1px solid #CDDBE0', marginTop: '15px', padding: '15px', borderRadius: '10px' } }, { children: [_jsx(Typography, Object.assign({ sx: { fontWeight: 500 } }, { children: "Pricing" }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 } }, { children: [_jsxs(Item, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                                fontWeight: 500,
                                                                marginRight: '8px', // Space between label and amount
                                                            } }, { children: "Net Amount (with gst) :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                                color: '#0F8537'
                                                            } }, { children: formatCurrency(order.net_amount_with_tips) }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                                fontWeight: 500,
                                                                marginRight: '8px', // Space between label and amount
                                                            } }, { children: "Shipping Gst Amount :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                                color: '#000000'
                                                            } }, { children: formatCurrency(order.shipping_gst_amount) }), void 0)] }), void 0)] }, void 0), _jsxs(Item, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                                fontWeight: 500,
                                                                marginRight: '8px', // Space between label and amount
                                                            } }, { children: "Total Amount :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                                color: '#000000'
                                                            } }, { children: formatCurrency(order.total_amount) }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                                fontWeight: 500,
                                                                marginRight: '8px', // Space between label and amount
                                                            } }, { children: "Total Shipping Charge :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                                color: '#000000'
                                                            } }, { children: formatCurrency(order.total_shipping_charge) }), void 0)] }), void 0)] }, void 0), _jsxs(Item, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                                fontWeight: 500,
                                                                marginRight: '8px', // Space between label and amount
                                                            } }, { children: "Total Discount :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                                color: '#000000'
                                                            } }, { children: formatCurrency(order.total_discount_amount) }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                                fontWeight: 500,
                                                                marginRight: '8px', // Space between label and amount
                                                            } }, { children: "Tip Amount :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                                color: '#000000'
                                                            } }, { children: formatCurrency(order.tip_amount) }), void 0)] }), void 0)] }, void 0)] }), void 0), _jsx(Typography, Object.assign({ sx: { fontWeight: 500 } }, { children: "Payment Method " }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 } }, { children: [_jsx(Item, { children: _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                            fontWeight: 500,
                                                            marginRight: '8px', // Space between label and amount
                                                        } }, { children: "Payment Mode :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                            color: '#000000'
                                                        } }, { children: paymentModeToHumanReadable(order.payment_mode) }), void 0)] }), void 0) }, void 0), _jsx(Item, { children: _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                            fontWeight: 500,
                                                            marginRight: '8px', // Space between label and amount
                                                        } }, { children: "Payment Order Id :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                            color: '#000000',
                                                        } }, { children: order.payment_order_id ? order.payment_order_id : '---' }), void 0)] }), void 0) }, void 0), _jsx(Item, { children: _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                            fontWeight: 500,
                                                            marginRight: '8px', // Space between label and amount
                                                        } }, { children: "Last Payment Status :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                            color: '#000000'
                                                        } }, { children: paymentStatusToHumanReadable(order.last_payment_status) }), void 0)] }), void 0) }, void 0)] }), void 0)] }), void 0), _jsx(BuyerComponent, { buyer: order.buyerInfo, shipping_address_info: order.shipping_address_info }, void 0), _jsx("div", { children: order.shipments ? order.shipments.map((shipment) => _jsx(ShipmentItemInfoComponent, Object.assign({}, { shipment, order_id: order.id, reload: handleLoad }), void 0)) : null }, void 0)] }, void 0)
                // <Container maxWidth="md" style={{ marginTop: '20px' }}>
                //     <Box
                //         sx={{
                //             height: "50px",
                //             backgroundColor: "#CBE7FC",
                //             display: "flex",
                //             alignItems: "center",
                //             justifyContent: "flex-start",
                //             padding: "16px",
                //             marginBottom: "20px"
                //         }}
                //     >
                //         <p style={{ color: 'gray', fontWeight: 600, margin: 0 }}>
                //             Shipment Details
                //         </p>
                //     </Box>
                //     <Paper elevation={3} style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: "20px", boxShadow: 'none', backgroundColor: '#F6F8FB' }}>
                //         <Grid container spacing={2} style={{
                //             display: "flex",
                //             alignItems: "center",
                //             justifyContent: "flex-end", marginBottom: "5%"
                //         }}>
                //             {/* {shipment.status === 'courier-not-assigned' && <Grid item>
                //                 <Button size="small" variant="contained" onClick={handleSubmitCourier} sx={{ backgroundColor: "#045998", fontWeight: 600 }} disableElevation>
                //                     Schedule Pickup
                //                 </Button>
                //             </Grid>} */}
                //             <Grid item>
                //                 <Button size="small" variant="contained" onClick={handleClickOpen} sx={{ backgroundColor: "#045998", fontWeight: 600 }} disableElevation>
                //                     Add / view Notes
                //                 </Button>
                //             </Grid>
                //         </Grid>
                //         <Grid container spacing={2} alignItems="center">
                //             <Grid item xs={3}>
                //                 <Typography sx={{ fontSize: "14px", color: "gray" }}>
                //                     Seller Name
                //                 </Typography>
                //                 <Typography sx={{ fontWeight: 400 }}>
                //                     {shipment.seller_name}
                //                 </Typography>
                //             </Grid>
                //             <Grid item xs={3}>
                //                 <Typography sx={{ fontSize: "14px", color: "gray" }}>
                //                     Store Name
                //                 </Typography>
                //                 <Typography sx={{ fontWeight: 400 }}>
                //                     {shipment.store_name}
                //                 </Typography>
                //             </Grid>
                //             <Grid item xs={6}>
                //                 <Typography sx={{ fontSize: "14px", color: "gray", textAlign: 'left' }}>
                //                     Store Address
                //                 </Typography>
                //                 <Typography sx={{ fontWeight: 400 }}>
                //                     {`${shipment.store_address?.address_line1}, ${shipment.store_address?.address_line2}, ${shipment.store_address?.city}, ${shipment.store_address?.pin_code}, ${shipment.store_address?.landmark}`}
                //                 </Typography>
                //             </Grid>
                //         </Grid>
                //         <Grid container spacing={2} style={{ marginTop: '20px' }}>
                //             <Grid item xs={12}>
                //                 <Typography style={{ fontWeight: 500, fontSize: "19px", lineHeight: "24px" }}>Courier Info</Typography>
                //             </Grid>
                //             <Grid item xs={4}>
                //                 <TextField id="filled-hidden-label-small"
                //                     size="small" fullWidth label="Courier Name" variant="outlined" name="courier" onChange={handleInputChange} value={courierInfo.courier} InputProps={textFieldProps} disabled={true} />
                //             </Grid>
                //             <Grid item xs={4}>
                //                 <TextField
                //                     fullWidth
                //                     size="small"
                //                     label="Tracking Number / Courier Consignment No"
                //                     name="tracking_number"
                //                     disabled={true} 
                //                     onChange={handleInputChange}
                //                     value={courierInfo.tracking_number}
                //                     InputProps={textFieldProps}
                //                 />
                //             </Grid>
                //         </Grid>
                //         <Divider sx={{ marginY: 3 }} />
                //         <Grid container spacing={2} style={{ marginTop: '20px' }}>
                //             <Grid item xs={12}>
                //                 <Typography style={{ fontWeight: 500, fontSize: "19px", lineHeight: "24px" }}>Shipping Items</Typography>
                //             </Grid>
                //             <Grid item xs={12}>
                //                 <TableContainer component={Paper}>
                //                     <Table>
                //                         <TableHead>
                //                             <TableRow>
                //                                 <TableCell>S.no</TableCell>
                //                                 <TableCell>Product Id</TableCell>
                //                                 <TableCell>Product Name</TableCell>
                //                                 <TableCell>Product Variant Id</TableCell>
                //                                 <TableCell>Quantity</TableCell>
                //                             </TableRow>
                //                         </TableHead>
                //                         <TableBody>
                //                             {shipment.items?.map((item: any, index: number) => (
                //                                 <TableRow key={item.id}>
                //                                     <TableCell>{index + 1}</TableCell>
                //                                     <TableCell>{item.product_uid}</TableCell>
                //                                     <TableCell>{item.product_name}</TableCell>
                //                                     <TableCell>{item.product_variant_uid}</TableCell>
                //                                     <TableCell>{item.quantity}</TableCell>
                //                                 </TableRow>
                //                             ))}
                //                         </TableBody>
                //                     </Table>
                //                 </TableContainer>
                //             </Grid>
                //         </Grid>
                //     </Paper>
                //     <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                //         <DialogTitle sx={{ backgroundColor: "#f5f5f5", textAlign: "center", fontWeight: "bold" }}>
                //             Shipment Log
                //         </DialogTitle>
                //         <DialogContent sx={{ padding: 3 }}>
                //             <Typography variant="h6" gutterBottom sx={{ fontWeight: "600", color: "#0545BE" }}>
                //                 Existing Logs
                //             </Typography>
                //             {/* Styled list of logs */}
                //             <Box
                //                 sx={{
                //                     maxHeight: "200px",
                //                     overflowY: "auto",
                //                     backgroundColor: "#f9f9f9",
                //                     padding: 2,
                //                     borderRadius: "8px",
                //                     boxShadow: "inset 0 0 5px rgba(0,0,0,0.1)",
                //                     marginBottom: 2,
                //                 }}
                //             >
                //                 <ul style={{ paddingLeft: "16px" }}>
                //                     {shipment && shipment.loges?.length > 0 ? (
                //                         shipment.loges?.map((log: any, index: number) => (
                //                             <li key={index} style={{ marginBottom: "8px", lineHeight: "1.5" }}>{log.log}</li>
                //                         ))
                //                     ) : (
                //                         <Typography variant="body2" color="textSecondary">
                //                             No logs available.
                //                         </Typography>
                //                     )}
                //                 </ul>
                //             </Box>
                //             {/* Input for new log */}
                //             <TextField
                //                 margin="dense"
                //                 label="Add New Note"
                //                 type="text"
                //                 fullWidth
                //                 value={newNote}
                //                 onChange={(e) => setNewNote(e.target.value)}
                //                 variant="outlined"
                //                 multiline
                //                 rows={3} // Make the input multiline for better UX
                //                 sx={{
                //                     backgroundColor: "#fff",
                //                     borderRadius: "8px",
                //                     marginBottom: 2,
                //                 }}
                //             />
                //         </DialogContent>
                //         <DialogActions sx={{ padding: 2 }}>
                //             <Button onClick={handleClose} color="secondary" variant="outlined">
                //                 Cancel
                //             </Button>
                //             <Button onClick={handleAddNote} color="primary" variant="contained">
                //                 Save Note
                //             </Button>
                //         </DialogActions>
                //     </Dialog>
                // </Container> 
                : null }), void 0) }, void 0));
};
export const OrderInfoPage = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(Orderinfo, Object.assign({}, props), void 0) }), void 0) }, void 0));
