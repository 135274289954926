import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
export const placeHolderImage = `http://data.exprzn.com:3050/image-service/images/retrieve/0x3364`;
const ColorComponent = ({ name, color }) => {
    const { colfixedstyle, coldynamicstyle, colorstyle } = useStyles("colfixedstyle", "coldynamicstyle", "colorstyle");
    return _jsxs("div", Object.assign({ css: [colorstyle, css({ display: "flex", justifyContent: "start" })] }, { children: [_jsx("div", { css: { backgroundColor: color, width: "24px", height: "24px", borderRadius: 4 } }, void 0), _jsx("div", Object.assign({ css: coldynamicstyle }, { children: name }), void 0)] }), void 0);
};
const ImageComponent = ({ name, value }) => {
    const { colfixedstyle, coldynamicstyle, colorstyle } = useStyles("colfixedstyle", "coldynamicstyle", "colorstyle");
    return _jsxs("div", Object.assign({ css: [colorstyle, css({ display: "flex", justifyContent: "start" })] }, { children: [_jsx("div", Object.assign({ css: [colfixedstyle] }, { children: _jsx("img", { css: { borderRadius: 4 }, width: "24px", height: "24px", src: value || placeHolderImage }, void 0) }), void 0), _jsx("div", Object.assign({ css: coldynamicstyle }, { children: name }), void 0)] }), void 0);
};
const StringComponent = ({ name, value }) => {
    const { colfixedstyle, coldynamicstyle, colorstyle } = useStyles("colfixedstyle", "coldynamicstyle", "colorstyle");
    return _jsx("div", Object.assign({ css: colorstyle }, { children: _jsx("div", Object.assign({ css: coldynamicstyle }, { children: name }), void 0) }), void 0);
};
export const VariantValueComponent = (props) => {
    const { instance } = props;
    const { variant_name, uid, variant_value_of, variant_value } = instance;
    const [varaint_type] = variant_value_of || [];
    const [value] = variant_value || [];
    return _jsxs("div", Object.assign({ style: { display: "flex", justifyContent: "start" } }, { children: [varaint_type.variant_type === "color" ? _jsx(Col, { children: _jsx(ColorComponent, Object.assign({}, { color: value.color, name: variant_name }), void 0) }, void 0) : null, varaint_type.variant_type === "image" ? _jsx(Col, { children: _jsx(ImageComponent, Object.assign({}, { name: variant_name, value: value.image.url }), void 0) }, void 0) : null, varaint_type.variant_type === "string" ? _jsx(Col, { children: _jsx(StringComponent, Object.assign({}, { name: value.name || variant_name }), void 0) }, void 0) : null] }), void 0);
};
