import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect } from "react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { toast } from "react-toastify";
import { Col } from "../../../components/Column";
import { Field, Form } from "../../../components/Form";
import { InputDate, InputEx } from "../../../components/Form/fields-extended";
import { useProgressBar } from "../../../components/ProgressBar";
import { Row } from "../../../components/Row";
import { Table } from "../../../components/Table/table";
import { useRestSubmit } from "../../../saga/use-rest-submit";
import { useSaga } from "../../../saga/use-saga";
import { CardNano } from "../../orders/card-nano";
const StoreOrder = (props) => {
    const { data } = props;
    const { date, id, amount } = data || {};
    return _jsxs(_Fragment, { children: [_jsx("td", Object.assign({ align: "left" }, { children: date.toDateString() }), void 0), _jsx("td", { children: id }, void 0), _jsx("td", { children: amount }, void 0)] }, void 0);
};
const PayoutComponent = (props) => {
    const { data: payout } = props;
    const { productstyle } = useStyles("productstyle");
    const { store_orders = [] } = payout;
    const orderDetails = store_orders.map(order => ({
        date: new Date(order.order.order_date),
        id: `${order.order.id}`,
        amount: order.sale_amount
    }));
    return _jsx("div", Object.assign({ css: productstyle }, { children: _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 6 }, { children: [payout.processed_datetime ?
                            _jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { title: "Processed Date", value: new Date(payout.processed_datetime).toDateString() }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "From Date", value: new Date(payout.sale_date_from).toDateString() }, void 0) }, void 0), _jsx(Col, { children: _jsx(CardNano, { title: "To Date", value: new Date(payout.sale_date_to).toDateString() }, void 0) }, void 0)] }, void 0) : null, _jsxs(Row, { children: [_jsx(Col, { children: _jsx(CardNano, { title: "Total Sales", value: `Rs.${payout.total_sales}` }, void 0) }, void 0), payout.processed_datetime ? _jsx(Col, { children: _jsx(CardNano, { title: "Commission", value: `Rs.${payout.commission}` }, void 0) }, void 0) : null] }, void 0)] }), void 0), _jsx(Col, Object.assign({ cols: 6 }, { children: _jsx(Row, { children: _jsx(Col, { children: orderDetails && orderDetails.length > 0 ? _jsx(Table, { component: StoreOrder, keys: ["date", "id", "amount"], header: { date: "Date", id: "Order Id", amount: "Amount" }, body: orderDetails }, void 0) : null }, void 0) }, void 0) }), void 0)] }, void 0) }), void 0);
};
const SaleComponent = (props) => {
    const { data: payout, storeUid, reloadFn } = props;
    const { productstyle, buttonlg } = useStyles("productstyle", "buttonlg");
    const { store_orders = [], total_sales } = payout;
    function onSuccess(data) {
        toast.success("Payout Created");
        reloadFn();
    }
    function onError(error) {
        toast.error(`Error creating Payout ${error}`);
    }
    const submitPayout = useRestSubmit(onSuccess, onError);
    const orderDetails = store_orders.map(order => ({
        date: new Date(order.order.order_date),
        id: `${order.order.id}`,
        amount: order.sale_amount
    }));
    function onSubmit(data) {
        const { payout } = data || {};
        const { commission, target_date: date } = payout || {};
        if (!date) {
            toast.error("Add a target Date");
        }
        else if (!commission) {
            toast.error("Commission value is not given ");
        }
        else if (commission > total_sales) {
            toast.error("Commission value should be less than total sales");
        }
        else {
            const target_date = new Date(date).toISOString();
            const body = { commission, target_date };
            submitPayout(`/trendutube/v1/admin-payout-processing/stores/${storeUid}/payout/create`, {
                body
            });
        }
    }
    return _jsx("div", Object.assign({ css: productstyle }, { children: _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 6, top: true }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(CardNano, { title: "Total Sales", value: `${payout.total_sales}` }, void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx(Form, Object.assign({ name: "payout", onSubmit: onSubmit }, { children: _jsxs(Row, { children: [_jsxs(Col, Object.assign({ cols: 4 }, { children: [_jsx("div", { children: "Target Date" }, void 0), _jsx("div", Object.assign({ css: { paddingRight: "12px" } }, { children: _jsx(Field, Object.assign({ name: "target_date" }, { children: _jsx(InputDate, {}, void 0) }), void 0) }), void 0)] }), void 0), _jsxs(Col, Object.assign({ cols: 4 }, { children: [_jsx("div", { children: "Commission" }, void 0), _jsx("div", Object.assign({ css: { paddingRight: "12px" } }, { children: _jsx(Field, Object.assign({ name: "commission" }, { children: _jsx(InputEx, {}, void 0) }), void 0) }), void 0)] }), void 0), _jsxs(Col, { children: [_jsx("div", { children: "\u00A0" }, void 0), _jsx("div", Object.assign({ css: { paddingRight: "0px" } }, { children: _jsx("button", Object.assign({ css: buttonlg }, { children: "Send Payout" }), void 0) }), void 0)] }, void 0)] }, void 0) }), void 0) }, void 0) }, void 0)] }), void 0), _jsx(Col, Object.assign({ cols: 6 }, { children: _jsx(Row, { children: _jsx(Col, { children: orderDetails && orderDetails.length > 0 ? _jsx(Table, { component: StoreOrder, keys: ["date", "id", "amount"], header: { date: "Date", id: "Order Id", amount: "Amount" }, body: orderDetails }, void 0) : null }, void 0) }, void 0) }), void 0)] }, void 0) }), void 0);
};
export const StorePayouts = (props) => {
    const { match } = props;
    const { uid: storeUid } = match.params;
    const { data: lastPayout } = useSaga(`/trendutube/v1/admin-payout-processing/stores/${storeUid}/last-payout`);
    const { data, isFetching, status, reload, loadUrl } = useSaga("", {}, false);
    const { payouts = [] } = data || {};
    const { productstyle } = useStyles("productstyle");
    const progress = useProgressBar();
    useEffect(() => progress(isFetching), [isFetching]);
    useEffect(() => {
        if (lastPayout) {
            const lp = lastPayout;
            const endDate = new Date(lp.sale_date_to).toDateString();
            const startDate = new Date().toISOString().substring(0, 10);
            console.log(`/trendutube/v1/admin-payout-processing/stores/${storeUid}/daily-sales?from_date=${lp.sale_date_to}&to_date=${startDate}`);
            loadUrl(`/trendutube/v1/admin-payout-processing/stores/${storeUid}/daily-sales?from_date=${lp.sale_date_to || "2020-06-01"}&to_date=${startDate}`);
        }
    }, [lastPayout]);
    return _jsxs("div", { children: [_jsx("div", { children: storeUid }, void 0), status == "done" && payouts && payouts.length <= 0 ?
                _jsx("div", Object.assign({ css: productstyle }, { children: "There may not be delivered orders yet" }), void 0) : null, data && payouts ? payouts.map((payout, index) => index == 0 ? _jsx(SaleComponent, { data: payout, storeUid: storeUid, reloadFn: reload }, void 0) :
                _jsx(PayoutComponent, { data: payout }, void 0)) : null] }, void 0);
};
