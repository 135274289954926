import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
export const Layer = React.forwardRef((props, ref) => {
    const { position, children, width } = props;
    const defaultStyle = Object.assign(Object.assign({ position: 'relative', zIndex: 10100 }, position), { pointerEvents: 'all', width: width || "fit-content" });
    const portalElement = () => _jsx("div", Object.assign({ 
        // onBlur / onFocus unreliable  
        ref: ref, style: defaultStyle }, { children: children }), void 0);
    return _jsx(_Fragment, { children: ReactDOM.createPortal(portalElement(), document.body) }, void 0);
});
