import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { useProgressBar } from "../../../components/ProgressBar";
import { useSaga } from "../../../saga/use-saga";
import { global_org_uid } from "../../../config";
export const AttributeSetListComponent = (props) => {
    const [isAddTemplate, setIsAddTemplate] = useState(false);
    const { product, addAttributeSetFn } = props || {};
    const { button } = useStyles("button");
    return _jsxs("div", { children: [!isAddTemplate ? _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: _jsx("button", Object.assign({ css: button, type: "button", onClick: () => setIsAddTemplate(true) }, { children: "Attribute Set Templates (add from)" }), void 0) }), void 0) : null, isAddTemplate ?
                _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isAddTemplate, title: "Attribute Set Templates", subtitle: "", width: "40%", onRequestClose: () => {
                        setIsAddTemplate(false);
                    } }, { children: _jsx("div", { children: _jsx(AttributeSetComponent, { product: product, addAttributeSetFn: addAttributeSetFn }, void 0) }, void 0) }), void 0) : null] }, void 0);
};
export const AttributeSetComponent = (props) => {
    const { product, addAttributeSetFn } = props;
    const [attributeSetTemplate, setAttributeSetTemplate] = useState();
    const product_classes = product.product_class.map(it => it.uid).join(",");
    const { data, isFetching } = useSaga(`/trendutube/v1/product-catalog/attribute-set-templates/list?org_uid=${global_org_uid}&product_classes=${product_classes}`, {}, false);
    const loading = useProgressBar();
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (data != null) {
            const [response] = (data || []);
            const { attribute_set_templates } = response || {};
            setAttributeSetTemplate(attribute_set_templates);
        }
    }, [data]);
    return _jsx("div", { children: _jsx("table", { children: (attributeSetTemplate || []).map(attrSet => _jsxs("tr", { children: [_jsxs("td", { children: [_jsx("div", Object.assign({ css: { marginTop: 14, fontSize: 16, fontWeight: 600 } }, { children: attrSet.attribute_set_name }), void 0), _jsx("div", Object.assign({ css: { fontSize: 14 } }, { children: attrSet.attribute_set_description }), void 0)] }, void 0), _jsxs("td", { children: [" ", _jsx(AttributeTemplateComponent, { template_uid: attrSet.uid, addAttributeSetFn: addAttributeSetFn }, void 0)] }, void 0), _jsxs("td", { children: [_jsx("button", Object.assign({ onClick: () => addAttributeSetFn(attrSet.uid), css: {
                                    margin: 10,
                                    backgroundColor: "#fe7271", color: "#fff", borderRadius: "4px",
                                    border: "0px solid ", padding: "4px 16px"
                                } }, { children: "Add" }), void 0), " "] }, void 0)] }, void 0)) }, void 0) }, void 0);
};
export const AttributeTemplateComponent = (props) => {
    const { template_uid, addAttributeSetFn } = props;
    const [tmpltView, setTmpltView] = useState(false);
    return _jsxs("div", { children: [_jsx("button", Object.assign({ onClick: () => setTmpltView(true), css: {
                    backgroundColor: "#84bd94", color: "#fff", borderRadius: "4px",
                    border: "0px solid ", padding: "4px 16px", margin: 10,
                } }, { children: "View" }), void 0), tmpltView ?
                _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: true, title: "Attribute Set Templates", subtitle: "", width: "30%", onRequestClose: () => setTmpltView(false) }, { children: _jsx(AttributeTemplate, { template_uid: template_uid, addAttributeSetFn: addAttributeSetFn }, void 0) }), void 0) : null] }, void 0);
};
export const AttributeTemplate = (props) => {
    const { template_uid, addAttributeSetFn } = props;
    const [attributeSetTemplate, setAttributeSetTemplate] = useState();
    const { data, isFetching } = useSaga(`/trendutube/v1/product-catalog/attribute-set-templates/get?uid=${template_uid}`, {}, false);
    const loading = useProgressBar();
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (data != null) {
            setAttributeSetTemplate(data);
        }
    }, [data]);
    return _jsx("div", { children: attributeSetTemplate ? _jsxs("div", { children: [_jsx("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: _jsx("button", Object.assign({ onClick: () => {
                            addAttributeSetFn(template_uid);
                        }, css: {
                            backgroundColor: "#fe7271", color: "#fff", borderRadius: "4px",
                            border: "0px solid ", padding: "4px 16px"
                        } }, { children: "Add" }), void 0) }), void 0), _jsx("div", Object.assign({ css: { marginTop: 14, fontSize: 16, fontWeight: 600 } }, { children: attributeSetTemplate.attribute_set_name }), void 0), _jsx("div", Object.assign({ css: { fontSize: 14 } }, { children: attributeSetTemplate.attribute_set_description }), void 0), attributeSetTemplate.attribute_group_templates && attributeSetTemplate.attribute_group_templates.map(group => _jsx("div", { children: _jsxs("div", Object.assign({ css: { marginTop: 20 } }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ css: { fontSize: 17, fontWeight: 600 } }, { children: group.attribute_group_name }), void 0), _jsx("div", Object.assign({ css: { color: "#a1a1af", fontSize: 14, marginBottom: "8px", fontWeight: 600, borderBottom: "1px solid black" } }, { children: group.attribute_group_description }), void 0)] }, void 0), _jsx("table", Object.assign({ width: "100%" }, { children: group.attributes && group.attributes.map(attrib => {
                                    return _jsxs("tr", Object.assign({ css: { borderBottom: "1px solid #dfdfdf" } }, { children: [_jsxs("td", Object.assign({ css: { padding: "8px 20px 20px 0px", } }, { children: [_jsx("div", Object.assign({ css: { fontSize: 16, fontWeight: 600, color: "#676767" } }, { children: attrib.attribute_name }), void 0), _jsx("div", Object.assign({ css: { fontSize: 14 } }, { children: attrib.attribute_description }), void 0)] }), void 0), _jsx("td", Object.assign({ css: { padding: "8px 20px 20px 0px" } }, { children: attrib.attribute_value_type }), void 0)] }), void 0);
                                }) }), void 0)] }), void 0) }, void 0))] }, void 0) : null }, void 0);
};
