import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component, createContext } from 'react';
import { FieldContext } from './type';
export const FormContext = createContext({});
function updateObject(objIn, path, value, index) {
    const segments = path.split(".");
    const lastSegment = segments.pop();
    for (var i = 0, len = segments.length; i < len; i++) {
        const seg = segments[i];
        if (typeof objIn[seg] !== "object" || !(seg in objIn))
            objIn[seg] = {};
        objIn = objIn[seg];
    }
    if (typeof index === 'number') {
        if (typeof objIn[lastSegment] === "undefined") {
            objIn[lastSegment] = [];
        }
        objIn[lastSegment][index] = value;
    }
    else {
        objIn[lastSegment] = value;
    }
}
function getValueFromObj(objIn, path, index) {
    const segments = path.split(".");
    const lastSegment = segments.pop();
    for (var i = 0, len = segments.length; i < len; i++) {
        const seg = segments[i];
        if (typeof objIn[seg] !== "object" || !(seg in objIn))
            return null;
        objIn = objIn[seg];
    }
    if (typeof index !== "undefined" && typeof objIn[lastSegment] === "object")
        return objIn[lastSegment][index];
    else
        return objIn[lastSegment];
}
//TODO may be we can return the remove element !
function removeArrayElementFromObject(objIn, path, index) {
    const segments = path.split(".");
    const lastSegment = segments.pop();
    for (var i = 0, len = segments.length; i < len; i++) {
        const seg = segments[i];
        if (typeof objIn[seg] !== "object" || !(seg in objIn))
            return null;
        objIn = objIn[seg];
    }
    if (typeof index !== "undefined" && typeof objIn[lastSegment] === "object") {
        if (Array.isArray(objIn[lastSegment])) {
            objIn[lastSegment].splice(index, 1);
        }
    }
}
export class Form extends Component {
    constructor() {
        super(...arguments);
        // static contextType = FormContext;
        this.value = {};
    }
    onSubmit(event) {
        const { onSubmit } = this.props;
        if (onSubmit)
            onSubmit(Object.assign({}, this.value));
        event.preventDefault();
        return;
    }
    render() {
        const { name, onChange } = this.props;
        return _jsx("form", Object.assign({ onSubmit: this.onSubmit.bind(this) }, { children: _jsx(FieldContext.Provider, Object.assign({ value: {
                    path: name,
                    form: {
                        update: (path, value, index) => {
                            updateObject(this.value, path, value, index);
                            this.setState(Object.assign({}, this.value));
                            onChange && onChange(this.value);
                        },
                        updateSilent: (path, value, index) => {
                            updateObject(this.value, path, value, index);
                            onChange && onChange(this.value);
                        },
                        getValue: (path, index) => {
                            return getValueFromObj(this.value, path, index);
                        },
                        removeArrayElement: (path, index) => {
                            removeArrayElementFromObject(this.value, path, index);
                            this.setState(Object.assign({}, this.value));
                        }
                    }
                } }, { children: this.props.children }), void 0) }), void 0);
    }
}
