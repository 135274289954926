import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from '@exprzn/react-style-lib';
import React, { useEffect, useState } from 'react';
export const SwitchInput = (props) => {
    const { value, onChange, backgroundColor } = props;
    const [onOrOff, setOnOrOff] = useState();
    useEffect(() => {
        setOnOrOff(value);
    }, []);
    const { switcheckboxstyle, switchlabelstyle, switchbuttonstyle } = useStyles("switcheckboxstyle", "switchlabelstyle", "switchbuttonstyle");
    const change = (event) => {
        setOnOrOff(event.target.checked);
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
    };
    return _jsx(_Fragment, { children: _jsxs("label", Object.assign({ style: { background: onOrOff && backgroundColor || "" }, css: switchlabelstyle, className: "switchlabel" }, { children: [_jsx("input", { checked: onOrOff, onChange: change, css: switcheckboxstyle, type: "checkbox" }, void 0), _jsx("span", { className: "switchbutton", css: switchbuttonstyle }, void 0)] }), void 0) }, void 0);
};
