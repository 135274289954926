import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { Col } from "../../../components/Column";
import { DragDrop } from "../../../components/DragDrop";
import { Field, Form } from "../../../components/Form";
import { useProgressBar } from "../../../components/ProgressBar";
import { Row } from "../../../components/Row";
import { SagaProvider } from "../../../saga/saga-context";
import { useSubmit } from "../../../saga/use-submit";
import container from "./style";
import { toast } from 'react-toastify';
import { Radio, RadioButtonItem } from "../../../components/Form/fields-extended-radio";
import { LabelledNewField } from "../../../components/Form/labelled-field";
import { useHistory } from "react-router-dom";
export const CategoryAddInternal = (props) => {
    const { pageReload, handleCloseDrawer } = props;
    const { category, rowstyle, button } = useStyles("category", "rowstyle", "button");
    const { submit, status, data, isFetching, error } = useSubmit();
    const loading = useProgressBar();
    useEffect(() => loading(isFetching), [isFetching]);
    const history = useHistory();
    useEffect(() => {
        if (status == "ok") {
            toast.success("Category Added", { toastId: "success-category" });
            handleCloseDrawer();
            pageReload();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    const [category_image_uid, setImage] = useState();
    const submitHandler = (formData) => {
        const { category } = formData || {};
        const { category_name, category_description, listing_type } = category || {};
        if (!category_name)
            toast.error("Specify a name for category", { toastId: "cat1" });
        else if (!category_description)
            toast.error("Specify a description for category", { toastId: "cat2" });
        else if (!listing_type)
            toast.error("Choose a listing type", { toastId: "cat3" });
        else if (!category_image_uid)
            toast.error("Please upload an image, ensure image is uploaded ", { toastId: "cat3" });
        else
            submit("/trendutube/v1/admin/categories/add", {
                body: Object.assign(Object.assign({}, formData.category), { category_image_uid }),
            });
    };
    function handleImageUpload([image]) {
        setImage(image.uid);
    }
    return (_jsxs("div", Object.assign({ style: { border: "0.6px solid #045998", marginTop: '10px', padding: '15px' } }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx("div", Object.assign({ css: { marginRight: 10, padding: 10 } }, { children: _jsxs(Form, Object.assign({ name: "category", onSubmit: submitHandler }, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsx(LabelledNewField, { name: "category_name", value: "", label: "Category Name" }, void 0), _jsx(LabelledNewField, { name: "category_description", value: "", label: "Category Description" }, void 0), _jsx(LabelledNewField, { name: "gravity", value: "", label: "Position" }, void 0), _jsxs("div", Object.assign({ style: { padding: '10px' } }, { children: [_jsx("div", Object.assign({ style: { color: 'gray' } }, { children: "List Stores or Sub Categories " }), void 0), _jsx("div", Object.assign({ css: { marginTop: 10, fontSize: 13 } }, { children: _jsx(Field, Object.assign({ name: "listing_type" }, { children: _jsxs(Radio, Object.assign({ name: "listing_type", align: "start" }, { children: [_jsx(RadioButtonItem, { value: "products", label: "Products or Sub Category" }, void 0), _jsx(RadioButtonItem, { value: "stores", label: "Stores" }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: rowstyle }, { children: _jsx("input", { type: "submit", value: "Add Category", style: { backgroundColor: '#045998', border: '1px solid #045998', color: 'white', padding: '5px' } }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: _jsx(DragDrop, { entity: "category", onImagesUpload: handleImageUpload }, void 0) }, void 0) }, void 0)] }), void 0));
};
export const CategoryAdd = () => (_jsx(SagaProvider, { children: _jsx(StyleProvider, { value: { container } }, void 0) }, void 0));
