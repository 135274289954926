import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';
import { useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { put, takeEvery } from 'redux-saga/effects';
import { SagaContext } from '../../saga/saga-context';
import { useReducerInjector } from '../../saga/use-reducer-injector';
import './style.css';
const kfIndeterminate = keyframes `
    0% {
        left: 50%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }

    100% {
        left: 107%;
        right: -8%;
    }
`;
const css = {
    // transform : `translateX(-100%)`,
    animation: `${kfIndeterminate} 1s ease infinite;`
};
function createReducer(prefix) {
    return function (state, action) {
        var _a;
        const [subAction, recvdPrefix] = (_a = action === null || action === void 0 ? void 0 : action.type) === null || _a === void 0 ? void 0 : _a.split(":");
        if (recvdPrefix !== prefix)
            return state || {};
        let newState = {};
        switch (subAction) {
            case 'LOADING_STATUS_ON':
                newState = { loading: true };
                break;
            // case 'LOADING_STATUS_OFF':
            default:
                newState = { loading: false };
                break;
        }
        return Object.assign(Object.assign({}, state), newState);
    };
}
const PREFIX = "PROGRESSBAR";
export const LinearProgressBar = () => {
    const { inject, expel } = useReducerInjector();
    const { sagaMiddleware } = useContext(SagaContext);
    const loading = useSelector((state) => {
        return state[PREFIX] && state[PREFIX].loading;
    });
    function* rootSaga() {
        yield takeEvery(`LOADING_ON:${PREFIX}`, sagaLoadStatus);
        yield takeEvery(`LOADING_OFF:${PREFIX}`, sagaNotLoadStatus);
    }
    function* sagaLoadStatus() {
        yield put({ type: `LOADING_STATUS_ON:${PREFIX}`, payload: true });
    }
    function* sagaNotLoadStatus() {
        yield put({ type: `LOADING_STATUS_OFF:${PREFIX}`, payload: false });
    }
    useEffect(() => {
        const reducer = createReducer(PREFIX);
        inject(PREFIX, reducer);
        sagaMiddleware.run(rootSaga);
    }, []);
    const defaultStyle = {
        position: 'fixed',
        zIndex: 10100,
        top: 0,
        pointerEvents: 'all',
        width: "100%",
        height: 3
    };
    const portalElement = () => _jsx("div", Object.assign({ style: defaultStyle, className: 'progress-bar-wrap' }, { children: _jsx("div", Object.assign({ className: "progress-bar-bar" }, { children: "Super" }), void 0) }), void 0);
    return loading ? createPortal(portalElement(), document.body) : null;
};
export function useProgressBar() {
    const dispatch = useDispatch();
    function loading(status) {
        dispatch({ type: status ? `LOADING_ON:${PREFIX}` : `LOADING_OFF:${PREFIX}` });
    }
    return loading;
}
export function useProgressWatch(value) {
    const setProgress = useProgressBar();
    useEffect(() => {
        setProgress(value);
    }, [value]);
}
