import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useState } from "react";
import SlidingPane from "react-sliding-pane";
import { useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { DropdownEx } from "../../../components/DropdownEx/dropdown-field";
import { Field, Form } from "../../../components/Form";
import { Row } from "../../../components/Row";
import { usePagination } from "../../../saga/use-pagination";
import { BasketItemEditComponent, InputVx } from "./basket-item-edit-component";
import { global_org_uid } from "../../../config";
const ImageGallery = (props) => {
    const { images } = props;
    const [index, setIndex] = useState(0);
    console.log(index, images);
    return _jsxs("div", { children: [_jsx("div", Object.assign({ style: { paddingRight: 20, paddingBottom: 160, position: "relative" } }, { children: images && images.length ?
                    _jsx("img", { width: "100%", height: "30%", style: { borderRadius: 5, margin: 5 }, src: images[index].url }, void 0) : null }), void 0), _jsx("div", Object.assign({ style: { marginTop: -40 } }, { children: images.map((image, index) => _jsx("img", { width: "32", height: "32", onClick: () => setIndex(index), style: { borderRadius: 5, margin: 5 }, src: image.url }, void 0)) }), void 0)] }, void 0);
};
export const AddBasketItemComp = (props) => {
    const { addBasketItemFn } = props;
    const { action_btn_ms } = useStyles("action_btn_ms");
    const { data: units } = usePagination(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/measure-units/list`, 40);
    const processForm = (formvalue) => {
        const { item } = formvalue || {};
        const { unit: unitObj, item_name, quantity, price, availability } = item || {};
        const { unit_short_id: unit } = unitObj || {};
        if (!item_name || !quantity)
            alert("error");
        else
            addBasketItemFn({ item_name, quantity, unit, price, availability });
    };
    return _jsx(Form, Object.assign({ onSubmit: processForm, name: "item" }, { children: _jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: [2] }, { children: _jsxs("div", { children: [_jsx("div", { children: "Item Desc" }, void 0), _jsx(Field, Object.assign({ name: "item_name" }, { children: _jsx(InputVx, {}, void 0) }), void 0)] }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: [3] }, { children: _jsxs("div", Object.assign({ css: { position: "relative" } }, { children: [_jsx("div", { children: "Unit" }, void 0), _jsx(Field, Object.assign({ name: "unit" }, { children: _jsx(DropdownEx, { items: units, titleProperty: "unit_name", width: "100%", keyProperty: "unit_name", 
                                    // onChange={onChange}
                                    view: (props) => _jsx("div", { children: props.item.unit_name }, void 0) }, void 0) }), void 0)] }), void 0) }), void 0), _jsx(Col, { children: _jsxs("div", { children: [_jsx("div", { children: "Quantity" }, void 0), _jsx(Field, Object.assign({ name: "quantity" }, { children: _jsx(InputVx, {}, void 0) }), void 0)] }, void 0) }, void 0), _jsx(Col, { children: _jsxs("div", { children: [_jsx("div", { children: "Price" }, void 0), _jsx(Field, Object.assign({ name: "price" }, { children: _jsx(InputVx, {}, void 0) }), void 0)] }, void 0) }, void 0), _jsx(Col, Object.assign({ cols: [2] }, { children: _jsx("div", Object.assign({ css: { display: "flex", alignContent: "flex-end", justifyContent: "flex-end" } }, { children: _jsx("button", Object.assign({ css: action_btn_ms, type: "submit" }, { children: "Add" }), void 0) }), void 0) }), void 0)] }, void 0) }), void 0);
};
export const BasketItemAddComponent = (props) => {
    const { isPaneOpen, closePane, basket_items, uid, images, addBasketItemFn, deleteBasketItemFn, updateBasketItemFn } = props;
    return _jsx(SlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isPaneOpen, title: "Basket Order Items", subtitle: "Add Items", width: (images && images.length) ? "80%" : "47%", onRequestClose: () => {
            // triggered on "<" on left top click or on outside click
            closePane();
        } }, { children: _jsxs(Row, { children: [images.length ?
                    _jsx(Col, Object.assign({ top: true, cols: 5 }, { children: _jsx(ImageGallery, Object.assign({}, { images }), void 0) }), void 0) : null, _jsxs(Col, Object.assign({ top: true, cols: images.length ? 7 : 12 }, { children: [_jsx("div", { children: basket_items.map(basket_item => _jsx(BasketItemEditComponent, Object.assign({}, { updateBasketItemFn, uid, basket_item, deleteBasketItemFn }), void 0)) }, void 0), _jsx("div", { children: _jsx(AddBasketItemComp, Object.assign({}, { addBasketItemFn }), void 0) }, void 0)] }), void 0)] }, void 0) }), void 0);
};
