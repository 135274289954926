import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import React, { Children, Component } from "react";
import { FieldContext } from './type';
import { validateField, validationKeys } from '../../validation';
function pick(source, ...keys) {
    const returnValue = {};
    keys.forEach(k => {
        returnValue[k] = source[k];
    });
    return returnValue;
}
export class FieldContextImpl {
    constructor(path, form, isListField = false, index) {
        this.path = path;
        this.form = form;
        this.isListField = isListField;
        this.index = index;
    }
    setError({ code, message }) {
        this.errors.push({ code, message });
    }
    setErrors(errors) {
        errors.forEach(err => this.errors.push(Object.assign({}, err)));
    }
    clear() {
        this.errors = [];
    }
}
export class Field extends Component {
    getChildren() {
        var _a;
        const { props, context } = this;
        const { children, name, value: propVal, onChange: fieldOnChange } = props;
        let { path, form, isListField, isSelect } = context;
        const childs = Children.toArray(children);
        const thisPath = !isListField ? (path ? `${path}.${name}` : name) : path || "";
        const index = isListField ? name : undefined;
        const onChange = (event) => {
            const { type } = event.target;
            const valName = (type === "checkbox") ? "checked" : (type === "file" ? "files" : "value");
            form.update(thisPath, event.target[valName], index);
            const validationProps = pick(this.props, ...validationKeys);
            if (fieldOnChange)
                fieldOnChange(event.target[valName]);
            try {
                validateField(event.target[valName], validationProps, thisPath);
                this.setState(Object.assign(Object.assign({}, this.state), { errors: [] }));
            }
            catch (e) {
                this.setState({ errors: [{ code: 'validation', message: e.toString() }] });
            }
        };
        const fieldContext = new FieldContextImpl(thisPath, this.context.form);
        const value = isSelect ? propVal : form.getValue(thisPath, index);
        const child1 = Array.isArray(childs) ? childs[0] : children;
        let valueToUpdate;
        if (this.state.initial === true)
            valueToUpdate = propVal;
        else
            valueToUpdate = value;
        form.updateSilent(thisPath, valueToUpdate, index);
        const elem = child1 ? React.cloneElement(child1, {
            name, onChange, value: value, errors: ((_a = this.state) === null || _a === void 0 ? void 0 : _a.errors) || []
        }) : null;
        if (this.state.initial) {
            this.setState({ initial: false });
        }
        return _jsx(FieldContext.Provider, Object.assign({ value: fieldContext }, { children: elem }), void 0);
    }
    componentDidMount() {
        //Nothing to do
    }
    componentWillMount() {
        this.setState({ initial: true });
    }
    render() {
        return this.getChildren();
    }
}
Field.contextType = FieldContext;
