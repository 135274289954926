import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useState } from "react";
import { X } from "react-feather";
import ReactSlidingPane from "react-sliding-pane";
import { Col } from "../../../components/Column";
import { Form } from "../../../components/Form";
import { LabelledField } from "../../../components/Form/labelled-field";
import { Row } from "../../../components/Row";
import { useRestSubmit } from "../../../saga/use-rest-submit";
import { DistributionCentreMap } from "./distribution-map";
export const DistributionCenterEdit = (props) => {
    const { onClose, franchisee_uid, distributionCenter: dist } = props;
    const { uid, distribution_center_address: distAddress = {} } = dist || {};
    const { coordinates } = distAddress.location || {};
    const [long, lat] = coordinates || [];
    const [showMap, setShowMap] = useState(false);
    const [error, setError] = useState();
    const submit = useRestSubmit(() => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }, (error) => {
        setError(error);
    });
    const { button } = useStyles("button");
    const handleSubmit = (formData) => {
        const { distribution_center_name, distribution_center_code, address_line1, address_line2, city, pin_code, landmark, longitude = 0, latitude = 0 } = formData.add_distribution || {};
        const address = {
            address_line1,
            address_line2,
            city,
            pin_code,
            landmark,
            location: {
                type: "Point",
                coordinates: [Number(longitude), Number(latitude)]
            },
        };
        const body = {
            distribution_center_name,
            distribution_center_code,
            address,
            uid
        };
        if (uid)
            submit(`/trendutube/v1/franchisee-management/franchisees/${franchisee_uid}/distribution-centers/add`, { body });
        else
            submit(`/trendutube/v1/franchisee-management/franchisees/${franchisee_uid}/distribution-centers/add`, { body });
    };
    return _jsxs("div", { children: [_jsxs("div", Object.assign({ style: {
                    borderRadius: ".6em",
                    backgroundColor: "#fff",
                    margin: "5px",
                    padding: "12px",
                    //display: disp,
                    // boxShadow:
                    // " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                } }, { children: [_jsx("div", Object.assign({ style: { textAlign: "right" } }, { children: _jsx("span", Object.assign({ style: { cursor: "pointer" }, onClick: () => onClose === null || onClose === void 0 ? void 0 : onClose() }, { children: _jsx(X, {}, void 0) }), void 0) }), void 0), _jsx("h1", { children: "Distribution Details" }, void 0), _jsx("hr", { style: {
                            border: "0",
                            height: "0",
                            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                            borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                            marginBottom: "5px",
                        } }, void 0), _jsx("div", Object.assign({ style: { padding: "10px", color: "#e62222" } }, { children: error }), void 0), _jsxs(Form, Object.assign({ name: "add_distribution", onSubmit: handleSubmit }, { children: [_jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: 3 }, { children: _jsx(LabelledField, { name: "distribution_center_name", value: dist === null || dist === void 0 ? void 0 : dist.distribution_center_name, label: "Distribution Name" }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: 9 }, { children: _jsx(LabelledField, { name: "distribution_center_code", value: dist === null || dist === void 0 ? void 0 : dist.distribution_center_code, label: "Distribution Code" }, void 0) }), void 0)] }, void 0), _jsx("h1", Object.assign({ css: { marginTop: 20 } }, { children: "Address" }), void 0), _jsx("hr", { style: {
                                    border: "0",
                                    height: "0",
                                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                    marginBottom: "5px",
                                } }, void 0), _jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: 3 }, { children: _jsx(LabelledField, { name: "address_line1", value: distAddress === null || distAddress === void 0 ? void 0 : distAddress.address_line1, label: "Address Line 1" }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: 3 }, { children: _jsx(LabelledField, { name: "address_line2", value: distAddress === null || distAddress === void 0 ? void 0 : distAddress.address_line2, label: "Address Line 2" }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: 3 }, { children: _jsx(LabelledField, { name: "city", value: distAddress === null || distAddress === void 0 ? void 0 : distAddress.city, label: "City" }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: 3 }, { children: _jsx(LabelledField, { name: "pin_code", value: distAddress === null || distAddress === void 0 ? void 0 : distAddress.pin_code, label: "Pincode" }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: 3 }, { children: _jsx(LabelledField, { name: "landmark", value: distAddress === null || distAddress === void 0 ? void 0 : distAddress.landmark, label: "Landmark" }, void 0) }), void 0)] }, void 0), _jsx("h1", Object.assign({ css: { marginTop: 20 } }, { children: "Location" }), void 0), _jsx("hr", { style: {
                                    border: "0",
                                    height: "0",
                                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                                    marginBottom: "5px",
                                } }, void 0), _jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: 3 }, { children: _jsx(LabelledField, { name: "latitude", value: lat, label: "Latitude" }, void 0) }), void 0), _jsx(Col, Object.assign({ cols: 3 }, { children: _jsx(LabelledField, { name: "longitude", value: long, label: "Longitude" }, void 0) }), void 0), _jsx(Col, { children: _jsx("button", Object.assign({ type: "button", value: "Map", css: button, onClick: () => { setShowMap(true); } }, { children: "Map" }), void 0) }, void 0)] }, void 0), _jsx(Row, { children: _jsx(Col, Object.assign({ cols: 12 }, { children: _jsx("div", Object.assign({ style: { marginTop: "10px", textAlign: "right" } }, { children: _jsx("input", { type: "submit", value: "Submit", css: button }, void 0) }), void 0) }), void 0) }, void 0)] }), void 0)] }), void 0), _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: showMap, title: "Mapx", subtitle: "", width: "40%", onRequestClose: () => {
                    setShowMap(false);
                } }, { children: _jsx("div", { children: _jsx(DistributionCentreMap, { lngLat: [] }, void 0) }, void 0) }), void 0)] }, void 0);
};
