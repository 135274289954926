import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react  */
import { useStyles } from "@exprzn/react-style-lib";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Field, Form } from "../../../components/Form";
import { Input, InputArea } from "../../../components/Form/fields-extended";
import { MultiSelect } from "../../../components/MultiSelect";
import { UpdatedSelect } from "../../../components/Select";
import { usePagination } from "../../../saga/use-pagination";
import { useSaga } from "../../../saga/use-saga";
import { OptionRenderer } from "./option-renderer";
import { SelectRenderer } from "./select-renderer";
import { FormControl, MenuItem, Switch, TextField } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CategoryTreeView } from "./category-tree-view";
import { fulfilledTypes } from "../../../utils";
import Select from '@mui/material/Select';
import { global_org_uid } from "../../../config";
export const ProductAddProps = React.forwardRef((props, ref) => {
    var _a;
    const { product, saveProduct, mode, delivery_options: adminDeliveryOptions } = props;
    const { titlestyle, action_btn_ms, inputstyle } = useStyles("titlestyle", "action_btn_ms", "inputstyle");
    const [product_category_uid, setCategoryUid] = useState();
    const [product_classes_uid, setProductClassUids] = useState();
    const [deleted_class_uid, setDeletedClassUid] = useState();
    const [measure_unit_uid, setUnitUid] = useState();
    const [fulfilledType, setFulfilledType] = useState("2");
    const [productCategories, setProductCategories] = useState();
    const [productClasses, setProductClasses] = useState();
    const [units, setUnits] = useState([]);
    useEffect(() => {
        var _a;
        setCategoryUid((_a = product === null || product === void 0 ? void 0 : product.product_category) === null || _a === void 0 ? void 0 : _a.uid);
    }, [product === null || product === void 0 ? void 0 : product.product_category]);
    useEffect(() => {
        setProductClassUids(((product === null || product === void 0 ? void 0 : product.product_class) || []).map(pc => pc.uid));
    }, [product === null || product === void 0 ? void 0 : product.product_class]);
    useEffect(() => {
        var _a;
        setUnitUid((_a = product === null || product === void 0 ? void 0 : product.measure_unit) === null || _a === void 0 ? void 0 : _a.uid);
    }, [product === null || product === void 0 ? void 0 : product.measure_unit]);
    useEffect(() => {
        if (deleted_class_uid) {
            const selectedClasses = (product_classes_uid || []).filter(it => deleted_class_uid != it);
            setProductClassUids(selectedClasses);
            // console.log("UNSELECt", item, product_classes_uid, selectedClasses)
        }
    }, [deleted_class_uid]);
    const handleCategory = (categoryUid) => {
        setCategoryUid(categoryUid);
    };
    const handleClasses = (items) => {
        const classUids = items.map(i => i.uid);
        setProductClassUids(classUids);
    };
    const handleUnSelectClass = (item, index) => {
        //since direct setting of setProductClassUids is not working an indirect way is used
        setDeletedClassUid(item.uid);
    };
    const handleFulfilledType = (value, index) => {
        setFulfilledType(value);
    };
    const handleUnits = (unitUid, index) => {
        setUnitUid(unitUid);
    };
    const { data: categoryData } = useSaga("/trendutube/v1/public/categories/list", {}, false);
    const { data: unitData } = usePagination(`/trendutube/v1/admin-product-catalog/organizations/${global_org_uid}/measure-units/list`, 40);
    const { data: classData } = useSaga(`/trendutube/v1/product-catalog/organizations/${global_org_uid}/product-classes/list`, {}, false);
    useEffect(() => {
        if (categoryData) {
            const { categories } = categoryData;
            const cat = categories === null || categories === void 0 ? void 0 : categories.map((item, key) => {
                return { text: item.category_name, value: item.uid };
            });
            setProductCategories(categories);
        }
    }, [categoryData]);
    useEffect(() => {
        if (classData) {
            const { product_classes } = classData;
            setProductClasses(product_classes);
        }
    }, [classData]);
    useEffect(() => {
        if (unitData) {
            const unitArr = unitData.map(unit => ({ text: unit.unit_name, value: unit.uid }));
            setUnits(unitArr);
        }
    }, [unitData]);
    const [productDeliveryOptions, setproductDeliveryOptions] = useState([{
            type: '',
            number_of_days_for_delivery: 0,
            minimum_amount_of_products_for_free_delivery: 0,
            shipment_rate: 0,
            free_delivery_opt_out: false,
            delivery_mode_uid: '',
            is_default: false
        }]);
    function onSubmit(formData) {
        const { product } = formData || {};
        if (!formData || !product)
            return;
        const { product_name, product_description } = product;
        const product_delivery = productDeliveryOptions.filter(delivery => delivery.type === null || delivery.type.length === 0);
        const duplicates = productDeliveryOptions === null || productDeliveryOptions === void 0 ? void 0 : productDeliveryOptions.filter((delivery, index, self) => self.findIndex(d => d.type === delivery.type) !== index);
        const standardDeliveryMode = productDeliveryOptions === null || productDeliveryOptions === void 0 ? void 0 : productDeliveryOptions.filter((delivery) => delivery.type === "Standard Delivery");
        if (!product_name)
            toast.error("Product name is mandatory ");
        else if (!product_description)
            toast.error("Product description is mandatory");
        else if (!product_category_uid)
            toast.error("Category is mandatory");
        else if (!product_classes_uid || product_classes_uid.length <= 0)
            toast.error("Add atleast one class");
        else if (fulfilledType && fulfilledType === "2" && product_delivery.length > 0)
            toast.error("Provide correct Delivery Details");
        // else if (!fulfilledType)
        //     toast.error("Provide select a fulfilled type")
        else if (duplicates.length > 0) {
            toast.error("Contain same Delivery Duplicates");
        }
        // else if (fulfilledType === "2" && standardDeliveryMode?.length === 0) {
        //     toast.error("Standard Delivery Mode is Mandatory")
        // }
        else {
            const productValue = Object.assign(Object.assign({}, product), { product_category_uid, product_classes_uid, measure_unit_uid, delivery_option: productDeliveryOptions, fulfilled_type: fulfilledType });
            saveProduct(productValue);
        }
    }
    const getSelectedClasses = () => ((product === null || product === void 0 ? void 0 : product.product_class) || [])
        .map(pc => (productClasses || [])
        .findIndex(item => item.uid == pc.uid));
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);
    const [isCategoryTreeOpen, setIsCategoryTreeOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(() => {
        if (product_category_uid) {
            if (productCategories) {
                const selectedCategory = findCategoryByUid(productCategories, product_category_uid);
                setSelectedCategoryName((selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.category_name) || null);
            }
        }
        else {
            setSelectedCategoryName(null);
        }
    }, [product_category_uid, productCategories]);
    const findCategoryByUid = (categories, uid) => {
        for (const category of categories) {
            if (category.uid === uid) {
                return category;
            }
            if (category.sub_categories) {
                const found = findCategoryByUid(category.sub_categories, uid);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };
    const handleSelectCategory = (uid) => {
        setCategoryUid(uid);
        setIsCategoryTreeOpen(false);
    };
    const handleTextFieldClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsCategoryTreeOpen(!isCategoryTreeOpen);
    };
    const handleAddForms = () => {
        setproductDeliveryOptions([...productDeliveryOptions, {
                type: '',
                number_of_days_for_delivery: 0,
                minimum_amount_of_products_for_free_delivery: 0,
                shipment_rate: 0,
                free_delivery_opt_out: false,
                delivery_mode_uid: '',
                is_default: false
            }]);
    };
    const handleRemoveForms = (index) => {
        setproductDeliveryOptions(productDeliveryOptions.filter((_, i) => i !== index));
    };
    const [deliveries, setDeliveries] = useState([{ type: '' }]);
    const [openStates, setOpenStates] = useState(deliveries.map(() => false));
    const handleChange = (index, e) => {
        const { value } = e.target;
        const selectedDeliveryOption = adminDeliveryOptions.find(d => d.type === value);
        if (selectedDeliveryOption) {
            const { number_of_days_for_delivery, shipment_rate, delivery_mode_uid, type } = selectedDeliveryOption;
            const updatedDeliveryModes = productDeliveryOptions === null || productDeliveryOptions === void 0 ? void 0 : productDeliveryOptions.map((mode, i) => i === index ? Object.assign(Object.assign({}, mode), { number_of_days_for_delivery, shipment_rate, type, delivery_mode_uid, delivery_option_uid: selectedDeliveryOption === null || selectedDeliveryOption === void 0 ? void 0 : selectedDeliveryOption.uid }) : mode);
            setproductDeliveryOptions(updatedDeliveryModes);
        }
    };
    const handleOpen = (index) => {
        setOpenStates((prevOpenStates) => {
            const newOpenStates = [...prevOpenStates];
            newOpenStates[index] = true;
            return newOpenStates;
        });
    };
    const handleClose = (index) => {
        setOpenStates((prevOpenStates) => {
            const newOpenStates = [...prevOpenStates];
            newOpenStates[index] = false;
            return newOpenStates;
        });
    };
    const handleEnableChange = (index) => {
        const updatedDeliveryModes = productDeliveryOptions.map((mode, i) => i === index ? Object.assign(Object.assign({}, mode), { free_delivery_opt_out: !mode.free_delivery_opt_out }) : mode);
        setproductDeliveryOptions(updatedDeliveryModes);
    };
    return _jsxs("div", { children: [_jsx(Form, Object.assign({ name: "product", onSubmit: onSubmit }, { children: _jsxs("div", { children: [_jsx("button", Object.assign({ type: "submit", css: { display: "none" }, ref: ref }, { children: "Save" }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Product Name" }), void 0), _jsx(Field, Object.assign({ name: "product_name", value: product === null || product === void 0 ? void 0 : product.product_name }, { children: _jsx(Input, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Product Description " }), void 0), _jsx(Field, Object.assign({ name: "product_description", value: product === null || product === void 0 ? void 0 : product.product_description }, { children: _jsx(InputArea, { rows: 6 }, void 0) }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Product Category " }), void 0), _jsx("div", { children: productCategories ?
                                _jsxs(_Fragment, { children: [_jsx(TextField, { value: selectedCategoryName || "Choose Category", onClick: handleTextFieldClick, fullWidth: true, InputProps: {
                                                endAdornment: (isCategoryTreeOpen ? _jsx(ExpandLess, { sx: { color: 'gray' } }, void 0) : _jsx(ExpandMore, { sx: { color: 'gray' } }, void 0)),
                                                readOnly: true,
                                            }, sx: {
                                                backgroundColor: '#F6F8F9', border: 'none',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none', // Removes the outer border
                                                },
                                            } }, void 0), isCategoryTreeOpen && (_jsx("div", Object.assign({ style: { position: 'relative', top: '100%', zIndex: 9999 } }, { children: _jsx(CategoryTreeView, { categories: productCategories, onSelectCategory: handleSelectCategory, selectedCategoryUid: product_category_uid || null }, void 0) }), void 0))] }, void 0) : null }, void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Product class " }), void 0), productClasses ? _jsx(MultiSelect, { title: "select", items: productClasses, titleProperty: "product_class_name", view: OptionRenderer, selectIndices: getSelectedClasses(), onSelectedListChange: handleClasses, onUnSelectItem: handleUnSelectClass, selectView: SelectRenderer }, void 0) : null, _jsx("div", Object.assign({ css: titlestyle }, { children: "Units" }), void 0), units ?
                            _jsx(UpdatedSelect, { selecttext: "Please select a unit", title: "product_category_uid", items: units, onSelectItem: handleUnits, keyval: ((_a = product === null || product === void 0 ? void 0 : product.measure_unit) === null || _a === void 0 ? void 0 : _a.uid) || "" }, void 0) : null, _jsx("div", Object.assign({ css: titlestyle }, { children: "Product Brand" }), void 0), _jsx(Field, Object.assign({ name: "product_brand", value: product === null || product === void 0 ? void 0 : product.product_brand }, { children: _jsx(Input, {}, void 0) }), void 0), (product === null || product === void 0 ? void 0 : product.has_variant) ?
                            _jsxs(_Fragment, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Product Price" }), void 0), _jsx(Field, Object.assign({ name: "product_price", value: product === null || product === void 0 ? void 0 : product.product_price }, { children: _jsx(Input, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Product Stock" }), void 0), _jsx(Field, Object.assign({ name: "product_stock", value: product === null || product === void 0 ? void 0 : product.product_stock }, { children: _jsx(Input, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Product Manufacturer" }), void 0), _jsx(Field, Object.assign({ name: "product_manufacturer", value: product === null || product === void 0 ? void 0 : product.product_manufacturer }, { children: _jsx(Input, {}, void 0) }), void 0)] }, void 0) : null, _jsx("div", Object.assign({ css: titlestyle }, { children: "Product Limit Per User" }), void 0), _jsx(Field, Object.assign({ name: "product_limit_per_user", value: product === null || product === void 0 ? void 0 : product.product_limit_per_user }, { children: _jsx(Input, {}, void 0) }), void 0)] }, void 0) }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Fulfilled Type" }), void 0), _jsx(UpdatedSelect, { selecttext: "Please select a Type", title: "product_category_uid", items: fulfilledTypes, onSelectItem: handleFulfilledType, keyval: fulfilledType || "" }, void 0), fulfilledType === "2" ? productDeliveryOptions.map((delivery, index) => (_jsxs("div", { children: [_jsxs("span", { children: [_jsxs("div", { children: [index + 1, " Delivery Option"] }, void 0), index > 0 ?
                                _jsx("button", Object.assign({ css: action_btn_ms, onClick: () => { handleRemoveForms(index); }, style: { marginRight: 10 } }, { children: "Cancel" }), void 0) :
                                null] }, void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Delivery Mode " }), void 0), _jsx(FormControl, Object.assign({ sx: {
                            position: "relative",
                            padding: "0.8em 0.8em",
                            width: "100%",
                            backgroundColor: "#ffffff",
                            border: "none",
                            color: "#111",
                            fontWeight: 600,
                            fontSize: "1em",
                        } }, { children: _jsx(Select, Object.assign({ id: "demo-controlled-open-select", open: openStates[index], name: "type", onClose: () => handleClose(index), onOpen: () => handleOpen(index), value: delivery.type, onChange: (e) => handleChange(index, e), sx: {
                                backgroundColor: '#F6F8F9',
                                border: 'none'
                            } }, { children: adminDeliveryOptions && adminDeliveryOptions.map((item) => (_jsx(MenuItem, Object.assign({ value: item.type }, { children: item.type }), item.uid))) }), void 0) }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Number Of Days For Free Delivery" }), void 0), _jsx("input", { type: "number", name: "minimum_number_of_days_for_free_delivery", value: delivery.number_of_days_for_delivery, css: inputstyle, disabled: true }, void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: " Opt Out For Free Delivery" }), void 0), _jsx(Switch, { name: "free_delivery_opt_out", checked: delivery.free_delivery_opt_out, onChange: (e) => handleEnableChange(index), inputProps: { 'aria-label': 'controlled' } }, void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Shipping Rate" }), void 0), _jsx("input", { type: "number", name: "shipment_rate", value: delivery.shipment_rate, disabled: true, css: inputstyle }, void 0)] }, index))) : null, fulfilledType === "2" ? _jsx("button", Object.assign({ css: action_btn_ms, onClick: () => { handleAddForms(); }, style: { marginRight: 10, marginTop: 5 } }, { children: "Add Delivey Options" }), void 0) : null] }, void 0);
});
