import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportsource @emotion/react */
import { useState } from "react";
import { useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { Row } from "../../../components/Row";
import { Checkbox } from "../../../components/Form/fields-extended";
export const VariantTypeAddComponent = (props) => {
    const { addVariantType } = props;
    const [name, setName] = useState();
    const [type, setType] = useState();
    const [affects_pricing, setAffectPricing] = useState(false);
    const { button, inputstyle, } = useStyles("inputstyle", "button");
    const handleClick = () => {
        if (!name || name == "")
            alert("Enter a valid name");
        else if (!type)
            alert("Select a valid type");
        else
            addVariantType({ affects_pricing, name, type });
    };
    const handleRadio = (e) => {
        setType(e.currentTarget.value);
    };
    return _jsxs("div", { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", Object.assign({ css: { marginTop: 1, fontWeight: 600, fontSize: 16, marginBottom: 10 } }, { children: " Variant Type" }), void 0), _jsxs("div", Object.assign({ css: { marginLeft: 50 } }, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsx("input", { onChange: handleRadio, name: "variant-type", type: "radio", value: "color", css: { marginRight: 10 } }, void 0), "Color"] }, void 0) }, void 0), _jsxs(Row, { children: [_jsxs(Col, { children: [_jsx("input", { onChange: handleRadio, name: "variant-type", type: "radio", value: "string", css: { marginRight: 10 } }, void 0), "String"] }, void 0), " "] }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsx("input", { onChange: handleRadio, name: "variant-type", type: "radio", value: "image", css: { marginRight: 10 } }, void 0), "Image"] }, void 0) }, void 0)] }), void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", Object.assign({ css: { marginTop: 10, fontWeight: 600, fontSize: 16, marginBottom: 5 } }, { children: "Variant Type Name" }), void 0), _jsx("input", { css: inputstyle, type: "text", onChange: (e) => setName(e.target.value) }, void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [" ", _jsxs("div", Object.assign({ css: { marginTop: 2, fontWeight: 600, fontSize: 16, marginBottom: 10 } }, { children: ["Does it affect pricing ? \u00A0", _jsx(Checkbox, { onChange: (e) => setAffectPricing(e.target.checked) }, void 0), " "] }), void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsx(Col, Object.assign({ right: true }, { children: _jsx("div", { children: _jsx("button", Object.assign({ css: button, onClick: handleClick, style: { backgroundColor: '#045998', boxShadow: 'none' } }, { children: " Add " }), void 0) }, void 0) }), void 0) }, void 0)] }, void 0);
};
