import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import ReactSlidingPane from "react-sliding-pane";
import { Col } from "../../../components/Column";
import { Row } from "../../../components/Row";
export const ProductAttributesComponent = (props) => {
    const { isOpen, onClose } = props;
    const { product } = props;
    const { attribute_sets } = product || {};
    return _jsx("div", { children: _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isOpen, title: "Attributes", subtitle: "", width: "40%", onRequestClose: () => {
                onClose(false);
            } }, { children: _jsx("div", { children: _jsx(ProductAttributesView, { attribute_sets: attribute_sets }, void 0) }, void 0) }), void 0) }, void 0);
};
export const ProductAttributesView = (props) => {
    const { attribute_sets = [] } = props;
    return _jsx("div", { children: _jsx(Row, { children: _jsx(Col, { children: attribute_sets.map(attrSet => _jsxs("div", { children: [_jsx("div", Object.assign({ css: { marginTop: 24, fontSize: 19, fontWeight: 600, color: "#f6b200" } }, { children: attrSet.template && attrSet.template.attribute_set_name }), void 0), attrSet.attribute_groups && attrSet.attribute_groups.map(group => _jsx("div", { children: _jsxs("div", Object.assign({ css: { marginTop: 20 } }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ css: { fontSize: 17, fontWeight: 600 } }, { children: group.attribute_group_name }), void 0), _jsx("div", Object.assign({ css: { color: "#a1a1af", fontSize: 14, marginBottom: "8px", fontWeight: 600, borderBottom: "1px solid black" } }, { children: group.attribute_group_description }), void 0)] }, void 0), _jsx("table", Object.assign({ width: "100%" }, { children: group.attributes && group.attributes.map(attrib => {
                                            return _jsxs("tr", Object.assign({ css: { borderBottom: "1px solid #dfdfdf" } }, { children: [_jsxs("td", Object.assign({ css: { padding: "8px 20px 20px 0px", } }, { children: [_jsx("div", Object.assign({ css: { fontSize: 16, fontWeight: 600, color: "#676767" } }, { children: attrib.attribute_name }), void 0), _jsx("div", Object.assign({ css: { fontSize: 14 } }, { children: attrib.attribute_description }), void 0)] }), void 0), _jsx("td", Object.assign({ css: { padding: "8px 20px 20px 0px" } }, { children: attrib.attribute_value }), void 0)] }), void 0);
                                        }) }), void 0)] }), void 0) }, void 0))] }, void 0)) }, void 0) }, void 0) }, void 0);
};
