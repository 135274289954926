import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from '@exprzn/react-style-lib';
import { toArray } from '../../utils';
import { Col } from '../Column';
import { Row } from '../Row';
export const StreamNav = ({ stream, loadPage, loadNextPage, maxPage, getUrl }) => {
    const { productstyle, streampagestyle, selectedstyle } = useStyles("productstyle", "streampagestyle", "selectedstyle");
    const clickNextHandler = () => {
        if (stream) {
            window.history.replaceState("", "", getUrl(Number(stream.page) + 1));
            loadNextPage();
        }
    };
    //returns a fn
    const clickPage = (pageNo) => () => {
        window.history.replaceState("", "", getUrl(pageNo));
        loadPage(pageNo);
    };
    return _jsx(Row, { children: _jsx(Col, Object.assign({ cols: 12 }, { children: _jsxs("div", Object.assign({ css: productstyle }, { children: [maxPage && stream ?
                        toArray(maxPage).map((pageNo) => _jsx("button", Object.assign({ css: stream.page == pageNo ? selectedstyle : streampagestyle, onClick: clickPage(pageNo) }, { children: pageNo }), void 0))
                        : 'Pages', !stream || stream.has_next ? _jsx("button", Object.assign({ css: streampagestyle, onClick: clickNextHandler }, { children: ">" }), void 0) : null] }), void 0) }), void 0) }, void 0);
};
