import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component, Fragment } from 'react';
import { Layer } from './Layer';
import { css, getPositionInViewport } from '@exprzn/dom';
function getRect(target, source, align = "right top", flip = "none") {
    const sRect = getPositionInViewport(source);
    let trgtPos = calcTargetPositionInViewPort(target, align);
    const diffs = { left: sRect.left - (trgtPos.left), top: sRect.top - (trgtPos.top) };
    const cssLeft = parseInt(css(source, "left"));
    const cssTop = parseInt(css(source, "top"));
    console.log("CSS", { cssLeft, cssTop });
    let top = cssTop - diffs.top, left = cssLeft - diffs.left;
    console.log("final", { left, top }, "target", trgtPos, "diffs", diffs, "original src", sRect, "offsets", source.offsetTop, source.offsetWidth);
    return { left, top };
}
function calcTargetPositionInViewPort(targetNode, align = "right top", flip = "none") {
    const [halign, valign] = align.split(" ");
    const targetRect = getPositionInViewport(targetNode);
    const targetHeight = targetNode.offsetHeight, targetWidth = targetNode.offsetWidth;
    let left, top, width, height;
    switch (halign) {
        case "left":
            width = 0;
            break;
        case "middle":
            width = targetNode.offsetWidth / 2;
            break;
        case "right":
        default:
            width = targetNode.offsetWidth;
    }
    switch (valign) {
        case "bottom":
            height = targetNode.offsetHeight;
            break;
        case "center":
            height = targetNode.offsetHeight / 2;
            break;
        case "top":
        default:
            height = 0;
    }
    /*
    const { clientHeight: totHeight, clientWidth: totWidth } = document.documentElement

    switch (flip) {
            case "vertical":
                    rect = { bottom: totHeight - top, left }
                    break;
            case "horizontal":
                    rect = { top, right: totWidth - left } // +1 ?
                    break;
            case "both":
                    rect = {
                            bottom: totHeight - top,
                            right: totWidth - left
                    }
                    break;
            case "none":
            default:
                    rect = { top, left }
    }
    */
    left = targetRect.left + width;
    top = targetRect.top + height;
    // const pos = { top: targetRect.top! + targetNode.offsetHeight, left: (targetRect.left! + width) }
    return { left, top };
}
export class RelPositioner extends Component {
    constructor(p) {
        super(p);
        this.layerRef = React.createRef();
        this.state = { show: true, position: {} };
    }
    componentDidMount() {
        let pos = {};
        let align = "right top";
        if (this.props.align instanceof Array)
            align = this.props.align.join(" ");
        else
            align = this.props.align;
        let target = undefined;
        if (typeof this.props.target === 'function') {
            target = this.props.target();
            pos = getRect(target, this.layerRef.current, align, this.props.flip);
        }
        else {
            const { x: left, y: top } = this.props.target;
            pos = { left, top };
        }
        this.setState({ position: pos });
        const mousedownListener = (event) => {
            var _a;
            if ((_a = this.layerRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
                if (this.props.onClickedInSide) {
                    this.props.onClickedInSide();
                }
            }
            else {
                if (this.props.onClickedOutSide)
                    this.props.onClickedOutSide();
                document.removeEventListener("mousedown", mousedownListener);
            }
        };
        document.addEventListener("mousedown", mousedownListener);
    }
    render() {
        return _jsx(Fragment, { children: _jsx(Layer, Object.assign({ ref: this.layerRef, position: this.state.position, style: { border: '1px solid green' }, width: this.props.width }, { children: this.props.children }), void 0) }, void 0);
    }
}
