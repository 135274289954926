import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { Row } from "../../../components/Row";
import { StreamNav } from "../../../components/StreamNav";
import { useStream } from "../../../saga/use-stream";
import { useProgressBar } from "../../../components/ProgressBar";
import { SearchToolBar } from "../../../components/Search";
export const Basket = (data) => {
    const { uid, status, delivery_date, basket_gross_price_amount, basket_gross_discount, basket_id, delivery_slot, ordered_by, basket_items, images = [] } = data;
    //1st element or undefined
    const buyer = ordered_by;
    const { rowstyle, productstyle } = useStyles("rowstyle", "productstyle");
    const deliveryDate = new Date(delivery_date);
    const { personal_profile, login_profile, buyer_profile, buyer_uid } = ordered_by || {};
    const { first_name, last_name, full_name } = personal_profile;
    const { address } = buyer_profile;
    const { username } = login_profile;
    const buyer_info = { first_name, last_name, full_name, address, username };
    const [addr] = address || [];
    const { address_line1, address_line2, city, pin_code, landmark, location } = addr || {};
    const line1 = Object.values({ address_line1, address_line2, city }).join(", ");
    const line2 = Object.values({ city, pin_code }).join(", ");
    const line3 = Object.values({ landmark }).join(", ");
    const displayAddress = addr ? Object.values({ line1, line2, line3 }).join("\n") : "";
    return _jsx("div", Object.assign({ css: productstyle, tabIndex: 2 }, { children: _jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: [12, 1] }, { children: basket_id }), void 0), _jsx(Col, Object.assign({ cols: 2 }, { children: deliveryDate.toLocaleString('en-IN', { timeZone: 'Asia/Calcutta' }) }), void 0), _jsx(Col, Object.assign({ cols: [12, 1] }, { children: status }), void 0), _jsxs(Col, Object.assign({ cols: [12, 4] }, { children: [_jsxs("div", { children: [" ", line1] }, void 0), _jsxs("div", { children: [" ", line2] }, void 0), _jsxs("div", { children: [" ", line3] }, void 0)] }), void 0), _jsx(Col, { children: _jsx("div", { children: images.map(image => _jsx("img", { width: "32", height: "32", style: { borderRadius: 4, margin: 4 }, src: image.url }, void 0)) }, void 0) }, void 0), _jsx(Col, { children: _jsx(NavLink, Object.assign({ to: `/dashboard/basket-orders/${uid}/details` }, { children: "View" }), void 0) }, void 0)] }, void 0) }), void 0);
};
export const BasketOrderInProgress = (props) => {
    const { routerProps, path } = props;
    const { page: pageStr } = routerProps.match.params;
    const page = parseInt(`${pageStr}`);
    const { data, stream, loadPage, loadUrl, loadNextPage, isFetching } = useStream("/trendutube/v1/basket-management/admin/baskets/list", { page });
    const [maxPage, setMaxPage] = useState(page);
    const { productstyle, pagestyle } = useStyles("productstyle", "pagestyle");
    const loading = useProgressBar();
    useEffect(() => {
        if (stream && (stream.page > maxPage))
            setMaxPage(stream.page);
    }, [stream]);
    useEffect(() => {
        loading(isFetching);
    }, [isFetching]);
    function onSearchChange(name, filters) {
        const qp = { name };
        if (name && name.length > 2) {
            filters.forEach(filter => {
                if (filter.filter)
                    qp[filter.value] = name;
            });
            loadUrl("/trendutube/v1/basket-management/admin/baskets/list", 0, qp);
        }
        else {
            loadUrl("/trendutube/v1/basket-management/admin/baskets/list", 0, {});
        }
    }
    const filters = [
        { title: "Basket Id", value: "basket_id", filter: false },
        // { title: "Buyer (First", value: "buyer_search_string", filter: false },
        { title: "Store Uid", value: "store_uid", filter: false },
        { title: "Buyer Uid", value: "buyer_uid", filter: false },
        // { title: "Order Id", value: "order_id", filter: true },
        // { title: "Username (email/mobile number)", value: "username", filter: false },
        // { title: "Buyer First Name", value: "first_name", filter: false },
        // { title: "Buyer Last Name", value: "last_name", filter: false },
        // { title: "Buyer Pincode", value: "pin_code", filter: false },
        // { title: "Buyer City", value: "city", filter: false },
    ];
    return _jsxs("div", { children: [_jsx("div", Object.assign({ css: productstyle }, { children: _jsx(SearchToolBar, { onChange: onSearchChange, filters: filters }, void 0) }), void 0), _jsx(StreamNav, Object.assign({}, {
                loadNextPage, loadPage, stream, maxPage,
                getUrl: (page) => `${path}/basket-orders/in-progress/${page}`
            }), void 0), _jsx("div", Object.assign({ css: productstyle }, { children: _jsx("div", Object.assign({ css: { fontWeight: 600 } }, { children: _jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: [12, 1] }, { children: "Order Id" }), void 0), _jsx(Col, Object.assign({ cols: 2 }, { children: "Date & Time" }), void 0), _jsx(Col, Object.assign({ cols: [12, 1] }, { children: "Status" }), void 0), _jsx(Col, Object.assign({ cols: [12, 4] }, { children: "Address" }), void 0), _jsx(Col, { children: "Items" }, void 0), _jsx(Col, { children: "View" }, void 0)] }, void 0) }), void 0) }), void 0), data && data.map((item) => _jsx(Basket, Object.assign({}, item), void 0))] }, void 0);
};
