import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import React from "react";
export function Table(props) {
    const { tablestyle } = useStyles("tablestyle");
    const { header, body = [], keys = [], component, style, itemOnClick, getKey } = props;
    const PassedComponent = component;
    return _jsxs("table", Object.assign({ css: style || tablestyle }, { children: [_jsx("thead", { children: _jsx("tr", { children: keys.map(key => _jsx("th", { children: header[key] }, void 0)) }, void 0) }, void 0), _jsx("tbody", { children: body.map((row, index) => _jsx("tr", { children: _jsx(PassedComponent, { data: row, itemOnClick: itemOnClick }, getKey ? getKey(row) : index) }, void 0)) }, void 0)] }), void 0);
}
