import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { Row } from '../Row';
import { Col } from '../Column';
import { useStyles } from '@exprzn/react-style-lib';
import { toArray } from '../../utils';
export const Pagination = ({ paging, loadPage }) => {
    const { pagestyle, pageselectstyle } = useStyles("pagestyle", "pageselectstyle");
    return _jsx(Row, { children: _jsx(Col, Object.assign({ cols: 12 }, { children: _jsx("div", { children: paging && paging.total_pages >= 1 ?
                    toArray(paging.total_pages).map((pageNo) => _jsx("button", Object.assign({ css: pageNo == paging.page ? pageselectstyle : pagestyle, onClick: () => { loadPage(pageNo); } }, { children: pageNo }), pageNo)) : 'Pages' }, void 0) }), void 0) }, void 0);
};
