var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
export const { style, container } = registry("product-details", true);
let ProductDetailStyle = class ProductDetailStyle extends CssStyle {
};
ProductDetailStyle.style = {
    padding: "30px",
    backgroundColor: "white"
};
ProductDetailStyle = __decorate([
    style("detailsstyle")
], ProductDetailStyle);
let MainStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class MainStyle extends CssStyle {
};
MainStyle.style = {
    padding: "2em",
    backgroundColor: 'white',
    borderRadius: "10px"
};
MainStyle = __decorate([
    style('containerstyle')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], MainStyle);
let AttribStyle = 
//eslint-disable-next-line @typescript-eslint/no-unused-vars
class AttribStyle extends CssStyle {
};
AttribStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1.2em",
    padding: "1.5em"
};
AttribStyle = __decorate([
    style("attribstyle")
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
], AttribStyle);
let AttribTitleRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class AttribTitleRowStyle extends CssStyle {
};
AttribTitleRowStyle.style = {
    fontWeight: 600,
    color: "#555555",
    margin: "8px 0px"
};
AttribTitleRowStyle = __decorate([
    style("attribtitlestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], AttribTitleRowStyle);
let AttribDescRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class AttribDescRowStyle extends CssStyle {
};
AttribDescRowStyle.style = {
    fontWeight: 400,
    color: "#555555",
    fontSize: ".9em"
};
AttribDescRowStyle = __decorate([
    style("attribdescstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], AttribDescRowStyle);
let TitleRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TitleRowStyle extends CssStyle {
};
TitleRowStyle.style = {
    fontWeight: 400,
    color: "#888888",
    margin: "8px 0px 0px 0px",
    fontSize: ".85em"
};
TitleRowStyle = __decorate([
    style("titlestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TitleRowStyle);
let TextStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TextStyle extends CssStyle {
};
TextStyle.style = {
    fontWeight: 700,
    color: "#555555",
    margin: "0px 0px 8px 0px",
    fonctSize: "1em"
};
TextStyle = __decorate([
    style("textstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TextStyle);
let GroupTextStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class GroupTextStyle extends CssStyle {
};
GroupTextStyle.style = {
    fontWeight: 500,
    color: "#efefef",
    margin: "0px 0px 0px 0px"
};
GroupTextStyle = __decorate([
    style("grouptextstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], GroupTextStyle);
let GroupTitleStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class GroupTitleStyle extends CssStyle {
};
GroupTitleStyle.style = {
    fontWeight: 600,
    color: "#ffffff",
    margin: "0px 0px 0px 0px"
};
GroupTitleStyle = __decorate([
    style("grouptitlestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], GroupTitleStyle);
let Grid = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Grid extends CssStyle {
};
Grid.style = {
    display: "grid",
    gap: "10px",
    "grid-template-columns": "repeat(2, 1fr)",
    "grid-auto-rows": "minmax(100px, auto)",
    border: "1px solid #f8b631",
    borderRadius: "0px 0px 8px 8px",
    minHeight: "4em",
    color: "#555555"
};
Grid = __decorate([
    style("gridstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], Grid);
let GroupStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class GroupStyle extends CssStyle {
};
GroupStyle.style = {
    background: "#f8b631",
    borderRadius: "8px 8px 0px 0px",
    padding: "10px",
    color: "white",
};
GroupStyle = __decorate([
    style("groupstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], GroupStyle);
let GroupContStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class GroupContStyle extends CssStyle {
};
GroupContStyle.style = {
    margin: "3em 0",
    color: "white",
};
GroupContStyle = __decorate([
    style("groupcontainerstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], GroupContStyle);
let AttribItemStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class AttribItemStyle extends CssStyle {
};
AttribItemStyle.style = {
    padding: "10px"
};
AttribItemStyle = __decorate([
    style("attribitemstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], AttribItemStyle);
