export function createReducer(prefix) {
    return function (state, action) {
        var _a, _b, _c;
        const [act, pr] = (_a = action === null || action === void 0 ? void 0 : action.type) === null || _a === void 0 ? void 0 : _a.split(":");
        if (prefix !== pr)
            return state || {};
        let newState = {};
        switch (act) {
            case 'FETCH_DATA':
                newState = { items: (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data, status: "done" };
                break;
            case 'FETCH_ERROR':
                const { error } = action === null || action === void 0 ? void 0 : action.payload;
                newState = { error, status: "error" };
                break;
            case 'FETCH_STARTED':
                newState = { status: "started" };
                break;
            case 'FETCH_META':
                newState = { meta: (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.stream, status: "done" };
                break;
        }
        return Object.assign(Object.assign({}, state), newState);
    };
}
