import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import { FieldContext } from './type';
class FieldContextImpl2 {
    constructor(path, form, isSelect = true) {
        this.path = path;
        this.form = form;
        this.isSelect = isSelect;
    }
    setError({ code, message }) {
        this.errors.push({ code, message });
    }
    setErrors(errors) {
        errors.forEach(err => this.errors.push(Object.assign({}, err)));
    }
    clear() {
        this.errors = [];
    }
}
export class FieldSelect extends Component {
    // context!: FieldContextProps
    getChildren() {
        const { props, context } = this;
        const { children } = props;
        let { path } = context;
        // const thisPath = path ? `${path}.${name}` : name
        const thisPath = path;
        const fieldContext = new FieldContextImpl2(thisPath, this.context.form);
        return _jsx(FieldContext.Provider, Object.assign({ value: fieldContext }, { children: children }), void 0);
    }
    render() {
        return this.getChildren();
    }
}
FieldSelect.contextType = FieldContext;
