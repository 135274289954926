export class ValidationException extends Error {
}
export const validationKeys = [
    "min",
    "max",
    "number",
    "required",
    "notEmpty",
    "notBlank",
    "notJustWs",
    "email",
    "alphanumeric",
    "alphabet",
    "range",
    "regex",
];
export const defaultValidVal = {
    min: Number.MIN_VALUE, max: Number.MAX_VALUE,
    required: true, notEmpty: true, notBlank: true, notJustWs: true,
    regex: /^.*$/, email: false, range: [0, 0], number: true,
    alphanumeric: true, alphabet: true
};
export function validateField(value, expr, fieldName) {
    for (var v in expr) {
        switch (v) {
            case "number":
                if (expr["number"] && !value.match(/^[\.\d]+$/))
                    throw new ValidationException(`${fieldName} should be a number`);
                break;
            case "min":
                if (expr["min"] && value < expr["min"])
                    throw new ValidationException("min error");
                break;
            case "max":
                if (expr["max"] && value > expr["max"])
                    throw new ValidationException(`${fieldName} max error`);
                break;
            case "notBlank":
                // previously /^[ \t\n\r]*$/
                if (expr["notBlank"] && value.match(/^\s+$/))
                    throw new ValidationException(`field ${fieldName} is blank or empty`);
                break;
            case "notEmpty":
                // previously   length==0
                if (expr["notEmpty"] && value.match(/^$/))
                    throw new ValidationException(`field ${fieldName} is empty`);
                break;
            case "regex":
                // previously   length==0
                if (expr["regex"] && value.match(expr["regex"]))
                    throw new ValidationException(`field ${fieldName} is empty`);
                break;
            case "alphanumeric":
                if (expr["alphanumeric"] && !value.match(/^[a-​zA-Z0-9]+$/))
                    throw new ValidationException(`${fieldName} should be alpha numeric`);
                break;
            case "alphabet":
                if (expr["alphabet"] && !value.match(/^[a-​zA-Z]*$/))
                    throw new ValidationException(`${fieldName} should be alphabets`);
                break;
            case "email":
                const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (expr["email"] && !emailRegex.test(String(value).toLowerCase()))
                    throw new ValidationException(`${fieldName} invalid email`);
                break;
        }
    }
}
