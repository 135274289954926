var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { css } from '@theme-ui/css';
import React, { useContext } from 'react';
import { StyleableComponent, StyleContext, StyleProvider, styling } from '@exprzn/react-style-lib';
import thisContainer from './style';
export class ColInternal extends StyleableComponent {
    render() {
        const { colstyle, props } = this;
        const { cols = [], stretch, top, left, center, bottom, right } = props;
        let widthCss = {};
        const columns = Array.isArray(cols) ? cols : [cols];
        const widths = columns.map(w => `${8.333333 * w}%`);
        widthCss = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ width: widths, flex: props.cols ? "0 0 auto" : "1 0 0%" }, top && { alignSelf: "start" }), bottom && { alignSelf: "end" }), stretch && { alignItems: "stretch" }), left && { justifySelf: "flex-start" }), right && { justifySelf: "flex-end" }), center && { justifySelf: "center", alignItems: "center" });
        const { theme } = this.context;
        const dynamicWidth = css(widthCss)(theme);
        // console.log("dynamicWidth", cols, columns, widths, dynamicWidth)
        return _jsx("div", Object.assign({ css: [colstyle, dynamicWidth] }, { children: this.props.children }), void 0);
    }
}
ColInternal.contextType = StyleContext;
__decorate([
    styling(),
    __metadata("design:type", Object)
], ColInternal.prototype, "colstyle", void 0);
//We shoudl avoid setting style context/provider  here !
export const Col = (props) => {
    const { container } = useContext(StyleContext);
    container === null || container === void 0 ? void 0 : container.merge(thisContainer);
    return _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(ColInternal, Object.assign({}, props, { children: props.children }), void 0) }), void 0);
};
