import { toast } from "react-toastify";
export const toArray = (pages) => {
    const arr = [];
    for (let i = 1; i <= pages; i++)
        arr.push(i);
    return arr;
};
export const fancyStatus = (status) => {
    let fancyStatus;
    switch (status) {
        case 'buyer-checked-out':
            fancyStatus = "Checked out";
            break;
        case 'delivery-picked-up':
            fancyStatus = "Picked up for delivery";
            break;
        case 'seller-accepted':
            fancyStatus = "Seller Accepted";
            break;
        default:
            fancyStatus = status;
    }
    return fancyStatus;
};
export const formatTime = (dateTime) => {
    return new Date(Date.parse(dateTime)).toLocaleString('en-IN', { timeZone: 'Asia/Calcutta' });
};
export const formatCurrency = (amount) => {
    const amountStr = Math.round(amount * 100 / 100).toFixed(2);
    return `\u20B9${amountStr}`;
};
export const rs = (amount) => formatCurrency(parseFloat(`${amount}`));
export const paymentStatusToHumanReadable = (status) => {
    let statusHumanReadable = "";
    switch (status) {
        case "not_commenced":
            statusHumanReadable = "Not Yet Commenced";
            break;
        case "completed":
            statusHumanReadable = "Payment Completed";
            break;
    }
    return statusHumanReadable;
};
export const discountType = (type) => {
    let discount_type = "";
    switch (type) {
        case "fixed_amount":
            discount_type = "Fixed Amount";
            break;
        case "percentage":
            discount_type = "Percentage";
            break;
        case "fixed-amount":
            discount_type = "Fixed Amount";
            break;
    }
    return discount_type;
};
export const paymentModeToHumanReadable = (status) => {
    let statusHumanReadable = "";
    switch (status) {
        case "cod":
            statusHumanReadable = "Cash On Delivery";
            break;
        case "online":
            statusHumanReadable = "Online Payment";
            break;
    }
    return statusHumanReadable;
};
export const deliverySlotToHumanReadable = (slot) => {
    let statusHumanReadable = slot;
    switch (slot) {
        case "slot_5am_to_8am":
            statusHumanReadable = "5AM to 8AM";
            break;
        case "slot_8am_to_12am":
            statusHumanReadable = "8AM to 12AM";
            break;
        case "slot_12pm_to_7pm":
            statusHumanReadable = "12PM to 7PM";
            break;
        case "9:00 ~ 11:00":
            statusHumanReadable = "9PM to 11PM";
            break;
    }
    return statusHumanReadable;
};
export const uidWOPrefix = (uid) => uid.replace(/^(0x|0X)/, "");
export const idOf = (uid) => `ID:${uidWOPrefix(uid).toUpperCase()}`;
export const idOfType = (uid, type) => `${type.toUpperCase()}:ID:${uidWOPrefix(uid).toUpperCase()}`;
export const NA = "Not Available";
export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
export function validateMobileNo(mobileNo) {
    const re = /^\d{10}$/;
    return re.test(mobileNo);
}
// export const localDate = (date: string) => new Date(date).toLocaleString('en-IN', { timeZone: 'Asia/Calcutta' })
export const localDatetime = (date) => new Date(date).toLocaleString('en-IN', { timeZone: 'Asia/Calcutta' });
export const localTime = (date) => new Date(date).toLocaleTimeString('en-IN', { timeZone: 'Asia/Calcutta' });
export const localDate = (date) => new Date(date).toLocaleDateString('en-IN', { timeZone: 'Asia/Calcutta' });
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const convertToQueryUrl = (obj) => {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(obj)) {
        if (Array.isArray(value))
            value.forEach((v) => params.append(`${key}[]`, v));
        else
            params.append(key, `${value}`);
    }
    return params.toString();
};
export const handleError = (e) => {
    let message;
    if (typeof e == "string") {
        message = e;
    }
    else if (typeof e == "object") {
        message = e.message;
    }
    toast.error(message, { toastId: Math.random() });
};
export const handleSuccess = (message) => {
    toast.success(message, { toastId: Math.random() });
};
export const fulfilledTypes = [
    {
        text: "Seller Fulfilled",
        value: 1
    },
    {
        text: "Expressare Fulfilled",
        value: 2
    }
];
export const deliveryModes = [
    {
        text: "Express Delivery",
        delivery_days: 3,
        value: 1
    },
    {
        text: "Standard Delivery",
        delivery_days: 10,
        value: 2
    }
];
export const shipmentTypes = ["Normal Shipment", "Direct Shipment", "Inward Shipment"];
export const minimum_amount_of_products_for_free_delivery = 5000;
