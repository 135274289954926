import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
const CustomPagination = ({ page, totalPages, hasNext, onPageChange }) => {
    const handleBack = () => {
        if (page > 0) {
            onPageChange(page - 1);
        }
    };
    const handleForward = () => {
        if (hasNext) {
            onPageChange(page + 1);
        }
    };
    return (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2, width: "100%" }, { children: [_jsx(Button, Object.assign({ onClick: handleBack, disabled: page === 1, startIcon: _jsx(ArrowBack, {}, void 0) }, { children: "Previous" }), void 0), page && totalPages && _jsx(Typography, Object.assign({ variant: "body1", mx: 2 }, { children: `${page} of ${totalPages}` }), void 0), _jsx(Button, Object.assign({ onClick: handleForward, disabled: !hasNext, endIcon: _jsx(ArrowForward, {}, void 0) }, { children: "Next" }), void 0)] }), void 0));
};
export default CustomPagination;
