import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react  */
import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "../Modal";
const ButtonComponent = (props) => {
    const { buttonType, close, key } = props;
    const { label, callback, css } = buttonType;
    return _jsx("button", Object.assign({ onClick: () => callback === null || callback === void 0 ? void 0 : callback(key, close), css: Object.assign({ marginLeft: "8px", marginTop: 12, padding: "6px 20px", border: "0", borderRadius: "4px", backgroundColor: "#f8b611", color: "#fff", boxShadow: "1px 1px 10px #faa" }, css) }, { children: label }), void 0);
};
export const ConfirmInternal = (props, ref) => {
    const { message, buttons } = props;
    const [show, setShow] = useState(false);
    const buttonKeys = Object.keys(buttons);
    useImperativeHandle(ref, () => ({
        show: setShow
    }));
    return _jsxs(_Fragment, { children: ["  ", show ?
                _jsx(Modal, Object.assign({ close: () => { setShow(false); } }, { children: _jsxs("div", Object.assign({ css: { backgroundColor: "#fff", width: 550, padding: 40, fontSize: "1.2em", fontWeight: 600 } }, { children: [_jsxs("div", { children: [" ", message] }, void 0), _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "right" } }, { children: buttonKeys.map(btn => _jsx(ButtonComponent, { buttonType: buttons[btn], close: () => setShow(false) }, btn)) }), void 0)] }), void 0) }), void 0) : null] }, void 0);
};
export const Confirm = forwardRef(ConfirmInternal);
