import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import createSagaMiddleware from "@redux-saga/core";
import { createContext } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
// import { fork } from "redux-saga/effects";
const sagaMiddleware = createSagaMiddleware();
const fetchReducer = (state, action) => {
    // console.log("REDUCER",action.type, state, action)
    return Object.assign({}, state);
};
const reducers = { fetches: fetchReducer };
// const dynamicReducers: ReducersMapObject<any, any> = {}
// const reducers = {
//         dynamicReducers,
//         staticReducers
// }
const root = combineReducers(reducers);
const store = createStore(root, applyMiddleware(sagaMiddleware));
export const SagaContext = createContext({ store, sagaMiddleware, reducers });
export const SagaProvider = ({ children }) => _jsx(SagaContext.Provider, Object.assign({ value: { store, sagaMiddleware, reducers } }, { children: _jsx(SagaContext.Consumer, { children: ({ store }) => _jsxs(Provider, Object.assign({ store: store }, { children: [children, " "] }), void 0) }, void 0) }), void 0);
