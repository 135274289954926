import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { useState } from "react";
import { Col } from "../../components/Column";
import { DragDrop } from "../../components/DragDrop";
import { Field, Form } from "../../components/Form";
import { Input, InputArea } from "../../components/Form/fields-extended";
import { Row } from "../../components/Row";
import { SagaProvider } from "../../saga/saga-context";
import container from "./style";
import { toast } from 'react-toastify';
import ReactSlidingPane from "react-sliding-pane";
import { UserListGeo } from "./buyer-list-map";
import { useRestSubmit } from "../../saga/use-rest-submit";
import { handleError } from "../../utils";
export const UserListDisplay = (props) => {
    const { users } = props;
    const maxUsers = users.slice(0, 10);
    return _jsxs("div", Object.assign({ css: {
            display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "10px 0px",
            flexWrap: "wrap"
        } }, { children: [maxUsers.map(user => _jsxs("div", Object.assign({ css: {
                    background: "#ffacac",
                    border: " 1px solid #f19898", borderRadius: 48, margin: "4px 8px 4px 0px", padding: "5px 20px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexWrap: "wrap",
                    fontSize: 12,
                } }, { children: [_jsxs("div", { children: [user.subject.first_name, " ", user.subject.last_name] }, void 0), _jsxs("div", { children: [" (", user.username, ")"] }, void 0)] }), void 0)), users.length > 10 ? _jsx("div", Object.assign({ css: { fontWeight: 700 } }, { children: "..." }), void 0) : null] }), void 0);
};
export const NotificationAddInternal = () => {
    const { category, titlestyle, button, divcontainer, btn_small, action_btn_ms, btnpositive } = useStyles("category", "titlestyle", "divcontainer", "button", "btnpositive", "btn_small", "action_btn_ms");
    const submit = useRestSubmit(() => {
        toast.success("Notification Sent", { toastId: "success-notification" + Math.random() });
        setUsers([]);
        setSelectedUids([]);
    }, handleError);
    const [viewMap, setViewMap] = useState();
    const [selectedUids, setSelectedUids] = useState([]);
    const [users, setUsers] = useState([]);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [image_url, setImage] = useState();
    const [big_image_url, setBigImage] = useState();
    const submitHandler = (formData) => {
        const { notification } = formData || {};
        const { title, message, url, button_label } = notification || {};
        if (!title)
            toast.error("Title is mandatory", { toastId: Math.random() });
        else if (!message)
            toast.error("Content is mandatory", { toastId: Math.random() });
        else if (selectedUids.length < 1)
            toast.error("Select a user", { toastId: Math.random() });
        // else if ((button_label != null && typeof button_label != "undefined" && button != "") && (url == null || url == ""))
        // toast.error("Link should be provided if button action name is present" + button_label, {toastId: "cat3" })
        else
            submit("/trendutube/v1/users/send-notification", {
                body: {
                    title,
                    message,
                    app: "BUYER",
                    user_uids: selectedUids,
                    data: {
                        image_url,
                        big_image_url,
                        url,
                        button_label,
                        notification_type: "promotion"
                    }
                }
            });
    };
    return (_jsxs("div", Object.assign({ css: category }, { children: [_jsxs(Form, Object.assign({ name: "notification", onSubmit: submitHandler }, { children: [_jsxs(Row, { children: [_jsx(Col, { children: _jsxs("div", Object.assign({ css: { padding: 20 } }, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Notification Title" }), void 0), _jsx("div", { children: _jsx(Field, Object.assign({ name: "title", value: title }, { children: _jsx(Input, {}, void 0) }), void 0) }, void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Notification Content" }), void 0), _jsx(Field, Object.assign({ name: "message", value: message }, { children: _jsx(InputArea, {}, void 0) }), void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Link (for update or otherwise) " }), void 0), _jsx(Field, Object.assign({ name: "url" }, { children: _jsx(Input, {}, void 0) }), void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", Object.assign({ css: titlestyle }, { children: "Button Action Name" }), void 0), _jsx(Field, Object.assign({ name: "button_label" }, { children: _jsx(Input, {}, void 0) }), void 0)] }, void 0) }, void 0), _jsxs(Row, { children: [_jsx(Col, { children: _jsxs("div", Object.assign({ css: { marginTop: 20 } }, { children: [_jsx("div", Object.assign({ css: { fontSize: 14, fontWeight: 500 } }, { children: "Icon Image" }), void 0), _jsx(DragDrop, { onImagesUpload: ([image]) => {
                                                                    if (image)
                                                                        setImage(image.url);
                                                                }, entity: "notification" }, void 0)] }), void 0) }, void 0), _jsx(Col, Object.assign({ top: true }, { children: _jsxs("div", Object.assign({ css: { marginLeft: 10, marginTop: 20 } }, { children: [_jsx("div", Object.assign({ css: { fontSize: 14, fontWeight: 500 } }, { children: "Icon Image - Preivew" }), void 0), _jsx("div", Object.assign({ css: divcontainer }, { children: _jsx("div", Object.assign({ style: {
                                                                        width: 100,
                                                                        height: 100,
                                                                        borderRadius: "8px",
                                                                        border: "1px solid #fff",
                                                                        display: "flex",
                                                                        justifyContent: "space-evenly",
                                                                    } }, { children: image_url ?
                                                                        _jsx("div", { css: {
                                                                                backgroundSize: "cover",
                                                                                backgroundImage: `url(${image_url}?nocloud=1)`, width: "100%", height: "100%",
                                                                            } }, void 0) : null }), void 0) }), void 0)] }), void 0) }), void 0)] }, void 0)] }), void 0) }, void 0), _jsxs(Col, Object.assign({ top: true }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ css: { marginLeft: 10 } }, { children: [_jsx("div", Object.assign({ css: { fontSize: 14, fontWeight: 500 } }, { children: "Big Background Image" }), void 0), _jsx(DragDrop, { onImagesUpload: ([image]) => {
                                                            if (image)
                                                                setBigImage(image.url);
                                                        }, entity: "notification" }, void 0)] }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx(Col, Object.assign({ top: true }, { children: _jsxs("div", Object.assign({ css: { marginLeft: 10 } }, { children: [_jsx("div", Object.assign({ css: { fontSize: 14, fontWeight: 500 } }, { children: "Big Background Image - Preivew" }), void 0), _jsx("div", Object.assign({ css: divcontainer }, { children: _jsx("div", Object.assign({ style: {
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                justifyContent: "space-evenly"
                                                            } }, { children: big_image_url ?
                                                                _jsx("div", { css: {
                                                                        backgroundSize: "cover",
                                                                        backgroundImage: `url(${big_image_url}?nocloud=1)`, width: "100%", height: "100%",
                                                                    } }, void 0) : null }), void 0) }), void 0)] }), void 0) }), void 0) }, void 0)] }), void 0)] }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", { children: _jsx(UserListDisplay, { users: users }, void 0) }, void 0), _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "flex-start", alignItems: "center" } }, { children: [_jsx("div", { children: users.length > 0 ? _jsxs("div", { children: [users.length, " users selected"] }, void 0) : "No Users selected" }, void 0), _jsx("button", Object.assign({ type: "button", css: btnpositive, onClick: () => setViewMap(true) }, { children: "Select Users..." }), void 0)] }), void 0)] }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ css: { marginTop: 20 } }, { children: _jsx("input", { type: "submit", value: "Sent Notification", css: action_btn_ms }, void 0) }), void 0) }, void 0)] }), void 0), _jsx(Row, { children: viewMap ?
                    _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: true, title: "USer Selection", subtitle: "", width: "70%", onRequestClose: () => setViewMap(false) }, { children: _jsx(UserListGeo, { selected: selectedUids, lngLat: [76.85070627053096, 9.122565669098792], onSelect: (data, users) => {
                                setViewMap(false);
                                setSelectedUids(data);
                                setUsers(users);
                            } }, void 0) }), void 0) : null }, void 0)] }), void 0));
};
export const NotificationAdd = () => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(NotificationAddInternal, {}, void 0) }), void 0) }, void 0));
