import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/* @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useProgressBar } from "../../../components/ProgressBar";
import { Table } from "../../../components/Table/table";
import { useSaga } from "../../../saga/use-saga";
export const UserShortComponent = (props) => {
    const { data: user } = props;
    return _jsxs(_Fragment, { children: [_jsx("td", { children: user.uid }, void 0), _jsx("td", { children: user.first_name }, void 0), _jsx("td", { children: user.last_name }, void 0), _jsx("td", { children: user.username }, void 0), _jsx("td", { children: _jsx(NavLink, Object.assign({ to: `/dashboard/tips-payouts/delivery-persons/${user.uid}` }, { children: "View Payouts" }), void 0) }, void 0)] }, void 0);
};
export const DeliveryPersonList = (props) => {
    const progress = useProgressBar();
    const [users, setUsers] = useState([]);
    const { bigtable, panelstyle } = useStyles("bigtable", "panelstyle");
    const { data, isFetching } = useSaga(`/trendutube/v1/delivery-management/delivery-persons/list`, {}, false);
    useEffect(() => {
        setUsers(data);
    }, [data]);
    useEffect(() => {
        progress(isFetching);
    }, [isFetching]);
    const header = {
        uid: "Uid",
        first_name: "First name",
        last_name: "Last name",
        username: "Username"
    };
    const keys = ["uid", "first_name", "last_name", "username"];
    return _jsxs("div", Object.assign({ css: panelstyle }, { children: [" ", users && users.length != 0 ?
                _jsx(Table, { style: bigtable, body: users, component: UserShortComponent, header: header, keys: keys }, void 0) : null] }), void 0);
};
