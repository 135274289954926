import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useStyles } from "@exprzn/react-style-lib";
import { Col } from "../../../components/Column";
import { useProgressBar } from "../../../components/ProgressBar";
import { Row } from "../../../components/Row";
import { useSubmit } from "../../../saga/use-submit";
import { StoreAssignment } from "./assign-stores";
const canDisplayAssign = (status) => status == "cart-assigned" || status == "unassigned";
export const BasketItemViewComponent = (props) => {
    const { action_btn_ms, row_basket, grid_right } = useStyles("row_basket", "action_btn_ms", "grid_right");
    const { basket_item, basket_uid, onAssigned, onUnAssigned, buyer_info, status } = props || {};
    const [isOpen, setIsOpen] = useState(false);
    const { submit: assignSubmit, data: assignResult, status: assignStatus } = useSubmit();
    const { submit: unAssignSubmit, data: unAssignResult, status: unAssignStatus, isFetching: isLoading } = useSubmit();
    const loading = useProgressBar();
    const { address = [] } = buyer_info || {};
    const [firstAddress] = address;
    const { location } = firstAddress || {};
    const { coordinates = [] } = location || {};
    // const [long, lat] = coord
    // const coordinates = [  long,lat]
    const { basket_item_uid, item_name, quantity, unit, price, availability, cart_item_of } = basket_item || {};
    const { store } = cart_item_of || {};
    const { store_uid, store_name } = store || {};
    function close() {
        setIsOpen(false);
    }
    function onAssign(store_uid) {
        const body = { store_uid, cart_item_uids: [basket_item_uid] };
        assignSubmit(`/trendutube/v1/basket-management/admin/baskets/${basket_uid}/items/assign`, { body });
    }
    useEffect(() => {
        onAssigned();
        setIsOpen(false);
    }, [assignStatus]);
    useEffect(() => {
        onUnAssigned();
        setIsOpen(false);
    }, [unAssignStatus]);
    useEffect(() => {
        loading(isLoading);
    }, [isLoading]);
    function onClickAssignBtn() {
        setIsOpen(true);
    }
    function onClickUnAssignBtn() {
        const body = { store_uid, cart_item_uids: [basket_item_uid] };
        unAssignSubmit(`/trendutube/v1/basket-management/admin/baskets/${basket_uid}/items/unassign`, { body });
    }
    return _jsxs("div", Object.assign({ css: row_basket }, { children: [_jsxs(Row, { children: [_jsx(Col, Object.assign({ cols: [12, 1] }, { children: basket_item_uid }), void 0), _jsx(Col, Object.assign({ cols: [12, 2] }, { children: item_name }), void 0), _jsx(Col, Object.assign({ cols: [12, 1] }, { children: quantity }), void 0), _jsx(Col, Object.assign({ cols: [12, 1] }, { children: price }), void 0), _jsx(Col, Object.assign({ cols: [12, 1] }, { children: unit }), void 0), _jsx(Col, Object.assign({ cols: [12, 1] }, { children: store_uid }), void 0), _jsx(Col, Object.assign({ cols: [12, 2] }, { children: store_name }), void 0), canDisplayAssign(status) ? _jsxs(_Fragment, { children: [_jsx(Col, Object.assign({ cols: [12, 2] }, { children: store_uid ? _jsx("div", Object.assign({ css: grid_right }, { children: _jsx("button", Object.assign({ onClick: onClickUnAssignBtn, css: action_btn_ms }, { children: "Un Assign" }), void 0) }), void 0) : null }), void 0), _jsx(Col, Object.assign({ cols: [12, 1] }, { children: _jsx("div", Object.assign({ css: grid_right }, { children: _jsx("button", Object.assign({ onClick: onClickAssignBtn, css: action_btn_ms }, { children: "Assign" }), void 0) }), void 0) }), void 0)] }, void 0) : null] }, void 0), isOpen ? _jsx(StoreAssignment, Object.assign({}, { coordinates, isOpen, close, onAssign }), void 0) : null] }), void 0);
};
