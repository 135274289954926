import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleProvider } from "@exprzn/react-style-lib";
import container from "./style";
import { SagaProvider } from "../../../saga/saga-context";
import { useEffect, useRef, useState } from "react";
import { useStreamPost } from "../../../saga/use-stream-post";
import { Grid, Stack, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useSaga } from "../../../saga/use-saga";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CategoryTreeView } from "./category-tree-view";
const Item = styled(Paper)(({ theme }) => (Object.assign(Object.assign({ backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff' }, theme.typography.body2), { padding: theme.spacing(1), textAlign: 'center', color: theme.palette.text.secondary })));
const VideoStreaming = ({ products, stream }) => {
    const videoRefs = useRef([]);
    const handleIntersection = (entries) => {
        entries.forEach(entry => {
            try {
                const video = entry.target;
                if (entry.isIntersecting) {
                    video.play();
                }
                else {
                    video.pause();
                }
            }
            catch (error) {
                console.error('Error in handleIntersection:', error);
            }
        });
    };
    useEffect(() => {
        try {
            const observer = new IntersectionObserver(handleIntersection, {
                threshold: 0.5,
            });
            videoRefs.current.forEach((video, index) => {
                if (video) {
                    observer.observe(video);
                    video.addEventListener('ended', () => {
                        video.currentTime = 0;
                        video.play();
                    });
                }
            });
            return () => {
                observer.disconnect();
                videoRefs.current.forEach(video => {
                    if (video) {
                        video.removeEventListener('ended', () => {
                            video.currentTime = 0;
                            video.play();
                        });
                    }
                });
            };
        }
        catch (error) {
            console.error('Error in useEffect:', error);
        }
    }, [products]);
    return (_jsx("div", Object.assign({ style: { maxHeight: '90vh', overflowY: 'auto' } }, { children: products.map((product, index) => (_jsxs("div", Object.assign({ style: { margin: '20px 0' } }, { children: [_jsxs("video", Object.assign({ ref: (el) => (videoRefs.current[index] = el), width: "400", controls: true, muted: true, autoPlay: true, style: { display: 'block', margin: '0 auto' } }, { children: [_jsx("source", { src: product.video_url, type: "video/mp4" }, void 0), "Your browser does not support the video tag."] }), void 0), _jsx("p", Object.assign({ className: "product_name" }, { children: product.product_name }), void 0), _jsx("p", Object.assign({ className: "product_uid" }, { children: product.product_uid }), void 0)] }), product.video_url))) }), void 0));
};
// ProductHomeInternal Component
const ProductHomeInternal = (props) => {
    const [paging, setPaging] = useState({ page: 1, page_size: 5 });
    const { data, stream, loadNextPage, loadPage, loadUrl } = useStreamPost('/trendutube/v1/product-catalog/products/admin-home', { paging, body: { category_uids: [], price_min: 0, price_max: 0, discount_uid: [], seller_uids: [], product_uid: null, size: [] } });
    const [products, setProducts] = useState([]);
    useEffect(() => {
        try {
            if (data && stream) {
                const newProducts = data;
                setProducts(newProducts);
                if (stream) {
                    setPaging({ page: stream.page, page_size: stream.page_size });
                }
            }
        }
        catch (error) {
            console.error('Error in useEffect:', error);
        }
    }, [data, stream]);
    const handleNextPage = () => {
        loadNextPage();
    };
    const handlePreviousPage = () => {
        loadPage(stream.page - 1);
    };
    const [product_category_uids, setCategoryUids] = useState([]);
    const [productCategories, setProductCategories] = useState();
    const { data: categoryData } = useSaga("/trendutube/v1/public/categories/list", {}, false);
    useEffect(() => {
        if (categoryData) {
            const { categories } = categoryData;
            setProductCategories(categories);
        }
    }, [categoryData]);
    const [selectedCategoryNames, setSelectedCategoryNames] = useState([]);
    const [isCategoryTreeOpen, setIsCategoryTreeOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(() => {
        if (product_category_uids) {
            if (productCategories) {
                const selectedCategory = findCategoryByUid(productCategories, product_category_uids);
                console.log(selectedCategory);
                setSelectedCategoryNames(selectedCategory);
            }
        }
        else {
            setSelectedCategoryNames([]);
        }
    }, [product_category_uids, productCategories]);
    const findCategoryByUid = (categories, uids) => {
        const foundCategories = [];
        const find = (cats) => {
            for (const category of cats) {
                if (uids.includes(category.uid)) {
                    foundCategories.push(category.category_name);
                }
                if (category.sub_categories) {
                    find(category.sub_categories);
                }
            }
        };
        find(categories);
        return foundCategories;
    };
    const handleSelectCategory = (uid) => {
        if (product_category_uids.includes(uid)) {
            setCategoryUids(product_category_uids.filter(item => item !== uid));
        }
        else {
            setCategoryUids([...product_category_uids, uid]);
        }
        setIsCategoryTreeOpen(false);
    };
    const handleApplyFilter = () => {
        loadUrl("/trendutube/v1/product-catalog/products/admin-home", stream.page, {}, { category_uids: product_category_uids, price_min: 0, price_max: 0, discount_uid: [], seller_uids: [], product_uid: null, size: [] });
    };
    const handleTextFieldClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsCategoryTreeOpen(!isCategoryTreeOpen);
    };
    return (_jsx(_Fragment, { children: _jsx("div", { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 8 }, { children: _jsx(Item, { children: products.length > 0 && _jsxs(_Fragment, { children: [_jsxs(Stack, Object.assign({ direction: "row", spacing: 2 }, { children: [(stream === null || stream === void 0 ? void 0 : stream.page) > 1 && _jsx(Button, Object.assign({ variant: "outlined", startIcon: _jsx(ArrowBackIosNewIcon, {}, void 0), onClick: handlePreviousPage }, { children: "Previous" }), void 0), (stream === null || stream === void 0 ? void 0 : stream.has_next) && _jsx(Button, Object.assign({ variant: "contained", endIcon: _jsx(ArrowForwardIosIcon, {}, void 0), onClick: handleNextPage }, { children: "Next" }), void 0)] }), void 0), _jsx(VideoStreaming, { products: products, stream: stream }, void 0)] }, void 0) }, void 0) }), void 0), _jsx(Grid, Object.assign({ item: true, xs: 4 }, { children: _jsx(Item, { children: productCategories ?
                                _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "contained", onClick: handleApplyFilter }, { children: "Apply Filter" }), void 0), _jsx(TextField, { value: selectedCategoryNames.join(',') || "Choose Category", onClick: handleTextFieldClick, fullWidth: true, InputProps: {
                                                endAdornment: (isCategoryTreeOpen ? _jsx(ExpandLess, { sx: { color: 'gray' } }, void 0) : _jsx(ExpandMore, { sx: { color: 'gray' } }, void 0)),
                                                readOnly: true,
                                            } }, void 0), isCategoryTreeOpen && (_jsx("div", Object.assign({ style: { position: 'relative', top: '100%', zIndex: 9999 } }, { children: _jsx(CategoryTreeView, { categories: productCategories, onSelectCategory: handleSelectCategory, selectedCategoryUids: product_category_uids || null }, void 0) }), void 0))] }, void 0) : null }, void 0) }), void 0)] }), void 0) }, void 0) }, void 0));
};
export const ProductHomePage = () => _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(SagaProvider, { children: _jsx(ProductHomeInternal, {}, void 0) }, void 0) }), void 0);
