var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
import mergeCont from '../style';
const { container, style } = registry("attribute-sets", true);
let AttSetsStyle = class AttSetsStyle extends CssStyle {
};
AttSetsStyle.style = {};
AttSetsStyle = __decorate([
    style()
], AttSetsStyle);
let InputStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputStyle extends CssStyle {
};
InputStyle.style = {
    position: "relative",
    border: '1px solid #e6e6e6',
    '&:hover': {
        backgroundColor: '#f8b63177',
        border: "1px solid #fefefe",
    },
    '&:focus': {
        backgroundColor: '#f8b63111'
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    backgroundColor: '#f1f1f1',
    color: '#e8a631',
    marginTop: "1em",
    fontWeight: 600,
    fontSize: '1em'
};
InputStyle = __decorate([
    style("input")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputStyle);
let ContainerStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ContainerStyle extends CssStyle {
};
ContainerStyle.style = {
    backgroundColor: 'white',
    padding: "1.5em",
    borderRadius: "12px"
};
ContainerStyle = __decorate([
    style("containerstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ContainerStyle);
let ButtonStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ButtonStyle extends CssStyle {
};
ButtonStyle.style = {
    position: "relative",
    outline: 'none',
    border: "0",
    '&:hover': {
        backgroundColor: '#e8b631'
    },
    '&:focus': {
        backgroundColor: '#f8b631'
    },
    padding: ".8em 2em",
    margin: "1.5em 0em",
    borderRadius: ".5em",
    backgroundColor: '#f8b611',
    boxShadow: "1px 1px 10px #f8e1aa",
};
ButtonStyle = __decorate([
    style("button")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ButtonStyle);
let TagContainerStyle = class TagContainerStyle {
};
TagContainerStyle.style = {
    borderRadius: "23px",
    backgroundColor: "#ffeeff",
    padding: ".2em .7em",
    display: "flex"
};
TagContainerStyle = __decorate([
    style("tagcontstyle")
], TagContainerStyle);
let TagStyle = class TagStyle {
};
TagStyle.style = {
    borderRadius: "23px",
    backgroundColor: "#FFE7B6",
    padding: ".2em .7em",
    flexGrow: "0"
};
TagStyle = __decorate([
    style("tagstyle")
], TagStyle);
let TagBtnStyle = class TagBtnStyle {
};
TagBtnStyle.style = {
    borderRadius: "23px",
    backgroundColor: "#F8B631",
    padding: "0em .5em",
    margin: "0.5em",
    border: "0px"
};
TagBtnStyle = __decorate([
    style("tagbtnstyle")
], TagBtnStyle);
let TitleStyle = class TitleStyle {
};
TitleStyle.style = {
    paddingTop: "1.4em",
};
TitleStyle = __decorate([
    style("titlestyle")
], TitleStyle);
let OptionStyle = class OptionStyle {
};
OptionStyle.style = {
    padding: ".6em",
    margin: "0em",
    ':hover': {
        backgroundColor: '#FFE7B6'
    }
};
OptionStyle = __decorate([
    style("optionstyle")
], OptionStyle);
let CloseStyle = class CloseStyle {
};
CloseStyle.style = {
    width: "2.5em",
    height: "2.5em",
    backgroundColor: "#f8b631",
    padding: ".5em",
    position: "relative",
};
CloseStyle = __decorate([
    style("closestyle")
], CloseStyle);
let BtnStyle = class BtnStyle {
};
BtnStyle.style = {
    padding: ".53em"
};
BtnStyle = __decorate([
    style("btnstyle")
], BtnStyle);
let ContStyle = class ContStyle {
};
ContStyle.style = {
    padding: "3em",
    maxWidth: "1200px",
    margin: 'auto',
    fitContent: "20em",
    overflowX: "auto",
    backgroundColor: "white"
};
ContStyle = __decorate([
    style("contstyle")
], ContStyle);
let XButtonStyle = class XButtonStyle {
};
XButtonStyle.style = {
    display: "block",
    marginLeft: "auto",
    marginRight: "10px",
    marginTop: "20px",
    backgroundColor: "transparent",
    border: "0"
};
XButtonStyle = __decorate([
    style("xbtnstyle")
], XButtonStyle);
container === null || container === void 0 ? void 0 : container.merge(mergeCont);
export default container;
