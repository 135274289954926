import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { Col } from "../../../components/Column";
import { Field, Form } from "../../../components/Form";
import { InputEx } from "../../../components/Form/fields-extended";
import { MultiSelect } from "../../../components/MultiSelect";
import { Row } from "../../../components/Row";
import { SagaProvider } from "../../../saga/saga-context";
import { useSaga } from "../../../saga/use-saga";
import { useSubmit } from "../../../saga/use-submit";
import { AttributeGroupAdd } from "./attribute-grp-tmplt-add";
import container from "./style";
import { global_org_uid } from "../../../config";
export const createUrl = `/trendutube/v1/product-catalog/attribute-set-templates/add`;
export const updateUrl = (uid) => `/trendutube/v1/product-catalog/attribute-set-templates/${uid}/update`;
export const deletePrdClsUrl = (uid) => `/trendutube/v1/product-catalog/attribute-set-templates/${uid}/product-classes/delete`;
const AttributeSetTemplateAddInt = (props) => {
    const { match } = props;
    const { uid: paramUid } = match.params;
    const { tagstyle, tagbtnstyle, tagcontstyle, titlestyle, closestyle, contstyle, btnstyle, optionstyle, } = useStyles("tagstyle", "closestyle", "contstyle", "titlestyle", "tagbtnstyle", "tagcontstyle", "optionstyle", "btnstyle");
    const [isEditMode, setEditMode] = useState(!!paramUid);
    const [uid, setUid] = useState(paramUid);
    const [selectedItems, setSelectedItems] = useState([]);
    const [productClasses, setProductClasses] = useState();
    const [catSelection, setCatSelection] = useState([]);
    const { data: attrData } = useSaga(`/trendutube/v1/product-catalog/attribute-set-templates/get?uid=${uid}`, {}, false);
    const attribSetTmplt = attrData;
    const { data, submit: submitCreate, isFetching, error, status, } = useSubmit();
    const { data: dataPrdCls, submit: submitDeletePrdCls, isFetching: isFetchingPrdCls, error: errorPrdCls, status: statusPrdCls, } = useSubmit();
    const { data: updateData, submit: submitUpdate, isFetching: updateIsFetching, error: updateError, status: updateStatus, } = useSubmit();
    const { data: classData } = useSaga(`/trendutube/v1/product-catalog/organizations/${global_org_uid}/product-classes/list`, {}, false);
    const { containerstyle, button } = useStyles("containerstyle", "button");
    useEffect(() => {
        if (attrData && classData) {
            const { product_class = [] } = attrData;
            const { product_classes } = classData;
            const selIndices = [];
            product_class.forEach((item) => {
                const idx = product_classes.findIndex((p) => p.uid === item.uid);
                if (idx > -1)
                    selIndices.push(idx);
            });
            setSelectedItems([...selIndices]);
        }
    }, [attrData, classData]);
    const { attribute_group_templates } = attribSetTmplt || {};
    useEffect(() => {
        if (data && data.attribute_set_template) {
            const { uid } = data.attribute_set_template;
            setUid(uid);
            setEditMode(true);
        }
    }, [data]);
    useEffect(() => {
        if (classData) {
            const { product_classes } = classData;
            setProductClasses(product_classes);
        }
    }, [classData]);
    const handleCreateAttrSetTmplt = (data) => {
        const { attribute_set_template } = data;
        const product_class_uid = catSelection;
        const { name, description } = attribute_set_template || {};
        if (!name) {
            alert("name cannot be empty");
            return;
        }
        if (!description) {
            alert("description cannot be empty");
            return;
        }
        if (catSelection.length <= 0)
            alert("add atleast one product class");
        else {
            const org_uid = global_org_uid;
            submitCreate(createUrl, {
                body: Object.assign(Object.assign({}, attribute_set_template), { product_class_uid, org_uid }),
            });
        }
    };
    const OptionRenderer = (props) => {
        console.log(props);
        const { item, index } = props;
        return _jsx("div", Object.assign({ css: optionstyle }, { children: item.product_class_name }), void 0);
    };
    const SelectRenderer = (props) => {
        console.log(props);
        const { item, index, unselect } = props;
        return (_jsxs("div", Object.assign({ css: tagstyle }, { children: [item.product_class_name, _jsx("button", Object.assign({ css: tagbtnstyle, type: "button", onClick: () => {
                        unselect && unselect();
                        const deletedItem = catSelection.splice(index, 1);
                        setCatSelection([...catSelection]);
                        const body = {
                            product_class_uids: deletedItem,
                            org_uid: global_org_uid,
                        };
                        submitDeletePrdCls(deletePrdClsUrl(uid), { body });
                        // return false
                    } }, { children: _jsx(X, { size: 10 }, void 0) }), void 0)] }), void 0));
    };
    const handleUpdateAttrSetTmplt = (updateData) => {
        const { attribute_set_template } = updateData;
        const product_class_uids = catSelection;
        const org_uid = global_org_uid;
        const body = Object.assign(Object.assign({}, attribute_set_template), { product_class_uids, org_uid });
        submitUpdate(updateUrl(uid), { body });
    };
    return (_jsxs("div", Object.assign({ css: containerstyle }, { children: [_jsx(Row, { children: _jsx(Col, Object.assign({ cols: 12 }, { children: isEditMode && uid ? (_jsxs(Form, Object.assign({ name: "attribute_set_template", onSubmit: handleUpdateAttrSetTmplt }, { children: ["Status :", " ", updateStatus ? (_jsxs("span", { children: [" ", updateStatus, " ", updateStatus === "error" && updateError] }, void 0)) : null, "Status :", " ", statusPrdCls ? (_jsxs("span", { children: [" ", statusPrdCls, " ", statusPrdCls === "error" && errorPrdCls] }, void 0)) : null, _jsx("div", Object.assign({ css: titlestyle }, { children: "Attribute Set Name" }), void 0), _jsx("div", { children: _jsx(Field, Object.assign({ name: "name", value: attribSetTmplt && attribSetTmplt.attribute_set_name }, { children: _jsx(InputEx, {}, void 0) }), void 0) }, void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Attribute Set Description" }), void 0), _jsx(Field, Object.assign({ name: "description", value: (attribSetTmplt &&
                                    attribSetTmplt.attribute_set_description) ||
                                    "" }, { children: _jsx(InputEx, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: " Product Class " }), void 0), _jsx("div", { children: productClasses && (_jsx(MultiSelect, { title: "select", items: productClasses, titleProperty: "product_class_name", view: OptionRenderer, selectIndices: selectedItems || [], onSelectItem: (item, index) => {
                                        setCatSelection([...catSelection, item === null || item === void 0 ? void 0 : item.uid]);
                                    }, selectView: SelectRenderer }, void 0)) }, void 0), _jsx("input", { css: button, type: "submit", value: "Update" }, void 0)] }), void 0)) : (_jsxs(Form, Object.assign({ name: "attribute_set_template", onSubmit: handleCreateAttrSetTmplt }, { children: ["Status :", " ", status ? (_jsxs("span", { children: [" ", status, " ", status === "error" && error] }, void 0)) : null, _jsx("div", Object.assign({ css: titlestyle }, { children: "Attribute Set Name" }), void 0), _jsx("div", { children: _jsx(Field, Object.assign({ name: "name" }, { children: _jsx(InputEx, {}, void 0) }), void 0) }, void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: "Attribute Set Description" }), void 0), _jsx(Field, Object.assign({ name: "description" }, { children: _jsx(InputEx, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: titlestyle }, { children: " Product Class " }), void 0), _jsx("div", { children: productClasses && (_jsx(MultiSelect, { title: "select", items: productClasses, titleProperty: "product_class_name", view: OptionRenderer, selectIndices: selectedItems || [], onSelectItem: (item, index) => {
                                        setCatSelection([...catSelection, item === null || item === void 0 ? void 0 : item.uid]);
                                    }, selectView: SelectRenderer }, void 0)) }, void 0), _jsx("input", { css: button, type: "submit", value: "Create" }, void 0)] }), void 0)) }), void 0) }, void 0), isEditMode ? (_jsx(Row, { children: _jsx(AttributeGroupAdd, Object.assign({}, { attribute_group_templates, uid }), void 0) }, void 0)) : null] }), void 0));
};
export const AttributeSetTemplateEdit = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(AttributeSetTemplateAddInt, Object.assign({}, props), void 0) }), void 0) }, void 0));
