import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useState } from 'react';
import { Field, Form } from "../../../components/Form";
import { InputDate } from "../../../components/Form/fields-extended";
import { useSaga } from "../../../saga/use-saga";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Table } from "../../../components/Table/table";
import { useAuth } from "../../../use-auth";
import { toast } from "react-toastify";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const ItemSalesComponent = (props) => {
    const { data } = props;
    const { id, order_date, order_id, order_id_string, rate, quantity, gross_amount, discounted_amount, net_amount, product_name, store_name, product_variant_uid, store_uid } = data || {};
    return _jsxs(_Fragment, { children: [_jsx("td", { children: id }, void 0), _jsx("td", { children: order_date }, void 0), _jsx("td", { children: order_id_string }, void 0), _jsxs("td", { children: [store_name, "(", store_uid, ")"] }, void 0), _jsx("td", { children: product_name }, void 0), _jsx("td", { children: rate }, void 0), _jsx("td", { children: quantity }, void 0), _jsx("td", { children: gross_amount }, void 0), _jsx("td", { children: discounted_amount }, void 0), _jsx("td", { children: net_amount }, void 0)] }, void 0);
};
const headers = {
    id: "Id",
    order_date: "Date",
    order_id_String: "Order Id",
    store_uid: "Store",
    product_name: "Product name",
    rate: "Rate",
    quantity: "Quantity",
    gross_amount: "Gross Amount",
    discounted_amount: "Discount",
    net_amount: "Net Amount"
};
const keys = ["id", "order_date", "order_id_String", "store_uid", "product_name", "rate", "quantity", "gross_amount", "discounted_amount", "net_amount"];
export const DailySalesItemized = (props) => {
    const today = new Date();
    const firstMonth = new Date(today.getFullYear(), today.getMonth(), 1).toLocaleDateString('sv-SE', { timeZone: 'Asia/Calcutta' }).substring(0, 10);
    const uptoMonth = today.toLocaleDateString('sv-SE', { timeZone: 'Asia/Calcutta' }).substring(0, 10);
    const [startDate, setStartDate] = useState(firstMonth);
    const [endDate, setEndDate] = useState(uptoMonth);
    const { access_token } = useAuth();
    const { data, loadUrl } = useSaga(`/trendutube/v1/admin-payout-processing/item-sales/daily-sales?from_date=${firstMonth}&to_date=${uptoMonth}`, {}, false);
    const { bigtable, panelstyle } = useStyles("bigtable", "panelstyle");
    function onSubmit() {
        loadUrl(`/trendutube/v1/admin-payout-processing/item-sales/daily-sales?from_date=${startDate}&to_date=${endDate}`);
    }
    function onFromDateChange(e) {
        const date = new Date(e).toLocaleDateString('sv-SE', { timeZone: 'Asia/Calcutta' }).substring(0, 10);
        setStartDate(date);
    }
    function onToDateChange(e) {
        const date = new Date(e).toLocaleDateString('sv-SE', { timeZone: 'Asia/Calcutta' }).substring(0, 10);
        setEndDate(date);
    }
    function handleDownload() {
        fetch(`/trendutube/v1/admin-payout-processing/item-sales/daily-sales-download?from_date=${startDate}&to_date=${endDate}`, { headers: { "Authorization": `Bearer ${access_token}` } })
            .then(resp => resp.blob())
            .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename  
            a.download = `trendutube-sales-all-stores-${startDate}-to-${endDate}.xls`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            toast.success("File Downloaded");
        })
            .catch(() => toast.error('could not download'));
    }
    return _jsxs("div", Object.assign({ css: panelstyle }, { children: [_jsx(Form, Object.assign({ name: "estimate", onSubmit: onSubmit }, { children: _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15 } }, { children: [_jsxs("div", { children: [_jsx("div", { children: "From Date   " }, void 0), _jsx("div", { children: _jsx(Field, Object.assign({ name: "from_date", onChange: onFromDateChange, value: startDate }, { children: _jsx(InputDate, {}, void 0) }), void 0) }, void 0)] }, void 0), _jsxs("div", { children: [_jsx("div", { children: "Upto Date  " }, void 0), _jsx("div", { children: _jsx(Field, Object.assign({ name: "target_date", onChange: onToDateChange, value: endDate }, { children: _jsx(InputDate, {}, void 0) }), void 0) }, void 0)] }, void 0), _jsx("button", Object.assign({ css: { padding: 10 } }, { children: " Get Sales " }), void 0), _jsx("button", Object.assign({ onClick: handleDownload, css: { padding: 10 } }, { children: " Download " }), void 0)] }), void 0) }), void 0), _jsx("h3", Object.assign({ css: { padding: 15 } }, { children: "Orders" }), void 0), data ? _jsx(Table, { style: bigtable, body: data, component: ItemSalesComponent, header: headers, keys: keys }, void 0) : null] }), void 0);
};
