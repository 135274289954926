var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
export const { container, style } = registry("stores-styles", true);
let StoreContStyle = class StoreContStyle {
};
StoreContStyle.style = {
    backgroundColor: "white",
};
StoreContStyle = __decorate([
    style("storecontstyle")
], StoreContStyle);
let StoreItemStyle = class StoreItemStyle {
};
StoreItemStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1.2em",
    padding: "2em",
};
StoreItemStyle = __decorate([
    style("storeitemtyle")
], StoreItemStyle);
let HeaderStyle = class HeaderStyle {
};
HeaderStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "blue",
    margin: "1.2em",
    padding: "2em",
};
HeaderStyle = __decorate([
    style("headerStyle")
], HeaderStyle);
let StoreHeaderStyle = class StoreHeaderStyle {
};
StoreHeaderStyle.style = {};
StoreHeaderStyle = __decorate([
    style("storeheaderstyle")
], StoreHeaderStyle);
let ProductStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ProductStyle extends CssStyle {
};
ProductStyle.style = {
    borderRadius: ".6em",
    backgroundColor: "white",
    margin: "1em",
    padding: "1.5em",
};
ProductStyle = __decorate([
    style("productstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ProductStyle);
let ImageStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ImageStyle extends CssStyle {
};
ImageStyle.style = {
    minWidth: "100%",
    maxWidth: "100%",
    minHeight: "300px",
    maxHeight: "300px",
    borderRadius: ".5em",
    objectFit: "cover",
    minPosition: "relative",
};
ImageStyle = __decorate([
    style("imagestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ImageStyle);
let InputStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputStyle extends CssStyle {
};
InputStyle.style = {
    position: "relative",
    border: "0px solid #dddddd00",
    width: "100%",
    backgroundColor: "#ffffff00",
    color: "#e8a631",
    fontWeight: 600,
    fontSize: "1.2em",
    paddingLeft: "4px",
};
InputStyle = __decorate([
    style("input")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputStyle);
let RowWrapStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowWrapStyle extends CssStyle {
};
RowWrapStyle.style = {
    position: "relative",
    border: "1px solid #dddddd",
    "&:hover": {
        backgroundColor: "#f8b63101",
    },
    "&:focus": {
        backgroundColor: "#fefefe",
        boxShadow: "4px 4px 7px #eeeeff",
    },
    padding: ".8em .8em",
    borderRadius: ".5em",
    width: "100%",
    height: "100%",
    backgroundColor: "#f3f3f3",
    color: "#e8a631",
    fontWeight: 600,
    fontSize: "1.1em",
    display: "flex",
};
RowWrapStyle = __decorate([
    style("rowwrap")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowWrapStyle);
let ErrorStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ErrorStyle extends CssStyle {
};
ErrorStyle.style = {
    margin: ".1em",
    fontSize: "1em",
    color: "red",
};
ErrorStyle = __decorate([
    style("error")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ErrorStyle);
let ImageBGStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ImageBGStyle extends CssStyle {
};
ImageBGStyle.style = {
    backgroundColor: "#f8f8f8",
};
ImageBGStyle = __decorate([
    style("imagebg")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ImageBGStyle);
let SearchBtnStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SearchBtnStyle extends CssStyle {
};
SearchBtnStyle.style = {
    // padding: '0px 0px',
    alignSelf: "center",
    padding: "0px .5rem",
};
SearchBtnStyle = __decorate([
    style("searchbtn")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], SearchBtnStyle);
let InputWrapStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class InputWrapStyle extends CssStyle {
};
InputWrapStyle.style = {
    // padding: '0px 0px',
    flex: "1 1 100%",
};
InputWrapStyle = __decorate([
    style("inputwrap")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], InputWrapStyle);
let ButtonStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ButtonStyle extends CssStyle {
};
ButtonStyle.style = {
    position: "relative",
    outline: "none",
    border: "0",
    "&:hover": {
        backgroundColor: "#e8b631",
    },
    "&:focus": {
        backgroundColor: "#f8b631",
    },
    paddingTop: "1px",
    paddingBottom: "1px",
    paddingRight: "8px",
    paddingLeft: "8px",
    borderRadius: ".5em",
    backgroundColor: "#f8b611",
    boxShadow: "1px 1px 10px #f8e1aa",
};
ButtonStyle = __decorate([
    style("button")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ButtonStyle);
let RowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RowStyle extends CssStyle {
};
RowStyle.style = {
    backgroundColor: "white",
    padding: ".5em",
    margin: ".5em",
};
RowStyle = __decorate([
    style("rowstyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RowStyle);
let Inputlatest = class Inputlatest extends CssStyle {
};
Inputlatest.style = {
    padding: "10px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1em",
    marginBottom: "10px",
    boxSizing: "border-box",
    backgroundColor: "#f9f9f9",
};
Inputlatest = __decorate([
    style("inputLatestStyle")
], Inputlatest);
let DisabledInput = class DisabledInput extends CssStyle {
};
DisabledInput.style = {
    padding: "10px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1em",
    marginBottom: "10px",
    boxSizing: "border-box",
    backgroundColor: "#e9e9e9",
    cursor: "not-allowed",
};
DisabledInput = __decorate([
    style("disabledInputStyle")
], DisabledInput);
let SubmitButton = class SubmitButton extends CssStyle {
};
SubmitButton.style = {
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007bff",
    color: "white",
    fontSize: "1em",
    cursor: "pointer",
    transition: "background-color 0.3s",
    ":hover": {
        backgroundColor: "#0056b3",
    },
};
SubmitButton = __decorate([
    style("submitButtonStyle")
], SubmitButton);
export default container;
