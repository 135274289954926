import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useProgressBar } from "../../../components/ProgressBar";
import { useSaga } from "../../../saga/use-saga";
import { useAuth } from "../../../use-auth";
import { OrderComponent } from "../order-component";
export const OrderDetails = (props) => {
    const { routerProps, statuses } = props;
    // const [data, setData] = useState<Order>()
    const { id } = routerProps.match.params;
    const onSuccess = (data) => {
        // if (data != undefined)
        // setData(data)
    };
    const onError = (error) => {
    };
    const onLoading = (isLoading) => { };
    //  useGet(`/trendutube/v1/admin-order-processing/orders/${id}/info`, {}, onSuccess, onError, onLoading)
    const setProgress = useProgressBar();
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { data, loadUrl, isFetching, reload } = useSaga(`/trendutube/v1/admin-order-processing/orders/${id}/shipment-items-list?status=${statuses}`, { headers }, false);
    useEffect(() => {
        setProgress(isFetching);
    }, [isFetching]);
    return _jsx("div", { children: data ? _jsx(OrderComponent, Object.assign({}, { order: data, reload }), void 0) : _jsx("div", {}, void 0) }, void 0);
};
