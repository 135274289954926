import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { StyleProvider, useStyles } from "@exprzn/react-style-lib";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Col } from "../../../components/Column";
import { DragDrop } from "../../../components/DragDrop";
import { Field, Form } from "../../../components/Form";
import { Radio, RadioButtonItem } from "../../../components/Form/fields-extended-radio";
import { useProgressBar } from "../../../components/ProgressBar";
import { Row } from "../../../components/Row";
import { SagaProvider } from "../../../saga/saga-context";
import { useSaga } from "../../../saga/use-saga";
import { useSubmit } from "../../../saga/use-submit";
import container from "./style";
import { LabelledField, LabelledNewField } from "../../../components/Form/labelled-field";
import { useHistory } from "react-router-dom";
export const CategoryEditInternal = (props) => {
    const { uid } = props.match.params;
    const { isFetching, reload, data } = useSaga(`/trendutube/v1/public/categories/${uid}/info?sub_category=false`, {}, false);
    const loading = useProgressBar();
    const progress = useProgressBar();
    useEffect(() => loading(isFetching), [isFetching]);
    const { category, rowstyle, button } = useStyles("category", "rowstyle", "button");
    const { submit, isFetching: isProgress, error, status } = useSubmit();
    const submitHandler = (formData) => {
        var _a;
        // formData.category.gravity = Number(formData.category.gravity)
        submit(`/trendutube/v1/admin/categories/${uid}/update`, {
            body: Object.assign(Object.assign({}, formData.category), { parent_category_uid: (_a = data === null || data === void 0 ? void 0 : data.ancestor) === null || _a === void 0 ? void 0 : _a.uid }),
        });
    };
    useEffect(() => { progress(isProgress); }, [isProgress]);
    const history = useHistory();
    useEffect(() => {
        if (status == "ok") {
            toast.success("updated category");
            history.replace('/dashboard/categories/list');
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    function handleImageSubmit([image]) {
        reload();
    }
    return (_jsxs("div", Object.assign({ css: category }, { children: [_jsx(Row, { children: _jsx("div", Object.assign({ css: { fontSize: "1.1em", fontWeight: 600, marginBottom: 16 } }, { children: data && (data === null || data === void 0 ? void 0 : data.ancestor) ? "Sub Category update" : "Category Update" }), void 0) }, void 0), _jsxs(Row, { children: [_jsx(Col, Object.assign({ top: true }, { children: data != null ?
                            _jsxs(Form, Object.assign({ name: "category", onSubmit: submitHandler }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ css: {
                                                    height: 384,
                                                    border: "1px solid #d2d2d2",
                                                    borderRadius: "8px",
                                                    marginRight: 10,
                                                    paddingLeft: 10,
                                                } }, { children: [_jsx(LabelledField, { label: (data === null || data === void 0 ? void 0 : data.ancestor) ? "Sub Category Name" : "Category Name", name: "category_name", value: data.category_name }, void 0), _jsx(LabelledField, { label: (data === null || data === void 0 ? void 0 : data.ancestor) ? "Sub Category Description" : "Category Description", name: "category_description", value: data.category_description }, void 0), _jsxs("div", Object.assign({ css: rowstyle }, { children: [_jsx("div", { children: "List Stores or Sub Categories " }, void 0), _jsx("div", Object.assign({ css: { marginTop: 10, fontSize: 16 } }, { children: _jsx(Field, Object.assign({ name: "listing_type", value: data.listing_type }, { children: _jsxs(Radio, Object.assign({ name: "listing_type", align: "end", value: data.listing_type }, { children: [_jsx(RadioButtonItem, { value: "products", label: "Products or Sub Category" }, void 0), _jsx(RadioButtonItem, { value: "stores", label: "Stores" }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0), _jsx(LabelledField, { name: "gravity", value: data.gravity, label: "Position" }, void 0)] }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx("input", { type: "submit", value: "Update Category", css: button }, void 0) }, void 0)] }), void 0) : null }), void 0), _jsx(Col, Object.assign({ top: true }, { children: data && data.image ?
                            _jsx("div", Object.assign({ css: {
                                    height: 384,
                                    marginRight: 10,
                                    padding: 20,
                                    justifySelf: "top",
                                    border: "1px solid #d2d2d2",
                                    borderRadius: "8px",
                                } }, { children: _jsx("div", { css: {
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        borderRadius: "8px",
                                        backgroundPosition: "center",
                                        backgroundImage: `url(${data.image.url})`, width: "100%", height: "100%",
                                    } }, void 0) }), void 0)
                            : null }), void 0), _jsx(Col, Object.assign({ top: true }, { children: _jsx(DragDrop, { entity: "category", onImagesUpload: handleImageSubmit, uid: uid, exclusive: true }, void 0) }), void 0)] }, void 0)] }), void 0));
};
export const CategorynewEditInternal = (props) => {
    const { uid, pageReload, handleCloseDrawer } = props;
    const { isFetching, reload, data } = useSaga(`/trendutube/v1/public/categories/${uid}/info?sub_category=false`, {}, false);
    const loading = useProgressBar();
    const progress = useProgressBar();
    useEffect(() => loading(isFetching), [isFetching]);
    const { category, rowstyle, button } = useStyles("category", "rowstyle", "button");
    const { submit, isFetching: isProgress, error, status } = useSubmit();
    const submitHandler = (formData) => {
        var _a;
        // formData.category.gravity = Number(formData.category.gravity)
        submit(`/trendutube/v1/admin/categories/${uid}/update`, {
            body: Object.assign(Object.assign({}, formData.category), { parent_category_uid: (_a = data === null || data === void 0 ? void 0 : data.ancestor) === null || _a === void 0 ? void 0 : _a.uid }),
        });
    };
    useEffect(() => { progress(isProgress); }, [isProgress]);
    const history = useHistory();
    useEffect(() => {
        if (status == "ok") {
            toast.success("updated category");
            pageReload();
            handleCloseDrawer();
        }
    }, [status]);
    useEffect(() => {
        if (error && error.message)
            toast.error(`Error:${error.message}`);
    }, [error]);
    function handleImageSubmit([image]) {
        reload();
    }
    return (_jsxs("div", Object.assign({ style: { marginTop: '50px' } }, { children: [_jsx(Row, { children: _jsx(Col, Object.assign({ top: true }, { children: data != null ?
                        _jsxs(Form, Object.assign({ name: "category", onSubmit: submitHandler }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", Object.assign({ css: {
                                                border: "0.6px solid #045998",
                                                borderRadius: "8px",
                                                marginRight: 10,
                                                paddingLeft: 10,
                                            } }, { children: [_jsx(LabelledNewField, { label: (data === null || data === void 0 ? void 0 : data.ancestor) ? "Sub Category Name" : "Category Name", name: "category_name", value: data.category_name }, void 0), _jsx(LabelledNewField, { label: (data === null || data === void 0 ? void 0 : data.ancestor) ? "Sub Category Description" : "Category Description", name: "category_description", value: data.category_description }, void 0), _jsxs("div", Object.assign({ style: { padding: '10px' } }, { children: [_jsx("div", Object.assign({ style: { color: 'gray' } }, { children: "List Stores or Sub Categories " }), void 0), _jsx("div", Object.assign({ css: { marginTop: 10, fontSize: 13 } }, { children: _jsx(Field, Object.assign({ name: "listing_type", value: data.listing_type }, { children: _jsxs(Radio, Object.assign({ name: "listing_type", align: "start", value: data.listing_type }, { children: [_jsx(RadioButtonItem, { value: "products", label: "Products or Sub Category" }, void 0), _jsx(RadioButtonItem, { value: "stores", label: "Stores" }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0), _jsx(LabelledNewField, { name: "gravity", value: data.gravity, label: "Position" }, void 0)] }), void 0) }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ style: { padding: '10px' } }, { children: _jsx("input", { type: "submit", value: "Update Category", style: { backgroundColor: '#045998', border: '1px solid #045998', color: 'white', padding: '5px' } }, void 0) }), void 0) }, void 0)] }), void 0) : null }), void 0) }, void 0), _jsx(Row, { children: _jsx(Col, { children: data && data.image ?
                        _jsx("div", Object.assign({ css: {
                                height: 384,
                                border: "1px solid #d2d2d2",
                                borderRadius: "8px",
                            } }, { children: _jsx("div", { css: {
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "8px",
                                    backgroundPosition: "center",
                                    backgroundImage: `url(${data.image.url})`, width: "100%", height: "100%",
                                } }, void 0) }), void 0)
                        : null }, void 0) }, void 0), _jsx(Row, { children: _jsx("div", Object.assign({ style: { marginTop: '10px', width: "100%" } }, { children: _jsx(DragDrop, { entity: "category", onImagesUpload: handleImageSubmit, uid: uid, exclusive: true }, void 0) }), void 0) }, void 0)] }), void 0));
};
export const CategoryEdit = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(CategoryEditInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
