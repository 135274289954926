import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useProgressBar } from "../../components/ProgressBar";
import { useSubmit } from "../../saga/use-submit";
import { formatCurrency, formatTime, paymentModeToHumanReadable, paymentStatusToHumanReadable } from "../../utils";
import { BuyerComponent } from "../views/buyer";
import { getNextPossibleOrderStatuses, orderStatusToHumanReadable } from "./status";
import { OrderStatusView } from "./status-view";
import { ShipmentItemComponent } from "./order-shipment";
import { Box, Paper, styled, Typography } from "@mui/material";
import './style.css';
export const OrderComponent = (props) => {
    const { order, reload } = props;
    const { productstyle } = useStyles("productstyle");
    const [expanded, setExpanded] = useState(true);
    const { id, order_date, status, delivery_slot, customer_uid, total_amount, total_shipping_charge, total_discount_amount, payment_mode, last_payment_status, payment_order_id, order_id, delivery_items, shipping_gst_amount, shipping_gst_rate, shipping_gst_enable_flag, tip_amount, net_amount_with_tips, updated_dateTime, buyer, shipments, shipping_address_info } = order;
    console.log("order", shipments, order.id, shipping_address_info);
    const { submit, isFetching, error, status: reqStatus } = useSubmit();
    const setLoading = useProgressBar();
    const { personal_profile, login_profile, buyer_profile } = buyer || {};
    const { first_name, last_name, full_name } = personal_profile || {};
    const { address } = buyer_profile || {};
    const { username } = login_profile || {};
    const buyerInfo = { first_name, last_name, full_name, address, username };
    useEffect(() => setLoading(isFetching), [isFetching]);
    useEffect(() => {
        if (error)
            toast.error(error.message);
    }, [error]);
    useEffect(() => {
        if (reqStatus == "ok") {
            toast.success("Order Item Status Updated", {});
        }
    }, [reqStatus]);
    const onSelectItem = (stat, index) => {
        const { status } = stat || {};
        submit(`/trendutube/v1/admin-order-processing/orders/${id}/update?status=${status}`, {});
    };
    const nextStatuses = getNextPossibleOrderStatuses(order);
    nextStatuses.push({ status: order.status, readable: orderStatusToHumanReadable(status) });
    const Item = styled(Paper)(({ theme }) => (Object.assign(Object.assign(Object.assign({ backgroundColor: '#fff' }, theme.typography.body2), { padding: theme.spacing(1), textAlign: 'center', border: 'None', boxShadow: 'None', color: theme.palette.text.secondary }), theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }))));
    return _jsxs("div", Object.assign({ css: Object.assign(Object.assign({}, productstyle), { padding: '40px' }) }, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsx(Typography, Object.assign({ sx: { opacity: 0.5 } }, { children: "Order Id" }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 } }, { children: [_jsx(Typography, Object.assign({ sx: { fontWeight: 400, fontSize: '22px', color: '#000000' } }, { children: order_id }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center', mr: 2 } }, { children: [_jsx(Typography, Object.assign({ sx: { opacity: 0.5 } }, { children: "Updated Date:" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                    fontWeight: 400,
                                                    color: '#000000',
                                                    marginLeft: '10px',
                                                } }, { children: formatTime(order_date) }), void 0)] }), void 0), _jsx(Box, Object.assign({ sx: {
                                            backgroundColor: '#04599833',
                                            padding: '4px 8px',
                                            borderRadius: '4px',
                                        } }, { children: _jsx(Typography, Object.assign({ sx: { fontWeight: 600, color: '#045998' } }, { children: OrderStatusView(status) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', flexDirection: 'column', border: '1px solid #CDDBE0', marginTop: '15px', padding: '15px', borderRadius: '10px' } }, { children: [_jsx(Typography, Object.assign({ sx: { fontWeight: 500 } }, { children: "Pricing" }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 } }, { children: [_jsxs(Item, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                    fontWeight: 500,
                                                    marginRight: '8px', // Space between label and amount
                                                } }, { children: "Net Amount (with gst) :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                    color: '#0F8537'
                                                } }, { children: formatCurrency(net_amount_with_tips) }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                    fontWeight: 500,
                                                    marginRight: '8px', // Space between label and amount
                                                } }, { children: "Shipping Gst Amount :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                    color: '#000000'
                                                } }, { children: formatCurrency(shipping_gst_amount) }), void 0)] }), void 0)] }, void 0), _jsxs(Item, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                    fontWeight: 500,
                                                    marginRight: '8px', // Space between label and amount
                                                } }, { children: "Total Amount :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                    color: '#000000'
                                                } }, { children: formatCurrency(total_amount) }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                    fontWeight: 500,
                                                    marginRight: '8px', // Space between label and amount
                                                } }, { children: "Total Shipping Charge :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                    color: '#000000'
                                                } }, { children: formatCurrency(total_shipping_charge) }), void 0)] }), void 0)] }, void 0), _jsxs(Item, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                    fontWeight: 500,
                                                    marginRight: '8px', // Space between label and amount
                                                } }, { children: "Total Discount :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                    color: '#000000'
                                                } }, { children: formatCurrency(total_discount_amount) }), void 0)] }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                    fontWeight: 500,
                                                    marginRight: '8px', // Space between label and amount
                                                } }, { children: "Tip Amount :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                    color: '#000000'
                                                } }, { children: formatCurrency(tip_amount) }), void 0)] }), void 0)] }, void 0)] }), void 0), _jsx(Typography, Object.assign({ sx: { fontWeight: 500 } }, { children: "Payment Method " }), void 0), _jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 } }, { children: [_jsx(Item, { children: _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                fontWeight: 500,
                                                marginRight: '8px', // Space between label and amount
                                            } }, { children: "Payment Mode :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                color: '#000000'
                                            } }, { children: paymentModeToHumanReadable(payment_mode) }), void 0)] }), void 0) }, void 0), _jsx(Item, { children: _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                fontWeight: 500,
                                                marginRight: '8px', // Space between label and amount
                                            } }, { children: "Payment Order Id :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                color: '#000000',
                                            } }, { children: payment_order_id ? payment_order_id : '---' }), void 0)] }), void 0) }, void 0), _jsx(Item, { children: _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: [_jsx(Typography, Object.assign({ sx: {
                                                fontWeight: 500,
                                                marginRight: '8px', // Space between label and amount
                                            } }, { children: "Last Payment Status :" }), void 0), _jsx(Typography, Object.assign({ sx: {
                                                color: '#000000'
                                            } }, { children: paymentStatusToHumanReadable(last_payment_status) }), void 0)] }), void 0) }, void 0)] }), void 0)] }), void 0), _jsx(BuyerComponent, { buyer: buyerInfo, shipping_address_info: shipping_address_info }, void 0), _jsx("div", Object.assign({ css: { padding: "8px", marginTop: "10px", borderRadius: "3px" } }, { children: expanded ?
                    _jsx("div", { children: shipments ? shipments.map(shipment => _jsx(ShipmentItemComponent, Object.assign({}, { shipment, order_id: order.id, reload }), void 0)) : null }, void 0) : null }), void 0)] }), void 0);
};
