import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AddressComponent = (props) => {
    const { address, multiline = false } = props;
    const { address_line1, address_line2, city, pin_code, landmark, location } = address || {};
    const fullAddress = [address_line1,
        address_line2,
        city,
        pin_code,
        landmark].join(", ");
    const line1 = [address_line1, address_line2].join(", ");
    const line2 = [city, pin_code].join(", ");
    const line3 = [landmark].join(", "); //future proof :-)
    return _jsx("div", { children: multiline ?
            _jsxs("div", { children: [_jsx("div", { children: "Address" }, void 0), _jsx("div", { children: line1 }, void 0), _jsx("div", { children: line2 }, void 0), _jsx("div", { children: line3 }, void 0)] }, void 0) :
            _jsx("div", Object.assign({ css: { fontSize: ".909rem", fontWeight: 500 } }, { children: fullAddress }), void 0) }, void 0);
};
