import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from "@exprzn/react-style-lib";
import { useRef, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { toast } from "react-toastify";
import { DragDrop, DragDropVideo } from "../../../components/DragDrop";
import { Field, Form } from "../../../components/Form";
import { Input } from "../../../components/Form/fields-extended";
import { X } from 'react-feather';
import { SwitchInput } from "../../../components/SwitchInput";
export const ProductVariantEditComponent = (props) => {
    const { product, isOpen, onClose, updateProductVariant, productVariantUid, onImageDelete, onVideoDelete, updateVariantListing, onReloadProduct } = props;
    return _jsx("div", { children: _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isOpen, title: "Edit Product Variant", subtitle: "", width: "30%", onRequestClose: () => {
                onClose(false);
            } }, { children: _jsx("div", { children: _jsx(ProductVariantEdit, { productVariantUid: productVariantUid, product: product, onReloadProduct: onReloadProduct, updateProductVariant: updateProductVariant, updateVariantListing: updateVariantListing, onImageDelete: onImageDelete, onVideoDelete: onVideoDelete }, void 0) }, void 0) }), void 0) }, void 0);
};
export const ProductVariantEdit = (props) => {
    const { product, updateProductVariant, productVariantUid, onImageDelete, onVideoDelete, updateVariantListing, onReloadProduct } = props;
    const variant = product.variants.find(v => v.product_variant_uid == productVariantUid);
    const { product_variant_uid, product_variant_price, product_variant_stock, variant_listing_flag, variant_value_inst } = variant || {};
    const { variant_types = [], uid } = product;
    const [variation, setVariation] = useState();
    const [image_uids, setImageUids] = useState([]);
    const [video_uids, setVideoUids] = useState([]);
    const ref = useRef(null);
    const [nocloud_image_uids, setNoCloudImageUids] = useState([]);
    const [nocloud_video_uids, setNoCloudVideoUids] = useState([]);
    const onChange = (value) => {
        setVariation(Object.assign(Object.assign({}, variation), value));
    };
    const onSubmit = (formData) => {
        const { product_variant } = formData || {};
        const { product_variant_price, product_variant_stock, variant_listing_flag } = product_variant || {};
        if (Object.keys(variation || {}).length != variant_types.length)
            toast.error("Please choose all types");
        else if (!product_variant_price)
            toast.error("Please specify price");
        else if (!product_variant_stock)
            toast.error("Please specify stock");
        else {
            const body = { variation, product_variant_price, product_variant_stock, image_uids, variant_listing_flag, video_uids };
            updateProductVariant(productVariantUid, body);
            // alert(JSON.stringify(body))
        }
    };
    const reWriteUrl = (im) => (nocloud_image_uids === null || nocloud_image_uids === void 0 ? void 0 : nocloud_image_uids.findIndex(item => item == im.uid)) > -1 ? `${im.url}?nocloud=1` : im.url;
    const reWriteVideoUrl = (im) => (nocloud_video_uids === null || nocloud_video_uids === void 0 ? void 0 : nocloud_video_uids.findIndex(item => item == im.uid)) > -1 ? `${im.url}?nocloud=1` : im.url;
    const { button } = useStyles("button");
    const handleSwitchInput = (pvariant_uid) => {
        // alert(pvariant_uid)
        return (event) => {
            // alert(pvariant_uid)
            updateVariantListing(pvariant_uid, event.target.checked);
        };
    };
    return _jsxs("div", { children: [variant_types.map(variantType => _jsx(VariantRadioComponent, { onChange: onChange, variantType: variantType }, void 0)), _jsx("div", { children: _jsxs(Form, Object.assign({ name: "product_variant", onSubmit: onSubmit }, { children: [_jsx("div", Object.assign({ css: { marginTop: 20 } }, { children: "Product Variant Price" }), void 0), _jsx(Field, Object.assign({ name: "product_variant_price", value: product_variant_price }, { children: _jsx(Input, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: { marginTop: 20 } }, { children: "Product Variant Stock" }), void 0), _jsx(Field, Object.assign({ name: "product_variant_stock", value: product_variant_stock }, { children: _jsx(Input, {}, void 0) }), void 0), _jsx("div", Object.assign({ css: { fontSize: 14, fontWeight: 600, marginTop: 10 } }, { children: "Is Product Variant Listed ?" }), void 0), _jsx("div", { children: _jsx(SwitchInput, { value: variant_listing_flag === true, backgroundColor: "#f8b200", onChange: handleSwitchInput(product_variant_uid || "") }, void 0) }, void 0), _jsx("button", Object.assign({ css: { display: "none" }, ref: ref }, { children: "Save" }), void 0)] }), void 0) }, void 0), _jsxs("div", { children: [_jsx("div", Object.assign({ css: { marginTop: 20, fontSize: 18, fontWeight: 600, color: "#222" } }, { children: "Product Variant Images" }), void 0), _jsxs("div", Object.assign({ css: { marginTop: 10 } }, { children: [_jsx(DragDrop, { onImagesUpload: (images) => {
                                    onReloadProduct();
                                }, entity: "product_variant", uid: variant === null || variant === void 0 ? void 0 : variant.product_variant_uid }, void 0), _jsx("div", Object.assign({ css: { display: 'flex', justifyContent: "space-between", flexWrap: "wrap", overflow: "hidden", border: "1px solid #bababa", padding: "1em", borderRadius: ".5em" } }, { children: variant && (variant.images || []).map(image => _jsxs("div", Object.assign({ css: { marginBottom: "10px", height: 124, width: 124, position: "relative" } }, { children: [_jsx("img", { height: 124, width: 124, css: { borderRadius: "6px" }, src: reWriteUrl(image) }, void 0), _jsx("div", Object.assign({ onClick: onImageDelete(image.uid), css: {
                                                position: "absolute", padding: 3, right: 8, bottom: 8, height: 20,
                                                borderRadius: 4, backgroundColor: "#222", cursor: "pointer",
                                                display: "flex", alignContent: "center"
                                            } }, { children: _jsx(X, { size: 14, color: "white" }, void 0) }), void 0)] }), void 0)) }), void 0)] }), void 0), _jsx("div", Object.assign({ css: { marginTop: 20, fontSize: 18, fontWeight: 600, color: "#222" } }, { children: "Product Variant Videos" }), void 0), _jsxs("div", Object.assign({ css: { marginTop: 10 } }, { children: [((variant === null || variant === void 0 ? void 0 : variant.videos) || []).length == 0 && _jsx(DragDropVideo, { onVideoUpload: (videos) => {
                                    onReloadProduct();
                                }, entity: "product_variant", uid: variant === null || variant === void 0 ? void 0 : variant.product_variant_uid }, void 0), _jsx("div", Object.assign({ css: { display: 'flex', justifyContent: "space-between", flexWrap: "wrap", overflow: "hidden", border: "1px solid #bababa", padding: "1em", borderRadius: ".5em" } }, { children: variant && (variant.videos || []).length > 0 && (variant.videos || []).map(video => _jsxs("div", Object.assign({ css: { marginBottom: "10px", height: 124, width: 124, position: "relative" } }, { children: [_jsxs("video", Object.assign({ height: 124, width: 124, controls: true, css: { borderRadius: "6px" }, style: { objectFit: 'cover' } }, { children: [_jsx("source", { src: reWriteVideoUrl(video), type: "video/mp4" }, void 0), "Your browser does not support the video tag."] }), void 0), _jsx("div", Object.assign({ onClick: onVideoDelete(video.uid), css: {
                                                position: "absolute", padding: 3, right: 8, bottom: 8, height: 20,
                                                borderRadius: 4, backgroundColor: "#222", cursor: "pointer",
                                                display: "flex", alignContent: "center"
                                            } }, { children: _jsx(X, { size: 14, color: "white" }, void 0) }), void 0)] }), void 0)) }), void 0)] }), void 0)] }, void 0), _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "end" } }, { children: _jsx("button", Object.assign({ css: button, onClick: () => { var _a; (_a = ref.current) === null || _a === void 0 ? void 0 : _a.click(); } }, { children: "Save" }), void 0) }), void 0)] }, void 0);
};
export const VariantRadioComponent = (props) => {
    const { variantType, onChange } = props;
    const handleRadio = (e) => {
        onChange({ [variantType.variant_type_name]: e.currentTarget.value });
    };
    return _jsxs("div", { children: [_jsx("div", Object.assign({ css: { color: "#111", fontWeight: 600, fontSize: "18px", marginTop: 10 } }, { children: variantType.variant_type_name }), void 0), _jsxs("div", Object.assign({ css: { marginLeft: 120 } }, { children: [variantType.variant_type == "color" ?
                        (variantType.variant_values || []).map(variantValue => _jsx(ColorRadioComponent, { onChange: handleRadio, variantTypeName: variantType.variant_type_name, variantValue: variantValue }, void 0)) : null, variantType.variant_type == "string" ?
                        (variantType.variant_values || []).map(variantValue => _jsx(StringRadioComponent, { onChange: handleRadio, variantTypeName: variantType.variant_type_name, variantValue: variantValue }, void 0)) : null, variantType.variant_type == "image" ?
                        (variantType.variant_values || []).map(variantValue => _jsx(ImageRadioComponent, { onChange: handleRadio, variantTypeName: variantType.variant_type_name, variantValue: variantValue }, void 0)) : null] }), void 0)] }, void 0);
};
const ColorRadioComponent = (props) => {
    const { variantValue, variantTypeName, onChange } = props;
    return _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "start", alignItems: "center" } }, { children: [_jsx("input", { name: variantTypeName, value: variantValue.name, css: { marginRight: "12px" }, type: "radio", onChange: onChange }, void 0), _jsx("div", { css: {
                    width: 20,
                    height: 20,
                    fontWeight: 500,
                    margin: "4px 12px 4px 4px",
                    borderRadius: 4,
                    backgroundColor: variantValue.color
                } }, void 0), _jsx("div", { children: variantValue.name }, void 0)] }), void 0);
};
const StringRadioComponent = (props) => {
    const { variantValue, variantTypeName, onChange } = props;
    return _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "start", alignItems: "center" } }, { children: [_jsx("input", { name: variantTypeName, value: variantValue.name, css: { marginRight: "4px" }, onChange: onChange, type: "radio" }, void 0), _jsx("div", { children: variantValue.name }, void 0)] }), void 0);
};
const ImageRadioComponent = (props) => {
    const { variantValue, variantTypeName, onChange } = props;
    return _jsxs("div", Object.assign({ css: { display: "flex", justifyContent: "start", alignItems: "center" } }, { children: [_jsx("input", { name: variantTypeName, value: variantValue.name, css: { marginRight: "12px" }, type: "radio", onChange: onChange }, void 0), _jsx("div", { css: {
                    width: 20,
                    height: 20,
                    fontWeight: 500,
                    margin: "4px 12px 4px 4px",
                    borderRadius: 4,
                    backgroundImage: `url(${variantValue.image.url})`
                } }, void 0), _jsx("div", { children: variantValue.name }, void 0)] }), void 0);
};
