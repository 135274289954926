var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CssStyle, registry } from "@exprzn/react-style-lib";
const { style, container } = registry("dragdrop", true);
let DivContainerStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class DivContainerStyle extends CssStyle {
};
DivContainerStyle.style = {
    // width: "100%",
    height: "384px",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#F6F8F9",
    overflow: "hidden",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center"
};
DivContainerStyle = __decorate([
    style("divcontainer")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], DivContainerStyle);
let BrowseClickStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class BrowseClickStyle extends CssStyle {
};
BrowseClickStyle.style = {
    cursor: "pointer"
};
BrowseClickStyle = __decorate([
    style("browseclick")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], BrowseClickStyle);
let RemoveBtnStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RemoveBtnStyle extends CssStyle {
};
RemoveBtnStyle.style = {
    position: "relative",
    fontSize: "12px",
    fontWeight: "600",
    outline: "none",
    border: "0",
    "&:hover": {
        backgroundColor: "#FF5252",
    },
    "&:focus": {
        backgroundColor: "#FF5252",
    },
    color: "#f4f4ff",
    padding: ".8em 2em",
    borderRadius: ".5em",
    marginRight: "10px",
    backgroundColor: "#D16464",
    boxShadow: "1px 1px 8px #FF7575",
};
RemoveBtnStyle = __decorate([
    style("removebtn")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RemoveBtnStyle);
let ButtonStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ButtonStyle extends CssStyle {
};
ButtonStyle.style = {
    position: "relative",
    fontSize: "12px",
    fontWeight: "600",
    outline: "none",
    border: "0",
    "&:hover": {
        backgroundColor: "#6464c9",
    },
    "&:focus": {
        backgroundColor: "#6464c9",
    },
    color: "#f4f4ff",
    padding: ".8em 2em",
    borderRadius: ".5em",
    backgroundColor: "#045998",
    boxShadow: "none",
};
ButtonStyle = __decorate([
    style("button")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ButtonStyle);
let TitleRowStyle = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class TitleRowStyle extends CssStyle {
};
TitleRowStyle.style = {
    fontWeight: 400,
    color: "#888888",
    margin: "8px 0px 0px 0px",
    fontSize: ".7em",
};
TitleRowStyle = __decorate([
    style("titlestyle")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], TitleRowStyle);
export default container;
