import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Edit, Trash2 } from "react-feather";
import ReactSlidingPane from "react-sliding-pane";
import { SwitchInput } from "../../../components/SwitchInput";
import { VariantValueComponent } from "./variant-value";
export const ProductVariantComponent = (props) => {
    const { variants, isOpen, onClose, onEdit, onDelete, updateVariantListing } = props;
    return _jsx("div", { children: _jsx(ReactSlidingPane, Object.assign({ className: "some-custom-class", overlayClassName: "some-custom-overlay-class", isOpen: isOpen, title: "Product Variants", subtitle: "", width: "30%", onRequestClose: () => {
                onClose(false);
            } }, { children: _jsx("div", { children: (variants || []).map((variant, index) => _jsx(ProductVariant, { index: index, onDelete: onDelete, onEdit: onEdit, productVariant: variant, updateVariantListing: updateVariantListing }, void 0)) }, void 0) }), void 0) }, void 0);
};
export const ProductVariant = (props) => {
    const { productVariant, onEdit, onDelete, index, updateVariantListing } = props;
    const { product_variant_price, product_variant_stock, variant_listing_flag, product_variant_uid, variant_value_inst = [], images } = productVariant || {};
    const handleSwitchInput = (pvariant_uid) => {
        // alert(pvariant_uid)
        return (event) => {
            // alert(pvariant_uid)
            updateVariantListing(pvariant_uid, event.target.checked);
        };
    };
    return _jsxs("div", Object.assign({ css: { margin: "8px 8px 8px 0px", borderRadius: "4px", border: "1px solid #f5f5ff", position: "relative" } }, { children: [_jsxs("div", Object.assign({ css: {
                    cursor: "pointer",
                    padding: 10,
                    display: "flex",
                    justifyContent: "end"
                } }, { children: [_jsxs("div", Object.assign({ css: {
                            margin: "6px 10px", fontWeight: 600, color: "#fe3344",
                            display: "flex", justifyContent: "center", alignItems: "center"
                        }, onClick: () => {
                            onDelete(index, product_variant_uid);
                        } }, { children: [_jsx(Trash2, { size: 16 }, void 0), "\u00A0", _jsx("u", { children: "Delete" }, void 0)] }), void 0), _jsxs("div", Object.assign({ css: {
                            margin: "6px 10px", fontWeight: 600,
                            display: "flex", justifyContent: "center", alignItems: "center"
                        }, onClick: () => {
                            onEdit(index, product_variant_uid);
                        } }, { children: [_jsx(Edit, { size: 16 }, void 0), "\u00A0", _jsx("u", { children: "Edit  " }, void 0)] }), void 0)] }), void 0), _jsx("div", { children: images.map(image => _jsx("img", { css: { margin: "10px 10px 10px 10px", borderRadius: 8 }, width: 100, height: 100, src: image.url }, void 0)) }, void 0), _jsx(CardRow, { title: "Price", value: `Rs.${product_variant_price}` }, void 0), _jsx(CardRow, { title: "Stock", value: product_variant_stock }, void 0), _jsxs("div", Object.assign({ css: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "0px 10px",
                    marginTop: 10
                } }, { children: [_jsx("div", Object.assign({ css: { fontSize: 14, fontWeight: 600, marginTop: 10 } }, { children: "Is Product Variant Listed ?" }), void 0), _jsx("div", { children: _jsx(SwitchInput, { value: variant_listing_flag, backgroundColor: "#f8b200", onChange: handleSwitchInput(product_variant_uid) }, void 0) }, void 0)] }), void 0), _jsx("div", Object.assign({ css: { display: "flex", justifyContent: "start" } }, { children: variant_value_inst.map(instance => _jsx(VariantValueComponent, { instance: instance }, void 0)) }), void 0)] }), void 0);
};
export const CardRow = ({ title, value }) => _jsxs("div", Object.assign({ css: {
        padding: "0px 10px",
        display: "flex",
        justifyContent: "space-between",
        margin: 5
    } }, { children: [_jsx("div", Object.assign({ css: { fontSize: 14, fontWeight: 600 } }, { children: title }), void 0), _jsx("div", Object.assign({ css: { fontSize: 14, fontWeight: 600 } }, { children: `${value}` }), void 0)] }), void 0);
