var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { SearchOutlined } from "@ant-design/icons";
import { StyleableComponent, StyleProvider, styling, useStyles } from "@exprzn/react-style-lib";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { NavLink } from "react-router-dom";
import { Modal } from "../../../components/Modal";
import { Row } from "../../../components/Row";
import { SagaProvider } from "../../../saga/saga-context";
import { usePagination } from "../../../saga/use-pagination";
import { useSaga } from "../../../saga/use-saga";
import { useSubmit } from '../../../saga/use-submit';
import { useAuth } from "../../../use-auth";
import DeleteIcon from '@mui/icons-material/Delete';
import container from "./style";
import { toast } from "react-toastify";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { AddressComponent } from "../../views/address";
import CustomPagination from "../../Shipments/custompagination";
import DownloadIcon from '@mui/icons-material/Download';
export class InputSearch extends StyleableComponent {
    render() {
        const { onChange, value, errors } = this.props;
        const { errorStyle, input, rowwrap, searchbtn, inputwrap } = this;
        return (_jsx(Row, { children: _jsxs("div", Object.assign({ css: rowwrap }, { children: [_jsx("div", Object.assign({ css: searchbtn }, { children: _jsx(SearchOutlined, { style: { fontSize: "1.6em" } }, void 0) }), void 0), _jsx("div", Object.assign({ style: inputwrap }, { children: _jsx("input", { css: input, onChange: onChange, value: value }, void 0) }), void 0)] }), void 0) }, void 0));
    }
}
__decorate([
    styling("error"),
    __metadata("design:type", Object)
], InputSearch.prototype, "errorStyle", void 0);
__decorate([
    styling("input"),
    __metadata("design:type", Object)
], InputSearch.prototype, "input", void 0);
__decorate([
    styling("rowwrap"),
    __metadata("design:type", Object)
], InputSearch.prototype, "rowwrap", void 0);
__decorate([
    styling(),
    __metadata("design:type", Object)
], InputSearch.prototype, "searchbtn", void 0);
__decorate([
    styling(),
    __metadata("design:type", Object)
], InputSearch.prototype, "inputwrap", void 0);
export const DisplayModal = (props) => {
    const { sellerid, setAttr } = props;
    const { btnstyle, xbtnstyle, sellersstyle } = useStyles("btnstyle", "xbtnstyle", "sellersstyle");
    const [mycertificates, setCertificates] = useState([]);
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const [open, setOpen] = useState(false);
    const [certificateUid, setCertificateUid] = useState();
    const [certificates, setcertificates] = useState([]);
    const { data, loadUrl } = useSaga(`/trendutube/v1/seller-management/sellers/${sellerid}/certificates/list`, { headers }, false);
    //useEffect(() => { console.log('Cert Data', data); },[data])
    const { submit, error, status } = useSubmit();
    const deleteHandler = (uid) => () => {
        setCertificateUid(uid);
        setOpen(true);
    };
    useEffect(() => {
        if (error)
            toast.error(error.message);
    }, [error]);
    useEffect(() => {
        if (data) {
            const { certificates } = data || {};
            if (certificates) {
                setcertificates(certificates);
            }
        }
    }, [data]);
    useEffect(() => {
        if (status == "ok") {
            toast.success("Certificate Deleted", {});
            loadUrl(`/trendutube/v1/seller-management/sellers/${sellerid}/certificates/list`);
        }
    }, [status]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleDelete = () => {
        setOpen(false);
        submit(`/trendutube/v1/seller-management/sellers/${sellerid}/certificates/${certificateUid}/delete`, { headers, method: "DELETE" });
    };
    return (_jsxs(Modal, { children: [_jsxs("div", Object.assign({ style: {
                    backgroundColor: "white",
                    width: "500px",
                    paddingLeft: "2%",
                    maxHeight: "500px",
                    overflowY: "auto",
                    padding: '5%',
                    paddingTop: '0px'
                } }, { children: [_jsx("button", Object.assign({ style: {
                            position: 'fixed',
                            top: '10px',
                            right: '10px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer', // M
                        }, type: "button", onClick: () => setAttr(false) }, { children: _jsx(X, {}, void 0) }), void 0), certificates && certificates.map((item, index) => {
                        return (_jsx(Box, Object.assign({ sx: {
                                border: "1px solid gray",
                                borderRadius: "5px",
                                padding: "30px",
                                boxShadow: "none",
                                backgroundColor: "#fff",
                                margin: "auto",
                                marginTop: '15px'
                            } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(Typography, Object.assign({ variant: "body1", sx: {
                                                    color: '#B3B3C1',
                                                    fontSize: '14px',
                                                    marginBottom: '4px',
                                                    fontFamily: 'Montserrat',
                                                    lineHeight: 2,
                                                } }, { children: [_jsx("span", Object.assign({ style: { display: 'block', color: '#B3B3C1', fontWeight: 'bold' } }, { children: "Certificate Name:" }), void 0), _jsx("span", Object.assign({ style: { color: '#000' } }, { children: item.certificate_name }), void 0)] }), void 0), _jsxs(Typography, Object.assign({ variant: "body1", sx: {
                                                    color: '#B3B3C1',
                                                    fontSize: '14px',
                                                    marginBottom: '4px',
                                                    fontFamily: 'Montserrat',
                                                    lineHeight: 2,
                                                } }, { children: [_jsx("span", Object.assign({ style: { display: 'block', color: '#B3B3C1', fontWeight: 'bold' } }, { children: "Issuing Authority:" }), void 0), _jsx("span", Object.assign({ style: { color: '#000' } }, { children: item.issuing_authority }), void 0)] }), void 0), _jsxs(Typography, Object.assign({ variant: "body1", sx: {
                                                    color: '#B3B3C1',
                                                    fontSize: '14px',
                                                    marginBottom: '4px',
                                                    fontFamily: 'Montserrat',
                                                    lineHeight: 2,
                                                } }, { children: [_jsx("span", Object.assign({ style: { display: 'block', color: '#B3B3C1', fontWeight: 'bold' } }, { children: "Cerificate Expiry:" }), void 0), _jsx("span", Object.assign({ style: { color: '#000' } }, { children: item.certificate_expiry }), void 0)] }), void 0)] }), void 0), _jsxs(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: [_jsxs(Typography, Object.assign({ variant: "body1", sx: {
                                                    color: '#B3B3C1',
                                                    fontSize: '14px',
                                                    marginBottom: '4px',
                                                    fontFamily: 'Montserrat',
                                                    lineHeight: 2,
                                                } }, { children: [_jsx("span", Object.assign({ style: { display: 'block', color: '#B3B3C1', fontWeight: 'bold' } }, { children: "Certificate Type:" }), void 0), _jsx("span", Object.assign({ style: { color: '#000' } }, { children: item.certificate_type }), void 0)] }), void 0), _jsxs(Typography, Object.assign({ variant: "body1", sx: {
                                                    color: '#B3B3C1',
                                                    fontSize: '14px',
                                                    marginBottom: '4px',
                                                    fontFamily: 'Montserrat',
                                                    lineHeight: 2,
                                                } }, { children: [_jsx("span", Object.assign({ style: { display: 'block', color: '#B3B3C1', fontWeight: 'bold' } }, { children: "Certificate No:" }), void 0), _jsx("span", Object.assign({ style: { color: '#000' } }, { children: item.certificate_no }), void 0)] }), void 0), _jsx(Button, Object.assign({ onClick: handleClose, color: "primary" }, { children: _jsx(Tooltip, Object.assign({ title: "Delete Certificate", arrow: true }, { children: _jsx(DeleteIcon, { sx: { color: 'red' } }, void 0) }), void 0) }), void 0), _jsx(Button, Object.assign({ onClick: handleClose, color: "primary" }, { children: _jsx(Tooltip, Object.assign({ title: "Dowload Certificate", arrow: true }, { children: _jsx(DownloadIcon, { sx: { color: 'Green' } }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0)
                        // <div style={{
                        //   marginBottom: "5%",
                        // }}><Col>
                        //     <Row><Col cols={10}><strong>Certificate Name:</strong> {item.certificate_name}</Col><Col cols={2}><button css={btnstyle} onClick={deleteHandler(item.cert_uid)}>Delete</button></Col></Row>
                        //     <Row><strong>Certificate Type:</strong> {item.certificate_type}</Row>
                        //     <Row><strong>Issuing Authority:</strong> {item.issuing_authority}</Row>
                        //     <Row><strong>Certificate No:</strong> {item.certificate_no}</Row>
                        //     <Row><strong>Cerificate Expiry:</strong> {item.certificate_expiry}</Row>
                        //     <Row>{item.images && item.images.map((k, v) => { return (<Col cols={2}><a href={k.url} target="_blank">Download</a></Col>) })}</Row></Col>
                        //     </div>
                        );
                    })] }), void 0), _jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogTitle, Object.assign({ id: "alert-dialog-title" }, { children: "Certificate Delete Confirmation" }), void 0), _jsx(DialogContent, { children: _jsx(DialogContentText, Object.assign({ id: "alert-dialog-description" }, { children: "Are you sure you want to delete?." }), void 0) }, void 0), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: handleClose, color: "primary" }, { children: "Cancel" }), void 0), _jsx(Button, Object.assign({ onClick: handleDelete, color: "primary", autoFocus: true }, { children: "Delete" }), void 0)] }, void 0)] }), void 0)] }, void 0));
};
const SellerItem = (props) => {
    const { seller, index } = props;
    const { containerstyle, sellerrowstyle, btnstyle, xbtnstyle, button } = useStyles("containerstyle", "sellerrowstyle", "sellerrowstyle", "btnstyle", "xbtnstyle", "button");
    const { seller_name, seller_profile } = seller || {};
    const { username, email, full_name, addresses } = seller_profile || {};
    const [attrModal, setAttrModal] = useState(false);
    return (_jsxs(TableRow, Object.assign({ sx: { '& td, & th': { paddingLeft: '2%' } } }, { children: [_jsx(TableCell, { children: seller_name }, void 0), _jsx(TableCell, { children: full_name }, void 0), _jsx(TableCell, { children: email }, void 0), _jsx(TableCell, { children: seller_profile.addresses && seller_profile.addresses.length > 0 ? _jsx(AddressComponent, { multiline: false, address: seller_profile.addresses[0] }, void 0) : _jsx("div", Object.assign({ css: { color: 'red' } }, { children: "Not Available" }), void 0) }, void 0), _jsx(TableCell, { children: _jsx(Button, Object.assign({ variant: "contained", sx: { backgroundColor: '#045998', textTransform: 'none' }, size: "small", onClick: () => setAttrModal(true) }, { children: "Certificates" }), void 0) }, void 0), _jsx(TableCell, { children: _jsx(Button, Object.assign({ variant: "text", sx: { color: '#045998', textTransform: 'none' } }, { children: _jsxs(NavLink, Object.assign({ to: `/dashboard/sellers/${seller.uid}/stores/list` }, { children: ["View Stores (", seller.stores_count, ")"] }), void 0) }), void 0) }, void 0), _jsx(TableCell, { children: _jsx(Button, Object.assign({ variant: "text", sx: { color: '#045998', textTransform: 'none' } }, { children: _jsx(NavLink, Object.assign({ to: `/dashboard/sellers/edit/${seller.uid}` }, { children: "Edit" }), void 0) }), void 0) }, void 0), attrModal ? (_jsx(DisplayModal, { sellerid: seller.uid, setAttr: setAttrModal }, void 0)) : null] }), index)
    // <div css={sellerrowstyle}>
    //   <Row>
    //     <Col cols={[12, 2]}><NavLink to={`/dashboard/sellers/profile/${seller.uid}`}>{seller_name}</NavLink></Col>
    //     <Col cols={2}>{full_name}</Col>
    //     <Col cols={[12, 3]}>{email}</Col>
    //     <Col>{city}</Col>
    //     <Col>
    //       <button onClick={() => { console.log('Seller UID', seller.uid); setAttrModal(true) }} css={button} >
    //         Certificates
    //       </button>
    //     </Col>
    //     <Col>
    //       <NavLink to={`/dashboard/sellers/${seller.uid}/stores/list`}>
    //         View Stores ({seller.stores_count})
    //       </NavLink>
    //     </Col>
    //     <Col>
    //       <NavLink to={`/dashboard/sellers/edit/${seller.uid}`}>
    //         Edit Seller
    //       </NavLink>
    //     </Col>
    //   </Row>
    );
};
const SellerListInternal = (props) => {
    const { containerstyle, sellersstyle, productstyle, pagestyle, btnstyle, xbtnstyle } = useStyles("containerstyle", "sellersstyle", "productstyle", "pagestyle", "btnstyle", "xbtnstyle");
    const { access_token } = useAuth(true);
    const Authorization = `Bearer ${access_token}`;
    const headers = { Authorization };
    const { data, loadUrl, loadPage, paging } = usePagination("/trendutube/v1/admin-seller-management/sellers/list", { headers }, false);
    const handleInputChange = (event) => {
        const value = event.target.value;
        loadUrl(`/trendutube/v1/admin-seller-management/sellers/list?name=${value}`);
    };
    return (_jsxs("div", Object.assign({ style: { padding: '5px 19px' } }, { children: [_jsx(FormControl, Object.assign({ fullWidth: true, sx: { height: '60px', backgroundColor: 'white', padding: '10px', marginBottom: '15px' } }, { children: _jsx(TextField, { fullWidth: true, size: "small", variant: "outlined", placeholder: "Search", onChange: handleInputChange, InputProps: {
                        startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(SearchOutlined, {}, void 0) }), void 0)),
                    } }, void 0) }), void 0), _jsxs(TableContainer, Object.assign({ component: Paper }, { children: [_jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Seller Name" }, void 0), _jsx(TableCell, { children: "Owner Name" }, void 0), _jsx(TableCell, { children: "Email" }, void 0), _jsx(TableCell, { children: "Address" }, void 0), _jsx(TableCell, { children: "Certificates" }, void 0), _jsx(TableCell, { children: "View Stores" }, void 0), _jsx(TableCell, { children: "Edit Seller" }, void 0)] }, void 0) }, void 0), _jsx(TableBody, { children: data && data.map((seller, index) => (_jsx(SellerItem, { seller: seller, index: index }, void 0))) }, void 0)] }, void 0), _jsx(CustomPagination, { page: paging === null || paging === void 0 ? void 0 : paging.page, totalPages: paging === null || paging === void 0 ? void 0 : paging.total_pages, hasNext: (paging === null || paging === void 0 ? void 0 : paging.total_pages) > Number(paging === null || paging === void 0 ? void 0 : paging.page), onPageChange: loadPage }, void 0)] }), void 0)] }), void 0));
};
export const SellerList = (props) => (_jsx(SagaProvider, { children: _jsx(StyleProvider, Object.assign({ value: { container } }, { children: _jsx(SellerListInternal, Object.assign({}, props), void 0) }), void 0) }, void 0));
