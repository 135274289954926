import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { useStyles } from '@exprzn/react-style-lib';
import { X } from 'react-feather';
export const SelectRenderer = (props) => {
    const { tagstyle, tagbtnstyle } = useStyles("tagstyle", "tagbtnstyle");
    const { item, index, unselect } = props;
    return (_jsxs("div", Object.assign({ css: tagstyle }, { children: [_jsx("div", Object.assign({ css: { padding: "0px 8px" } }, { children: item.product_class_name }), void 0), _jsx("button", Object.assign({ css: tagbtnstyle, type: "button", onClick: () => {
                    unselect && unselect();
                    return false;
                } }, { children: _jsx(X, { size: 10 }, void 0) }), void 0)] }), void 0));
};
